import * as React from "react";
import DropdownButton, { DropdownButtonMenu, DropdownButtonMenuItem } from "components/Button/DropdownButton";
import ExternalLink from "components/Navigation/ExternalLink";
import { ActionButtonType } from "components/Button/ActionButton";
import { withTheme } from "components/Theme";

interface TentacleDownloadButtonProps {
    className?: string;
}

const TentacleDownloadButton: React.FC<TentacleDownloadButtonProps> = ({ className }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <DropdownButton open={open} onClick={() => setOpen(true)} onClose={() => setOpen(false)} type={ActionButtonType.Primary} className={className} label={"Download Tentacle"}>
            {renderProps => (
                <DropdownButtonMenu {...renderProps.getMenuProps()}>
                    <ExternalLink className={renderProps.getLinkClass()} showIcon={false} href="LatestTentacleWinX86">
                        <DropdownButtonMenuItem {...renderProps.getMenuItemProps()}>Windows 32-bit/x86 installer</DropdownButtonMenuItem>
                    </ExternalLink>
                    <ExternalLink className={renderProps.getLinkClass()} showIcon={false} href="LatestTentacleWinX64">
                        <DropdownButtonMenuItem {...renderProps.getMenuItemProps()}>Windows 64-bit/x64 installer</DropdownButtonMenuItem>
                    </ExternalLink>
                    <ExternalLink className={renderProps.getLinkClass()} showIcon={false} href="ProductDownloadPage">
                        <DropdownButtonMenuItem {...renderProps.getMenuItemProps()}>Download a custom version</DropdownButtonMenuItem>
                    </ExternalLink>
                </DropdownButtonMenu>
            )}
        </DropdownButton>
    );
};

export default TentacleDownloadButton;
