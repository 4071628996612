import { flow } from "lodash";
import { getResolver, getServerEndpoint, LocationProps } from "client/utils";

export { LocationProps };

const getEndpointResolver = (getLocation: () => LocationProps = () => window.location) => {
    return flow(getLocation, getServerEndpoint, getResolver)();
};

export const getPackageEndpoint = (getLocation: () => LocationProps = () => window.location) => {
    return getEndpointResolver(getLocation)("~/nuget/packages");
};

export const getRawPackageEndpoint = (getLocation: () => LocationProps = () => window.location) => {
    return getEndpointResolver(getLocation)("~/api/packages/raw");
};
