import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
const styles = require("./style.less");
import { RoleChip } from "components/Chips/index";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import routeLinks from "../../../../routeLinks";
import BusyIndicator from "components/BusyIndicator";
import { Section } from "components/Section/Section";
import { withTheme } from "components/Theme";

interface RolesCardProps {
    machineRoles: string[] | undefined;
}

interface RolesCardState extends DataBaseComponentState {
    filterText: string;
}

export default class RolesCard extends DataBaseComponent<RolesCardProps, RolesCardState> {
    constructor(props: RolesCardProps) {
        super(props);
        this.state = {
            filterText: "",
        };
    }

    render() {
        return withTheme(theme => {
            if (!this.props.machineRoles) {
                return this.props.machineRoles === undefined ? null : (
                    <div className={styles.card}>
                        <div className={styles.cardTitleContainer}>
                            <div className={styles.cardTitle}>Target Roles</div>
                        </div>
                        <Section>
                            <BusyIndicator inline={true} show={true} />
                        </Section>
                    </div>
                );
            }
            let machineRoles = this.props.machineRoles;
            machineRoles = machineRoles.filter(item => this.onFilter(this.state.filterText, item));
            return (
                <div className={styles.card}>
                    <div className={styles.cardTitleContainer}>
                        <div className={styles.cardTitle}>
                            Target Roles <span className={styles.cardCount}>({machineRoles.length.toLocaleString()})</span>
                        </div>
                    </div>
                    <div className={styles.cardContentContainer}>
                        {(this.props.machineRoles.length > 1 || this.state.filterText) && (
                            <div>
                                <FilterSearchBox
                                    placeholder="Filter..."
                                    onChange={value => this.setState({ filterText: value.toLowerCase() })}
                                    inputClassName={styles.dashboardFilterInputClassName}
                                    iconColor={theme.secondaryText}
                                    iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                                />
                            </div>
                        )}
                        {this.renderRolesList(machineRoles)}
                    </div>
                </div>
            );
        });
    }

    private onFilter(filter: string, role: string) {
        return !filter || filter.length === 0 || !role || role.toLowerCase().includes(filter.toLowerCase());
    }

    private renderRolesList(roles: string[]) {
        return roles.map(r => {
            return <RoleChip role={r} key={"role-" + r} to={routeLinks.infrastructure.machines.filtered({ roles: r })} />;
        });
    }
}
