import React from "react";
import { Permission } from "client/resources/permission";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { repository } from "clientInstance";
import { PackageReference } from "client/resources/packageReference";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import FeedResource from "client/resources/feedResource";

interface DisplayFeedNameProps {
    pkg: PackageReference;
}

interface DisplayFeedNameState extends DataBaseComponentState {
    feedResult: FeedResource | "Is Loading" | "No Permission";
}

export class DisplayFeedName extends DataBaseComponent<DisplayFeedNameProps, DisplayFeedNameState> {
    constructor(props: DisplayFeedNameProps) {
        super(props);
        this.state = {
            feedResult: "Is Loading",
        };
    }

    async componentDidMount() {
        if (!isAllowed({ permission: Permission.FeedView })) {
            this.setState({ feedResult: "No Permission" });
            return;
        }

        if (this.props.pkg.FeedId.includes("#{")) {
            return;
        }

        await this.doBusyTask(async () => {
            const feed = await repository.Feeds.get(this.props.pkg.FeedId);
            this.setState({ feedResult: feed });
        });
    }

    render() {
        const feedResult = this.state.feedResult;
        const pkg = this.props.pkg;

        if (feedResult === "Is Loading" || pkg.FeedId.includes("#{")) {
            return <em>{this.props.pkg.FeedId}</em>;
        } else if (feedResult === "No Permission") {
            return <strong>{`(Feed unknown: ${Permission.FeedView} permission required)`}</strong>;
        }

        return <strong>{feedResult.Name}</strong>;
    }
}
