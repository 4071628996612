import * as React from "react";
import Note from "../../form/Note/Note";

/**
 * The shared note displayed with the YAML edit button for the Kubernetes steps.
 */
export const getYAMLWarning = (
    <div>
        <Note>Directly edit the raw Kubernetes resource in YAML. This is intended to populated the step from existing YAML, or for the resource created by this step to be exported.</Note>
        <Note>
            Be aware that not every valid Kubernetes property is reflected in the UI, and unrecognized properties are ignored. Also note that the YAML here is parsed literally, and does not implement variable replacements. The{" "}
            <code>Deploy raw Kubernetes YAML</code> step can be used to deploy raw YAML without these restrictions.
        </Note>
    </div>
);
