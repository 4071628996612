import { clone } from "lodash";
import { SensitiveValue } from "../../../client/resources";

// Returns a reset-value function for use with bound-field directive
const getSensitiveResetValue = (model: undefined | SensitiveValue) => {
    // If not an object, then it wasn't a sensitive-value
    if (typeof model !== "object") {
        return () => {
            return { HasValue: false };
        };
    }

    const initial = clone(model);
    return () => {
        return clone(initial);
    };
};

export default getSensitiveResetValue;
