/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { MachineResource, TenantedDeploymentMode, NewMachineResource } from "client/resources";

export interface DeploymentTargetResource extends MachineResource {
    EnvironmentIds: string[]; //ReferenceCollection;
    Roles: string[]; //ReferenceCollection;
    TenantedDeploymentParticipation: TenantedDeploymentMode;
    TenantIds: string[]; //ReferenceCollection;
    TenantTags: string[]; //ReferenceCollection;
}

export interface NewDeploymentTargetResource extends NewMachineResource {
    EnvironmentIds: string[]; //ReferenceCollection;
    Roles: string[]; //ReferenceCollection;
    TenantedDeploymentParticipation: TenantedDeploymentMode;
    TenantIds: string[]; //ReferenceCollection;
    TenantTags: string[]; //ReferenceCollection;
}

export function isDeploymentTarget(machine: MachineResource): machine is DeploymentTargetResource {
    return (machine as DeploymentTargetResource).EnvironmentIds !== undefined;
}

export default DeploymentTargetResource;
