import React from "react";
import { SharedButtonProps } from "./types";
import ToolTip from "components/ToolTip";
import { default as MaterialIconButton } from "material-ui/IconButton";
import { useOctopusTheme } from "components/Theme";
import classnames from "classnames";
import { iconButtonTestId } from "uiTestIds";

const styles = require("./styles.less");

interface MaterialIconButtonWithToolTipProps extends SharedButtonProps {
    icon?: JSX.Element;
    className?: string;
}

export const MaterialIconButtonWithToolTip: React.FC<MaterialIconButtonWithToolTipProps> = ({ icon, disabled, tabIndex, buttonRef, className, style = {}, onClick, toolTipContent, ...otherProps }) => {
    const theme = useOctopusTheme();

    const button = (
        <MaterialIconButton
            octopus-test-id={iconButtonTestId}
            {...otherProps}
            className={classnames(styles.materialUIIcon, className)}
            disabled={disabled}
            tabIndex={tabIndex}
            onClick={onClick}
            ref={buttonRef}
            style={{ fill: theme.iconNeutral, ...style }}
        >
            {icon}
        </MaterialIconButton>
    );

    if (!toolTipContent) {
        return button;
    }

    return <ToolTip content={toolTipContent}>{button}</ToolTip>;
};
