import ToolTip from "../ToolTip";
import ExternalLink from "../Navigation/ExternalLink/ExternalLink";
import * as React from "react";
const styles = require("./style.less");
import { repository } from "clientInstance";
import CloseButton from "components/Button/CloseButton";

interface EAPBannerState {
    version: string;
}

interface EAPBannerProps {
    onClose(): void;
}

class EAPBanner extends React.Component<EAPBannerProps, EAPBannerState> {
    constructor(props: EAPBannerProps) {
        super(props);

        this.state = {
            version: "",
        };
    }

    componentDidMount() {
        const serverInfo = repository.getServerInformation();

        this.setState({
            version: serverInfo.version,
        });
    }

    onClose() {
        this.props.onClose();
    }

    render() {
        return (
            <div className={styles.eapBanner}>
                <ToolTip
                    content={
                        <div>
                            <p>Due to the unstable nature of EAP releases we do not recommend using them in production, or upgrading them to/from a production instance</p>
                        </div>
                    }
                >
                    <ExternalLink href="ProductDownloadPage" showIcon={false}>
                        Your self-hosted Octopus Server is running an early-access release: {this.state.version}
                    </ExternalLink>
                </ToolTip>

                <div className={styles.closeBanner}>
                    <CloseButton onClose={() => this.onClose()} />
                </div>
            </div>
        );
    }
}

export default EAPBanner;
