/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { SimpleList } from "components/List";
import { ReactNode } from "react";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import { UserRoleResource } from "client/resources/userRoleResource";
import { RouteComponentProps } from "react-router";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import SectionNote from "components/SectionNote/SectionNote";
import { LicenseStatusResource, PermissionsMode } from "client/resources";
import { RestrictedPermissionsCallout } from "components/RestrictedPermissionsCallout";

interface RolesState extends DataBaseComponentState {
    roles?: UserRoleResource[];
    licenseStatus?: LicenseStatusResource;
}

class RoleList extends SimpleList<UserRoleResource> {}

export class Roles extends DataBaseComponent<RouteComponentProps<{}>, RolesState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const roles = await repository.UserRoles.all();
            const licenseStatus = await repository.Licenses.getCurrentStatus();
            this.setState({
                roles,
                licenseStatus,
            });
        });
    }

    renderRole(role: UserRoleResource): ReactNode {
        return (
            <div key={role.Id}>
                <ListTitle>{role.Name}</ListTitle>
                <div>{role.Description}</div>
            </div>
        );
    }

    render() {
        const addButton = (
            <PermissionCheck permission={Permission.UserRoleEdit}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add Custom Role" href={routeLinks.configuration.roles.create} />
            </PermissionCheck>
        );
        return (
            <PaperLayout title="User Roles" busy={this.state.busy} errors={this.state.errors} sectionControl={addButton}>
                <RestrictedPermissionsCallout isVisible={(this.state.licenseStatus && this.state.licenseStatus.PermissionsMode === PermissionsMode.Restricted)!} />
                <RoleList
                    items={this.state.roles!}
                    onRowRedirectUrl={(role: UserRoleResource) => routeLinks.configuration.role(role.Id)}
                    onRow={this.renderRole}
                    match={this.props.match}
                    autoFocusOnFilterSearch={true}
                    onFilter={(filter, item) => item.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1}
                />
            </PaperLayout>
        );
    }
}

export default Roles;
