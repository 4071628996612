/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import KeyboardHandler, { Key } from "../KeyboardHandler/KeyboardHandler";
import { BaseComponent } from "../BaseComponent/BaseComponent";

export interface FormComponentProps {
    className?: string;
    onFormSubmit(): void;
}

export default class FormComponent extends BaseComponent<FormComponentProps, never> {
    constructor(props: FormComponentProps) {
        super(props);
    }

    render() {
        const keyboardRegistrations = [
            { key: Key.Enter, onKeyPressed: this.onEnter },
            { key: Key.CtrlEnter, onKeyPressed: this.onCtrlEnter },
        ];
        return (
            <div style={{ width: "100%" }} className={this.props.className}>
                <KeyboardHandler registrations={keyboardRegistrations} className={this.props.className}>
                    {this.props.children}
                </KeyboardHandler>
            </div>
        );
    }

    onEnter = (event: KeyboardEvent): boolean => {
        const target: any = event.target ? event.target : event.srcElement;
        const tagName = target.tagName;
        if (tagName.toUpperCase() === "INPUT") {
            this.props.onFormSubmit();
            return true;
        }
        return false;
    };

    onCtrlEnter = (event: KeyboardEvent) => {
        this.props.onFormSubmit();
        return true;
    };
}
