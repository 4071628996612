import * as React from "react";
import { Switch, RouteComponentProps, withRouter } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import { RedirectAs404 } from "components/NotFound/NotFound";
import ProcessStepsLayoutLoader from "../Process/ProcessStepsLayoutLoader";
import ProcessListLayoutLoader from "../Process/ProcessListLayoutLoader";
import { withRunbookProcess } from "../Process/Pages";
import { ProcessType } from "client/resources";

export const RunbookProcessStepsLayoutLoaderPage = withPage({ page: pageIds.project().runbook.process.root })(withRunbookProcess(ProcessStepsLayoutLoader));
export const RunbookProcessListLayoutLoaderPage = withPage({ page: pageIds.project().runbook.process.root })(withRunbookProcess(ProcessListLayoutLoader));

type ProcessRouteProps = {
    path: string;
};

type Props = ProcessRouteProps & RouteComponentProps<{ projectSlug: string; runbookId: string }>;

class RunbookProcessRoute extends React.Component<Props> {
    render() {
        return (
            <Switch>
                <ReloadableRoute path={`${this.props.path}/steps`} exact={true} render={props => <RunbookProcessStepsLayoutLoaderPage processType={ProcessType.Runbook} {...props} />} />
                <ReloadableRoute path={`${this.props.path}`} render={props => <RunbookProcessListLayoutLoaderPage processType={ProcessType.Runbook} {...props} />} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

const EnhancedRunbookProcessRoute = withRouter(RunbookProcessRoute);

export default EnhancedRunbookProcessRoute;
