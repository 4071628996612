import taskPageIds from "areas/tasks/components/TaskRoutes/taskPageIds";
import dashboardPageIds from "areas/dashboard/DashboardOverview/dashboardPageIds";
import projectPageIds from "areas/projects/components/ProjectsRoutes/projectPageIds";
import tenantPageIds from "areas/tenants/TenantsRoutes/tenantPageIds";
import projectGroupPageIds from "areas/projects/components/ProjectGroupLayout/projectGroupPageIds";
import deploymentPageIds from "areas/deployments/deploymentPageIds";
import userProfilePageIds from "areas/users/UserProfileRoutes/userProfilePageIds";
import libraryPageIds from "areas/library/components/LibraryRoutes/libraryPageIds";
import infrastructurePageIds from "areas/infrastructure/components/InfrastructureRoutes/infrastructurePageIds";
import configurationPageIds from "areas/configuration/components/ConfigurationRoutes/configurationPageIds";
import { pageId } from "utils/pageId";

const pageIds = parameterizedPageIds();
function parameterizedPageIds() {
    const root = "";
    return {
        root,
        dashboard: dashboardPageIds,
        ...projectPageIds(),
        ...tenantPageIds(),
        ...taskPageIds(),
        ...projectGroupPageIds(),
        ...deploymentPageIds(),
        currentUser: userProfilePageIds(),
        library: libraryPageIds(),
        infrastructure: infrastructurePageIds(),
        configuration: configurationPageIds(),
        styleGuide: pageId("StyleGuide", "Style guide"),
        uxGuide: pageId("UxGuide", "UX guide"),
    };
}

export default pageIds;
