import * as React from "react";
import { Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { MoreHoriz } from "@material-ui/icons";
import { useOctopusTheme, OctopusTheme } from "components/Theme";
import DialogTrigger from "components/Dialog/DialogTrigger";
import CommitDialog from "./CommitDialog";

interface PropsWithOctopusTheme {
    theme: OctopusTheme;
}

const useStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({
        borderRadius: 2,
        display: "inline-flex",
        height: "2.25rem",
        background: props.theme.primaryButtonBackground,
        boxShadow: `0px 1px 6px ${props.theme.shadow12}, 0px 1px 4px ${props.theme.shadow12}`,
    }),
    grouped: (props: PropsWithOctopusTheme) => ({
        borderRadius: 2,
        fontSize: "0.8125rem",
        background: props.theme.primaryButtonBackground,
        color: props.theme.primaryButtonText,
        "&:hover": {
            backgroundColor: "rgb(255, 255, 255, 0.08)",
        },
        "&:active": {
            boxShadow: `0px 3px 10px ${props.theme.shadow14}, 0px 3px 10px ${props.theme.shadow14}`,
        },
        "&:not(:first-child)": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        "&:not(:last-child)": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: `1px solid ${props.theme.successBackground}`,
        },
        padding: "9px 16px 6px",
    }),
}));

interface CommitButtonProps {
    branchName: string;
    defaultSummary: string;
    onCommit(message: string): Promise<boolean>;
}

const CommitButton: React.FC<CommitButtonProps> = props => {
    const [autoCommit, setAutoCommit] = React.useState<boolean>();
    const theme = useOctopusTheme();
    const classes = useStyles({ theme });
    // halve horizontal padding of '...' button to make it less pronounced
    const iconStyles = { paddingLeft: 8, paddingRight: 8 };

    return (
        <DialogTrigger
            render={({ open, openDialog, closeDialog }) => {
                const showDialog = (withAutoCommit: boolean) => {
                    setAutoCommit(withAutoCommit);
                    openDialog();
                };

                return (
                    <>
                        <div role="group" className={classes.root}>
                            <Button className={classes.grouped} onClick={() => showDialog(true)} title="Commit with default message">
                                Commit
                            </Button>
                            <IconButton className={classes.grouped} style={iconStyles} onClick={() => showDialog(false)} title="Enter a commit message">
                                <MoreHoriz />
                            </IconButton>
                        </div>
                        <CommitDialog branchName={props.branchName} defaultSummary={props.defaultSummary} onClose={closeDialog} open={open} onCommit={props.onCommit} autoCommit={autoCommit} />
                    </>
                );
            }}
        />
    );
};

export default CommitButton;
