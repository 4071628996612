import * as React from "react";
import { DocumentTypeResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { DocumentTypeChip } from "components/Chips/index";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";

interface DocumentTypeMultiSelectProps extends FormFieldProps<string[]> {
    items: DocumentTypeResource[];
    label?: string | JSX.Element;
    error?: string;
}

const DocumentTypeTypedMultiSelect = MultiSelect<DocumentTypeResource>();
const LookupDocumentTypeChip = LookupResourceChipComponent<DocumentTypeResource>();

const DocumentTypeMultiSelect: React.StatelessComponent<DocumentTypeMultiSelectProps> = props => {
    const chipRenderer = (r: DocumentTypeResource, onRequestDelete: (event: object) => void) => {
        return (
            <LookupDocumentTypeChip
                lookupCollection={props.items}
                lookupId={r.Id}
                type={ChipIcon.DocumentType}
                chipRender={item => <DocumentTypeChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} documentType={item} />}
            />
        );
    };

    return <DocumentTypeTypedMultiSelect items={props.items} fieldName="document types" renderChip={chipRenderer} {...props} />;
};

export default DocumentTypeMultiSelect;
