import * as React from "react";
import { SettingsMetadataItemResource } from "client/configuration/settingsMetadataResource";
import { ResourceCollection } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { SimpleList } from "../../../../components/List";
import { RouteComponentProps } from "react-router";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import ListTitle from "components/ListTitle/ListTitle";
import { ReactNode } from "react";
import routeLinks from "../../../../routeLinks";

interface SettingsState extends DataBaseComponentState {
    settingsResponse?: ResourceCollection<SettingsMetadataItemResource>;
}

class SettingsList extends SimpleList<SettingsMetadataItemResource> {}

export class Settings extends DataBaseComponent<RouteComponentProps<{}>, SettingsState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const settingsResponse = await repository.Settings.list();
            this.setState({ settingsResponse });
        });
    }

    render() {
        return (
            <PaperLayout title="Settings" busy={this.state.busy} errors={this.state.errors}>
                {this.state.settingsResponse && (
                    <SettingsList items={this.state.settingsResponse.Items} onRow={(item: SettingsMetadataItemResource) => this.buildSettingsRow(item)} onRowRedirectUrl={setting => routeLinks.configuration.setting(setting.Id)} />
                )}
            </PaperLayout>
        );
    }

    private buildSettingsRow(settingsMetadata: SettingsMetadataItemResource): ReactNode {
        return (
            <div>
                <ListTitle>{settingsMetadata.Name}</ListTitle>
                <div>{settingsMetadata.Description}</div>
            </div>
        );
    }
}
