/* eslint-disable no-eq-null */

import * as React from "react";
import { UserResource } from "client/resources/userResource";
import { RouteComponentProps } from "react-router";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { ResourceCollection } from "client/resources";
import Avatar from "components/Avatar";
import { ReactNode } from "react";
import { NavigationButton, NavigationButtonType } from "components/Button";
import PaperLayout from "components/PaperLayout/PaperLayout";
import List from "components/List";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import ActionList from "components/ActionList";
import { isOctopusIdEnabled } from "./identityProviders";
import { InviteOctoIdUserDialogLink } from "./InviteOctoIdUserDialogLink";
const styles = require("./style.less");

interface UsersState extends DataBaseComponentState {
    usersResponse?: ResourceCollection<UserResource>;
    isOctoIdEnabled?: boolean;
}

class UserList extends List<UserResource> {}

export class Users extends DataBaseComponent<RouteComponentProps<{}>, UsersState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const usersResponse = await repository.Users.list();
            this.setState({ usersResponse, isOctoIdEnabled: await isOctopusIdEnabled() });
        });
    }

    render() {
        return (
            <PaperLayout title="Users" busy={this.state.busy} errors={this.state.errors} sectionControl={this.createUserAction()}>
                {this.state.usersResponse && (
                    <UserList
                        initialData={this.state.usersResponse}
                        onRow={(item: UserResource) => this.buildUserRow(item)}
                        onFilter={this.filter}
                        filterSearchEnabled={true}
                        apiSearchParams={["filter"]}
                        match={this.props.match}
                        filterHintText="Filter by username or display name..."
                    />
                )}
            </PaperLayout>
        );
    }

    private createUserAction(): ReactNode {
        const regularActions = [
            <PermissionCheck permission={[Permission.UserEdit]}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add users" href={`${this.props.match.url}/create`} />
            </PermissionCheck>,
        ];

        const octoIdActions = [
            <PermissionCheck permission={[Permission.UserEdit]}>
                <NavigationButton type={NavigationButtonType.Secondary} label="Create service account" href={`${this.props.match.url}/create`} />
            </PermissionCheck>,
            <PermissionCheck permission={[Permission.UserEdit]}>
                <InviteOctoIdUserDialogLink label="Invite user" />
            </PermissionCheck>,
        ];

        const isOctoIdEnabled = this.state.isOctoIdEnabled;
        const actionSection = <ActionList actions={!!isOctoIdEnabled ? octoIdActions : regularActions} />;

        return isOctoIdEnabled != null ? actionSection : null;
    }

    private buildUserRow(user: UserResource): ReactNode {
        return (
            <div className={styles.row}>
                <div className={styles.avatar}>
                    <Avatar avatarLink={user && user.Links && user.Links.Avatar} isService={user && user.IsService} size={40} />
                </div>
                <div>
                    <ListTitle>{user.DisplayName}</ListTitle>
                    <div>{user.EmailAddress || user.Username}</div>
                </div>
            </div>
        );
    }

    private filter(filter: string, resource: UserResource) {
        return (
            !filter ||
            filter.length === 0 ||
            !resource ||
            resource.Username.toLowerCase().includes(filter.toLowerCase()) ||
            (resource.EmailAddress && resource.EmailAddress.toLowerCase().includes(filter.toLowerCase())) ||
            resource.DisplayName.toLowerCase().includes(filter.toLowerCase())
        );
    }
}

export default Users;
