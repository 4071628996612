import cn from "classnames";
import { DashboardItemResource, TaskState } from "client/resources";

const styles = require("./style.less");

interface IconStyleProps {
    State: TaskState;
    IsCompleted: boolean;
    HasPendingInterruptions: boolean;
    HasWarningsOrErrors: boolean;
    IsPrevious?: boolean;
    IsCurrent?: boolean;
}

export default (item: IconStyleProps, small: boolean = false) => {
    let stateClass: string = item.State;
    if (item.HasPendingInterruptions && !item.IsCompleted) {
        stateClass = "Paused";
    } else if (item.State === TaskState.Success && item.HasWarningsOrErrors) {
        stateClass = "SuccessWithWarnings";
    }

    let iconStyles = cn(styles.status, styles[stateClass], small ? styles.small : null);
    if (item.IsPrevious) {
        iconStyles = cn(iconStyles, "status-previous");
    } else if (item.IsCurrent === false && item.IsPrevious === false) {
        iconStyles = cn(iconStyles, "status-archive");
    }
    return iconStyles;
};

export { IconStyleProps };
