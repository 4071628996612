import * as React from "react";
const styles = require("./style.less");

interface ColorGroupProps {
    heading: string;
}

class ColorGroup extends React.Component<ColorGroupProps> {
    constructor(props: ColorGroupProps) {
        super(props);
    }

    render() {
        return (
            <div className={styles.colors}>
                <h4>{this.props.heading}</h4>
                {this.props.children}
            </div>
        );
    }
}
export default ColorGroup;
