import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { useLocation, useParams } from "react-router";
import PaperLayout from "components/PaperLayout";
import InternalRedirect from "components/Navigation/InternalRedirect";
import React from "react";

type BranchAwareRedirectProps = { children: React.ReactNode } & WithProjectContextInjectedProps;

const BranchAwareRedirectInternal: React.FC<BranchAwareRedirectProps> = props => {
    const location = useLocation();
    const { projectSlug, branchName } = useParams();

    if (!projectSlug) {
        throw new Error("Cannot use a BranchAwareRedirect where a projectslug route parameter does not exist");
    }

    let redirectTo = undefined;
    if (!branchName) {
        const project = props.projectContext.state.model;
        if (project.IsVersionControlled) {
            const branch = props.projectContext.state.branch;
            if (branch === undefined) {
                throw new Error("Cannot use BranchAwareRedirect without a Branch loaded into ProjectContext");
            }

            const path = location.pathname;
            const insertionIndex = path.indexOf("/", path.indexOf(projectSlug));
            const pathWithBranch = [path.slice(0, insertionIndex), `/branches/${branch.Name}`, path.slice(insertionIndex)].join("");
            redirectTo = { ...location, pathname: pathWithBranch };
        }
    }

    return redirectTo ? <InternalRedirect from={location.pathname} to={redirectTo} push={false} /> : <>{props.children}</>;
};

export const BranchAwareRedirect = withProjectContext(BranchAwareRedirectInternal);
