import * as React from "react";
import { ActionPlugin, ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import { ScriptActionEdit, ScriptProperties } from "../script/scriptAction";
import AwsLoginComponent, { AWSScriptProperties } from "./awsLoginComponent";
import { VariableLookupText } from "components/form/VariableLookupText";
import { AccountResource } from "../../../client/resources";
import FeedResource from "../../../client/resources/feedResource";
import ExpandableFormSection from "../../form/Sections/ExpandableFormSection";
import { Summary, StringRadioButtonGroup, RadioButton, FormSectionHeading } from "../../form";
import Note from "../../form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import { SupportedLanguage } from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";
import { TargetRoles } from "areas/projects/components/Process/types";
import { doesRunOnSupportBundledTools } from "areas/projects/components/Process/Common/CommonProcessHelpers";

export interface AWSScriptActionEditState {
    accounts: AccountResource[];
    feeds: FeedResource[];
}

interface AWSRunScriptProperties extends AWSScriptProperties, ScriptProperties {
    OctopusUseBundledTooling: string;
}

class AWSRunScriptActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>Log into AWS and run a script with the AWS CLI.</div>;
    }
}

export class AWSRunScriptActionEdit extends BaseComponent<ActionEditProps<AWSRunScriptProperties, ScriptPackageProperties>, AWSScriptActionEditState> {
    constructor(props: ActionEditProps<AWSRunScriptProperties, ScriptPackageProperties>) {
        super(props);
        this.state = {
            feeds: [],
            accounts: [],
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({
                feeds: await repository.Feeds.all(),
                accounts: await repository.Accounts.all(),
            });

            if (!this.props.properties["Octopus.Action.Aws.AssumeRole"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.AssumeRole"]: "False" }, true);
            }

            if (!this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]) {
                this.props.setProperties({ ["Octopus.Action.AwsAccount.UseInstanceRole"]: "False" }, true);
            }
        });
    }

    toolingSummary() {
        if (this.props.properties.OctopusUseBundledTooling === "False") {
            return Summary.summary("Use AWS tools pre-installed on the worker");
        }
        return Summary.summary("Use AWS tools bundled with Octopus");
    }

    render() {
        return (
            <div>
                {this.renderBundledToolsSection()}

                <FormSectionHeading title="Amazon Web Services" />

                <AwsLoginComponent
                    projectId={this.props.projectId}
                    properties={this.props.properties}
                    packages={this.props.packages}
                    plugin={this.props.plugin}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    getFieldError={this.props.getFieldError}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                />
                <ExpandableFormSection errorKey="Octopus.Action.Aws.Region" isExpandedByDefault={this.props.expandedByDefault} title="Region" summary={this.regionSummary()} help={"Specify the default region"}>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Aws.Region"]}
                        label="Region"
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Aws.Region"]: val })}
                        error={this.props.getFieldError("Octopus.Action.Aws.Region")}
                    />
                    <Note>
                        View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation for a current list of the available region codes.
                    </Note>
                </ExpandableFormSection>

                <ScriptActionEdit
                    plugin={this.props.plugin}
                    projectId={this.props.projectId}
                    packages={this.props.packages}
                    localNames={this.props.localNames}
                    properties={this.props.properties}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    getFieldError={this.props.getFieldError}
                    supportedLanguages={SupportedLanguage.PowerShellAndBash}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                    parameters={this.props.parameters}
                />
            </div>
        );
    }

    private renderBundledToolsSection = () => {
        const useBundled = this.props.properties.OctopusUseBundledTooling || "True";

        const bundledToolsSection = (
            <ExpandableFormSection
                errorKey="OctopusUseBundledTooling"
                isExpandedByDefault={this.props.expandedByDefault}
                title="AWS Tools"
                summary={this.toolingSummary()}
                help="Select whether to use the bundled AWS tools or use the tooling pre-installed on the worker"
            >
                <Note>This step depends on the AWS CLI.</Note>
                <Note>
                    For <strong>Linux workers the AWS CLI must be pre-installed</strong>. The options below do not apply.
                </Note>
                <Note>
                    When executing on a worker running Windows, Octopus server can supply the AWS CLI 1.16.178 (and the AWS PowerShell modules 3.3.390). Alternatively, to use a different version the AWS CLI should be pre-installed on the workers in
                    the worker pool.
                </Note>
                <StringRadioButtonGroup
                    value={useBundled}
                    onChange={(x: string) => {
                        this.props.setProperties({ OctopusUseBundledTooling: x });
                    }}
                >
                    <RadioButton value="True" label="Use AWS tools bundled with Octopus" />
                    <RadioButton value="False" label="Use AWS tools pre-installed on the worker" />
                </StringRadioButtonGroup>
            </ExpandableFormSection>
        );

        if (doesRunOnSupportBundledTools(this.props.runOn)) {
            return bundledToolsSection;
        }

        return null;
    };

    private regionSummary() {
        const properties = this.props.properties;

        if (properties["Octopus.Action.Aws.Region"]) {
            return Summary.summary(
                <span>
                    Using <strong>{properties["Octopus.Action.Aws.Region"]}</strong> as the default region
                </span>
            );
        }

        return Summary.placeholder("Specify the default AWS region");
    }
}

export default (): ActionPlugin => ({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AwsRunScript",
    summary: (properties, targetRolesAsCSV) => <AWSRunScriptActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: AWSRunScriptActionEdit,
    canHaveChildren: () => true,
    canBeChild: true,
    targetRoleOption: () => TargetRoles.Optional,
    hasPackages: () => false,
    features: {
        optional: ["Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
});
