/* eslint-disable @typescript-eslint/no-explicit-any */

import BasicRepository from "./basicRepository";
import Client from "../client";

class RetentionPolicyRepository extends BasicRepository<any, any> {
    constructor(client: Client) {
        super("RetentionPolicies", client);
    }
}

export default RetentionPolicyRepository;
