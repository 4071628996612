import * as React from "react";
import SvgImage, { SvgImageProps } from "../../SvgImage";
const styles = require("../../styles.less");

const AzureServiceFabric = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 58 70" title="Azure Service Fabric" {...props}>
        <g fillRule="nonzero" strokeWidth="1">
            <path
                className={styles.imgDark}
                d="M27.333 45.748V57.5c-1.169.431-2.155 1.04-2.958 1.826-.803.786-1.345 1.673-1.625 2.66H10.312c-.791.341-1.187.846-1.187 1.514s.396 1.165 1.188 1.491H22.75c1.162 3.006 3.141 4.509 5.938 4.509 2.796 0 4.736-1.503 5.819-4.509H46.75c1-.264 1.5-.761 1.5-1.491 0-.73-.5-1.234-1.5-1.514H34.507c-.272-1.098-.735-1.985-1.389-2.66-.653-.675-1.598-1.243-2.833-1.702V45.748h-2.952z"
            />
            <rect className={styles.imgCyan} width="57.41" height="48.35" x=".29" y=".25" rx="3.02" />
            <g className={styles.imgWhite} transform="translate(10 6)">
                <path d="M19 7.43l11.52 9.65-3.89 13.29H11.12L7.46 17.1 19 7.43zm0-4.11L3.89 16l4.85 17.55H29L34.11 16 19 3.32z" />
                <path d="M19 .29a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm12.09 9.07a6 6 0 1 1-.1 12 6 6 0 0 1 .1-12zm-3.03 15.11a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm-17.06 0a6 6 0 1 1-.1 12 6 6 0 0 1 .1-12zM6.91 9.36a6 6 0 1 1 0 12 6 6 0 0 1 0-12z" />
            </g>
        </g>
    </SvgImage>
);
export { AzureServiceFabric };
