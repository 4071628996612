import * as React from "react";
import { DataBaseComponent, DataBaseComponentState, Errors } from "components/DataBaseComponent/index";
import BusyFromPromise from "../BusyFromPromise";

interface RenderAlternateProps {
    busy: boolean;
    clearError: () => void;
    errors: Errors | undefined;
}

interface DataLoaderProps<TData> {
    load(): Promise<TData>;
    renderWhenLoaded(data: TData): React.ReactNode;
    renderAlternate(props: RenderAlternateProps): React.ReactNode;
}

interface DataLoaderState<TData> extends DataBaseComponentState {
    loadedData?: TData;
}

export default function DataLoader<TData>() {
    return class DataLoadInner extends DataBaseComponent<DataLoaderProps<TData>, DataLoaderState<TData>> {
        constructor(props: DataLoaderProps<TData>) {
            super(props);
            this.state = {};
        }

        async componentDidMount() {
            await this.doBusyTask(async () => {
                this.setState({
                    loadedData: await this.props.load(),
                });
            });
        }

        render() {
            return this.state.loadedData && !this.state.errors ? (
                this.props.renderWhenLoaded(this.state.loadedData)
            ) : (
                <BusyFromPromise promise={this.state.busy}>{(busy: boolean) => this.props.renderAlternate({ errors: this.state.errors, clearError: () => this.clearError(), busy })}</BusyFromPromise>
            );
        }
    };
}
