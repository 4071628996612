import * as React from "react";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { Errors } from "components/DataBaseComponent";
import * as H from "history";
import { match as Match } from "react-router-dom";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { useProjectContext } from "areas/projects/context";
import { useProcessContext } from "./Contexts/ProcessContext";
import { getPlaceholderActionList, getCommonOverflowMenuItems } from "./Common/CommonProcessHelpers";
import { EnhancedProcessContextPaperLayout } from "./CustomPaperLayouts/ProcessContextPaperLayout";
import { EnhancedProcessListPage } from "./Pages";
import { ProcessListLayoutLoaderLookupData } from "./ProcessListLayoutLoader";
import { useProcessErrorActions } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { ProcessType, Permission } from "client/resources";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";

interface ProcessPageProps {
    processType: ProcessType;
    lookups: ProcessListLayoutLoaderLookupData;
    errors?: Errors;
    busy?: Promise<void>;
    doBusyTask: DoBusyTask;
    history: H.History;
    location: H.Location;
    match: Match<ProjectRouteParams>;
    isBuiltInWorkerEnabled: boolean;
}

const ProcessListLayout: React.FC<ProcessPageProps> = props => {
    const processContext = useProcessContext();
    const { model: project, projectContextRepository } = useProjectContext().state;
    const runbookContext = useOptionalRunbookContext();
    const errorActions = useProcessErrorActions();

    const hasLoadedNecessaryLookupData = (): boolean => {
        const { actionTemplates, feeds } = processContext.lookupsState;
        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true });
        return actionTemplates && actionTemplates.length > 0 && (!feedViewPermissionGranted || (feedViewPermissionGranted && feeds && feeds.length > 0));
    };

    if (!processContext.selectors.isReady() || !hasLoadedNecessaryLookupData()) {
        return <EnhancedProcessContextPaperLayout processType={props.processType} busy={true} disableAnimations={true} overflowMenuItems={[]} sectionControl={getPlaceholderActionList(props.processType)} doBusyTask={props.doBusyTask} />;
    }

    const runbook = runbookContext?.state?.runbook;

    const overflowMenuItems = getCommonOverflowMenuItems(projectContextRepository, project, runbook, props.processType, processContext.selectors, processContext.actions, errorActions);
    const { busy, errors, lookups } = props;
    return (
        <EnhancedProcessContextPaperLayout processType={props.processType} busy={props.busy} errors={props.errors} overflowMenuItems={overflowMenuItems} doBusyTask={props.doBusyTask}>
            <EnhancedProcessListPage processType={props.processType} lookups={lookups} busy={busy} errors={errors} />
        </EnhancedProcessContextPaperLayout>
    );
};

export default React.memo(ProcessListLayout);
