import * as React from "react";
import ToolTip from "components/ToolTip/index";
import Chip from "../Chips/Chip";
import { tagClass } from "uiTestClasses";
import { LocationDescriptor } from "history";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { useRequiredContext } from "hooks";
import { OctopusThemeContext } from "components/Theme";
const styles = require("./styles.less");

interface CommonTagProps {
    description: string;
    tagName: string;
    tagColor: string;
    borderColor?: string;
    small?: boolean;
    showTooltip?: boolean;
    tabIndex?: number;
    to?: LocationDescriptor;
    onClick?: () => void;
}

interface DeletableTagProps extends CommonTagProps {
    onRequestDelete: (event: object) => void;
    deleteButtonAccessibleName: string;
}

type TagProps = CommonTagProps | DeletableTagProps;

function isDeletableTag(tagProps: TagProps): tagProps is DeletableTagProps {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (tagProps as DeletableTagProps).onRequestDelete !== undefined;
}

const Tag: React.FC<TagProps> = props => {
    const themeContext = useRequiredContext(OctopusThemeContext, "OctopusThemeContext");

    const { tagName, tagColor, tabIndex, description, onClick, borderColor, small = false } = props;
    const tagDescription = description ? tagName + ": " + description : "Tag: " + tagName;
    const deletableProps = isDeletableTag(props) ? { onRequestDelete: props.onRequestDelete, deleteButtonAccessibleName: props.deleteButtonAccessibleName } : {};

    if (!small) {
        const theTag = (
            <Chip className={tagClass} tabIndex={tabIndex} onClick={onClick} backgroundColor={tagColor} bordercolor={borderColor} description={tagDescription} labelColor={themeContext.whiteConstant} {...deletableProps}>
                {tagName}
            </Chip>
        );
        if (props.to) {
            return (
                <InternalLink to={props.to} className={styles.clickableTag}>
                    {theTag}
                </InternalLink>
            );
        }
        return theTag;
    }
    return (
        <ToolTip content={tagName}>
            <em className={`${"fa fa-square tag-swatch"} ${tagClass}`} style={{ color: tagColor, marginLeft: "0.0625rem", marginRight: "0.0625rem" }} />
        </ToolTip>
    );
};

export default Tag;
