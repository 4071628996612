import BasicRepository, { AllArgs } from "./basicRepository";
import Client from "../client";
import {
    RunbookResource,
    DeploymentPromotionTarget,
    RunbookSnapshotTemplateResource,
    RunbookRunPreviewResource,
    ResourceCollection,
    RunbookSnapshotResource,
    RunbookRunTemplateResource,
    EnvironmentResource,
    NewRunbookResource,
} from "client/resources";
import { RouteArgs } from "client/resolver";

type RunbookRepositoryListArgs = {
    skip?: number;
    take?: number;
    orderBy?: string;
} & RouteArgs;

type RunbookRepositoryAllArgs = {
    projectIds?: string[];
} & AllArgs;

class RunbookRepository extends BasicRepository<RunbookResource, NewRunbookResource, RunbookRepositoryListArgs, RunbookRepositoryAllArgs> {
    constructor(client: Client) {
        super("Runbooks", client);
    }
    getRunbookSnapshotTemplate(runbook: RunbookResource): Promise<RunbookSnapshotTemplateResource> {
        return this.client.get<RunbookSnapshotTemplateResource>(runbook.Links["RunbookSnapshotTemplate"]);
    }
    getRunbookRunTemplate(runbook: RunbookResource): Promise<RunbookRunTemplateResource> {
        return this.client.get<RunbookRunTemplateResource>(runbook.Links["RunbookRunTemplate"]);
    }
    getRunbookRunPreview(promotionTarget: DeploymentPromotionTarget) {
        return this.client.get<RunbookRunPreviewResource>(promotionTarget.Links["RunbookRunPreview"], { includeDisabledSteps: true });
    }
    getRunbookSnapshots(runbook: RunbookResource, args?: { skip?: number; take?: number } & RouteArgs): Promise<ResourceCollection<RunbookSnapshotResource>> {
        return this.client.get<ResourceCollection<RunbookSnapshotResource>>(runbook.Links["RunbookSnapshots"], args);
    }
    getRunbookEnvironments(runbook: RunbookResource): Promise<EnvironmentResource[]> {
        return this.client.get<EnvironmentResource[]>(runbook.Links["RunbookEnvironments"]);
    }
}

export default RunbookRepository;
