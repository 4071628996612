import * as React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/styles";
import { useCallback, useMemo } from "react";
import { useTabs, RenderTabCallback } from "./useTabs";
import { OctopusTheme, useOctopusTheme } from "components/Theme";

export interface ControlledTabComponentProps {
    value: string;
    afterTabsElement?: JSX.Element;
    onChange: (value: string) => void;
    renderTab?: RenderTabCallback;
}

interface PropsWithOctopusTheme {
    octopusTheme: OctopusTheme;
}

const useMuiStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({ color: props.octopusTheme.primary }),
    indicator: (props: PropsWithOctopusTheme) => ({ backgroundColor: props.octopusTheme.tabActiveIndicator }),
}));

const useTabStyles = makeStyles(() => ({
    tabsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));

const rootTabStyle: React.CSSProperties = {
    minWidth: "7.23rem", // Needed for DrawerWrapperLayout.
};

const ControlledTabsContainer: React.FC<ControlledTabComponentProps> = ({ onChange, afterTabsElement, renderTab, children, ...rest }) => {
    const octopusTheme = useOctopusTheme();

    const classes = useMuiStyles({ octopusTheme });
    const tabStyles = useTabStyles();

    const memoizedRenderTab = useMemo(() => {
        const defaultTabRender: RenderTabCallback = renderProps => <Tab style={rootTabStyle} {...renderProps} key={renderProps.value} />;
        return renderTab || defaultTabRender;
    }, [renderTab]);

    const { tabs, content, value } = useTabs(children, rest.value, memoizedRenderTab);

    const handleTabChange = useCallback(
        (event: React.ChangeEvent<{}>, tabValue: string) => {
            if (onChange) {
                onChange(tabValue);
            }
        },
        [onChange]
    );

    return (
        <React.Fragment>
            <div className={tabStyles.tabsContainer}>
                <Tabs classes={classes} value={value} onChange={handleTabChange} variant="scrollable" scrollButtons="off">
                    {tabs}
                </Tabs>
                {afterTabsElement}
            </div>
            {content}
        </React.Fragment>
    );
};

export default ControlledTabsContainer;
