import * as React from "react";
import { renderWithLayout } from "components/RenderWithLayout/RenderWithLayout";
import { withPage } from "components/Page/Page";
import { Switch, RouteComponentProps } from "react-router";
import ReloadableRoute from "components/ReloadableRoute";
import { RedirectAs404 } from "components/NotFound/NotFound";
import routeLinks from "routeLinks";
import pageIds from "pageIds";
import { UxGuide } from ".";
import UxGuidelinesLayout from "./UxGuidelinesLayout";
import UxGuidelinesOverview from "./UxGuidelines/UxGuidelinesOverview";
import UxPatternsOverview from "./UxPatterns/UxPatternsOverview";
import UxPatternsLayout from "./UxPatternsLayout";
import UxPatternsWhatever from "./UxPatterns/UxPatternsWhatever";
import UxGuidelinesCheatSheet from "./UxGuidelines/UxGuidelinesCheatSheet";
import UxGuidelines from "./UxGuidelines/UxGuidelines";

const UxGuidelinesOverviewPage = renderWithLayout(UxGuidelinesLayout)(UxGuidelinesOverview);
const UxGuidelinesPage = renderWithLayout(UxGuidelinesLayout)(UxGuidelines);
const UxGuidelinesCheatSheetPage = renderWithLayout(UxGuidelinesLayout)(UxGuidelinesCheatSheet);

const UxPatternsOverviewPage = renderWithLayout(UxPatternsLayout)(UxPatternsOverview);
const UxPatternsWhateverPage = renderWithLayout(UxPatternsLayout)(UxPatternsWhatever);

const UxGuidePage = withPage({ page: pageIds.uxGuide })(UxGuide);

export class UxRoutes extends React.Component<RouteComponentProps<void>> {
    render() {
        return (
            <Switch>
                <ReloadableRoute path={routeLinks.uxGuide.guidelines.root}>
                    <Switch>
                        <ReloadableRoute exact={true} path={routeLinks.uxGuide.guidelines.cheatsheet} component={UxGuidelinesCheatSheetPage} />
                        <ReloadableRoute exact={true} path={routeLinks.uxGuide.guidelines.guidelines} component={UxGuidelinesPage} />
                        <ReloadableRoute exact={true} path={[routeLinks.uxGuide.guidelines.overview, routeLinks.uxGuide.guidelines.root]} component={UxGuidelinesOverviewPage} />
                        <RedirectAs404 />
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.uxGuide.patterns.root}>
                    <Switch>
                        <ReloadableRoute exact={true} path={routeLinks.uxGuide.patterns.whatever} component={UxPatternsWhateverPage} />
                        <ReloadableRoute exact={true} path={[routeLinks.uxGuide.patterns.overview, routeLinks.uxGuide.patterns.root]} component={UxPatternsOverviewPage} />
                        <RedirectAs404 />
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.uxGuide.root} component={UxGuidePage} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

export default UxRoutes;
