import BasicRepository, { AllArgs } from "./basicRepository";
import Client from "../client";
import { OctopusPackageVersionBuildInformationMappedResource } from "client/resources";

type BuildInformationListArgs = {
    take?: number;
    skip?: number;
    filter?: string;
    packageId?: string;
    latest?: boolean;
};

type BuildInformationGetArgs = {};

class BuildInformationRepository extends BasicRepository<OctopusPackageVersionBuildInformationMappedResource, OctopusPackageVersionBuildInformationMappedResource, BuildInformationListArgs, AllArgs, BuildInformationGetArgs> {
    constructor(client: Client) {
        super("BuildInformation", client);
    }

    deleteMany(ids: string[]) {
        return this.client.del(this.client.getLink("BuildInformationBulk"), null, { ids });
    }
}

export default BuildInformationRepository;
