/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { cloneDeep, compact } from "lodash";
import FormFieldProps from "../FormFieldProps";
import { SensitiveValue } from "client/resources/propertyValueResource";
import Text, { TextInput } from "components/form/Text/Text";
import { Icon, default as IconButton } from "components/IconButton/IconButton";
import IconButtonList from "components/IconButtonList/IconButtonList";
import FlatButton from "material-ui/FlatButton";
import { default as MaterialIconButton } from "material-ui/IconButton";
import InputWithActions from "components/InputWithActions/InputWithActions";
import { withBoundField } from "components/form/BoundField/BoundField";
import { IncludeVariableSubstitutionWarning } from "components/form/BoundField/IncludeVariableSubstitutionWarning";

export interface SensitiveOtherProps {
    autoFocus?: boolean;
    placeholder?: string;
    underlineShow?: boolean;
    canRemove?: boolean;
    applyMaxWidth?: boolean;
    showHideTabIndex?: number;
    changeSetTabIndex?: number;
    cancelTabIndex?: number;
    removeTabIndex?: number;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    monoSpacedFont?: boolean;
}

export interface SensitiveState {
    valueBeforeEditing?: SensitiveValue;
    originalValue: SensitiveValue;
    isEditing: boolean;
    isHidden: boolean;
}

export const ObfuscatedPlaceholder = "********";

export function createInitialSensitiveState(originalValue: SensitiveValue | null): SensitiveState {
    const value: SensitiveValue = originalValue || {
        HasValue: false,
    };
    return {
        isEditing: !value.HasValue,
        isHidden: true,
        originalValue: cloneDeep(value),
    };
}

type SensitiveProps = SensitiveOtherProps & FormFieldProps<SensitiveValue>;

class StatefulSensitive extends React.Component<FormFieldProps<SensitiveValue> & SensitiveOtherProps, SensitiveState> {
    constructor(props: FormFieldProps<SensitiveValue> & SensitiveOtherProps) {
        super(props);
        this.state = createInitialSensitiveState(props.value!);
    }

    render() {
        return <StatelessSensitive onStateChanged={(state: SensitiveState) => this.setState(state)} {...this.props} {...this.state} />;
    }
}

interface ExtraStatelessSensitiveProps {
    onStateChanged(changedState: SensitiveState): void;
}

export type StatelessSensitiveProps = SensitiveProps & SensitiveState & ExtraStatelessSensitiveProps;

export class StatelessSensitive extends React.Component<StatelessSensitiveProps> {
    static defaultProps: Partial<SensitiveProps> = {
        autoFocus: false,
        canRemove: true,
        applyMaxWidth: false,
    };

    private changeButton: FlatButton | MaterialIconButton | undefined;
    private textField: TextInput | null = null;

    componentDidUpdate(prevProps: StatelessSensitiveProps) {
        if (this.props.isEditing && !prevProps.isEditing && this.textField) {
            this.textField.focus();
        }
    }

    select() {
        if (this.props.isEditing) {
            if (this.textField && !this.textField.isFocused()) {
                this.textField.select();
            }
        } else {
            if (this.changeButton) {
                (this.changeButton as MaterialIconButton).button!.button!.focus();
            }
        }
    }

    render() {
        const currentValue = this.currentValue();
        const {
            onChange,
            value,
            valueBeforeEditing,
            removeTabIndex,
            changeSetTabIndex,
            showHideTabIndex,
            cancelTabIndex,
            canRemove,
            originalValue,
            isEditing,
            isHidden,
            placeholder: placeholder,
            onStateChanged,
            applyMaxWidth,
            warning,
            ...otherProps
        } = this.props;

        // isEditing is used when you don't originally have a value, otherwise its value is meaningless
        // The reason we don't use this all the time is because we don't necessarily want the field to focus
        // when isEditingDerived becomes true, for example, after you have deleted the value
        const isEditingDerived = isEditing || !originalValue.HasValue;
        const placeholderDerived = currentValue.HasValue && !isEditingDerived ? ObfuscatedPlaceholder : placeholder ? placeholder : "";
        const newValue = currentValue.NewValue ? currentValue.NewValue : "";
        const displayValue = newValue;

        return (
            <InputWithActions
                input={
                    <Text
                        disabled={!isEditingDerived}
                        type={isHidden ? "password" : "text"}
                        placeholder={placeholderDerived}
                        value={displayValue}
                        onChange={this.handleChange}
                        applyMaxWidth={applyMaxWidth}
                        underlineShow={this.props.underlineShow}
                        warning={warning}
                        textInputRef={textField => (this.textField = textField)}
                        usePlaceholderAsLabel={true} // Special case for sensitive fields.
                        {...otherProps}
                    />
                }
                actions={<IconButtonList buttons={this.buttons(isEditingDerived, currentValue)} />}
                applyMaxWidth={applyMaxWidth}
            />
        );
    }

    private handleChange = (value: string) => {
        this.notifyChange({
            HasValue: true,
            NewValue: value,
        });
    };

    private currentValue = (): SensitiveValue => {
        return (
            this.props.value || {
                HasValue: false,
            }
        );
    };

    private onStateChanged<K extends keyof SensitiveState>(newState: Pick<SensitiveState, K> & object) {
        const previousState = {
            valueBeforeEditing: this.props.valueBeforeEditing,
            originalValue: this.props.originalValue,
            isEditing: this.props.isEditing,
            isHidden: this.props.isHidden,
        };
        this.props.onStateChanged({
            ...previousState,
            ...(newState as SensitiveState),
        });
    }

    private notifyChange(value: SensitiveValue) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    private enterEditMode = () => {
        const valueBeforeEditing = cloneDeep(this.currentValue());
        this.onStateChanged({ isEditing: true, valueBeforeEditing });
        this.notifyChange({
            HasValue: valueBeforeEditing.HasValue,
            NewValue: "",
        });
    };

    private handleRemove = () => {
        const originalValue: SensitiveValue = {
            HasValue: false,
            NewValue: undefined,
        };
        this.onStateChanged({ isEditing: false, originalValue });
        this.notifyChange(originalValue);
    };

    private handleCancel = () => {
        this.onStateChanged({ isEditing: false });
        this.notifyChange(this.props.valueBeforeEditing!);
    };

    private toggleVisibility = () => {
        this.onStateChanged({ isHidden: !this.props.isHidden });
    };

    private buttons(isEditing: boolean, currentValue: SensitiveValue) {
        const buttons: Array<React.ReactElement | null> = [];
        buttons.push(
            isEditing && currentValue.NewValue ? <IconButton toolTipContent={this.props.isHidden ? "Show" : "Hide"} icon={this.props.isHidden ? Icon.Show : Icon.Hide} tabIndex={this.props.showHideTabIndex} onClick={this.toggleVisibility} /> : null
        );
        buttons.push(
            !isEditing && this.props.originalValue.HasValue ? (
                <IconButton
                    toolTipContent={currentValue.HasValue ? "Change" : "Set"}
                    buttonRef={(changeButton: FlatButton | MaterialIconButton) => (this.changeButton = changeButton)}
                    onClick={this.enterEditMode}
                    tabIndex={this.props.changeSetTabIndex}
                    icon={Icon.Edit}
                />
            ) : null
        );
        buttons.push(!isEditing && currentValue.HasValue && this.props.canRemove ? <IconButton toolTipContent="Remove" onClick={this.handleRemove} tabIndex={this.props.removeTabIndex} icon={Icon.Remove} /> : null);
        buttons.push(isEditing && this.props.originalValue.HasValue ? <IconButton toolTipContent="Cancel" onClick={this.handleCancel} tabIndex={this.props.cancelTabIndex} icon={Icon.Cancel} /> : null);
        return compact(buttons);
    }
}

const Sensitive = StatefulSensitive;
export default Sensitive;

export const BoundSensitive = withBoundField<SensitiveValue, SensitiveOtherProps>(IncludeVariableSubstitutionWarning(StatefulSensitive));
