/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import { repository } from "clientInstance";
import { TeamResource, TeamMembership, UserResource } from "client/resources";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import { SimpleList } from "components/List";
import { MemberRow } from "./MemberRow";
import ListTitle from "components/ListTitle";
import { ProjectedTeamDescription } from "../ProjectedTeamsList/ProjectedTeamsList";
const projectedTeamStyles = require("../ProjectedTeamsList/ProjectedTeamsList.less");

interface UserMember {
    user: UserResource;
    membership: TeamMembership;
}

interface AllMembersProps {
    team: TeamResource;
}

interface AllMembersState extends DataBaseComponentState {
    userMembers: UserMember[];
}

class AllMembers extends DataBaseComponent<AllMembersProps, AllMembersState> {
    constructor(props: AllMembersProps) {
        super(props);
        this.state = { userMembers: [] };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const memberships = await repository.TeamMembership.previewTeam(this.props.team);
            const users = await repository.Users.all({ ids: memberships.map(m => m.UserId) });
            const userMembers = memberships.map(membership => ({ user: users.find(u => u.Id === membership.UserId)!, membership }));

            this.setState({
                userMembers,
            });
        });
    }

    render() {
        return (
            <InfoDialogLayout title="All Members" errors={this.state.errors} busy={this.state.busy}>
                <SimpleList
                    items={this.state.userMembers}
                    onRow={userMember => (
                        <ListTitle>
                            <MemberRow member={userMember.user} />
                            <div className={projectedTeamStyles.externalGroups}>
                                <ProjectedTeamDescription isDirectAssignment={userMember.membership.IsDirectlyAssigned} externalGroups={userMember.membership.ExternalSecurityGroups} />
                            </div>
                        </ListTitle>
                    )}
                    onFilter={(filter, userMember) => !filter || filter === "" || !userMember || userMember.user.DisplayName.toLowerCase().includes(filter.toLowerCase())}
                    filterHintText="Filter by name..."
                />
            </InfoDialogLayout>
        );
    }
}

export default AllMembers;
