/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import * as _ from "lodash";
import { ActionPlugin, ActionEditProps } from "components/Actions/pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionSummaryProps } from "components/Actions/actionSummaryProps";
import { ActionExecutionLocation, GetPrimaryPackageReference, InitialisePrimaryPackageReference, RemovePrimaryPackageReference, SetPrimaryPackageReference } from "client/resources";
import { default as FormSectionHeading } from "components/form/Sections/FormSectionHeading";
import { ExpandableFormSection, Summary } from "components/form";
import { AccountResource } from "client/resources";
import { AWSScriptProperties, default as AwsLoginComponent } from "./awsLoginComponent";
import FeedResource from "client/resources/feedResource";
import { default as CodeEditor, TextFormat } from "components/CodeEditor/CodeEditor";
import CommonSummaryHelper from "components/../utils/CommonSummaryHelper/CommonSummaryHelper";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import Note from "components/form/Note/Note";
import RadioButton from "components/form/RadioButton/RadioButton";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import PackageSelector from "components/PackageSelector/PackageSelector";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import { repository } from "clientInstance";
import Roles from "components/Actions/Roles";
import { TargetRoles } from "areas/projects/components/Process/types";
import { DataContext, TypeMetadata, MetadataTypeCollection } from "client/resources/dynamicFormResources";
import DynamicForm from "components/DynamicForm/DynamicForm";
import { VariableLookupText } from "components/form/VariableLookupText";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import SourceCodeDialog from "components/SourceCodeDialog/SourceCodeDialog";
import ChangesetFeature from "./awsCloudFormationChangesetFeature";
import { JsonUtils } from "utils/jsonUtils";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Chip from "components/Chips/Chip";
import { ActionWithFeeds, LoadFeedsFromOptionalContext } from "../commonActionHelpers";
import { withOptionalProcessContext, WithOptionalProcessContextInjectedProps } from "areas/projects/components/Process/Contexts/ProcessContext";

class AwsCloudFormationActionSummary extends BaseComponent<ActionSummaryProps, any> {
    render() {
        return (
            <div>
                Deploy an AWS CloudFormation template
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

interface AwsParameter {
    ParameterKey: string;
    ParameterValue: any;
}

const knownCapabilities = [
    { Id: "CAPABILITY_IAM", Name: "The template has IAM resources (CAPABILITY_IAM)" },
    { Id: "CAPABILITY_NAMED_IAM", Name: "The template has IAM resources with custom names (CAPABILITY_NAMED_IAM)" },
    { Id: "CAPABILITY_AUTO_EXPAND", Name: "The template contains macros (CAPABILITY_AUTO_EXPAND)" },
];

const CapabilityMultiselect = MultiSelect<SelectItem>();

interface AwsCloudFormationProperties extends AWSScriptProperties {
    "Octopus.Action.Aws.CloudFormationStackName": string;
    "Octopus.Action.Aws.TemplateSource": string;
    "Octopus.Action.Aws.CloudFormationTemplate": string;
    "Octopus.Action.Aws.CloudFormationTemplateParametersRaw": string;
    "Octopus.Action.Aws.CloudFormationTemplateParameters": string;
    "Octopus.Action.Aws.WaitForCompletion": string;
    "Octopus.Action.Aws.IamCapabilities": string;
    "Octopus.Action.Aws.DisableRollback": string;
    "Octopus.Action.Aws.CloudFormation.RoleArn": string;
}

interface AwsCloudFormationActionEditState extends ActionWithFeeds {
    accounts: AccountResource[];
    capabilities: string[];
    parameterTypes?: TypeMetadata[];
    parameterValues?: any;
}

type Props = ActionEditProps<AwsCloudFormationProperties> & WithOptionalProcessContextInjectedProps;

class AwsCloudFormationActionEditInternal extends BaseComponent<Props, AwsCloudFormationActionEditState> {
    parameterValues: {};
    parameters: {};
    source: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            feeds: [],
            accounts: [],
            capabilities: [],
        };
        this.parameterValues = {};
        this.parameters = {};
        this.source = { octopus: "octopus" };
    }

    async componentDidMount() {
        await LoadFeedsFromOptionalContext(this.props.doBusyTask, this.props.processContext, (feeds: FeedResource[]) => {
            this.setState({ feeds });
        });

        await this.props.doBusyTask(async () => {
            if (!this.props.properties["Octopus.Action.Aws.WaitForCompletion"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.WaitForCompletion"]: "True" }, true);
            }

            if (!this.props.properties["Octopus.Action.Aws.AssumeRole"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.AssumeRole"]: "False" }, true);
            }

            if (!this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]) {
                this.props.setProperties({ ["Octopus.Action.AwsAccount.UseInstanceRole"]: "False" }, true);
            }

            if (!this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParameters"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationTemplateParameters"]: "" }, true);
            }

            if (!this.props.properties["Octopus.Action.Aws.TemplateSource"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.TemplateSource"]: "Inline" }, true);
            }

            // If CloudFormationTemplateParametersRaw isn't set, default it to the value of
            // CloudFormationTemplateParameters. This accounts for cases where people have
            // populated CloudFormationTemplateParameters and upgraded to an Octopus version that
            // expects the UI input to be saved in CloudFormationTemplateParametersRaw.
            if (!this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]) {
                this.props.setProperties(
                    {
                        ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParameters"],
                    },
                    true
                );
            }

            this.setState({
                accounts: await repository.Accounts.all(),
            });
        });
    }

    async componentWillMount() {
        if (this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"] && this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Inline") {
            await this.refreshParametersFromTemplate(this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]);
        }

        if (this.props.properties["Octopus.Action.Aws.IamCapabilities"]) {
            this.setState({ capabilities: JSON.parse(this.props.properties["Octopus.Action.Aws.IamCapabilities"]) });
        }
    }

    templateSourceSummary() {
        const source = this.props.properties["Octopus.Action.Aws.TemplateSource"];
        if (source === "Inline") {
            return Summary.summary("Source code");
        }
        if (source === "Package") {
            return Summary.summary("File inside a package");
        }
        return Summary.placeholder("Template source not specified");
    }

    pathsSummary() {
        const summary = [];

        this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]
            ? summary.push(
                  <span>
                      Using the template in <strong>{this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]}</strong>
                  </span>
              )
            : summary.push(
                  <span>
                      {" "}
                      <em>no template path specified</em>
                  </span>
              );

        this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]
            ? summary.push(
                  <span>
                      {" "}
                      with the parameters in <strong>{this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]}</strong>
                  </span>
              )
            : summary.push(
                  <span>
                      {" "}
                      <em>no parameters path specified</em>
                  </span>
              );

        return Summary.summary(React.Children.toArray(summary));
    }

    onChangeTemplateSource(value: any) {
        this.props.setProperties({
            ["Octopus.Action.Aws.TemplateSource"]: value,
            ["Octopus.Action.Aws.CloudFormationTemplate"]: "",
            ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: "",
            ["Octopus.Action.Aws.CloudFormationTemplateParameters"]: "",
        });

        if (value === "Inline") {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        } else {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.state.feeds));
        }
    }

    setCapabilityProperty = () => {
        this.props.setProperties({ "Octopus.Action.Aws.IamCapabilities": JSON.stringify(this.state.capabilities) });
    };

    setCapabilities = (capabilites: string[]) => {
        this.setState(
            prev => ({
                ...prev,
                capabilities: [...capabilites],
            }),
            this.setCapabilityProperty
        );
    };

    removeCapability = (capability: string) => {
        this.setState(
            (prev, props) => ({
                ...prev,
                capabilities: prev!.capabilities.filter(x => x !== capability),
            }),
            this.setCapabilityProperty
        );
    };

    render() {
        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);

        return (
            <div>
                <FormSectionHeading title="AWS" />
                <AwsLoginComponent
                    projectId={this.props.projectId}
                    properties={this.props.properties}
                    packages={this.props.packages}
                    plugin={this.props.plugin}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    getFieldError={this.props.getFieldError}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Aws.Region|Octopus.Action.Aws.CloudFormationStackName"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="CloudFormation"
                    summary={this.cloudFormationSummary()}
                    help={"Specify the details of the CloudFormation stack"}
                >
                    <VariableLookupText
                        label="Region"
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Aws.Region"]}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Aws.Region"]: val })}
                        error={this.props.getFieldError("Octopus.Action.Aws.Region")}
                    />
                    <Note>
                        View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation for a current list of the available region codes.
                    </Note>
                    <VariableLookupText
                        label="CloudFormation stack name"
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Aws.CloudFormationStackName"]}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationStackName"]: val })}
                        error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationStackName")}
                    />
                    <VariableLookupText
                        label="Role ARN"
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Aws.CloudFormation.RoleArn"]}
                        onChange={val => this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.RoleArn": val })}
                        error={this.props.getFieldError("Octopus.Action.Aws.CloudFormation.RoleArn")}
                    />
                    <Note>The Amazon Resource Name (ARN) of an AWS Identity and Access Management (IAM) role that AWS CloudFormation assumes when executing any operations. This role will be used for any future operations on the stack.</Note>

                    <CapabilityMultiselect
                        items={knownCapabilities}
                        label="Acknowledged IAM Capabilities"
                        placeholder="Select IAM Capability"
                        renderChip={x => (
                            <Chip deleteButtonAccessibleName={`Delete ${x.Name}`} onRequestDelete={() => this.removeCapability(x.Id)} description={x.Name}>
                                {x.Id}
                            </Chip>
                        )}
                        value={this.state.capabilities}
                        onChange={this.setCapabilities}
                    />
                    <Note>
                        Additional capabilities are required for templates that have IAM resources or named IAM resources. Refer to the <ExternalLink href="AwsDocsControllingIAM">AWS documentation</ExternalLink> for more information.
                    </Note>
                    <BoundStringCheckbox
                        resetValue={"False"}
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        value={properties["Octopus.Action.Aws.DisableRollback"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.Aws.DisableRollback"]: x })}
                        label="Disable rollback"
                    />
                    <Note>Select this checkbox to disable the automatic rollback of a CloudFormation stack if it failed to be created successfully. This has no effect if the stack exists and is being updated.</Note>
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={"True"}
                        value={properties["Octopus.Action.Aws.WaitForCompletion"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.Aws.WaitForCompletion"]: x })}
                        label="Wait for completion"
                    />
                    <Note>
                        Select this checkbox to force the step to wait until the CloudFormation stack has been completed before getting the outputs and finishing the step. Be aware that unselecting this option can mean that no output variables are
                        created, or that output variables may contain outdated values, as the CloudFormation outputs may not have been created or updated before this step completed. Unselecting this option also means the step will not indicate an
                        error if the stack was rolled back during deployment.
                    </Note>
                </ExpandableFormSection>
                <FormSectionHeading title="Template" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Aws.TemplateSource|Octopus.Action.Aws.CloudFormationTemplate"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Template Source"
                    fillCardWidth={CardFill.FillRight}
                    summary={this.templateSourceSummary()}
                    help={"Select the source of the template."}
                >
                    <Note>Templates can be entered as source-code, or contained in a package.</Note>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.Aws.TemplateSource"]} onChange={(val: string) => this.onChangeTemplateSource(val)} error={this.props.getFieldError("Octopus.Action.Aws.TemplateSource")}>
                        <RadioButton value={"Inline"} label="Source code" />
                        <RadioButton value={"Package"} label="File inside a package" />
                    </RadioButtonGroup>
                    {this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Inline" && (
                        <div>
                            <br />
                            {this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"] && (
                                <CodeEditor
                                    value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]}
                                    language={JsonUtils.tryParseJson(this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]) ? TextFormat.JSON : TextFormat.YAML}
                                    allowFullScreen={false}
                                    readOnly={true}
                                />
                            )}
                            <div>
                                <OpenDialogButton
                                    label={this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"] ? "Edit Source Code" : "Add Source Code"}
                                    renderDialog={({ open, closeDialog }) => (
                                        <SourceCodeDialog
                                            title="Edit CloudFormation Template"
                                            open={open}
                                            close={closeDialog}
                                            value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]}
                                            autocomplete={[]}
                                            validate={this.validateTemplate}
                                            saveDone={async value => {
                                                this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationTemplate"]: value });
                                                await this.refreshParametersFromMetadata(await this.getMetadata(value));
                                            }}
                                            language={
                                                !this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"] || JsonUtils.tryParseJson(this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]) ? TextFormat.JSON : TextFormat.YAML
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </ExpandableFormSection>

                {this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Package" && (
                    <div>
                        <ExpandableFormSection
                            errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Package"
                            summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                            help={"Choose the package that contains the template source."}
                        >
                            <PackageSelector
                                packageId={pkg?.PackageId}
                                feedId={pkg?.FeedId}
                                onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                                onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                                packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                                feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                                projectId={this.props.projectId}
                                feeds={this.state.feeds}
                                localNames={this.props.localNames}
                                refreshFeeds={this.loadFeeds}
                            />
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            errorKey="Octopus.Action.Aws.CloudFormationTemplate|Octopus.Action.Aws.CloudFormationTemplateParametersRaw"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Paths"
                            summary={this.pathsSummary()}
                            help={"Enter relative the paths for the template and parameter files in the package."}
                        >
                            <VariableLookupText
                                label="Template path"
                                localNames={this.props.localNames}
                                value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]}
                                onChange={(val: string) => this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationTemplate"]: val })}
                                error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationTemplate")}
                            />

                            <Note>Relative path to the JSON template file contained in the package</Note>
                            <VariableLookupText
                                label="Template parameters path"
                                localNames={this.props.localNames}
                                value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]}
                                onChange={val => this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: val })}
                                error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationTemplateParametersRaw")}
                            />
                            <Note>Relative path to the JSON parameters file contained in the package</Note>
                        </ExpandableFormSection>
                    </div>
                )}

                {this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Inline" && this.state.parameterTypes && (
                    <div>
                        <FormSectionHeading title="Parameters" />
                        <DynamicForm
                            types={this.state.parameterTypes}
                            values={this.state.parameterValues}
                            isBindable={true}
                            onChange={data => this.updateParameters(data)}
                            getBoundFieldProps={() => ({ projectId: this.props.projectId, localNames: this.props.localNames })}
                        />
                    </div>
                )}
            </div>
        );
    }

    private getMetadata = (value: string): Promise<{ Metadata: MetadataTypeCollection; Values: DataContext }> => {
        return repository.CloudTemplates.getMetadata(value, "CloudFormation");
    };

    private validateTemplate = async (value: string) => {
        try {
            await this.getMetadata(value);
        } catch (err) {
            return err;
        }
        return null;
    };

    private cloudFormationSummary() {
        const properties = this.props.properties;

        if (properties["Octopus.Action.Aws.CloudFormationStackName"]) {
            return Summary.summary(
                <span>
                    Creating stack <strong>{properties["Octopus.Action.Aws.CloudFormationStackName"]}</strong>
                    {properties["Octopus.Action.Aws.Region"] && (
                        <span>
                            {" "}
                            in <strong>{properties["Octopus.Action.Aws.Region"]}</strong>
                        </span>
                    )}
                    {properties["Octopus.Action.Aws.WaitForCompletion"] !== "False" && <span> waiting for completion</span>}
                    {properties["Octopus.Action.Aws.WaitForCompletion"] === "False" && <span> not waiting for completion</span>}
                    {properties["Octopus.Action.Aws.DisableRollback"] === "True" && <span>, with rollback disabled</span>}
                    {properties["Octopus.Action.Aws.IamCapabilities"] && properties["Octopus.Action.Aws.IamCapabilities"] !== "NONE" && <span>, and with IAM capabilities</span>}
                    {!properties["Octopus.Action.Aws.IamCapabilities"] || (properties["Octopus.Action.Aws.IamCapabilities"] === "NONE" && <span>, and with no IAM capabilities</span>)}
                </span>
            );
        }

        return Summary.placeholder("Specify the details of the CloudFormation stack");
    }

    private async refreshParametersFromTemplate(template: string) {
        await this.props.doBusyTask(async () => {
            const response = await repository.CloudTemplates.getMetadata(template, "CloudFormation");
            await this.refreshParametersFromMetadata(response);
        });
    }

    private async refreshParametersFromMetadata(metadataResponse: { Metadata: MetadataTypeCollection; Values: DataContext }) {
        await this.props.doBusyTask(async () => {
            // merge stored parameter values from step data with default values from template
            const storedParameters: DataContext = this.flattenParameters();
            Object.keys(metadataResponse.Values).forEach(key => {
                if (storedParameters[key]) {
                    metadataResponse.Values[key] = storedParameters[key];
                }
            });
            this.props.setProperties({
                ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: JSON.stringify(Object.keys(metadataResponse.Values).map(k => ({ ParameterKey: k, ParameterValue: metadataResponse.Values[k] }))),
            });
            this.syncParameters(metadataResponse.Values);
            this.setState({ parameterTypes: metadataResponse.Metadata.Types, parameterValues: metadataResponse.Values });
        });
    }

    private flattenParameters(): DataContext {
        const parameters: DataContext = {};
        if (this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]) {
            const storedParameters = JSON.parse(this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]);
            storedParameters.forEach((p: AwsParameter) => {
                parameters[p.ParameterKey] = p.ParameterValue;
            });
        }

        return parameters;
    }

    private updateParameters(data: DataContext) {
        const objectKeys = Object.keys(data);
        this.props.setProperties({
            ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: JSON.stringify(objectKeys.map(k => ({ ParameterKey: k, ParameterValue: data[k] }))),
        });

        this.syncParameters(data);
    }

    private syncParameters(data: DataContext) {
        const objectKeys = Object.keys(data);
        /*
            Arrays are presented as new line separated strings, but are saved in the properties files
            as comma separated lists. So while Octopus.Action.Aws.CloudFormationTemplateParametersRaw
            retains the data input by the user, Octopus.Action.Aws.CloudFormationTemplateParameters
            is processed to contain the lists that CloudFormation expects.

            See https://github.com/aws/aws-cli/issues/1529 for details.
         */
        this.props.setProperties({
            ["Octopus.Action.Aws.CloudFormationTemplateParameters"]: JSON.stringify(
                objectKeys.map(k => {
                    if (Array.isArray(data[k])) {
                        return { ParameterKey: k, ParameterValue: data[k].join(",") };
                    } else {
                        // We can't have null values, only empty strings
                        return { ParameterKey: k, ParameterValue: data[k] || "" };
                    }
                })
            ),
        });
    }

    private loadFeeds = async () => {
        await this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() });
        });
    };
}

export const AwsCloudFormationActionEdit = withOptionalProcessContext(AwsCloudFormationActionEditInternal);

export default (): ActionPlugin => ({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AwsRunCloudFormation",
    summary: (properties, targetRolesAsCSV) => <AwsCloudFormationActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: step => true,
    canBeChild: true,
    edit: AwsCloudFormationActionEdit,
    targetRoleOption: action => TargetRoles.Optional,
    hasPackages: action => false,
    features: {
        optional: [ChangesetFeature().featureName],
    },
});
