/* eslint-disable @typescript-eslint/consistent-type-assertions */

import Onboarding from "./Onboarding";
import * as React from "react";
import { EnvironmentResource, EnvironmentsSummaryResource, TenantResource, Permission } from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton } from "components/Button";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../InfrastructureLayout";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { ActionButtonType } from "components/Button";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import EnvironmentsSorter from "./EnvironmentsSorter";
import EnvironmentSummarySection from "./EnvironmentSummarySection";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import AddEnvironmentsDialog from "./AddEnvironmentsDialog";
import AdvancedFilterLayout, { FilterSection, AdvancedFilterCheckbox } from "components/AdvancedFilterLayout";
import { defaultEnvironmentSummaryFilter, environmentSummaryFilterToQuery, environmentSummaryQueryToFilter, EnvironmentSummaryFilter, createEnvironmentSummaryArgs } from "./EnvironmentSummaryFilter";
import { isEqual } from "lodash";
import { TenantMultiSelect, RoleMultiSelect, TenantTagMultiSelect, EnvironmentMultiSelect, MachineModelHealthStatusMultiSelect, EndpointCommunicationStyleMultiSelect, ShellNameMultiSelect } from "components/MultiSelect";
import { connect } from "react-redux";
import { machineActions } from "../../reducers/machines";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import { Section } from "components/Section/Section";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import { AdvancedFilterTextInput } from "components/AdvancedFilterLayout/Text/AdvancedFilterTextInput";
import { QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { EnvironmentSummaryQuery } from "areas/infrastructure/components/EnvironmentsLayout/EnvironmentSummaryQuery";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import NumberedPagingBar from "components/PagingBaseComponent/NumberedPagingBar";
import { NoResults } from "components/NoResults/NoResults";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import ConfirmTentacleUpgradePanel from "../MachinesLayout/ConfirmTentacleUpgradePanel";
import { Dispatch, Action } from "redux";
import FormPage from "components/FormPage/FormPage";
import { useHistory, useLocation } from "react-router-dom";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

export interface EnvironmentsRouteParams {
    ids: string;
}

type SimplifiedRouteProps<T> = Omit<RouteComponentProps<T>, "match">;

interface EnvironmentLayoutProps extends SimplifiedRouteProps<EnvironmentsRouteParams> {
    initialData: InitialData;
    onClearMachine(): void;
}

interface EnvironmentLayoutState extends DataBaseComponentState {
    environmentsSummary: EnvironmentsSummaryResource;
    showOnboarding: boolean;
    filter: EnvironmentSummaryFilter;
    queryFilter?: EnvironmentSummaryFilter;
    isSearching?: boolean;
    redirectToTaskId?: string;
    currentPageIndex: number;
    redirectToEnvironmentId?: string;
}

interface InitialData {
    environmentsSummary: EnvironmentsSummaryResource;
    environments: EnvironmentResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    showOnboarding: boolean;
    machineShellNames: string[];
}

const PageSize = 20;
const Title = "Environments";

const EnvironmentQueryStringFilters = QueryStringFilters.For<EnvironmentSummaryFilter, EnvironmentSummaryQuery>();

const EnvironmentFormPage = FormPage<InitialData>();
const EnvironmentsLayout: React.FC<RouteComponentProps<EnvironmentsRouteParams>> = props => {
    const history = useHistory();
    const location = useLocation();

    return (
        <EnvironmentFormPage
            title={Title}
            load={async () => {
                const tagIndex = await tenantTagsets.getTagIndex();
                const args = createEnvironmentSummaryArgs(defaultEnvironmentSummaryFilter, tagIndex);
                const environmentsSummaryPromise = repository.Environments.summary(args);
                const environmentsPromise = repository.Environments.all();
                const machineRolesPromise = repository.MachineRoles.all();
                const tenantsPromise = repository.Tenants.all();
                const machineShellNamesPromise = repository.MachineShells.all();
                const showOnboarding = (await repository.Environments.list({ take: 0 })).TotalResults === 0;

                const [environmentsSummary, environments, machineRoles, tenants, machineShellNames] = await Promise.all([environmentsSummaryPromise, environmentsPromise, machineRolesPromise, tenantsPromise, machineShellNamesPromise]);

                return { environmentsSummary, environments, machineRoles, tenants, tagIndex, showOnboarding, machineShellNames };
            }}
            renderWhenLoaded={data => <ConnectedEnvironmentsLayout initialData={data} location={location} history={history} />}
            renderAlternate={args => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class FilterLayout extends AdvancedFilterLayout<EnvironmentSummaryFilter> {}

class EnvironmentsLayoutInternal extends DataBaseComponent<EnvironmentLayoutProps, EnvironmentLayoutState> {
    private machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    private communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: EnvironmentLayoutProps) {
        super(props);

        this.state = {
            filter: defaultEnvironmentSummaryFilter,
            isSearching: false,
            showOnboarding: false,
            currentPageIndex: 0,
            environmentsSummary: props.initialData.environmentsSummary,
        };
    }

    async componentDidMount() {
        this.props.onClearMachine();
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }

        if (this.state.redirectToEnvironmentId) {
            return <InternalRedirect to={routeLinks.infrastructure.environment(this.state.redirectToEnvironmentId)} push={true} />;
        }

        const actions = [
            this.props.initialData.environments && this.props.initialData.environments.length > 0 && (
                <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                    <NavigationButton href={routeLinks.infrastructure.machines.new()} label="Add deployment target" />
                </PermissionCheck>
            ),
            <PermissionCheck permission={Permission.EnvironmentCreate} environment="*">
                <OpenDialogButton label="Add Environment" type={ActionButtonType.Primary}>
                    <AddEnvironmentsDialog
                        saveDone={async env => {
                            this.setState({ redirectToEnvironmentId: env.Id });
                        }}
                    />
                </OpenDialogButton>
            </PermissionCheck>,
            this.props.initialData.environments && this.props.initialData.environments.length > 0 && (
                <PermissionCheck permission={Permission.EnvironmentEdit} environment="*">
                    <OverflowMenu menuItems={[OverflowMenuItems.dialogItem("Reorder", <EnvironmentsSorter saveDone={this.refreshEnvironmentSummaryData} />)]} />
                </PermissionCheck>
            ),
        ];

        const actionSection = <ActionList actions={actions} />;

        let environmentExpanders: React.ReactNode = null;
        const environmentSummaries = this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries;

        if (environmentSummaries) {
            const start = this.state.currentPageIndex === 0 ? 0 : this.state.currentPageIndex * PageSize;
            const end = start + PageSize;
            environmentExpanders = environmentSummaries.slice(start, end).map(environmentsSummary => {
                return (
                    <EnvironmentSummarySection
                        key={environmentsSummary.Environment.Id}
                        environmentSummary={environmentsSummary}
                        filter={this.state.filter}
                        isFiltering={this.isFiltering()}
                        tenants={this.props.initialData.tenants}
                        tagIndex={this.props.initialData.tagIndex}
                    />
                );
            });
        }

        if (this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length === 0) {
            environmentExpanders = (
                <Section>
                    <NoResults />
                </Section>
            );
        }

        const filterSections: FilterSection[] = [
            {
                render: (
                    <div>
                        <AdvancedFilterCheckbox
                            label="Disabled only"
                            value={this.state.filter.isDisabled}
                            onChange={x => {
                                this.setFilterState({ isDisabled: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <AdvancedFilterCheckbox
                            label="Hide empty environments"
                            value={this.state.filter.hideEmptyEnvironments}
                            onChange={x => {
                                this.setFilterState({ hideEmptyEnvironments: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <AdvancedFilterTextInput
                            fieldName={"deployment target"}
                            value={this.state.filter.machinePartialName || ""}
                            onChange={x => {
                                this.setFilterState({ machinePartialName: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <EnvironmentMultiSelect
                            items={this.props.initialData.environments}
                            value={this.state.filter.environmentIds}
                            onChange={x => {
                                this.setFilterState({ environmentIds: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <RoleMultiSelect
                            items={this.props.initialData.machineRoles ? this.props.initialData.machineRoles : []}
                            value={this.state.filter.roles}
                            onChange={x => {
                                this.setFilterState({ roles: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <MachineModelHealthStatusMultiSelect
                            items={this.machineHealthStatuses}
                            value={this.state.filter.healthStatuses}
                            onChange={x => {
                                this.setFilterState({ healthStatuses: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <EndpointCommunicationStyleMultiSelect
                            items={this.communicationStyles}
                            value={this.state.filter.commStyles}
                            onChange={x => {
                                this.setFilterState({ commStyles: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <TenantMultiSelect
                                    value={this.state.filter.tenantIds}
                                    items={this.props.initialData.tenants}
                                    onChange={x => {
                                        this.setFilterState({ tenantIds: x }, () => {
                                            this.onFilterChange();
                                        });
                                    }}
                                />
                                <TenantTagMultiSelect
                                    value={this.state.filter.tenantTags}
                                    doBusyTask={this.doBusyTask}
                                    onChange={x => {
                                        this.setFilterState({ tenantTags: x }, () => {
                                            this.onFilterChange();
                                        });
                                    }}
                                />
                            </PermissionCheck>
                        </FeatureToggle>
                        <ShellNameMultiSelect
                            items={this.props.initialData.machineShellNames ? this.props.initialData.machineShellNames : []}
                            value={this.state.filter.shellNames}
                            onChange={x => {
                                this.setFilterState({ shellNames: x }, this.onFilterChange);
                            }}
                        />
                    </div>
                ),
            },
        ];

        const tentacleUpgradesRequiredWarning = environmentSummaries && environmentSummaries.length > 0 && environmentSummaries.find(x => x.TentacleUpgradesRequired === true) && (
            <ConfirmTentacleUpgradePanel
                doBusyTask={this.doBusyTask}
                calloutDescriptionElement={<p>One or more deployment targets are running old versions of the Tentacle deployment agent and can be upgraded.</p>}
                onTentacleUpgradeComplete={taskId => {
                    this.setState({ redirectToTaskId: taskId });
                }}
            />
        );

        return (
            <InfrastructureLayout {...this.props}>
                <EnvironmentQueryStringFilters
                    filter={this.state.filter}
                    getQuery={environmentSummaryFilterToQuery}
                    getFilter={environmentSummaryQueryToFilter}
                    onFilterChange={filter => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())}
                />
                <PaperLayout busy={this.state.busy} errors={this.state.errors} title={Title} sectionControl={actionSection}>
                    {this.state.showOnboarding && <Onboarding />}
                    {environmentSummaries && !this.state.showOnboarding && (
                        <div>
                            {tentacleUpgradesRequiredWarning}
                            <FilterLayout
                                filterSections={filterSections}
                                filter={this.state.filter}
                                queryFilter={this.state.queryFilter}
                                defaultFilter={defaultEnvironmentSummaryFilter}
                                initiallyShowFilter={this.isFiltering()}
                                additionalHeaderFilters={[
                                    <FilterSearchBox
                                        placeholder={"Search environments..."}
                                        value={this.state.filter.partialName}
                                        onChange={x => {
                                            this.setFilterState({ partialName: x }, () => {
                                                this.onFilterChange();
                                            });
                                        }}
                                        autoFocus={true}
                                    />,
                                ]}
                                onFilterReset={(filter: EnvironmentSummaryFilter) => {
                                    this.setState({ filter }, () => {
                                        this.onFilterChange();
                                        const location = { ...this.props.history, search: "" };
                                        this.props.history.replace(location);
                                    });
                                }}
                                renderContent={() => (
                                    <div>
                                        {this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length > 0 && <ExpansionButtons />}
                                        {environmentExpanders}
                                        {this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length > 0 && (
                                            <NumberedPagingBar
                                                currentPageIndex={this.state.currentPageIndex}
                                                totalItems={this.state.environmentsSummary.EnvironmentSummaries.length}
                                                pageSize={PageSize}
                                                onPageSelected={(_, currentPageIndex) => this.setState({ currentPageIndex })}
                                            />
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    )}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }

    private setFilterState<K extends keyof EnvironmentSummaryFilter>(state: Pick<EnvironmentSummaryFilter, K>, callback?: () => void) {
        this.setState(
            prev => ({
                filter: { ...(prev.filter as object), ...(state as object) },
            }),
            callback
        );
    }

    private isFiltering(): boolean {
        return !isEqual(this.state.filter, defaultEnvironmentSummaryFilter);
    }

    private onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.refreshEnvironmentSummaryData();
            this.setState({ isSearching: false });
        });
    }

    private refreshEnvironmentSummaryData = async () => {
        await this.doBusyTask(() => this.loadEnvironmentSummaries());
    };

    private async loadEnvironmentSummaries() {
        const args = createEnvironmentSummaryArgs(this.state.filter, this.props.initialData.tagIndex);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Environments.summary(args), response => {
            this.setState({
                environmentsSummary: response as EnvironmentsSummaryResource,
                currentPageIndex: 0,
            });
        });
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onClearMachine: () => {
            dispatch(machineActions.machineCleared());
        },
    };
};

const ConnectedEnvironmentsLayout = connect(null, mapGlobalActionDispatchersToProps)(EnvironmentsLayoutInternal);

export default EnvironmentsLayout;
