import * as React from "react";
import TabbedFormPaperLayout, { TabbedFormPaperLayoutProps } from "./TabbedFormPaperLayout";
import RunbooksNavigationTabs from "../RunbooksNavigationTabs";
import StringHelper from "utils/StringHelper";

type RunbooksFormPaperLayoutProps = TabbedFormPaperLayoutProps;
const RunbooksFormPaperLayout: React.FC<RunbooksFormPaperLayoutProps> = ({ tabNavigation = RunbooksNavigationTabs, breadcrumbTitle = "Runbooks", title = StringHelper.ellipsis, disableAnimations = true, fullWidth = true, ...rest }) => (
    <TabbedFormPaperLayout breadcrumbTitle={breadcrumbTitle} title={title} tabNavigation={tabNavigation} disableAnimations={disableAnimations} fullWidth={fullWidth} {...rest} />
);

export default RunbooksFormPaperLayout;
