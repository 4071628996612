/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import pluginRegistry from "../pluginRegistry";
import BaseComponent from "../../BaseComponent";
import { ActionSummaryProps } from "../actionSummaryProps";
import { DisplayFeedName } from "../DisplayFeedName";
import { GetPrimaryPackageReference } from "client/resources/deploymentActionResource";
import Roles from "../Roles";
import { ActionExecutionLocation } from "client/resources";
import { TentaclePackageActionEdit } from "../tentaclePackage/tentaclePackageAction";
import { TargetRoles } from "areas/projects/components/Process/types";
import Callout, { CalloutType } from "components/Callout";

interface NginxActionSummaryState {
    feedName: string;
}

class NginxActionSummary extends BaseComponent<ActionSummaryProps, NginxActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg /* there was a validation bug that allowed saving of steps to not pick a package */ ? (
            <div>
                {"Deploy a web application and configure NGINX using package"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.Nginx",
    summary: (properties, targetRolesAsCsv, packages) => <NginxActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCsv} packages={packages} />,
    edit: TentaclePackageActionEdit,
    canHaveChildren: step => true,
    canBeChild: true,
    targetRoleOption: action => TargetRoles.Optional,
    hasPackages: action => true,
    features: {
        permanent: ["Octopus.Features.Nginx"],
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
        ],
    },
});
