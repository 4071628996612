/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import ReleaseFilter from "areas/projects/components/DeploymentsOverview/ReleaseFilter";
import { ProjectResource, TenantedDeploymentMode } from "client/resources";
import TenantResource from "client/resources/tenantResource";
import AdvancedFilterLayout, { FilterSection } from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { AdvancedTenantsAndTenantTagsSelector } from "components/AdvancedTenantSelector";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import SelectField from "components/form/Select/SelectField";
import { EnvironmentMultiSelect } from "components/MultiSelect";
import MenuItem from "material-ui/MenuItem";
import * as React from "react";
import { withTheme } from "components/Theme";
import { DashboardFilters, DataCube, DimensionTypes } from "../DashboardDataSource/DataCube";
const styles = require("./style.less");

interface OverviewFiltersProps {
    cube: DataCube;
    filters: DashboardFilters;
    defaultFilter: DashboardFilters;
    project: ProjectResource;
    tenants: TenantResource[];
    doBusyTask: DoBusyTask;
    onFiltersUpdated: (filters: DashboardFilters) => void;
    render: () => React.ReactNode;
}

class FilterLayout extends AdvancedFilterLayout<DashboardFilters> {}

interface OverviewFiltersState {
    selectedTenants?: string[];
    selectedTags?: string[];
}

class OverviewFilters extends React.Component<OverviewFiltersProps, OverviewFiltersState> {
    constructor(props: OverviewFiltersProps) {
        super(props);
    }

    render() {
        const cube = this.props.cube;
        if (cube === null) {
            return null;
        }

        const filters = [this.getReleaseFilter(), this.getGroupingOptions()];
        return (
            <div>
                <FilterLayout
                    filter={this.props.filters}
                    defaultFilter={this.props.defaultFilter}
                    filterSections={this.getAdvancedFilters()}
                    overflowXHidden={true}
                    additionalHeaderFilters={filters}
                    onFilterReset={defaultFilter => this.props.onFiltersUpdated(defaultFilter)}
                    renderContent={this.props.render}
                />
            </div>
        );
    }

    private invokeUpdate = (newFilter: DashboardFilters) => {
        if (this.props.onFiltersUpdated) {
            this.props.onFiltersUpdated(newFilter);
        }
    };

    private groupingChanged = (groupBy?: DimensionTypes, groupByExtra?: string) => {
        const filt = {
            ...this.props.filters,
            groupBy,
            groupByExtra,
        };

        this.invokeUpdate(filt);
    };

    private getGroupingOptions = () => {
        const cube = this.props.cube;
        const filters = this.props.filters;
        const selectedGroup = filters.groupBy === DimensionTypes.None || !filters.groupBy ? null : `${filters.groupBy}-${filters.groupByExtra || ""}`;

        const menuItems: any[] = [];

        if (Object.keys(cube.channelIndex).length > 1) {
            menuItems.push(<MenuItem value={`${DimensionTypes.Channel}-`} primaryText="Channel" key="Channel" onClick={() => this.groupingChanged(DimensionTypes.Channel)} />);
        }

        if (Object.keys(cube.tagSetIndex).length > 0) {
            Object.keys(cube.tagSetIndex).forEach(tagsetId =>
                menuItems.push(<MenuItem value={`${DimensionTypes.TagSet}-${tagsetId}`} primaryText={cube.tagSetIndex[tagsetId].Name} key={`TagSet${tagsetId}`} onClick={() => this.groupingChanged(DimensionTypes.TagSet, tagsetId)} />)
            );
        }

        if (menuItems.length === 0) {
            return null;
        }

        return withTheme(theme => (
            <SelectField placeholder="Group by" selectedMenuItemStyle={{ color: theme.primary }} value={selectedGroup!} className={styles.selectFilter}>
                <MenuItem value={`${DimensionTypes.None}-`} primaryText="No grouping" onClick={() => this.groupingChanged()} />
                {menuItems}
            </SelectField>
        ));
    };

    private onUpdate = (selectedTenants: string[], selectedTags: string[]) => {
        this.setState({ selectedTenants, selectedTags });
        const filt = { ...this.props.filters };
        if (selectedTenants.length === 0) {
            delete filt[DimensionTypes.Tenant];
        } else {
            filt[DimensionTypes.Tenant] = selectedTenants.reduce((f: any, tenantId) => {
                f[tenantId] = true;
                return f;
            }, {});
        }
        if (selectedTags.length === 0) {
            delete filt[DimensionTypes.TagSet];
        } else {
            filt[DimensionTypes.TagSet] = selectedTags.reduce((f: any, tagId) => {
                f[tagId] = true;
                return f;
            }, {});
        }
        this.invokeUpdate(filt);
    };

    private getAdvancedFilters: () => FilterSection[] = () => {
        return [
            {
                render: this.getEnvironmentFilter(),
            },
            {
                render: this.getTenantFilters(),
            },
        ];
    };

    private getTenantFilters = () => {
        if (!this.props.project || this.props.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return null;
        }
        const selectedTags = Object.keys(this.props.filters[DimensionTypes.TagSet] || {});
        const selectedTenants = Object.keys(this.props.filters[DimensionTypes.Tenant] || {});

        return (
            <AdvancedTenantsAndTenantTagsSelector
                selectedTenantIds={selectedTenants}
                selectedTenantTags={selectedTags}
                tenants={this.props.tenants}
                doBusyTask={this.props.doBusyTask}
                onChange={(tenantIds, tenantTags) => this.onUpdate(tenantIds, tenantTags)}
                showPreviewButton={true}
            />
        );
    };

    private handleReleaseFilterUpdated = (releaseId: string) => {
        const filt = { ...this.props.filters };
        if (!releaseId) {
            delete filt[DimensionTypes.Release];
        } else {
            filt[DimensionTypes.Release] = { [releaseId]: true };
        }
        this.invokeUpdate(filt);
    };

    private getReleaseFilter = () => {
        const cube = this.props.cube;

        if (this.props.filters.rowDimension === DimensionTypes.Release) {
            return null;
        }

        const releases = Object.values(cube.releaseIndex);
        if (releases.length === 0) {
            return null;
        }

        return <ReleaseFilter filterUpdated={this.handleReleaseFilterUpdated} project={this.props.project} releases={releases} channelIndex={cube.channelIndex} value={this.filteredReleaseVersion()} />;
    };

    private handleEnvironmentFilterUpdated = (environmentIds: string[]) => {
        const filt = { ...this.props.filters };

        if (environmentIds.length === 0) {
            delete filt[DimensionTypes.Environment];
        } else {
            filt[DimensionTypes.Environment] = environmentIds.reduce((f: any, environmentId) => {
                f[environmentId] = true;
                return f;
            }, {});
        }

        this.invokeUpdate(filt);
    };

    private getEnvironmentFilter = () => {
        const cube = this.props.cube;

        const environments = Object.values(cube.environmentIndex);
        if (environments.length === 0) {
            return null;
        }

        return <EnvironmentMultiSelect items={environments} value={this.filteredEnvironment()} onChange={this.handleEnvironmentFilterUpdated} />;
    };

    private filteredReleaseVersion() {
        if (this.props.filters[DimensionTypes.Release]) {
            const releaseId = Object.keys(this.props.filters[DimensionTypes.Release])[0];
            return releaseId;
        }
        return "";
    }

    private filteredEnvironment() {
        return Object.keys(this.props.filters[DimensionTypes.Environment] || {});
    }
}

export default OverviewFilters;
