import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab, { TabProps } from "@material-ui/core/Tab";
import routeLinks from "routeLinks";
import { generatePath } from "react-router";
import { makeStyles } from "@material-ui/styles";
import { keyBy } from "lodash";
import { useRunbookContext } from "../Runbooks/RunbookContext";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { Permission } from "client/resources";
import { useProjectContext } from "areas/projects/context";
import { useOctopusTheme, OctopusTheme } from "components/Theme";

type NavTabProps = TabProps;

interface PropsWithOctopusTheme {
    octopusTheme: OctopusTheme;
}

const useMuiStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({ color: props.octopusTheme.primaryText }),
    indicator: (props: PropsWithOctopusTheme) => ({ backgroundColor: props.octopusTheme.primary }),
}));

const useTabStyles = makeStyles(() => ({
    tabsContainer: (props: PropsWithOctopusTheme) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: props.octopusTheme.paper0,
        borderTopStyle: "solid",
        borderTopWidth: "0.0625rem",
        borderTopColor: props.octopusTheme.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: "0.0625rem",
        borderBottomColor: props.octopusTheme.divider,
    }),
    rootTab: {
        minWidth: "7.5rem", // Needed for DrawerWrapperLayout.
        textTransform: "none",
        fontWeight: "normal",
        letterSpacing: "0",
        fontSize: "0.9125rem",
    },
}));

const useNavTabStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({
        "&:hover": {
            opacity: 1,
            color: props.octopusTheme.primaryDark,
            transition: "backgroundColor 10s",
        },
    }),
}));

const NavTab: React.FC<NavTabProps> = props => {
    const octopusTheme = useOctopusTheme();
    const tabStyles = useNavTabStyles({ octopusTheme });
    return <Tab classes={tabStyles} {...props} />;
};

const RunbooksNavigationTabsInternal: React.FC<RouteComponentProps<{ projectSlug: string; spaceId: string; runbookId: string }>> = props => {
    const octopusTheme = useOctopusTheme();
    const classes = useMuiStyles({ octopusTheme });
    const tabStyles = useTabStyles({ octopusTheme });

    const match = props.match;
    const context = useRunbookContext();
    const processId = context.state.runbook && context.state.runbook.RunbookProcessId;
    const links = routeLinks.project(":projectSlug").operations.runbook(":runbookId");

    const projectContext = useProjectContext();
    const tabs = [];
    tabs.push({ label: "Overview", value: generatePath(links.overview, match.params) });
    if (
        isAllowed({
            permission: Permission.TaskView,
            project: projectContext.state.model.Id,
            wildcard: true,
        })
    ) {
        tabs.push({ label: "Runs", value: generatePath(links.runslist, match.params) });
    }
    tabs.push({ label: "Process", value: processId ? generatePath(links.runbookProcess.runbookProcess(processId).root, match.params) : "", disabled: !processId });
    tabs.push({ label: "Settings", value: generatePath(links.settings, match.params) });
    const lookup = keyBy(tabs, x => x.value);

    const found = lookup[props.location.pathname] || tabs.find(x => props.location.pathname.indexOf(x.value) >= 0);
    const partialTabMatch = found && found.value;

    return (
        <div className={tabStyles.tabsContainer}>
            <Tabs
                value={partialTabMatch}
                classes={classes}
                onChange={(_, val) => {
                    props.history.push(val);
                }}
                variant="scrollable"
                scrollButtons="off"
            >
                {tabs.map(x => (
                    <NavTab key={x.label} label={x.label} value={x.value} disabled={x.disabled} className={tabStyles.rootTab} />
                ))}
            </Tabs>
        </div>
    );
};

const RunbooksNavigationTabs = withRouter(RunbooksNavigationTabsInternal);
export default RunbooksNavigationTabs;
