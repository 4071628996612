import * as React from "react";
import { List as BaseList } from "components/List/List";
import { ActionTemplateResource, ResourceCollection } from "client/resources";
import ListTitle from "components/ListTitle/ListTitle";
const styles = require("./style.less");
import Logo from "components/Logo/Logo";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import MarkdownDescription from "components/MarkdownDescription";

class List extends BaseList<ActionTemplateResource> {}

interface ActionTemplateListProps {
    templates: ResourceCollection<ActionTemplateResource>;
    filter: (t: ActionTemplateResource) => boolean;
    title: string;
    onPrimaryActionRequested?: (t: ActionTemplateResource) => React.ReactNode;
    actions: React.ReactNode[];
    autoFocusOnFilterSearch: boolean;
}

const ActionTemplateList = (props: ActionTemplateListProps) => {
    return (
        <div className={styles.list}>
            <div className={styles.listHeader}>
                <h4>{props.title}</h4>
                <div className={styles.actions}>
                    <ActionList actions={props.actions} />
                </div>
            </div>
            <List
                initialData={props.templates}
                onRow={(template: ActionTemplateResource) => buildRow(template, props)}
                filterSearchEnabled={true}
                apiSearchParams={["partialName"]}
                filterHintText="Filter by name..."
                autoFocusOnFilterSearch={props.autoFocusOnFilterSearch}
                onRowRedirectUrl={(template: ActionTemplateResource) => routeLinks.library.stepTemplate(template.Id).root}
                onFilter={(text: string, template: ActionTemplateResource) => props.filter(template) && filter(text, template)}
            />
        </div>
    );
};

const filter = (filterText: string, actionTemplate: ActionTemplateResource) => {
    return !filterText || filterText.length === 0 || actionTemplate.Name.toLowerCase().includes(filterText.toLowerCase());
};

const buildRow = (template: ActionTemplateResource, props: ActionTemplateListProps) => {
    return (
        <div>
            <div className={styles.templateItem}>
                <div>
                    <Logo url={template.Links.Logo} />
                </div>
                <div className={styles.templateItemName}>
                    <ListTitle>{template.Name}</ListTitle>
                </div>
                <div>{props.onPrimaryActionRequested && props.onPrimaryActionRequested(template)}</div>
            </div>
            <MarkdownDescription markup={template.Description} />
        </div>
    );
};

export default ActionTemplateList;
