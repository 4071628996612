import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectRunbookTopics } from "../../CommonComponents/CommonProjectRunbooksViews";
import { CommonProjectRunbookSnapshotOverview } from "../../CommonComponents/CommonProjectRunbookSnapshotsViews";

export const ProjectRunbookSnapshotsCreateRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.runbookSnapshot().root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookSnapshotOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookTopics />;
    },
};
