export class ExtensionManager {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    extensions: Record<string, Record<string, any>> = {};

    getExtension(name: string, type: string) {
        return this.extensions && this.extensions[type] ? this.extensions[type][name] : null;
    }

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerExtension(name: string, type: string, extension: any): void {
        if (!this.extensions[type]) {
            this.extensions[type] = { name, extension };
        }
        this.extensions[type][name] = extension;
    }
}

const extensionManager = new ExtensionManager();

export default extensionManager;
