/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { Section } from "components/Section/Section";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import DeleteDialog from "components/DialogLayout/DeleteDialog";

interface DeleteApiKeyProps {
    apiKeyPurpose: string;
    open: boolean;
    onConfirm(): Promise<any>;
    onClose(): void;
}

class DeleteApiKeyDialog extends DataBaseComponent<DeleteApiKeyProps, DataBaseComponentState> {
    constructor(props: DeleteApiKeyProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <DeleteDialog busy={this.state.busy} errors={this.state.errors} title={"Delete API Key"} onDeleteClick={this.props.onConfirm} onClose={this.props.onClose} open={this.props.open}>
                <Section>
                    Are you sure you want to delete the API Key <b>{this.props.apiKeyPurpose}</b>?
                </Section>
            </DeleteDialog>
        );
    }
}

export default DeleteApiKeyDialog;
