import { ScriptingLanguage } from "components/scriptingLanguage";

export function codeEditorVariablesList(serverNames: string[], localNames: string[], syntax?: ScriptingLanguage, filter?: string) {
    const allNames = (localNames ? localNames : []).concat(serverNames);
    const lcFilter = (filter ?? "").toLowerCase();
    const filterFunc = (item: string) => !lcFilter || item.toLowerCase().includes(lcFilter);

    return allNames
        .filter(filterFunc)
        .map(v => ({
            display: v,
            code: delimit(v, syntax),
        }))
        .concat(
            getCodeEditorFunctions(syntax)
                .filter(filterFunc)
                .map(v => ({
                    display: v,
                    code: v,
                }))
        );
}

function getCodeEditorFunctions(syntax?: ScriptingLanguage) {
    return getLanguageFunctions(syntax).concat([
        "#{if VariableName}value_if_true#{/if}",
        "#{unless VariableName}value_if_false#{/unless}",
        "#{each item in VariablePrefix}#{item.Postfix}#{/each}",
        "#{each number in NumberArrayVariable}#{number}#{/each}",
        "#{each item in JsonObjectVariable}#{item.Key}: #{item.Value.Property}#{/each}",
        "#{ VariableName | HtmlEscape }",
        "#{ VariableName | JsonEscape }",
        "#{ VariableName | XmlEscape }",
        "#{ VariableName | Markdown }",
        "#{ VariableName | ToLower }",
        "#{ VariableName | ToUpper }",
        "#{ | NowDate }",
        "#{ | NowDateUtc }",
        '#{ | NowDate "HH dd-MMM-yyyy" }',
        "#{ | NowDate zz }",
        "#{ | NowDate #{MyFormat} }",
        "#{ VariableName | Format C}",
    ]);
}

function delimit(varName: string, syntax?: ScriptingLanguage) {
    if (!syntax) {
        return `#{${varName}}`;
    }

    // Some escaping is probably required here.
    if (syntax === ScriptingLanguage.PowerShell) {
        if (varName.match(/^[A-z0-9]+$/)) {
            return "$" + varName;
        } else {
            return `$OctopusParameters["${varName}"]`;
        }
    }

    if (syntax === ScriptingLanguage.CSharp) {
        return `Octopus.Parameters["${varName}"]`;
    }

    if (syntax === ScriptingLanguage.Bash) {
        return `$(get_octopusvariable "${varName}")`;
    }

    if (syntax === ScriptingLanguage.FSharp) {
        return `Octopus.findVariable "${varName}"`;
    }

    if (syntax === ScriptingLanguage.Python) {
        return `get_octopusvariable("${varName}")`;
    }

    return varName;
}

function getLanguageFunctions(syntax?: ScriptingLanguage) {
    switch (syntax) {
        case ScriptingLanguage.PowerShell:
            return [
                "New-OctopusArtifact",
                "Set-OctopusVariable",
                "Fail-Step",
                "Write-Verbose",
                "Write-Highlight",
                "Write-Wait",
                "Write-Warning",
                "Update-Progress",
                "New-OctopusAzureServicePrincipalAccount",
                "New-OctopusAzureWebAppTarget",
                "New-OctopusAzureServiceFabricTarget",
                "New-OctopusAzureCloudServiceTarget",
                "Remove-OctopusTarget",
            ];
        case ScriptingLanguage.Bash:
            return ["new_octopusartifact", "set_octopusvariable", "fail_step", "write_verbose", "write_highlight", "write_wait", "write_warning", "update_progress"];
        case ScriptingLanguage.FSharp:
            return ["createArtifact", "setVariable", "failStep", "writeVerbose", "writeHighlight", "writeWait", "writeWarning", "updateProgress"];
        case ScriptingLanguage.CSharp:
            return ["Octopus.CreateArtifact", "Octopus.SetVariable", "Octopus.FailStep", "Octopus.WriteVerbose", "Octopus.WriteHighlight", "Octopus.WriteWait", "Octopus.WriteWarning", "Octopus.UpdateProgress"];
        default:
            return [];
    }
}
