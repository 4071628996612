import * as React from "react";
import { SFC } from "react";
import { Callout, CalloutType } from "components/Callout/Callout";

const HALogWarning: SFC<{ isHA: boolean }> = props => (
    <React.Fragment>
        {props.isHA && (
            <Callout type={CalloutType.Warning} title={"Only showing logs from one node"}>
                This Octopus instance has multiple nodes, the logs below are only from the one node the API request was sent to.
            </Callout>
        )}
    </React.Fragment>
);

HALogWarning.displayName = "HALogWarning";

export default HALogWarning;
