import * as React from "react";

import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { EnvironmentsSummaryResource, TenantResource } from "client/resources";

import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { LookupTenantChip } from "components/Chips/index";
import Permission from "client/resources/permission";
import { connect } from "react-redux";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import routeLinks from "../../../../routeLinks";
import { withTheme } from "components/Theme";

const styles = require("./style.less");

interface TenantsCardProps {
    environmentSummary: EnvironmentsSummaryResource;
    tenants: TenantResource[];
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

interface TenantsCardState extends DataBaseComponentState {
    filterText: string;
}

type Props = TenantsCardProps & GlobalConnectedProps;

class TenantsCard extends DataBaseComponent<Props, TenantsCardState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            filterText: "",
        };
    }

    render() {
        if (
            !this.props.environmentSummary ||
            !this.props.tenants ||
            !this.props.isMultiTenancyEnabled ||
            !isAllowed({
                permission: Permission.TenantView,
                tenant: "*",
            })
        ) {
            return null;
        }

        const tenantSummaries = this.props.environmentSummary.MachineTenantSummaries;
        const tenants = this.props.tenants.filter(item => this.onFilter(this.state.filterText, item));
        const tenantRows = tenants.map(tenant => {
            const totalMachines = (tenantSummaries && tenantSummaries[tenant.Id]) || 0;
            return (
                <div className={styles.tenantRowsContainer} key={tenant.Id}>
                    <div className={styles.tenantName}>
                        <LookupTenantChip lookupTenants={this.props.tenants} id={tenant.Id} to={routeLinks.infrastructure.machines.filtered({ tenantIds: tenant.Id })} />
                    </div>
                    <div className={styles.tenantMachinesCount}>{totalMachines.toLocaleString()}</div>
                </div>
            );
        });

        return (
            <div className={styles.card}>
                <div className={styles.cardTitleContainer}>
                    <div className={styles.cardTitle}>
                        Tenants <span className={styles.cardCount}>({tenants.length.toLocaleString()})</span>
                    </div>
                </div>
                <div className={styles.cardContentContainer}>
                    {(this.props.tenants.length > 1 || this.state.filterText) && (
                        <div>
                            {withTheme(theme => (
                                <FilterSearchBox
                                    placeholder="Filter..."
                                    onChange={value => this.setState({ filterText: value.toLowerCase() })}
                                    inputClassName={styles.dashboardFilterInputClassName}
                                    iconColor={theme.secondaryText}
                                    iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                                />
                            ))}
                        </div>
                    )}
                    {tenantRows}
                </div>
            </div>
        );
    }

    private onFilter(filter: string, resource: TenantResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: TenantsCardProps): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

export default connect(mapGlobalStateToProps)(TenantsCard);
