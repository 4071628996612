/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import * as _ from "lodash";
import { repository } from "clientInstance";
import { RunbookResource, ResourceCollection } from "client/resources";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import List from "components/List";
import { ActionButtonType, NavigationButton, NavigationButtonType } from "components/Button";
import PaperLayout from "components/PaperLayout/index";
import { RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PermissionCheck, { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { withProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import ListTitle from "components/ListTitle";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import routeLinks from "routeLinks";
import InternalRedirect from "components/Navigation/InternalRedirect";
import AddRunbook from "./AddRunbook";
import RunbooksOnboarding from "./RunbooksOnboarding";
import { RunbooksNewFeatureCallout } from "./OperationsOverviewLayout";
import MarkdownDescription from "components/MarkdownDescription";
import { MenuItem, OverflowMenuItems } from "components/Menu/OverflowMenu";
import ListItem from "components/ListItem";

interface RunbooksLayoutState extends DataBaseComponentState {
    runbooks: ResourceCollection<RunbookResource>;
    redirectTo?: string;
}

class RunbooksLayoutList extends List<RunbookResource> {}

type RunbooksLayoutProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps;

class RunbooksLayoutInternal extends DataBaseComponent<RunbooksLayoutProps, RunbooksLayoutState> {
    private match: any = null;

    constructor(props: RunbooksLayoutProps) {
        super(props);
        this.match = this.props.match;
        this.state = {
            runbooks: null!,
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async reload() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const runbooks = await repository.Projects.getRunbooks(project, { skip: 0, take: 30 });
            this.setState({
                runbooks,
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const project = this.props.projectContext.state.model;
        const addProcessButton = (
            <PermissionCheck permission={Permission.RunbookEdit} project={project.Id} wildcard={true}>
                <OpenDialogButton label="Add Runbook" type={ActionButtonType.Primary}>
                    <AddRunbook projectId={project.Id} onProcessCreated={id => this.setState({ redirectTo: routeLinks.project(project.Slug).operations.runbook(id).root })} />
                </OpenDialogButton>
            </PermissionCheck>
        );
        return (
            <PaperLayout busy={this.state.busy} errors={this.state.errors} title="Runbooks" sectionControl={addProcessButton}>
                <RunbooksNewFeatureCallout />
                {this.state.runbooks && this.state.runbooks.Items.length === 0 && <RunbooksOnboarding />}
                {this.state.runbooks && this.state.runbooks.Items.length > 0 && (
                    <>
                        <RunbooksLayoutList
                            initialData={this.state.runbooks}
                            onRow={(item: any) => this.buildRow(item)}
                            match={this.match}
                            onRowRedirectUrl={(runbook: RunbookResource) => routeLinks.project(this.props.projectContext.state.model.Slug).operations.runbook(runbook.Id).root}
                            onFilter={this.filter}
                            filterSearchEnabled={true}
                            apiSearchParams={["partialName"]}
                            filterHintText="Filter by name..."
                        />
                    </>
                )}
            </PaperLayout>
        );
    }

    private filter(filter: string, resource: RunbookResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private clonePermission(): PermissionCheckProps {
        const project = this.props.projectContext.state.model;
        return {
            permission: Permission.RunbookEdit,
            projectGroup: project && project.ProjectGroupId,
            wildcard: true,
        };
    }

    private buildRow(runbook: RunbookResource) {
        const project = this.props.projectContext.state.model;
        const runbookDescription = runbook.Description && <MarkdownDescription markup={runbook.Description} />;

        const CloneDialog = () => <AddRunbook projectId={project.Id} onProcessCreated={async () => this.reload()} cloneId={runbook.Id} />;

        const overFlowActions: Array<MenuItem | MenuItem[]> = [];
        overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, this.clonePermission()));
        overFlowActions.push([
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.runbookEventsForProject(project.Id, runbook.Id), null!, {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);
        const runAction = isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, projectGroup: project.ProjectGroupId, wildcard: true }) ? (
            <NavigationButton label="Run..." href={routeLinks.project(project.Slug).operations.runbook(runbook.Id).runNow.root} type={NavigationButtonType.Secondary} />
        ) : null;
        return (
            <ListItem overflowMenuItems={overFlowActions} secondaryAction={runAction!}>
                <ListTitle>{runbook.Name}</ListTitle>
                {runbookDescription}
            </ListItem>
        );
    }
}

export const RunbooksLayout = withProjectContext(RunbooksLayoutInternal);
export type EnhancedRunbooksLayoutProps = Parameters<typeof RunbooksLayout>[0];

export default RunbooksLayout;
