/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import cn from "classnames";
import { History } from "history";
type LocationDescriptor = History.LocationDescriptor;
import Breadcrumb from "components/Breadcrumb";
import BusyFromPromise from "components/BusyFromPromise/BusyFromPromise";
import BusyRefreshIndicator from "components/BusyRefreshIndicator";
import IconButton, { Icon } from "components/IconButton/IconButton";
import { connect } from "react-redux";
import { toggleDrawer, isDrawerOpen } from "components/Drawer/reducers";
import { Dispatch, Action } from "redux";
import MediaQuery from "react-responsive";
import { drawerBreakpointWidth } from "components/Drawer/DrawerWrapperLayout";
import configurationSelectors from "areas/configuration/reducers/selectors";
import InternalLink from "components/Navigation/InternalLink";
import { MenuItem, OverflowMenuNavLink } from "components/Menu/OverflowMenu";

const styles = require("./style.less");

interface GlobalConnectedProps {
    isDrawerOpen?: boolean;
    isHelpSidebarEnabled?: boolean;
}

interface GlobalDispatchProps {
    toggleDrawer?(): void;
}

interface SectionTitlePropsInternal {
    title: React.ReactNode;
    titlePath?: LocationDescriptor;
    className?: string;
    sectionControl?: React.ReactNode;
    titleLogo?: JSX.Element;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    breadcrumbOverflow?: OverflowMenuNavLink[]; // If specified, this will create a Dropdown button with ternary theme and the specified overflow items
    breadcrumbChip?: JSX.Element; // If specified, this will put the chip next to the  breadcrumb title.
    busy?: Promise<any> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
    hideHelpIcon?: boolean;
}

type SectionTitleProps = SectionTitlePropsInternal & GlobalConnectedProps & GlobalDispatchProps;

class SectionTitleInternal extends React.Component<SectionTitleProps, never> {
    render() {
        const props = this.props;
        return (
            <div className={cn(styles.container, props.className)}>
                <div className={styles.titleContainer}>
                    <div className={styles.breadcrumbContainer}>
                        <Breadcrumb title={props.breadcrumbTitle} path={props.breadcrumbPath} overflow={props.breadcrumbOverflow} />
                        {props.breadcrumbChip}
                    </div>
                    <div className={styles.title}>
                        {props.titleLogo && <div className={styles.logo}>{props.titleLogo}</div>}
                        <h2 className={styles.header}>
                            {props.titlePath ? (
                                <InternalLink to={props.titlePath} className={styles.pageTitleLink}>
                                    {props.title}
                                </InternalLink>
                            ) : (
                                <span>{props.title}</span>
                            )}
                            {this.props.isHelpSidebarEnabled && !this.props.hideHelpIcon && (
                                <MediaQuery minWidth={drawerBreakpointWidth}>
                                    <div className={cn(styles.helpIconContainer, this.props.isDrawerOpen ? styles.helpIconContainerClosed : null)}>
                                        <IconButton toolTipContent={this.props.isDrawerOpen ? "Hide help" : "Show help"} onClick={this.props.toggleDrawer} icon={this.props.isDrawerOpen ? Icon.CloseHelp : Icon.OpenHelp} />
                                    </div>
                                </MediaQuery>
                            )}
                        </h2>
                        {/* We show a less intrusive refresh indicator for screens that have auto-refresh implemented (because the main horizontal loading bar is annoying). */}
                        {props.enableLessIntrusiveLoadingIndicator && (
                            <div className={styles.refreshIndicator}>
                                <BusyFromPromise promise={props.busy}>
                                    {(busy: boolean) => {
                                        return busy && props.enableLessIntrusiveLoadingIndicator ? (
                                            <div className={styles.refreshContainer}>
                                                <BusyRefreshIndicator show={true} />
                                            </div>
                                        ) : (
                                            null!
                                        );
                                    }}
                                </BusyFromPromise>
                            </div>
                        )}
                    </div>
                </div>
                {props.sectionControl}
            </div>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isDrawerOpen: isDrawerOpen(state),
        isHelpSidebarEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isHelpSidebarEnabled)(state),
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        toggleDrawer: () => dispatch(toggleDrawer()),
    };
};

const SectionTitle = connect<{}, GlobalDispatchProps, SectionTitleProps>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(SectionTitleInternal);

export default SectionTitle;
