/* eslint-disable @typescript-eslint/no-explicit-any */

import * as _ from "lodash";
import { ProjectResource, ActionTemplateParameterResource, ControlType } from "client/resources";
import { VariableResource, VariableType } from "client/resources/variableResource";
import { LibraryVariableSetResource } from "client/resources/libraryVariableSetResource";
import { VariableSetResource } from "client/resources/variableSetResource";
import { curry } from "lodash";

type ReferenceCallback<T> = (id: string) => Promise<T>;

interface VariableCallbacks {
    fetchLibraryVariableSet: ReferenceCallback<LibraryVariableSetResource>;
    fetchVariableSet: ReferenceCallback<VariableSetResource>;
}

type UsedProjectResourceProps = Pick<ProjectResource, "IncludedLibraryVariableSetIds" | "Templates" | "VariableSetId">;

const isControlType = curry((controlType: ControlType, template: ActionTemplateParameterResource) => !!template.DisplaySettings && template.DisplaySettings["Octopus.ControlType"] === controlType);

const isVariableType = curry(<T extends { Type: VariableType }>(variableType: VariableType, resource: T) => resource.Type === variableType);

const fetchEach = <T extends any>(callback: ReferenceCallback<T>, ids: string[]): Promise<T[]> => Promise.all(ids.map(callback));
const pickVariableSetId = (item: { VariableSetId: string }) => item.VariableSetId;
const pickVariables = (item: { Variables: VariableResource[] }) => item.Variables;
const pickTemplates = (item: { Templates: ActionTemplateParameterResource[] }) => item.Templates;
const pickName = (item: { Name: string }) => item.Name;

const collateProjectVariables = curry(
    async (callbacks: VariableCallbacks, controlType: ControlType, variableType: VariableType, project: UsedProjectResourceProps): Promise<string[]> => {
        const libraryVariableSets = await fetchEach(callbacks.fetchLibraryVariableSet, project.IncludedLibraryVariableSetIds);
        const variableSetIds = _.union([project.VariableSetId], libraryVariableSets.map(pickVariableSetId));
        const variableResults = await fetchEach(callbacks.fetchVariableSet, variableSetIds).then(x => x.map(pickVariables));
        const variables = _.flattenDeep<VariableResource>(variableResults)
            .filter(isVariableType(variableType))
            .map(pickName);
        const templates = _.union(project.Templates, _.flattenDeep<ActionTemplateParameterResource>(libraryVariableSets.map(pickTemplates)))
            .filter(isControlType(controlType))
            .map(pickName);

        return _.chain(variables)
            .union(templates)
            .sort()
            .uniq()
            .value();
    }
);

export default collateProjectVariables;
export { collateProjectVariables, isControlType, isVariableType, ReferenceCallback, VariableCallbacks, UsedProjectResourceProps };
