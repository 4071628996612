/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import ToolTip from "../ToolTip";
import Markdown from "../Markdown";
import DebounceValue from "components/DebounceValue";
import IconButton from "components/IconButton";
import { ChipProps } from "components/Chips/Chip";
import Popover from "components/Popover/Popover";
import { Icon } from "components/IconButton/IconButton";
import Text, { TextInput } from "components/form/Text/Text";
import VirtualListWithKeyboard, { Item } from "components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import { FormFieldProps } from "components/form";
import UseLabelStrategy from "components/LabelStrategy/LabelStrategy";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";
const styles = require("./MultiSelect.less");

interface MultiSelectProps<T extends SelectItem> extends FormFieldProps<string[]> {
    value: string[];
    label?: string | JSX.Element;
    placeholder?: string;
    description?: string;
    items: T[];
    error?: string;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    hideFloatingLabel?: boolean;
    addNewTemplate?: (text: string) => string;
    renderItem?: (item: T) => Item;
    renderChip: (item: T | SelectItem, onRequestDelete: (event: object) => void) => React.ReactElement<ChipProps>;
    onNew?: (text: string) => void;
    multiSelectRef?(multiSelect: FocusableComponent | null): void;
    accessibleName?: string;
}

interface MultiSelectState {
    filter: string;
    open: boolean;
    isFocused: boolean;
}

const DebounceText = DebounceValue(Text);

export default function MultiSelect<T extends SelectItem>() {
    const VirtualList = VirtualListWithKeyboard<T>();

    class MultiSelectInternal extends React.Component<MultiSelectProps<T>, MultiSelectState> {
        static defaultProps: Partial<MultiSelectProps<T>> = {
            addNewTemplate: text => `${text} (add new)`,
            multiSelectRef: multiSelect => {
                /* Do nothing */
            },
            renderItem: (item: T) => ({ primaryText: item.Name }),
            items: [],
            value: [],
        };

        private updatePopoverPosition: () => void = undefined!;
        private textField: TextInput | undefined;
        private textAnchor: HTMLDivElement | undefined | null;
        private uniqueId: string = undefined!;
        private timeoutId: NodeJS.Timer = undefined!;
        private virtualList: FocusableComponent | null = undefined!;
        private skipOpenningOnNextFocus: boolean = false;

        constructor(props: MultiSelectProps<T>) {
            super(props);
            this.state = {
                filter: "",
                open: false,
                isFocused: false,
            };
        }

        focus() {
            this.focusText();
        }

        componentDidMount() {
            if (this.props.multiSelectRef) {
                this.props.multiSelectRef(this);
            }
        }

        componentWillUnmount() {
            if (this.props.multiSelectRef) {
                this.props.multiSelectRef(null);
            }
        }

        componentWillMount() {
            const uniqueId = `MultiSelect-${Math.floor(Math.random() * 0xffff)}`;
            this.uniqueId = uniqueId.replace(/[^A-Za-z0-9-]/gi, "");
        }

        render() {
            const errorTextElement = this.props.error && <div className={styles.error}>{this.props.error}</div>;

            // TODO: Replace with better null checking solution, discuss with team how to handle this situation.
            const value = this.props.value ? this.props.value : [];
            const items = this.props.items ? this.props.items : [];

            let newItem: SelectItem | null = null;
            if (this.state.filter.length > 0 && this.props.onNew) {
                // TODO: The case insensitive comparison here is specific to roles
                // (which is the only use of `newItem` at the moment) and probably shouldn't be hard-coded here.
                // Either toggle this behavior from a prop, or extract it from this component entirely
                const val = this.state.filter.toLowerCase();

                if (items.find(i => i.Name.toLowerCase() === val) === undefined && !value.find(i => i && i.toLowerCase() === val)) {
                    newItem = { Id: "", Name: this.state.filter };
                }
            }

            const filteredList = this.filteredList(items, value, this.state.filter);
            if (newItem) {
                filteredList.unshift(newItem as T);
            }

            if (this.state.open) {
                this.updatePopoverPositionWorkaround();
            }

            let label = this.props.label;
            if (this.props.description) {
                label = <ToolTip content={<Markdown markup={this.props.description} />}>{this.props.label}</ToolTip>;
            }

            const getNamesForSelectedValues = (values: string[]) => {
                const selectedItems = this.props.items.filter(i => values.includes(i.Id));
                return JSON.stringify(selectedItems.map(item => item.Name));
            };

            return (
                <div role="combobox" aria-label={this.props.accessibleName}>
                    <div>
                        {value.map(this.renderChip)}
                        {/* Hidden input that has the all the selected items as it's value. This helps us make this component more accessible and aids with tests.*/}
                        <input type="text" readOnly className={styles.visuallyHidden} aria-label={`Selected multiselect values: ${this.props.accessibleName}`} value={getNamesForSelectedValues(value)} tabIndex={-1} />
                    </div>
                    <div ref={el => (this.textAnchor = el)} className={styles.textContainer}>
                        <DebounceText
                            id={this.uniqueId}
                            textInputRef={(textField: TextInput) => (this.textField = textField)}
                            label={label}
                            placeholder={this.props.placeholder}
                            value={this.state.filter}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={e => this.onTextKeyDown(e, filteredList)}
                            onChange={this.onSearchTextChange}
                            onFocus={this.onTextFocus}
                            onBlur={this.onTextBlur}
                            onClick={() => this.setState({ open: true })}
                            debounceDelay={100} // Need this more responsive when adding new entries into a multi-select (I.e. roles).
                            generateUniqueName={true} // To stop browser's autocomplete shenanigans by force (when our autocomplete settings do not work).
                        />
                        <div className={styles.iconContainer}>
                            <IconButton icon={Icon.ArrowDown} onClick={this.onToggle} tabIndex={-1} accessibleName="ToggleDropDown" />
                        </div>
                    </div>
                    {errorTextElement}
                    <Popover
                        open={this.state.open}
                        disableAutoFocus={true}
                        anchorEl={this.textAnchor}
                        onClose={() => this.onRequestClose()}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        getUpdatePosition={update => (this.updatePopoverPosition = update)}
                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                    >
                        <div style={{ minWidth: this.textAnchor ? `${this.textAnchor.offsetWidth}px` : undefined }} onKeyDown={this.onKeyEsc}>
                            <VirtualList
                                multiSelectRef={el => (this.virtualList = el)}
                                items={filteredList}
                                onSelected={id => {
                                    this.onRequestClose(() => this.focusText(true));
                                    if (items.find(i => i.Id === id)) {
                                        if (this.props.onChange) {
                                            this.props.onChange(value.concat(id));
                                        }
                                    } else {
                                        if (this.props.onNew) {
                                            this.props.onNew(newItem!.Name!);
                                        }
                                    }
                                }}
                                onResized={() => {
                                    // When the content's size changes, we re-render so that the
                                    // popover can re-position itself based on the new `VirtualList` size
                                    // if (this.updatePopoverPosition) {
                                    //     this.updatePopoverPosition();
                                    // }
                                    this.updatePopoverPositionWorkaround();
                                }}
                                renderItem={this.props.renderItem}
                                addNewTemplate={() => {
                                    if (this.props.addNewTemplate) {
                                        return this.props.addNewTemplate(newItem!.Name);
                                    }
                                    return null;
                                }}
                                onBlur={() => this.textField?.focus()}
                            />
                        </div>
                    </Popover>
                </div>
            );
        }

        private onTextBlur = () => {
            // We using a timout here to stop the flickering, see https://medium.com/@jessebeach/dealing-with-focus-and-blur-in-a-composite-widget-in-react-90d3c3b49a9b
            this.timeoutId = setTimeout(() => {
                if (this.state.isFocused) {
                    this.setState({
                        isFocused: false,
                    });
                }
            }, 0);
        };

        private onTextFocus = () => {
            clearTimeout(this.timeoutId);

            if (!this.state.isFocused) {
                this.setState({ isFocused: true });
            }

            if (!this.skipOpenningOnNextFocus) {
                this.setState({
                    open: this.props.openOnFocus !== undefined ? this.props.openOnFocus : false,
                });
            }

            this.skipOpenningOnNextFocus = false;
        };

        private focusText = (skipOpenningOnNextFocus?: boolean) => {
            this.skipOpenningOnNextFocus = !!skipOpenningOnNextFocus;

            if (this.textField) {
                this.textField.focus();
            }
        };

        private onTextKeyDown = (event: KeyboardEvent, filteredList: SelectItem[]) => {
            if (event.key === "ArrowDown") {
                this.setState({
                    open: true,
                });

                if (this.state.open) {
                    this.virtualList?.focus();
                }

                event.preventDefault();
            }

            if (event.key === "Tab") {
                this.onRequestClose();
            }

            // To stop ppl accidentally triggering deployments when using multi-selects in deployment screens!
            if (event.key === "Enter") {
                event.preventDefault();
                this.executeOnNewHandlerOnEnter();
            }
        };

        private executeOnNewHandlerOnEnter() {
            // If they've typed a new entry and hit enter...
            if (this.state.filter.length > 0 && this.props.onNew) {
                // TODO: The case insensitive comparison here is specific to roles
                // (which is the only use of `newItem` at the moment) and probably shouldn't be hard-coded here.
                // Either toggle this behavior from a prop, or extract it from this component entirely
                const val = this.state.filter.toLowerCase();
                const items = this.props.items ? this.props.items : [];
                const value = this.props.value ? this.props.value : [];
                let newItem: SelectItem | null = null;
                if (items.find(i => i.Name.toLowerCase() === val) === undefined && !value.find(i => i && i.toLowerCase() === val)) {
                    newItem = { Id: "", Name: this.state.filter };
                }
                if (newItem) {
                    this.props.onNew(newItem.Name);
                    this.setState({
                        open: false,
                        filter: "",
                    });
                }
            }
        }

        private onKeyEsc = (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Escape") {
                this.onRequestClose(() => this.focusText(true));
            }
        };

        private filteredList(items: T[], value: string[], filter: string) {
            let results = items.slice(); //clone array

            if (filter.length > 0) {
                const search = filter.toLowerCase();
                results = items.filter(i => i.Name.toLowerCase().includes(search));
            }

            results = results.filter(i => !value.includes(i.Id)); // filter out existing selected items

            return results;
        }

        private onSearchTextChange = (val: string) => {
            this.setState({
                open: true,
                filter: val.trim(),
            });
        };

        private onToggle = () => {
            this.setState(prevState => ({
                open: !prevState.open,
            }));
        };

        private onRequestClose = (callback?: () => void) => {
            this.setState(
                {
                    filter: "",
                    open: false,
                },
                callback
            );
        };

        private renderChip = (id: string, index: number) => {
            const item = this.props.items && this.props.items.find(i => i.Id === id);
            const element = this.props.renderChip(item || { Id: id, Name: id }, (event: object) => {
                if (this.props.onChange) {
                    this.props.onChange(this.props.value?.filter(i => i !== id)!);
                }
            });

            if (!React.isValidElement(element)) {
                return null;
            }

            return <span key={id}>{element}</span>;
        };

        // TODO: This is a workaround to this issue - https://github.com/mui-org/material-ui/issues/16901
        // MUI Core version at the time of this workaround: 4.0.2, official MUI fix is in 4.6.0
        // What's happening on octopus? - If the list goes beyond the window, the overflow is hidden and you can scroll
        // dispatching a resize event, fires an internal update function in MUI. The `updatePopoverPosition` function in
        // no longer works, therefore same work around is use in line onResized function in virtual list
        private updatePopoverPositionWorkaround = () => {
            window.requestAnimationFrame(() => {
                window.dispatchEvent(new CustomEvent("resize"));
            });
        };
    }

    return UseLabelStrategy(MultiSelectInternal, fieldName => `Select ${fieldName}`);
}
