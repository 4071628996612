import * as React from "react";
import SvgImage, { SvgImageProps } from "../../SvgImage";
const styles = require("../../styles.less");

const ExecuteWorker = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 82 36" title="Execute Worker" {...props}>
        <g fillRule="evenodd" strokeWidth="1">
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M63.446 25v4.948a3.357 3.357 0 0 0-1.21.769 2.53 2.53 0 0 0-.664 1.12h-5.086c-.324.143-.486.356-.486.637s.162.49.486.628h5.086C62.047 34.367 62.856 35 64 35s1.937-.633 2.38-1.898h5.007c.409-.112.613-.32.613-.628 0-.307-.204-.52-.613-.638H66.38c-.111-.462-.3-.835-.568-1.12-.267-.284-.654-.523-1.159-.716v-5h-1.207z"
            />
            <path
                className={styles.imgDark}
                d="M20 12.972h19.672v-4.18c-.027-.366.102-.613.386-.742.283-.129.624-.008 1.02.362l4.625 4.56c.375.317.563.621.563.912 0 .291-.115.55-.344.774L41.078 19.4c-.413.3-.753.391-1.02.275-.268-.116-.396-.401-.386-.856v-4.544H20v-1.302z"
            />
            <path className={styles.imgDark} fillRule="nonzero" d="M63.53.22H48.47a.88.88 0 0 0-.88.88V28a.88.88 0 0 0 .88.88h32.36a.89.89 0 0 0 .88-.88V1.1a.89.89 0 0 0-.88-.88H65.76" />
            <path className={styles.imgLight} fillRule="nonzero" d="M57.7 14.1a7.11 7.11 0 0 0 13.94 0H57.7zm5.66-7.47H66l.84-3.51a1.44 1.44 0 0 0-1.15-.57h-2.08a1.43 1.43 0 0 0-1.21.66l.96 3.42z" />
            <path
                className={styles.imgLight}
                fillRule="nonzero"
                d="M72.24 10.44H72A7.27 7.27 0 0 0 68.12 4l-.85 3.5a.73.73 0 0 1-.71.55h-3.75a.72.72 0 0 1-.7-.53l-1-3.49a7.28 7.28 0 0 0-3.77 6.38h-.3a1.07 1.07 0 1 0 0 2.14h15.19a1.07 1.07 0 1 0 0-2.14l.01.03z"
            />
            <circle className={styles.imgLight} cx="68.3" cy="25.79" r="1" fillRule="nonzero" />
            <circle className={styles.imgLight} cx="61.13" cy="25.81" r="1" fillRule="nonzero" />
            <path
                className={styles.imgLight}
                fillRule="nonzero"
                d="M74.69 26.81a10.58 10.58 0 0 0-4.87-6.51v6.49l4.87.02zm-15.2-1.54v-5c-2.27 1.46-4.38 3.8-4.88 6.52h4.89v-1.51l-.01-.01zm1.21-.76c2.626.33 5.284.33 7.91 0v-4.26a7.69 7.69 0 0 1-7.91 0v4.26z"
            />
            <circle className={styles.imgCyan} cx="12.92" cy="14.37" r="12.6" fillRule="nonzero" />
            <path
                className={styles.imgWhite}
                fillRule="nonzero"
                d="M6.7 18.31c1.14-.74 2.48-2 2-3.49a9 9 0 0 1-.7-2.39 5.43 5.43 0 0 1 .32-2.18A5.53 5.53 0 0 1 15.11 7a4.92 4.92 0 0 1 3.26 6.31c-.62 1.5-.89 2.64.48 3.8.36.31 1.26.78 1.25 1.34 0 .73-1.44-.16-1.6-.28.19.31 2 2.2.85 2.33-1.15.13-2-1.35-2.63-2-1.07-1.07-.88 1.29-.89 1.78 0 .77-.55 2.33-1.52 1.32-.8-.84-.5-2.18-1.06-3.11-.56-.93-1.63 1-1.89 1.39-.26.39-1.71 2.43-2.27 1.36-.47-.87.27-2.24.64-3-.14.28-1.07.71-1.34.85a3.65 3.65 0 0 1-1.94.45c-1.44-.09-.34-.85.25-1.23z"
            />
        </g>
    </SvgImage>
);
export { ExecuteWorker };
