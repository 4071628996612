/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
const styles = require("./style.less");
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import moment from "moment";
import { Moment } from "moment";
import { Callout, CalloutType } from "components/Callout/Callout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import DatePicker from "./DatePicker/datePicker";
import rollForward from "./rollFoward";
import TimePicker from "./TimePicker/timePicker";
import { DeploymentModelType } from "areas/projects/components/Runbooks/RunbookRunNowLayout";

interface NowOrLaterProps {
    modelType: DeploymentModelType;
    onScheduleDatesSet(scheduledAt: Moment, expiresAt: Moment): void;
}

export interface NowOrLaterState extends DataBaseComponentState {
    nowOrLater: NowOrLaterEnum;
    scheduledAt: Moment;
    expiresAt: Moment;
    userTimezone: string;
    scheduledDatePopupDate: Moment;
    expiryDatePopupDate: Moment;
}

export enum NowOrLaterEnum {
    Now,
    Later,
}

function initialState(currentTime: Date): NowOrLaterState {
    const scheduledTime = rollForward(moment(currentTime), 10);
    const expiryTime = rollForward(scheduledTime, 30);
    return {
        nowOrLater: NowOrLaterEnum.Now,
        scheduledAt: moment(scheduledTime),
        expiresAt: moment(expiryTime),
        userTimezone: moment(currentTime).format("[GMT] Z"),
        expiryDatePopupDate: null!,
        scheduledDatePopupDate: null!,
    };
}

export default class NowOrLater extends DataBaseComponent<NowOrLaterProps, NowOrLaterState> {
    constructor(props: NowOrLaterProps) {
        super(props);
        this.state = initialState(new Date());
    }

    render() {
        const modelTypeLabel = this.props.modelType === DeploymentModelType.Deployment ? "deployment" : "run";
        const help = this.props.modelType === DeploymentModelType.Deployment ? "Choose when to start deployment" : "Choose when to run your runbook";
        return (
            <ExpandableFormSection title="When" errorKey="when" summary={this.buildSummary()} help={help}>
                <div className={styles.nowOrLaterContainer}>
                    <RadioButtonGroup
                        value={this.state.nowOrLater}
                        onChange={val => {
                            this.setState({ nowOrLater: val as NowOrLaterEnum }, () => this.updateScheduleDates());
                        }}
                    >
                        <RadioButton value={NowOrLaterEnum.Now} label="Now" isDefault={true} />
                        <RadioButton value={NowOrLaterEnum.Later} label="Later" />
                    </RadioButtonGroup>
                    {this.state.nowOrLater === NowOrLaterEnum.Later && (
                        <div className={styles.nowOrLaterCustomize}>
                            {this.state.expiresAt && this.state.scheduledAt && this.state.expiresAt <= this.state.scheduledAt && <Callout type={CalloutType.Warning} title={"The expiry date must be after the scheduled date"} />}

                            <h4>Scheduled start time</h4>
                            <div className={styles.dateTimeRow}>
                                <DatePicker date={this.state.scheduledAt} onDateChanged={this.handleScheduledAtChanged} />
                                <TimePicker time={this.state.scheduledAt} onTimeChanged={this.handleScheduledAtChanged} />
                                {this.state.userTimezone}
                            </div>
                            <h4>Expiry time</h4>
                            <div className={styles.dateTimeRow}>
                                <DatePicker date={this.state.expiresAt} onDateChanged={expiresAt => this.setState({ expiresAt }, () => this.updateScheduleDates())} />
                                <TimePicker
                                    time={this.state.expiresAt}
                                    onTimeChanged={expiresAt => {
                                        this.setState({ expiresAt }, () => this.updateScheduleDates());
                                    }}
                                />
                                {this.state.userTimezone}
                            </div>
                            {this.state.expiresAt > this.state.scheduledAt && (
                                <Callout type={CalloutType.Information} title={`Scheduled ${modelTypeLabel}`}>
                                    The {modelTypeLabel} will start in approximately <b>{this.momentFromFilter(this.state.scheduledAt, moment())} </b>
                                    and must begin within <b>{this.momentFromFilter(this.state.expiresAt, this.state.scheduledAt)}</b> of the scheduled start time or it will time out.
                                </Callout>
                            )}
                        </div>
                    )}
                </div>
            </ExpandableFormSection>
        );
    }

    private handleScheduledAtChanged = (scheduledAt: Moment) => {
        this.setState(
            state => {
                // adjust the expiry to keep the diff the same
                const previousDiff = moment.duration(state.expiresAt.diff(state.scheduledAt));
                const expiresAt = moment(scheduledAt).add(previousDiff);
                return {
                    scheduledAt,
                    expiresAt,
                };
            },
            () => this.updateScheduleDates()
        );
    };

    private updateScheduleDates() {
        if (this.state.nowOrLater !== NowOrLaterEnum.Later) {
            this.props.onScheduleDatesSet(null!, null!);
            return;
        }

        let scheduledAt = this.state.scheduledAt;
        const now = moment().add(1, "minute"); // server will return an error error if you schedule for _now_
        if (scheduledAt.isBefore(now)) {
            scheduledAt = now;
        }

        let expiresAt = this.state.expiresAt;
        const minimum = moment(scheduledAt).add(5, "minutes");
        if (expiresAt.isBefore(minimum)) {
            expiresAt = minimum;
        }

        this.setState({
            scheduledAt,
            expiresAt,
        });
        this.props.onScheduleDatesSet(scheduledAt, expiresAt);
    }

    private momentFromFilter(fromDate: any, toDate: any) {
        if (!fromDate) {
            return null;
        }

        const fromMoment = moment(fromDate);
        const toMoment = toDate ? moment(toDate) : moment();

        const diff = fromMoment.diff(toMoment);
        const duration = moment.duration(diff);
        const mins = duration.minutes();
        const hours = duration.hours();
        const days = Math.floor(duration.asDays());
        let result = mins === 1 ? "1 minute" : mins + " minutes";
        result = (hours !== 0 ? (hours === 1 ? "1 hour" : hours + " hours") + " and " : "") + result;
        result = (days !== 0 ? (days === 1 ? "1 day" : days + " days") + ", " : "") + result;
        return result;
    }

    private buildSummary() {
        const modelTypeLabel = this.props.modelType === DeploymentModelType.Deployment ? "deployment" : "run";
        return this.state.nowOrLater === NowOrLaterEnum.Now
            ? Summary.default("Now")
            : Summary.summary(
                  <div>
                      The {modelTypeLabel} will start in approximately {this.momentFromFilter(this.state.scheduledAt, moment())}
                  </div>
              );
    }
}
