import { MachinesLayoutProps, MachinesLayoutState, BaseMachinesLayout } from "./BaseMachinesLayout";
import { repository } from "clientInstance";
import { EnvironmentsSummaryResource, EnvironmentResource, TenantResource, Permission, SummaryResource } from "client/resources";
import { TagIndex } from "components/tenantTagsets";
import * as tenantTagsets from "components/tenantTagsets";
import * as React from "react";
import { PermissionCheck } from "components/PermissionCheck";
import { NavigationButton, NavigationButtonType } from "components/Button";
import routeLinks from "routeLinks";
import { cloneDeep, isEqual } from "lodash";
import OnboardingDeploymentTargets from "../EnvironmentsLayout/OnboardingDeploymentTargets";
import Section from "components/Section";
import { EnvironmentMultiSelect, RoleMultiSelect, TenantMultiSelect, TenantTagMultiSelect, ShellNameMultiSelect } from "components/MultiSelect";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router";
import { machineActions } from "../../reducers/machines";
import DeploymentTargetsSummarySection from "./DeploymentTargetsSummarySection";
import { NoResults } from "components/NoResults/NoResults";
import { Dispatch, Action } from "redux";
import FormPage from "components/FormPage/FormPage";
import { EnvironmentSummaryQuery } from "../EnvironmentsLayout/EnvironmentSummaryQuery";
import { EnvironmentSummaryFilter, defaultEnvironmentSummaryFilter, environmentSummaryFilterToQuery, environmentSummaryQueryToFilter, createEnvironmentSummaryArgs } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import URI from "urijs";
import InfrastructureLayout, { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

const { machineCleared } = machineActions;

interface DeploymentTargetsLayoutProps extends MachinesLayoutProps<EnvironmentSummaryFilter, EnvironmentSummaryQuery>, RouteComponentProps {
    filterToQuery: (filter: EnvironmentSummaryFilter) => EnvironmentSummaryQuery;
    initialData: InitialData;
    defaultFilter: EnvironmentSummaryFilter;
    onClearMachine(): void;
}

interface DeploymentTargetsLayoutState extends MachinesLayoutState<EnvironmentSummaryFilter> {
    environmentsSummary: EnvironmentsSummaryResource;
    filter: EnvironmentSummaryFilter;
}

interface InitialData {
    environments: EnvironmentResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    hasMachines: boolean;
    machineShellNames: string[];
    environmentsSummary: EnvironmentsSummaryResource;
}

const Title = "Deployment Targets";
const DeploymentTargetsLayoutFormPage = FormPage<InitialData>();
const DeploymentTargetsLayout: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const query = URI(props.location.search).search(true);
    const filter = environmentSummaryQueryToFilter(query);

    return (
        <DeploymentTargetsLayoutFormPage
            title={Title}
            load={async () => {
                const defaultArgs = createEnvironmentSummaryArgs(filter);
                const environments = repository.Environments.all();
                const machineRoles = repository.MachineRoles.all();
                const tenants = repository.Tenants.all();
                const machineShellNames = repository.MachineShells.all();
                const environmentsSummary = repository.Environments.summary(defaultArgs);

                return {
                    environments: await environments,
                    machineRoles: await machineRoles,
                    tenants: await tenants,
                    tagIndex: await tenantTagsets.getTagIndex(),
                    hasMachines: (await repository.Machines.list({ take: 0 })).TotalResults > 0,
                    machineShellNames: await machineShellNames,
                    environmentsSummary: await environmentsSummary,
                };
            }}
            renderWhenLoaded={data => (
                <DeploymentTargetsLayoutConnected initialFilter={filter} defaultFilter={defaultEnvironmentSummaryFilter} filterToQuery={environmentSummaryFilterToQuery} initialData={data} hasMachines={data.hasMachines} {...props} />
            )}
            renderAlternate={args => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class DeploymentTargetsLayoutInternal extends BaseMachinesLayout<DeploymentTargetsLayoutProps, DeploymentTargetsLayoutState, EnvironmentSummaryFilter, EnvironmentSummaryQuery> {
    constructor(props: DeploymentTargetsLayoutProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            filter: props.initialFilter,
            queryFilter: props.initialFilter,
            environmentsSummary: this.props.initialData.environmentsSummary,
        };
    }

    async componentDidMount() {
        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }

    protected getNameFilter(searchHintText: string): JSX.Element[] {
        return [
            <FilterSearchBox
                placeholder={searchHintText}
                value={this.state.filter.machinePartialName}
                onChange={x => {
                    this.setFilterState({ machinePartialName: x }, this.onFilterChange);
                }}
                autoFocus={true}
            />,
        ];
    }

    protected async loadSummaries(): Promise<void> {
        const args = createEnvironmentSummaryArgs(this.state.filter);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Environments.summary(args), response => {
            this.setState({
                environmentsSummary: response,
            });
        });
    }

    protected getSummaries(): SummaryResource {
        return this.props.initialData.environments && this.state.environmentsSummary;
    }

    protected isFiltering() {
        return !isEqual(this.state.filter, this.props.defaultFilter);
    }

    protected extraFilters(): React.ReactNode[] {
        return [
            <EnvironmentMultiSelect
                key="filterEnvironment"
                items={this.props.initialData.environments}
                value={this.state.filter.environmentIds}
                onChange={x => {
                    this.setFilterState({ environmentIds: x }, this.onFilterChange);
                }}
            />,
            <RoleMultiSelect
                key="filterRole"
                items={this.props.initialData.machineRoles ? this.props.initialData.machineRoles : []}
                value={this.state.filter.roles}
                onChange={x => {
                    this.setFilterState({ roles: x }, this.onFilterChange);
                }}
            />,
            <FeatureToggle feature={Feature.MultiTenancy} key="filterMultiTenancy">
                <PermissionCheck permission={Permission.TenantView} tenant="*">
                    <TenantMultiSelect
                        value={this.state.filter.tenantIds}
                        items={this.props.initialData.tenants}
                        onChange={x => {
                            this.setFilterState({ tenantIds: x }, this.onFilterChange);
                        }}
                    />
                    <TenantTagMultiSelect
                        value={this.state.filter.tenantTags}
                        doBusyTask={this.doBusyTask}
                        onChange={x => {
                            this.setFilterState({ tenantTags: x }, this.onFilterChange);
                        }}
                    />
                </PermissionCheck>
            </FeatureToggle>,
            <ShellNameMultiSelect
                key="filterShellName"
                items={this.props.initialData.machineShellNames ? this.props.initialData.machineShellNames : []}
                value={this.state.filter.shellNames}
                onChange={x => {
                    this.setFilterState({ shellNames: x }, this.onFilterChange);
                }}
            />,
        ];
    }

    protected getActions(): JSX.Element[] {
        return [
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*" key="perms">
                <NavigationButton href={routeLinks.infrastructure.machines.new()} label="Add deployment target" type={NavigationButtonType.Primary} />
            </PermissionCheck>,
        ];
    }

    protected renderOnboarding(): JSX.Element {
        return <OnboardingDeploymentTargets />;
    }

    protected renderMachinesExpander(): React.ReactNode {
        let machinesExpander: React.ReactNode = null;
        const environmentsSummaries = this.props.initialData.environments && this.state.environmentsSummary;
        if (environmentsSummaries) {
            machinesExpander = (
                <DeploymentTargetsSummarySection
                    key="allMachines"
                    environmentsSummary={environmentsSummaries}
                    filter={this.state.filter}
                    tenants={this.props.initialData.tenants}
                    tagIndex={this.props.initialData.tagIndex}
                    environments={this.props.initialData.environments}
                />
            );
        }

        if (this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length === 0) {
            machinesExpander = (
                <Section>
                    <NoResults />
                </Section>
            );
        }

        return machinesExpander;
    }
}

const mapGlobalStateToProps = (state: GlobalState) => {
    return {
        title: Title,
        itemDescriptions: "deployment targets",
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onClearMachine: () => {
            dispatch(machineCleared());
        },
    };
};

const DeploymentTargetsLayoutConnected = withRouter(connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(DeploymentTargetsLayoutInternal));

export default DeploymentTargetsLayout;
