import * as React from "react";
import PaperLayout from "components/PaperLayout";
import Section from "components/Section";
import { ExpandableFormSection, Summary } from "components/form";
import Callout, { CalloutType } from "components/Callout";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import { DataTable, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn, DataTableBody } from "components/DataTable";
import OpenDialogImage from "components/Dialog/OpenDialogImage";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import ExternalLink from "components/Navigation/ExternalLink";
import FormPaperLayout from "components/FormPaperLayout";
import { noOp } from "utils/noOp";
const styles = require("./UxGuidelinesCheatSheet.less");

const accessibilityGoodImage = require("./examples/accessibility-good.png");
const accessibilityBadImage = require("./examples/accessibility-bad.png");
const alignmentGoodImage = require("./examples/alignment-good.png");
const alignmentBadImage = require("./examples/alignment-bad.png");
const loadingGoodImage = require("./examples/loading-good.png");
const loadingBadImage = require("./examples/loading-bad.png");
const onboardingGoodImage = require("./examples/onboarding-good.png");
const onboardingBadImage = require("./examples/onboarding-bad.png");
const onboardingSidebarGoodImage = require("./examples/onboarding-sidebar-good.png");
const onboardingSidebarBadImage = require("./examples/onboarding-sidebar-bad.png");
const performanceGoodImage = require("./examples/performance-good.png");
const performanceBadImage = require("./examples/performance-bad.png");
const themeGoodImage = require("./examples/theme-good.png");
const themeBadImage = require("./examples/theme-bad.png");

interface UxGuidelinesEssentialsModel {
    title: string;
    question: React.ReactNode;
    answer: React.ReactNode;
    goodExampleImage?: string;
    goodSummary?: React.ReactNode;
    badExampleImage?: string;
    badSummary?: React.ReactNode;
    keyTakeaway?: React.ReactNode;
}

const UxGuidelinesCheatSheet: React.FC<never> = () => {
    const essentials = getSortedEssentials();
    return (
        <FormPaperLayout title={"Cheat Sheet"} model={undefined} cleanModel={undefined} onSaveClick={() => noOp()} customPrimaryAction={<span />}>
            <Section>
                <Callout type={CalloutType.Success} title={"Cheat Sheet"}>
                    Use this list to help get yourself into the headspace of engineering great UX.
                </Callout>
                {essentials.map(value => {
                    const summaryNode = (
                        <div className={styles.summaryContainer}>
                            <div className={styles.question}>Q. {value.question}</div>
                            <div className={styles.answer}>A. {value.answer}</div>
                        </div>
                    );
                    const goodSummary = value.goodSummary && (
                        <Callout type={CalloutType.Success} title={"Note"}>
                            {value.goodSummary}
                        </Callout>
                    );
                    const badSummary = value.badSummary && (
                        <Callout type={CalloutType.Danger} title={"Note"}>
                            {value.badSummary}
                        </Callout>
                    );
                    return (
                        <ExpandableFormSection errorKey={value.title} title={value.title} summary={Summary.summary(summaryNode)} help={summaryNode} fillCardWidth={CardFill.FillRight}>
                            {value.goodExampleImage && value.badExampleImage ? (
                                <DataTable>
                                    <DataTableHeader>
                                        <DataTableRow>
                                            <DataTableHeaderColumn>Good</DataTableHeaderColumn>
                                            <DataTableHeaderColumn>Bad</DataTableHeaderColumn>
                                        </DataTableRow>
                                    </DataTableHeader>
                                    <DataTableBody>
                                        <DataTableRow>
                                            <DataTableRowColumn>
                                                <OpenDialogImage image={value.goodExampleImage} imageAlt="Good example" thumbnailWidth={320} thumbnailHeight={200} summary={goodSummary} />
                                            </DataTableRowColumn>
                                            <DataTableRowColumn>
                                                <OpenDialogImage image={value.badExampleImage} imageAlt="Bad example" thumbnailWidth={320} thumbnailHeight={200} summary={badSummary} />
                                            </DataTableRowColumn>
                                        </DataTableRow>
                                    </DataTableBody>
                                </DataTable>
                            ) : (
                                <Callout type={CalloutType.Warning} title="We need your help to populate this guide!">
                                    Got an example that you think can highlight this particular UX guideline? Please let the #topic-ux channel know :)
                                </Callout>
                            )}
                            {value.keyTakeaway && (
                                <Callout type={CalloutType.Information} title="Key Takeaway">
                                    {value.keyTakeaway}
                                </Callout>
                            )}
                        </ExpandableFormSection>
                    );
                })}
            </Section>
        </FormPaperLayout>
    );
};

const getSortedEssentials = () => {
    const essentials: UxGuidelinesEssentialsModel[] = [];
    essentials.push({
        title: "Accessibility (fonts)",
        question: "I use the my browser's font-size settings to help me read. Does this all 'just work' for me?",
        answer: "Yes, everything scales correctly when I change my browser's default font size.",
        goodExampleImage: accessibilityGoodImage,
        goodSummary: "All text has scaled with the user's font-size settings to help them read text.",
        badExampleImage: accessibilityBadImage,
        badSummary: (
            <div>
                <p>All text has NOT scaled with the user's font-size settings due to inconsistencies in our usage of `px` vs `rem` in our styles.</p>
                <p>
                    User's who have difficulty reading will be frustrated by this, forcing them to then rely on zoom-settings <i>on top of</i> their font-size settings.
                </p>
            </div>
        ),
        keyTakeaway: (
            <div>
                <p>Imagine you (the user) have to squint to read some text on the screen and the browser's font-size settings make no difference for you.</p>
                <p>
                    <i>Let's remove that frustration and make a great experience!</i>
                </p>
                <p>Using `rem` (not `px`) ensures we're scaling up/down naturally with a user's font-size settings.</p>
            </div>
        ),
    });
    essentials.push({
        title: "Alignments",
        question: "Does this have the level of polish I've come to expect from an Octopus feature?",
        answer: "Yes, there's clean lines and consistent spacing. Nothing causes unnecessary strain on my eyes.",
        goodExampleImage: alignmentGoodImage,
        goodSummary: "You can trace a vertical line down the left edge of all elements and everything aligns as expected.",
        badExampleImage: alignmentBadImage,
        badSummary: (
            <div>
                <p>No care has been taken in this case to keep a clean left edge to all elements.</p>
                <p>
                    This causes <i>more eye-strain on the consumer</i>, as their brain struggles to make the adjustments to the order and priority of what they're seeing.
                </p>
            </div>
        ),
        keyTakeaway: (
            <div>
                <p>Imagine you (the user) have a short time-frame to learn this new Octopus feature, but alignments and spacing are so bad they're putting additional stress on your eyes.</p>
                <p>
                    <i>Let's remove that frustration and make a great experience!</i>
                </p>
                <p>Alignments and spacing, when done well, makes your content easier to consume = less for users to think about.</p>
            </div>
        ),
    });
    essentials.push({ title: "Clarity", question: "At a glance, is it clear what I need to do?", answer: "Yes, there is a clear path to success, free of unnecessary noise." });
    essentials.push({
        title: "Consistency",
        question: "Have I been consistent with components?",
        answer: (
            <span>
                Yes, I've used components from our <InternalLink to={routeLinks.styleGuide}>style guide</InternalLink>.
            </span>
        ),
    });
    essentials.push({
        title: "Intuitiveness",
        question: "Have we put on both hats re. beginners and advanced users?",
        answer: "Yes, I've role-played as both types of customers, people new to the product and seasoned veterans.",
    });
    essentials.push({
        title: "Loading",
        question: "I'm on a slow network. Is it clear when things are loading?",
        answer: "Yes, loading indicators are working as expected.",
        goodExampleImage: loadingGoodImage,
        goodSummary: "The loading indicator is active when network requests are pending, informing the user that activity is happening and they need to wait.",
        badExampleImage: loadingBadImage,
        badSummary: (
            <div>
                <p>There's no indication that network operations are pending. The user is left wondering whether anything's happening.</p>
                <p>
                    <i>"Do I need to refresh the page? What's going on?"</i>
                </p>
            </div>
        ),
        keyTakeaway: (
            <div>
                <p>Imagine you (the user) are stuck using Octopus on a slow network, every ... single ... day. You rely on a feature that forces you to wait 30+ seconds for a screen to load/render your hundreds of records.</p>
                <p>
                    <i>Let's remove that frustration and make a great experience!</i>
                </p>
                <p>
                    Putting your browser into Slow3G-mode before creating your PR can help you spot edge-cases around network loading and, most importantly, you get to experience what customers on slow networks will feel when they use your feature.
                </p>
            </div>
        ),
    });
    essentials.push({ title: "Loveable", question: "I could do all of this from the API, is the UI providing me with a 'wow' effect?", answer: "Yes, this connects a lot of dots for me and makes me want to use the UI because I love it!" });
    essentials.push({ title: "Minimalism", question: "Do I feel a sense of calm when I encounter this page for the first time?", answer: "Yes, noise has been carefully considered." });
    essentials.push({ title: "Mobile", question: "I'm using my phone to access this feature, is it fully usable?", answer: "Yes, everything works as I expect on my mobile." });
    essentials.push({
        title: "Onboarding",
        question: "It's my first time using this feature, can I learn this easily?",
        answer: "Yes, onboarding has been carefully considered for my first time through.",
        goodExampleImage: onboardingGoodImage,
        goodSummary: "Onboarding is included to introduce a user to this feature.",
        badExampleImage: onboardingBadImage,
        badSummary: "No onboarding is present, leaving the user with no introduction to the feature. This may cause them to abandon the page and only come back once they've searched for external documentation themselves.",
        keyTakeaway: (
            <div>
                <p>Imagine you (the user) have just installed Octopus for the very first time. You don't know the terminology and there's no onboarding to help you figure things out.</p>
                <p>
                    <i>Let's remove that frustration and make a great experience!</i>
                </p>
                <p>We (as developers and users) spend a lot of time looking at screens that are populated with one or many records, that we often forget what it was like to populate that very first record.</p>
                <p>Make sure you've taken the time to properly introduce your feature :)</p>
            </div>
        ),
    });
    essentials.push({
        title: "Onboarding Sidebar",
        question: "I'm joining a team that's already using Octopus (that's populated with data). Can I still learn about this feature without the original onboarding?",
        answer: "Yes, sidebar-onboarding has been carefully considered for users joining existing teams.",
        goodExampleImage: onboardingSidebarGoodImage,
        goodSummary: "Sidebar text is included to introduce a user to this feature, including a targeted 'X.509 certificates' documentation link for more information.",
        badExampleImage: onboardingSidebarBadImage,
        badSummary: (
            <div>
                <p>The sidebar is using the default 'Need some help?' placeholder, with nothing related to this feature.</p>
                <p>This leaves the user with no introduction to the feature (nor targeted documentation on where they can find help).</p>
                <p>This may cause them to abandon the page and only come back once they've searched and found external documentation themselves.</p>
            </div>
        ),
        keyTakeaway: (
            <div>
                <p>Imagine you (the user) have just joined an existing team that's been using Octopus for years. You don't know the terminology and there's no help text to help you figure things out.</p>
                <p>
                    <i>Let's remove that frustration and make a great experience!</i>
                </p>
                <p>The sidebar allows us to communicate help text about features to new users joining existing Octopus instances.</p>
                <p>Make sure you've taken the time to properly introduce your feature in the sidebar :)</p>
            </div>
        ),
    });
    essentials.push({
        title: "Performance",
        question: "Will this feature scale with me, as I move to hundreds/thousands of things?",
        answer: "Yes, I've scaled my instance to confirm this feature works well for customers operating at scale.",
        goodExampleImage: performanceGoodImage,
        goodSummary: "You have loaded up hundreds/thousands of records and confirmed the experience for scale customers.",
        badExampleImage: performanceBadImage,
        badSummary: (
            <div>
                <p>No care has been taken in this case to check for customers who may be operating at scale.</p>
                <p>Note the hundreds of records that are being returned by the API and rendered to this user's browser. This means that the more records a customer has, the worse their experience becomes. Not good.</p>
            </div>
        ),
        keyTakeaway: (
            <div>
                <p>Imagine you (the user) suddenly need to scale to hundreds of machine policies, but the screen suddenly takes 10+ seconds to load and you're left staring at loading indicators for a significant portion of your day.</p>
                <p>
                    <i>Let's remove that frustration and make a great experience!</i>
                </p>
                <p>Performance is a key part of a great user-experience. We, as users, like things that are fast/responsive and scale up as we add more data.</p>
                <p>
                    Use our internal tools like <ExternalLink href={"https://github.com/OctopusDeploy/SeaMonkey"}>SeaMonkey</ExternalLink> to load up hundreds/thousands of records for the features you are working on.
                </p>
                <p>Would you be happy using this feature several times a day at scale?</p>
            </div>
        ),
    });
    essentials.push({
        title: "Themes",
        question: "I prefer [insert theme preference]. Has my theme been fully considered?",
        answer: "Yes, it's clear this feature has been manually tested in all themes.",
        goodExampleImage: themeGoodImage,
        goodSummary: "All text is clearly readable against the background. E.g. the information callout text.",
        badExampleImage: themeBadImage,
        badSummary: "Not all text is clearly readable in this case. The information callout text is unreadable because of a theme bug (it's incorrectly using the light theme styling).",
        keyTakeaway: (
            <div>
                <p>Imagine you (the user) have been using the light theme for years, and Octopus introduce a dark theme and suddenly you can't read text because the foreground/background are the same colour.</p>
                <p>
                    <i>Let's remove that frustration and make a great experience!</i>
                </p>
                <p>Don't assume everyone uses the same theme as you. Trying all the theme combinations allows you to spot edge cases like this early.</p>
            </div>
        ),
    });
    return essentials.sort((a, b) => a.title.localeCompare(b.title));
};

export default UxGuidelinesCheatSheet;
