import { repository } from "../../clientInstance";
import { AccountType } from "../../client/resources/accountResource";
import { AmazonWebServicesIcon, AzureIcon, SshKeyIcon, UsernamePasswordIcon, TokenIcon } from "./components/AccountsLayout/AccountIcons";
import * as React from "react";
import { MachineModelHealthStatus } from "client/resources";

interface AccountTypeDetails {
    types: AccountType[];
    name: string;
    namePlural: string;
    icon: JSX.Element;
    description: JSX.Element;
    /**
     * If true, this account type will only be displayed in the UI for EAP builds
     */
    eapOnly: boolean;
}

/**
 * A collection that defines the variable types available to the UI
 */
export const RawAccountTypeDetailsMap: AccountTypeDetails[] = [
    {
        types: [AccountType.AzureSubscription, AccountType.AzureServicePrincipal],
        name: "Azure Subscription",
        namePlural: "Azure Subscriptions",
        icon: <AzureIcon />,
        eapOnly: false,
        description: (
            <div>
                Use <em>Azure Subscriptions</em> to set subscription IDs, management certificates, and service principals to use when deploying to Microsoft Azure.
            </div>
        ),
    },
    {
        types: [AccountType.AmazonWebServicesAccount],
        name: "AWS Account",
        namePlural: "AWS Accounts",
        icon: <AmazonWebServicesIcon />,
        eapOnly: false,
        description: (
            <div>
                Use <em>Amazon Web Service accounts</em> when deploying to Amazon Web services.
            </div>
        ),
    },
    {
        types: [AccountType.SshKeyPair],
        name: "SSH Key Pair",
        namePlural: "SSH Key Pairs",
        icon: <SshKeyIcon />,
        eapOnly: false,
        description: (
            <div>
                <em>SSH Key Pairs</em> are used when authenticating to machines running Linux or another Unix-like operating system.
            </div>
        ),
    },
    {
        types: [AccountType.UsernamePassword],
        name: "Username/Password",
        namePlural: "Usernames/Passwords",
        icon: <UsernamePasswordIcon />,
        eapOnly: false,
        description: <div>Records a username and password pair.</div>,
    },
    {
        types: [AccountType.Token],
        name: "Token",
        namePlural: "Tokens",
        icon: <TokenIcon />,
        eapOnly: false,
        description: <div>An authorization token without username.</div>,
    },
];

/**
 * A collection of account types, filtered to hide eap only variable types if this is an EAP build.
 */
export function AccountTypeDetailsMap(): AccountTypeDetails[] {
    return RawAccountTypeDetailsMap.filter(variable => repository.getServerInformation().isEarlyAccessProgram || !variable.eapOnly);
}

/**
 * Ordered list of Health Statuses - this order determines page render order
 */
export const orderedHealthStatuses = [MachineModelHealthStatus.Healthy, MachineModelHealthStatus.HasWarnings, MachineModelHealthStatus.Unhealthy, MachineModelHealthStatus.Unavailable, MachineModelHealthStatus.Unknown];
