/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import dialogIdGenerator from "./dialogIdGenerator";

interface SequenceProps {
    sequence: string;
}

export const useSequenceId = (name?: string) => {
    return React.useMemo(() => name || dialogIdGenerator.next(), [name]);
};

export const SequenceId: React.FC<{ sequence?: string; children: (sequence: string) => React.ReactElement }> = ({ children, sequence }) => {
    const result = useSequenceId(sequence);
    return children(result);
};

export function withDialogIdentifier(name?: string) {
    return <TInner extends Partial<SequenceProps>>(component: React.ComponentType<TInner & SequenceProps>) => {
        class WithDialogIdentifier extends React.Component<TInner & Partial<SequenceProps>, { sequence: string }> {
            constructor(props: TInner & Partial<SequenceProps>) {
                super(props);
                const sequence = (this.props.sequence || name || dialogIdGenerator.next())!;
                this.state = { sequence };
            }

            render() {
                const Component = component;
                return <Component sequence={this.state.sequence} {...this.props} />;
            }
        }
        return WithDialogIdentifier;
    };
}

export default withDialogIdentifier;
