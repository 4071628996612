import { pageId } from "utils/pageId";

export default () => ({
    runbookSnapshots: pageId("Project.RunbookSnapshots", "RunbookSnapshots"),
    runbookSnapshotCreate: pageId("Project.RunbookSnapshotNew", "New RunbookSnapshot"),
    runbookSnapshot: () => {
        return {
            root: pageId("Project.RunbookSnapshot", "RunbookSnapshot"),
            edit: pageId("Project.RunbookSnapshotEdit", "Edit RunbookSnapshot"),
            runbookRuns: {
                specific: pageId("Project.RunbookRun", "RunbookRun"),
                retry: pageId("Project.RunbookRunRetry", "Retry RunbookRun"),
                create: pageId("Project.RunbookRunNew", "New RunbookRun"),
            },
        };
    },
});
