/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import SpecialVariables from "client/specialVariables";
import { repository } from "clientInstance";
import { ActionSummaryProps } from "../actionSummaryProps";
import EmailLink from "components/EmailLink";
import Targets from "../Targets";
import { TeamResource, SmtpIsConfiguredResource, Permission } from "client/resources";
import { TeamChip, ChipIcon } from "components/Chips";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import { ExpandableFormSection, Summary, SummaryNode } from "components/form";
import { VariableLookupText } from "components/form/VariableLookupText";
import TeamMultiSelect from "../../MultiSelect/TeamMultiSelect";
import ParseHelper from "../../../utils/ParseHelper/ParseHelper";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import { StringRadioButtonGroup, BoundRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import MoreInfo from "components/form/Sections/MoreInfo";
import { EmailPriority } from "client/resources/emailPriority";
import { TargetRoles } from "areas/projects/components/Process/types";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { SmtpNeedsConfigurationCallout } from "areas/configuration/components/Smtp/SmtpNeedsConfigurationCallout";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";

class TeamsList extends Targets {}
const LookupTeamChip = LookupResourceChipComponent<TeamResource>();

interface EmailActionSummaryState {
    teams?: TeamResource[];
}

class EmailActionSummary extends BaseComponent<ActionSummaryProps, EmailActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const teams = await repository.Teams.all();
        this.setState({ teams });
    }

    render() {
        const properties = this.props.properties;
        const toTeamIds = properties[SpecialVariables.Action.Email.ToTeamIds] as string;
        const teamIds = toTeamIds ? toTeamIds.split(",") : [];
        return (
            <div>
                Send an email to <EmailLink emailAddress={properties[SpecialVariables.Action.Email.To] as string} />
                {teamIds && this.state.teams && (
                    <span>
                        <TeamsList
                            targets={teamIds.map(id => (
                                <LookupTeamChip lookupCollection={this.state.teams!} key={id} lookupId={id} type={ChipIcon.Team} chipRender={team => <TeamChip team={team} />} />
                            ))}
                        />
                    </span>
                )}
                {properties[SpecialVariables.Action.Email.CC] || properties[SpecialVariables.Action.Email.CCTeamIds] || properties[SpecialVariables.Action.Email.Bcc] || properties[SpecialVariables.Action.Email.BccTeamIds]
                    ? " (including CC/BCC)"
                    : null}
            </div>
        );
    }
}

interface EmailProperties {
    "Octopus.Action.Email.Body": string;
    "Octopus.Action.Email.IsHtml": string;
    "Octopus.Action.Email.Priority"?: EmailPriority;
    "Octopus.Action.Email.Subject": string;
    "Octopus.Action.Email.To": string;
    "Octopus.Action.Email.ToTeamIds": string;
    "Octopus.Action.Email.CC": string;
    "Octopus.Action.Email.CCTeamIds": string;
    "Octopus.Action.Email.Bcc": string;
    "Octopus.Action.Email.BccTeamIds": string;
}

interface EmailActionEditState {
    teams: TeamResource[];
    bodyHelpExpanded: boolean;
    smtpIsConfigured?: SmtpIsConfiguredResource;
}

class EmailActionEdit extends BaseComponent<ActionEditProps<EmailProperties>, EmailActionEditState> {
    constructor(props: ActionEditProps<EmailProperties>) {
        super(props);
        this.state = {
            teams: [],
            bodyHelpExpanded: false,
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const [teams, smtpIsConfigured] = await Promise.all([repository.Teams.all(), repository.SmtpConfiguration.IsConfigured()]);

            this.setState({
                teams,
                smtpIsConfigured,
            });
        });
    }

    render() {
        const properties = this.props.properties;
        return (
            <div>
                <ExpanderSectionHeading title="Send an Email" />
                <SmtpNeedsConfigurationCallout configured={this.state.smtpIsConfigured ? this.state.smtpIsConfigured.IsConfigured : true} hasPermissions={isAllowed({ permission: Permission.ConfigureServer })} />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Email.To"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="To"
                    summary={this.toSummary()}
                    help={
                        <div>
                            At least one <em>To</em> address or <em>Team</em> must be entered.
                        </div>
                    }
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        label="To"
                        placeholder="Separate multiple email addresses with ; or ,"
                        value={properties["Octopus.Action.Email.To"]}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Email.To"]: val })}
                        error={this.props.getFieldError("Octopus.Action.Email.To")}
                    />
                    <TeamMultiSelect
                        label="To Teams"
                        items={this.state.teams}
                        value={ParseHelper.parseCSV(properties["Octopus.Action.Email.ToTeamIds"])}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Email.ToTeamIds"]: ParseHelper.encodeCSV(val) })}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.CC" isExpandedByDefault={this.props.expandedByDefault} title="CC" summary={this.ccSummary()}>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        label="CC"
                        placeholder="Separate multiple email addresses with ; or ,"
                        value={properties["Octopus.Action.Email.CC"]}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Email.CC"]: val })}
                    />
                    <TeamMultiSelect
                        label="CC Teams"
                        items={this.state.teams}
                        value={ParseHelper.parseCSV(properties["Octopus.Action.Email.CCTeamIds"])}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Email.CCTeamIds"]: ParseHelper.encodeCSV(val) })}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.Bcc" isExpandedByDefault={this.props.expandedByDefault} title="BCC" summary={this.bccSummary()}>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        label="BCC"
                        placeholder="Separate multiple email addresses with ; or ,"
                        value={properties["Octopus.Action.Email.Bcc"]}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Email.Bcc"]: val })}
                    />
                    <TeamMultiSelect
                        label="CC Teams"
                        items={this.state.teams}
                        value={ParseHelper.parseCSV(properties["Octopus.Action.Email.BccTeamIds"])}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Email.BccTeamIds"]: ParseHelper.encodeCSV(val) })}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.Priority" isExpandedByDefault={this.props.expandedByDefault} title="Priority" help="Select the priority of the email." summary={this.prioritySummary()}>
                    <BoundRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Email.Priority"] || EmailPriority.Normal}
                        onChange={(val: EmailPriority) => this.props.setProperties({ ["Octopus.Action.Email.Priority"]: val })}
                    >
                        <RadioButton value={EmailPriority.Low} label="Low" />
                        <RadioButton value={EmailPriority.Normal} label="Normal" />
                        <RadioButton value={EmailPriority.High} label="High" />
                    </BoundRadioButtonGroup>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.Subject" isExpandedByDefault={this.props.expandedByDefault} title="Subject" help="Provide the subject line of the email." summary={this.subjectSummary()}>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        label="Subject"
                        value={properties["Octopus.Action.Email.Subject"]}
                        onChange={val => this.props.setProperties({ ["Octopus.Action.Email.Subject"]: val })}
                        error={this.props.getFieldError("Octopus.Action.Email.Subject")}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.Body" isExpandedByDefault={this.props.expandedByDefault} title="Body" help="Provide the email body as raw text or HTML." summary={this.bodySummary()}>
                    <VariableLookupText localNames={this.props.localNames} label="Body" multiline={true} value={properties["Octopus.Action.Email.Body"]} onChange={val => this.props.setProperties({ ["Octopus.Action.Email.Body"]: val })} />
                    <MoreInfo
                        label={"This field supports extended template syntax."}
                        content={
                            <div>
                                <p>
                                    <b>Extended template syntax</b>
                                </p>
                                <span>
                                    Conditional <code>if</code> and <code>unless</code>:<pre>{"#{if MyVar}...#{/if}"}</pre>
                                </span>
                                <br />
                                <span>
                                    Iteration over variable sets or comma-separated values with <code>each</code>:<pre>{"#{each mv in MyVar}...#{mv}...#{/each}"}</pre>
                                </span>
                            </div>
                        }
                    />
                    <StringRadioButtonGroup label="Email body format" value={properties["Octopus.Action.Email.IsHtml"] || "False"} onChange={val => this.props.setProperties({ ["Octopus.Action.Email.IsHtml"]: val })}>
                        <RadioButton value="False" label="Body is plain text" isDefault={true} />
                        <RadioButton value="True" label="Body is HTML" />
                    </StringRadioButtonGroup>
                </ExpandableFormSection>
            </div>
        );
    }

    toSummary(): SummaryNode {
        return this.emailSummary("No recipient addresses set", "Octopus.Action.Email.To", "Octopus.Action.Email.ToTeamIds");
    }

    ccSummary(): SummaryNode {
        return this.emailSummary("No CC addresses set", "Octopus.Action.Email.CC", "Octopus.Action.Email.CCTeamIds");
    }

    bccSummary(): SummaryNode {
        return this.emailSummary("No BCC addresses set", "Octopus.Action.Email.Bcc", "Octopus.Action.Email.BccTeamIds");
    }

    emailSummary(nothing: string, text: string, teamIds: string): SummaryNode {
        const bcc = (this.props.properties as any)[text];
        const teams = ParseHelper.parseCSV((this.props.properties as any)[teamIds]);
        if (!bcc && teams.length === 0) {
            return Summary.placeholder(nothing);
        }

        const chips = teams.map(t => <LookupTeamChip lookupCollection={this.state.teams} key={t} lookupId={t} type={ChipIcon.Environment} chipRender={team => <TeamChip team={team} />} />);
        return Summary.summary(
            <div>
                {bcc} {chips}
            </div>
        );
    }

    subjectSummary(): SummaryNode {
        const val = this.props.properties["Octopus.Action.Email.Subject"];
        return val ? Summary.summary(val) : Summary.placeholder("The email subject has not been provided");
    }

    prioritySummary(): SummaryNode {
        const val = this.props.properties["Octopus.Action.Email.Priority"] || EmailPriority.Normal;
        return val === EmailPriority.Normal ? Summary.default("Normal") : Summary.summary(val);
    }

    bodySummary(): SummaryNode {
        const val = this.props.properties["Octopus.Action.Email.Body"];
        return val ? Summary.summary("The email body has been provided") : Summary.placeholder("An email body has not been provided");
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    canRunOnWorker: false,
    actionType: "Octopus.Email",
    canRunInContainer: false,
    summary: (properties, targetRolesAsCSV) => <EmailActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: step => false,
    canBeChild: true,
    edit: EmailActionEdit,
    targetRoleOption: action => TargetRoles.None,
});
