/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import Dialog from "components/Dialog/Dialog";
import DynamicConnectivityCheckDialog from "./DynamicConnectivityCheckDialog";
import { ActionButton } from "components/Button";

interface DynamicConnectivityCheckProps {
    title: string;
    url: string;
    values: { [key: string]: any };
}

interface DynamicConnectivityCheckState extends DataBaseComponentState {
    showConnectivityCheckDialog: boolean;
}

export default class DynamicConnectivityCheck extends DataBaseComponent<DynamicConnectivityCheckProps, DynamicConnectivityCheckState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showConnectivityCheckDialog: false,
        };
    }

    render() {
        if (!this.props.url) {
            return <div />;
        }

        return (
            <div>
                <ActionButton label="Test" onClick={() => this.setState({ showConnectivityCheckDialog: true })} />
                <Dialog open={this.state.showConnectivityCheckDialog}>
                    <DynamicConnectivityCheckDialog title={this.props.title} url={this.props.url} values={this.props.values} onOkClick={() => this.setState({ showConnectivityCheckDialog: false })} />
                </Dialog>
            </div>
        );
    }
}
