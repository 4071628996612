/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { uniq, isEqual, compact } from "lodash";
import VariableNameCell from "../VariableNameCell";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { FocusableCellType } from "areas/variables/CellFocus/CellFocus";
import { CellAligner } from "components/ScrollTable/ScrollTable";
const styles = require("./style.less");
import { VariableMessages } from "areas/variables/VariableMessages/VariableMessages";
import { FocusField } from "areas/variables/EditVariableDialog/EditVariableDialog";
import { VariableModel } from "areas/variables/VariablesModel/VariablesModel";
import VariableCell from "areas/variables/VariableCell/VariableCell";
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import VariableNameAndDescriptionCell from "areas/variables/VariableNameAndDescriptionCell/VariableNameAndDescriptionCell";
import VariableStatusIcon from "areas/variables/VariableStatusIcon/VariableStatusIcon";
import { VariableStatus } from "areas/variables/VariableStatusIcon";
import { VariableValueModel } from "../VariablesModel";
const keycode = require("keycode");

export const VariableHeaderRowHeight = 32; // Matches VariableEditorHeadings.headerRow

export interface VariableHeaderRowProps {
    variable: VariableModel;
    value: VariableValueModel; // Should not need to pass a variable here... Things need a bit of refactoring first
    isVariableDeleted: boolean;
    variableMessages: VariableMessages;
    cellAligner: CellAligner;
    isNameCellFocused?: boolean;
    onNameChanged: (variable: VariableModel, name: string) => void;
    onMergeClicked: (variable: VariableModel, value: VariableValueModel) => void;
    onDontMergeClicked: (variable: VariableModel) => void;
    onResetChanges: (value: VariableValueModel) => void;
    onDuplicateVariable: (variable: VariableModel) => void;
    onAddValue: (variable: VariableModel, selectedValue: VariableValueModel) => void;
    onDeleteValue: (value: VariableValueModel) => void;
    undoDeleteValue: (value: VariableValueModel) => void;
    openVariableEditor: (value: VariableValueModel, name: string, focus?: FocusField) => void;
    onBlur: (value: VariableValueModel, blurredFrom: FocusableCellType) => void;
    onFocus: (value: VariableValueModel, focus: FocusableCellType) => void;
    onNavigateUp: (value: VariableValueModel) => void;
    onNavigateDown: (value: VariableValueModel) => void;
    variableStatuses: ReadonlyArray<VariableStatus>;
}

export default class VariableHeaderRow extends React.Component<VariableHeaderRowProps> {
    shouldComponentUpdate(nextProps: VariableHeaderRowProps) {
        return (
            this.props.variable !== nextProps.variable ||
            this.props.value !== nextProps.value ||
            this.props.isVariableDeleted !== nextProps.isVariableDeleted ||
            this.props.variableMessages !== nextProps.variableMessages ||
            this.props.cellAligner !== nextProps.cellAligner ||
            this.props.isNameCellFocused !== nextProps.isNameCellFocused ||
            !isEqual(this.props.variableStatuses, nextProps.variableStatuses)
        );
    }

    render() {
        return (
            <div
                onKeyDown={ev => {
                    const code = keycode(ev);
                    if (code === "down") {
                        this.props.onNavigateDown(this.props.value);
                        ev.preventDefault();
                    } else if (code === "up") {
                        this.props.onNavigateUp(this.props.value);
                        ev.preventDefault();
                    }
                }}
            >
                <div style={{ height: VariableHeaderRowHeight }}>
                    {this.props.cellAligner([
                        <VariableNameAndDescriptionCell
                            name={
                                this.props.value.IsEditable ? (
                                    <VariableNameCell
                                        id={this.props.value.Id}
                                        name={this.props.variable.name}
                                        placeholder={"Enter name"}
                                        isDuplicate={this.props.variableMessages.hasDuplicateName}
                                        warningMessages={this.props.variableMessages.variableWarningMessages}
                                        onNameChanged={name => this.props.onNameChanged(this.props.variable, name)}
                                        deleted={this.props.isVariableDeleted}
                                        onOpenEditorClicked={() => this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Name)}
                                        onMergeClicked={() => this.props.onMergeClicked(this.props.variable, this.props.value)}
                                        onDontMergeClicked={() => this.props.onDontMergeClicked(this.props.variable)}
                                        isFocused={this.props.isNameCellFocused!}
                                        onFocus={() => this.props.onFocus(this.props.value, FocusableCellType.Name)}
                                        onBlur={() => this.props.onBlur(this.props.value, FocusableCellType.Name)}
                                    />
                                ) : (
                                    <VariableCell>
                                        <ReadonlyText text={this.props.variable.name} />
                                    </VariableCell>
                                )
                            }
                            description={undefined}
                        />,
                        <VariableCell>
                            <div className={styles.variablePlaceholderText}>Multiple values</div>
                        </VariableCell>,
                        <div className={styles.lastColumn}>
                            <div className={styles.scopeCell}>
                                <VariableCell>
                                    <div className={styles.variablePlaceholderText}>Multiple scopes</div>
                                </VariableCell>
                            </div>
                            <div className={styles.controlCell}>
                                <div onClick={() => this.props.value.IsEditable && this.props.openVariableEditor(this.props.value, null!)}>
                                    <VariableStatusIcon status={this.aggregatedStatus()} />
                                </div>
                                {this.props.value.IsEditable && <OverflowMenu menuItems={this.menuItems()} tabIndex={-1} />}
                            </div>
                        </div>,
                    ])}
                </div>
            </div>
        );
    }

    private aggregatedStatus = () => {
        const uniqueStatuses = uniq(this.props.variableStatuses);
        if (uniqueStatuses.length > 1) {
            return VariableStatus.Modified;
        }
        return uniqueStatuses[0];
    };

    private menuItems() {
        const hasSomeStatusNotDeleted = this.props.variableStatuses.some(s => s !== VariableStatus.Deleted);
        const hasEveryStatusDeleted = this.props.variableStatuses.every(s => s === VariableStatus.Deleted);
        return compact([
            OverflowMenuItems.item("Add Value", () => this.props.onAddValue(this.props.variable, this.props.value)),
            hasSomeStatusNotDeleted ? OverflowMenuItems.item("Duplicate Variable", () => this.props.onDuplicateVariable(this.props.variable)) : null,
            hasSomeStatusNotDeleted
                ? OverflowMenuItems.item("Delete Variable", () => {
                      return this.props.variable.values.map(value => this.props.onDeleteValue(value));
                  })
                : null,
            hasEveryStatusDeleted
                ? OverflowMenuItems.item("Undo Delete Variable", () => {
                      return this.props.variable.values.map(value => this.props.undoDeleteValue(value));
                  })
                : null,
        ]);
    }
}
