/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

// Allows other parties to register 'plugins' that provide the view and edit experience for
// custom deployment actions and conventions.
//
//   var module = angular.module('myPlugin', [], function(pluginRegistry) {
//      pluginRegistry.registerDeploymentAction("Octopus.TentaclePackage", {
//        title: "Deploy a NuGet package",               // Display text for users adding the step
//        summary: "Deploys a NuGet package...",         // Help text to show
//        summaryTemplateUrl: "area/foo/bar.html",       // Template for a short summary on the process overview page
//        summaryLink: function (scope, element) {       // Angular directive-style link function
//          var action = scope.action;                   // Get the action we are rendering
//          var step = scope.step;                       // Get the step we are rendering
//          var jQueryElement = element;                 // The HTML element for the bar.html we just inserted
//        },
//        editTemplate: "area/bar/baz.html"              // Template for editing
//      });
//
//      pluginRegistry.registerFeature("Octopus.Features.CustomPackageDirectory", {
//        isEnabled: function(action, step) {  },
//        enable: function(action, step) {  },
//        disable: function(action, step) {  },
//        editTemplateUrl: "area/foo/bar.html",
//        editLink: function(scope, element) { }
//      });
//   });

import * as React from "react";
import { ActionExecutionLocation, DeploymentActionResource, ActionTemplateParameterResource, WorkerPoolResource, ProjectResource, IProcessResource } from "client/resources";
import ActionProperties from "client/resources/actionProperties";
import { Errors } from "components/DataBaseComponent";
import { PackageRequirement } from "client/resources";
import { PackageReference, PackageReferenceProperties } from "../../client/resources/packageReference";
import { StoredAction, StoredStep, RunOn } from "areas/projects/components/Process/types";
import { TargetRoles } from "areas/projects/components/Process/types";
import ProjectContextRepository from "client/repositories/projectContextRepository";

export interface Features {
    optional?: string[];
    initial?: string[];
    permanent?: string[];
}

export interface BoundFieldProps {
    localNames?: string[];
    projectId?: string;
}

// If you need access to the Step add a new child object here to scope
// the details you need. This won't be supplied in the Template area
export interface AdditionalActions {
    packageAcquisition: {
        canRunBeforeAcquisition: boolean;
        stepPackageRequirement?: PackageRequirement;
        onStepPackageRequirementChanged(value: PackageRequirement): void;
        onCanRunBeforeAcquisitionChanged(value: boolean): void;
    };
    stepTargetRoles: string;
    actionType: string;
}

export interface ActionEditProps<T = ActionProperties, P = PackageReferenceProperties> extends BoundFieldProps {
    properties: T;
    packages: Array<PackageReference<P>>;
    plugin: ActionPlugin;
    runOn?: RunOn; // Will not be supplied if an action-template, as the execution location is not chosen
    additionalActions?: AdditionalActions;
    errors: Errors | undefined; // only used for shouldComponentUpdate
    busy: Promise<any> | boolean | undefined;
    expandedByDefault: boolean;
    parameters?: ActionTemplateParameterResource[];
    getFieldError(field: string): string;
    setProperties(properties: Partial<T>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference<P>>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshRunOn?(): void;
    getProcessResource?(): Readonly<IProcessResource>; // Will not be supplied if an action-template, as no process exists
}

export interface ActionPlugin {
    actionType: string;
    summary: (action: ActionProperties, targetRolesAsCSV: string, packages: Array<PackageReference<{}>>, workerPool?: WorkerPoolResource, workerPoolVariable?: string | null) => React.ReactNode;
    canHaveChildren: (step: StoredStep) => boolean;
    canBeChild: boolean;
    executionLocation: ActionExecutionLocation;
    edit: any;
    canRunOnWorker?: boolean;
    hasPackages?: (action: StoredAction) => boolean;
    targetRoleOption: (action: StoredAction | DeploymentActionResource) => TargetRoles;
    disableAddTargetRoles?: boolean;
    features?: Features;
    canRunInContainer?: boolean;
}

export interface FeaturePlugin {
    description: string;
    featureName: string;
    priority: number;
    title: string;
    edit: any;
    enable?(properties: ActionProperties): void;
    disable?(properties: ActionProperties): void;
    preSave?(properties: ActionProperties): void;
    validate?(properties: ActionProperties, errors: {}): void;
}

export enum ActionScope {
    Deployments = "deployments",
    Runbooks = "runbooks",
}

export class ActionPluginRegistry<TActionPlugin extends { actionType: string; features?: Features } = ActionPlugin, TFeaturePlugin extends { featureName: string; priority: number } = FeaturePlugin> {
    private registeredActions: Map<string, TActionPlugin> = new Map();
    private registeredFeatures: Map<string, TFeaturePlugin> = new Map();

    registerAction(registration: TActionPlugin) {
        this.registeredActions.set(registration.actionType, registration);
    }

    registerFeature(registration: TFeaturePlugin) {
        this.registeredFeatures.set(registration.featureName, registration);
    }

    getAction(actionType: string): TActionPlugin {
        const registration = this.resolve(this.registeredActions, actionType);
        if (!registration) {
            throw new Error(`There is no plugin registered for ${actionType} action type.`);
        }
        return registration;
    }

    getAllActions(): TActionPlugin[] {
        return this.resolveAll(this.registeredActions);
    }

    getFeature(featureName: string): TFeaturePlugin {
        const feature = this.resolve(this.registeredFeatures, featureName);
        if (!feature) {
            throw new Error(`There is no plugin registered for ${featureName} feature type.`);
        }
        return feature;
    }

    getAllFeatures(): TFeaturePlugin[] {
        return this.resolveAll(this.registeredFeatures).sort(x => x.priority);
    }

    hasFeaturesForAction(actionType: string): boolean {
        const actionPlugin = this.getAction(actionType);
        const featuresForAction = this.getAllFeatures().filter(f => actionPlugin.features && actionPlugin.features.optional && actionPlugin.features.optional.includes(f.featureName));
        return featuresForAction.length > 0;
    }

    hasAction(actionType: string) {
        return this.registeredActions.has(actionType);
    }

    private resolve<T>(container: Map<string, T>, key: string) {
        return container.get(key);
    }

    private resolveAll<T>(container: Map<string, T>) {
        return Array.from(container.values());
    }
}

export class AggregatePluginRegistry {
    private registries: Record<ActionScope, ActionPluginRegistry<ActionPlugin, FeaturePlugin>> = {
        deployments: new ActionPluginRegistry(),
        runbooks: new ActionPluginRegistry(),
    };

    registerDeploymentAction(registration: ActionPlugin) {
        this.registries.deployments.registerAction(registration);
    }

    registerActionForAllScopes(registration: ActionPlugin) {
        this.getScopedRegistries().forEach(x => x.registerAction(registration));
    }

    /*
    Being able to register different actions or the same action in different scopes allows us to vary the action individually if need be as well i.e.
    if we need different properties / UI for an action for a runbook process vs deployment process etc.
    */
    registerAction(registration: ActionPlugin, contexts?: ActionScope[]) {
        this.getScopedRegistries(contexts).forEach(x => x.registerAction(registration));
    }

    registerFeature(registration: FeaturePlugin, contexts?: ActionScope[]) {
        this.getScopedRegistries(contexts).forEach(x => x.registerFeature(registration));
    }

    registerFeatureForAllScopes(registration: FeaturePlugin) {
        this.getScopedRegistries().forEach(x => x.registerFeature(registration));
    }

    getAction(actionType: string, context: ActionScope): ActionPlugin {
        return this.registries[context].getAction(actionType);
    }

    getDeploymentAction(actionType: string): ActionPlugin {
        return this.registries[ActionScope.Deployments].getAction(actionType);
    }

    getAllActions(context: ActionScope): ActionPlugin[] {
        return this.registries[context].getAllActions();
    }

    getAllDeploymentActions(): ActionPlugin[] {
        return this.registries[ActionScope.Deployments].getAllActions();
    }

    getFeature(featureName: string, context: ActionScope): FeaturePlugin {
        return this.registries[context].getFeature(featureName);
    }

    getDeploymentActionFeature(featureName: string) {
        return this.registries[ActionScope.Deployments].getFeature(featureName);
    }

    getAllFeatures(context: ActionScope): FeaturePlugin[] {
        return this.registries[context].getAllFeatures();
    }

    getAllDeploymentActionFeatures() {
        return this.registries[ActionScope.Deployments].getAllFeatures();
    }

    hasFeaturesForAction(actionType: string, context: ActionScope): boolean {
        return this.registries[context].hasFeaturesForAction(actionType);
    }

    hasFeatureForDeploymentAction(actionType: string): boolean {
        return this.registries[ActionScope.Deployments].hasFeaturesForAction(actionType);
    }

    hasAction(actionType: string, context: ActionScope) {
        return this.registries[context].hasAction(actionType);
    }

    private getApplicableScopes(contexts?: ActionScope[]) {
        return contexts ? contexts : (Object.keys(this.registries) as ActionScope[]);
    }

    private getScopedRegistries(contexts?: ActionScope[]) {
        return this.getApplicableScopes(contexts).map(x => this.registries[x]);
    }
}

const pluginRegistry = new AggregatePluginRegistry();

export default pluginRegistry;
