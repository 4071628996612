/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { Section } from "components/Section/Section";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { RemoveItemsList } from "components/RemoveItemsList/RemoveItemsList";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { UserResource, ResourceCollection, ApiKeyResource } from "client/resources";
import { repository } from "clientInstance";
import AddApiKeyDialogLayout from "./AddApiKeyDialogLayout";
import { NothingToSeeHere } from "components/NothingToSeeHere";
import TimeFromNowLabel from "components/TimeLabels/TimeFromNowLabel";
import DeleteApiKeyDialog from "./DeleteApiKeyDialog";
import CustomDialog from "components/Dialog/CustomDialog";

const styles = require("./style.less");

interface UserApiKeysListProps {
    user: UserResource;
}

interface UserApiKeysState extends DataBaseComponentState {
    apiKeyCollection: ResourceCollection<ApiKeyResource>;
    purpose: string;
    lastApiKey: string;
    apiKeyToDelete: ApiKeyResource;
}

class ApiKeyList extends RemoveItemsList<ApiKeyResource> {}

export default class UserApiKeysList extends DataBaseComponent<UserApiKeysListProps, UserApiKeysState> {
    constructor(props: UserApiKeysListProps) {
        super(props);

        this.state = {
            apiKeyCollection: null!,
            purpose: null!,
            lastApiKey: null!,
            apiKeyToDelete: null!,
        };
    }
    async componentDidMount() {
        const apiKeyCollection = await repository.Users.listApiKeys(this.props.user);

        this.setState({
            apiKeyCollection,
        });
    }

    render() {
        return (
            <Section>
                {this.state.apiKeyCollection && this.renderApiKeys()}
                {this.state.apiKeyCollection && this.state.apiKeyCollection.Items.length === 0 && <NothingToSeeHere content="There aren't currently any API keys to show." />}
            </Section>
        );
    }

    renderApiKeyDeleteDialog = () => {
        return <DeleteApiKeyDialog open={!!this.state.apiKeyToDelete} onConfirm={() => this.handleKeyRemoved()} onClose={() => this.setState({ apiKeyToDelete: null! })} apiKeyPurpose={this.state.apiKeyToDelete.Purpose} />;
    };

    renderApiKeys = () => {
        const actions = [
            <OpenDialogButton
                key="newKey"
                label="New API Key"
                title={this.props.user.IsService ? "New API Key" : "API Keys can only be created for yourself or a Service Account"}
                disabled={!this.props.user.IsRequestor && !this.props.user.IsService}
                renderDialog={renderProps => (
                    <CustomDialog
                        open={renderProps.open}
                        close={renderProps.closeDialog}
                        render={dialogProps => (
                            <AddApiKeyDialogLayout
                                {...dialogProps}
                                apiKey={this.state.lastApiKey}
                                onAdd={purpose => this.handleKeyAdded(purpose)}
                                onClose={() => {
                                    renderProps.closeDialog();
                                    this.setState({ lastApiKey: null! });
                                }}
                            />
                        )}
                    />
                )}
            />,
        ];

        return (
            <>
                <ApiKeyList
                    listActions={actions}
                    data={this.state.apiKeyCollection.Items}
                    onRow={apiKey => (
                        <div key={apiKey.Id}>
                            <h3 className={styles.apiKeyEntry}>{apiKey.Purpose || "General-purpose API key"}</h3>
                            <div>
                                Created <TimeFromNowLabel time={apiKey.Created} />
                            </div>
                        </div>
                    )}
                    onRemoveRow={apiKey => this.setState({ apiKeyToDelete: apiKey })}
                />
                {this.state.apiKeyToDelete && this.renderApiKeyDeleteDialog()}
            </>
        );
    };

    async handleKeyAdded(purpose: string): Promise<any> {
        const newKey = await repository.Users.createApiKey(this.props.user, purpose);

        this.setState(state => ({
            apiKeyCollection: {
                ...state!.apiKeyCollection,
                Items: [newKey, ...state!.apiKeyCollection.Items],
            },
            lastApiKey: newKey.ApiKey,
        }));
    }

    async handleKeyRemoved() {
        await this.doBusyTask(async () => {
            const result = await repository.Users.revokeApiKey(this.state.apiKeyToDelete);
            this.setState(state => ({
                apiKeyCollection: {
                    ...state!.apiKeyCollection,
                    Items: [...state!.apiKeyCollection.Items.filter(i => i.Id !== this.state.apiKeyToDelete.Id)],
                },
                apiKeyToDelete: null,
            }));
        });
    }
}
