/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { UserResource, UserRoleResource, SpaceResource, TeamResource, OctopusServerNodeSummaryResource, Permission, ScopedUserRoleResource } from "client/resources";
import URI from "urijs";
import { repository, session } from "clientInstance";

export default (root: string) => {
    const configRoot = `${root}/configuration`;

    return {
        root: configRoot,
        userInvites: `${configRoot}/userinvites`,
        auditBaseRoute: `${configRoot}/audit`,
        audit: (spaceId: string | null) => {
            const hasEventViewInCurrentSpace = session.currentPermissions!.scopeToSpace(spaceId).hasPermissionInAnyScope(Permission.EventView);

            const shouldFilterToCurrentSpace = spaceId && hasEventViewInCurrentSpace;
            const includeSystem = true;

            const queryString = new URI()
                .search({
                    ...{ includeSystem },
                    ...(shouldFilterToCurrentSpace ? { spaces: encodeURIComponent(spaceId!) } : {}),
                })
                .search();

            return `${configRoot}/audit${queryString}`;
        },
        machineEventsForUser: (user: string, category: string) => `${configRoot}/audit?users=${encodeURIComponent(user)}&eventCategories=${category}&documentTypes=Machines`,
        eventsForProject: (projectId: string) => `${configRoot}/audit?projects=${encodeURIComponent(projectId)}`,
        eventsForTenant: (tenantId: string) => `${configRoot}/audit?tenants=${encodeURIComponent(tenantId)}`,
        eventsForEnvironment: (envId: string) => `${configRoot}/audit?environments=${encodeURIComponent(envId)}`,
        eventsForUser: (userId: string) => `${configRoot}/audit?users=${encodeURIComponent(userId)}`,
        eventsRegardingTeam: (team: TeamResource) => regardingAny(configRoot, [team.Id], !team.SpaceId),
        eventsRegardingUserRole: (role: UserRoleResource) => regardingAny(configRoot, [role.Id], true),
        eventsRegardingScopedUserRole: (sur: ScopedUserRoleResource) => regardingAny(configRoot, [sur.Id], !sur.SpaceId),
        eventsRegardingNode: (node: OctopusServerNodeSummaryResource) => regardingAny(configRoot, [node.Id], true),
        eventsRegardingAny: (regardingIds: string[], includeSystem?: boolean) => regardingAny(configRoot, regardingIds, includeSystem),
        runbookEventsForProject: (projectId: string, runbookId: string) => `${configRoot}/audit?projects=${encodeURIComponent(projectId)}&documentTypes=Runbooks&regardingAny=${encodeURIComponent(runbookId)}`,
        runbookProcessEventsForRunbook: (projectId: string, runbookId: string) => `${configRoot}/audit?projects=${encodeURIComponent(projectId)}&documentTypes=RunbookProcess&regardingAny=${encodeURIComponent(runbookId)}`,
        deploymentProcessEventsForProject: (projectId: string) => `${configRoot}/audit?projects=${encodeURIComponent(projectId)}&documentTypes=deploymentprocess`,
        variableSetEventsForProject: (projectId: string) => `${configRoot}/audit?projects=${encodeURIComponent(projectId)}&documentTypes=variableset`,
        libraryVariableSetEventsRegardingAny: (regardingIds: string[]) => `${configRoot}/audit?documentTypes=variableset${regardingIds.map(x => `&regardingAny=${encodeURIComponent(x)}`).join("")}`,
        backup: `${configRoot}/backup`,
        features: `${configRoot}/features`,
        license: `${configRoot}/license`,
        smtp: `${configRoot}/smtp`,
        nodes: {
            root: `${configRoot}/nodes`,
            serverSettings: `${configRoot}/nodes/serverSettings`,
            config: `${configRoot}/nodes/config`,
        },
        thumbprint: `${configRoot}/thumbprint`,
        maintenance: `${configRoot}/maintenance`,
        v3route: {
            certificates: `${configRoot}/certificates`,
            usersInvite: `${configRoot}/users/invite`,
            testPermissions: `${configRoot}/teams/test`,
        },
        letsEncrypt: {
            root: `${configRoot}/letsencrypt`,
            configure: `${configRoot}/letsencrypt/configure`,
        },
        testPermissions: `${configRoot}/testpermissions`,
        testPermission: (userId: string) => `${configRoot}/testpermissions/${userId}`,
        subscriptions: {
            root: `${configRoot}/subscriptions`,
            create: `${configRoot}/subscriptions/create`,
        },
        subscription: (subscriptionId: string) => `${configRoot}/subscriptions/${subscriptionId}`,
        users: {
            root: `${configRoot}/users`,
            create: `${configRoot}/users/create`,
        },
        user: (userId: string | UserResource) => {
            if (!!userId && typeof userId !== "string") {
                userId = userId.Id;
            }
            return `${configRoot}/users/${userId}`;
        },
        spaces: {
            root: `${configRoot}/spaces`,
            create: `${configRoot}/spaces/create`,
        },
        space: (spaceId: string | SpaceResource) => {
            if (!!spaceId && typeof spaceId !== "string") {
                spaceId = spaceId.Id;
            }
            return `${configRoot}/spaces/${spaceId}`;
        },
        teams: {
            baseRoute: `${configRoot}/teams`,
            root: () => {
                const hasTeamViewInCurrentSpace = session.currentPermissions!.scopeToSpace(repository.spaceId).isAuthorized({ permission: Permission.TeamView });

                const shouldFilterToCurrentSpace = repository.spaceId && hasTeamViewInCurrentSpace;

                const queryString = new URI()
                    .search({
                        includeSystem: "true",
                        ...(shouldFilterToCurrentSpace ? { spaces: encodeURIComponent(repository.spaceId!) } : {}),
                    })
                    .search();

                return `${configRoot}/teams${queryString}`;
            },
            redirect: (scopedRoleId: string) => `${configRoot}/teams/redirect/${scopedRoleId}`,
        },
        team: (teamId: string | TeamResource, activeTab?: string) => {
            if (!!teamId && typeof teamId !== "string") {
                teamId = teamId.Id;
            }
            return `${configRoot}/teams/${teamId}${activeTab ? `?activeTab=${activeTab}` : ""}`;
        },
        roles: {
            root: `${configRoot}/roles`,
            create: `${configRoot}/roles/create`,
        },
        role: (roleId: string | UserRoleResource) => {
            if (!!roleId && typeof roleId !== "string") {
                roleId = roleId.Id;
            }
            return `${configRoot}/roles/${roleId}`;
        },
        diagnostics: {
            root: `${configRoot}/diagnostics`,
            logs: {
                root: `${configRoot}/diagnostics/logs`,
                autoDeploy: `${configRoot}/diagnostics/autodeploy`,
                scheduledDeploy: `${configRoot}/diagnostics/scheduleddeploy`,
                machineCleanup: `${configRoot}/diagnostics/machinecleanup`,
                subscription: `${configRoot}/diagnostics/subscription`,
            },
        },
        settings: {
            root: `${configRoot}/settings`,
        },
        setting: (settingId: string) => {
            return `${configRoot}/settings/${settingId}`;
        },
        performance: `${configRoot}/performance`,
    };
};

function regardingAny(configRoot: string, regardingIds: string[], includeSystem?: boolean) {
    let queryString = regardingIds.map(x => `&regardingAny=${encodeURIComponent(x)}`).join("");
    if (queryString.substring(0, 1) === "&") {
        queryString = queryString.substring(1);
    }
    return `${configRoot}/audit?${queryString}${!!includeSystem ? "&includeSystem=true" : ""}`;
}
