/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { SummaryResource, CommunicationStyle } from "client/resources";
import InternalLink from "components/Navigation/InternalLink";
const styles = require("./style.less");
import MachineIconHelper from "utils/MachineIconHelper";
import BusyIndicator from "components/BusyIndicator";
import { Section } from "components/Section/Section";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import { withTheme } from "components/Theme";

interface MachineCardProps {
    title: string;
    summary: SummaryResource | undefined;
    machineIconHelper: MachineIconHelper;
    link(obj: object): string;
}

interface MachineCardState extends DataBaseComponentState {
    filterText?: string;
}

export default class MachineCard extends DataBaseComponent<MachineCardProps, MachineCardState> {
    constructor(props: MachineCardProps) {
        super(props);
        this.state = {};
    }

    render() {
        return withTheme(theme => {
            if (!this.props.summary) {
                return this.props.summary === undefined ? null : (
                    <div className={styles.card}>
                        <div className={styles.cardTitleContainer}>
                            <div className={styles.cardTitle}>
                                <InternalLink to={this.props.link({})} color={theme.whiteConstant}>
                                    {this.props.title}
                                </InternalLink>
                            </div>
                        </div>
                        <Section>
                            <BusyIndicator inline={true} show={true} />
                        </Section>
                    </div>
                );
            }

            const endpointSummaries = this.props.summary.MachineEndpointSummaries;
            const endpointRows = Object.keys(endpointSummaries).map((k: keyof typeof CommunicationStyle) => {
                const machinesCount = endpointSummaries[k] || 0;
                if (!machinesCount || machinesCount === 0) {
                    return null;
                }
                const commStyle = k as CommunicationStyle;
                const endpointName = EndpointsHelper.getFriendlyName(commStyle);
                const endpointIcon = this.props.machineIconHelper.communicationStyleIcons[commStyle];
                const endpointIconImage = endpointIcon && <img src={endpointIcon} className={styles.healthStatusIcon} alt="Health status" />;
                return (
                    <div className={styles.endpointRowsContainer} key={commStyle}>
                        <div className={styles.endpointIcon}>{endpointIconImage}</div>
                        <div className={styles.endpointName}>
                            <InternalLink to={this.props.link({ commStyles: commStyle })}>{endpointName}</InternalLink>
                        </div>
                        <div className={styles.endpointMachinesCount}>{machinesCount.toLocaleString()}</div>
                    </div>
                );
            });

            const totalMachines = this.props.summary.TotalMachines;

            return (
                <div className={styles.card}>
                    <div className={styles.cardTitleContainer}>
                        <div className={styles.cardTitle}>
                            <InternalLink to={this.props.link({})} color={theme.whiteConstant}>
                                {this.props.title} <span className={styles.cardCount}>({totalMachines.toLocaleString()})</span>
                            </InternalLink>
                        </div>
                    </div>
                    <div className={styles.cardContentContainer}>{endpointRows}</div>
                </div>
            );
        });
    }
}
