/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
const styles = require("./style.less");

interface PagingControlButtonProps {
    label: string;
    isActive: boolean;
    onPageSelected?(event: any): void;
}

const PagingControlButton: React.StatelessComponent<PagingControlButtonProps> = props => {
    const activeClass = props.isActive ? styles.pagingControlButtonActive : styles.pagingControlButtonInactive;
    return (
        <button className={styles.pagingControlButton + " " + activeClass} onClick={props.onPageSelected} title={props.label}>
            <span className={styles.pagingControlButtonText}>{props.label}</span>
        </button>
    );
};

export default PagingControlButton;
