import * as React from "react";
import { ProjectContextActions, ProjectContextState, useProjectContext } from "./ProjectContext";
import { DistributiveOmit } from "utils/distributiveMappedTypes";

export type WithProjectContextInjectedProps = {
    projectContext: {
        state: ProjectContextState;
        actions: ProjectContextActions;
    };
};

export const withProjectContext = <TProps extends WithProjectContextInjectedProps>(Component: React.ComponentType<TProps>) => {
    type TPropsWithoutContextInjectedProps = DistributiveOmit<TProps, keyof WithProjectContextInjectedProps>;
    const WithProjectContext: React.FC<TPropsWithoutContextInjectedProps> = props => {
        const context = useProjectContext();
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return <Component projectContext={context} {...((props as unknown) as TProps)} />;
    };
    return WithProjectContext;
};
