import React from "react";
import { useDevToolsState, DirtyItemState } from "../DevToolsContext";
import { List, ListItem } from "@material-ui/core";

interface DirtyListProps {
    onSelect: (name: string) => void;
}

export const DirtyList: React.FC<DirtyListProps> = props => {
    const state = useDevToolsState();
    const lookup = state?.dirty ?? {};
    const items = Object.keys(lookup);

    return (
        <List>
            {items.map(x => (
                <ListItem button={true} key={x} onClick={() => props.onSelect(x)}>
                    {x}
                </ListItem>
            ))}
        </List>
    );
};
