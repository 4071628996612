/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { MachineModelHealthStatus, MachineResource, TaskRestrictedTo, SummaryResource, WorkerPoolsSummaryResource, EnvironmentsSummaryResource, WorkerMachineResource } from "client/resources";
import { ResourceCollection } from "client/resources";
import { ReactNode } from "react";
import MachineIconHelper from "utils/MachineIconHelper";
import List from "components/List";
import { FormSectionHeading } from "components/form";
import { Section } from "components/Section/Section";
import { toPagingCollection, createMachineHealthMap, BaseInfrastructureFilter } from "../MachinesLayout/MachineFilter";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { NoResults } from "components/NoResults/NoResults";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";
import { PagingCollection } from "client/resources/pagingCollection";
import { orderedHealthStatuses } from "areas/infrastructure/InfrastructureDetails";

interface SmallCloseButtonProps {
    onClose?: () => void;
}
export const SmallCloseButton: React.SFC<SmallCloseButtonProps> = props => {
    return <IconButton onClick={props.onClose} icon={Icon.CancelSmall} />;
};

class MachinesList extends List<MachineResource> {}

export interface BaseAllMachinesSummaryProps<Filter> {
    filter: Filter;
}

export type HealthStatusRecord = Record<keyof typeof MachineModelHealthStatus | "Disabled", PagingCollection<MachineResource>>;

export interface BaseAllMachinesSummaryState extends DataBaseComponentState {
    machinesResponse: PagingCollection<MachineResource>;
    machineHealthStatusFastLookup: HealthStatusRecord;
    currentPageIndex: number; // This has a custom endpoint, so we manage our own paging implementation in List/onLoadMore.
    expanded: boolean; // Need to know if we're currently expanded so we can choose to reload when the filter changes or not.
    healthStatusFilter: string;
    isDisabledFilter: boolean;
    redirectToTaskId?: string;
}

abstract class BaseAllMachinesSummary<Props extends BaseAllMachinesSummaryProps<Filter>, Filter extends BaseInfrastructureFilter, State extends BaseAllMachinesSummaryState> extends DataBaseComponent<Props, State> {
    protected machineListTakeSize = repository.takeDefaultPageSize;
    protected machineIconHelper = new MachineIconHelper();
    protected commonInitialState = {
        currentPageIndex: 0,
        expanded: true,
        healthStatusFilter: "",
        isDisabledFilter: false,
    };

    constructor(props: Props) {
        super(props);
    }

    protected abstract async loadData(): Promise<void>;
    protected abstract renderMachine(machine: MachineResource, needsUpgrading?: boolean): ReactNode;

    protected reloadDataAndCurrentPageIndex() {
        this.setState({ currentPageIndex: 0 }, async () => {
            await this.doBusyTask(async () => {
                await this.loadData();
            });
        });
    }

    protected async performHealthCheck(taskRestrictedTo: TaskRestrictedTo, machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createHealthCheckTaskRestrictedTo(taskRestrictedTo, machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected async performTentacleUpgrade(taskRestrictedTo: TaskRestrictedTo, machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpgradeTentaclesTaskRestrictedTo(taskRestrictedTo, machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected async performCalamariUpgradeOnTargets(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetsTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected async performCalamariUpgradeOnWorkers(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnWorkersTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected renderMachinesList(summary: EnvironmentsSummaryResource | WorkerPoolsSummaryResource) {
        const componentKey = "allMachines";
        return <div key={componentKey}>{this.state.expanded && this.state.machinesResponse && <div>{this.renderMachinesListGroupedByHealthStatus(summary)}</div>}</div>;
    }

    protected renderMachinesListGroupedByHealthStatus(summary: EnvironmentsSummaryResource | WorkerPoolsSummaryResource) {
        const allMachines = this.state.machinesResponse.Items;
        if (allMachines.length === 0) {
            return (
                <Section>
                    <NoResults />
                </Section>
            );
        }

        let machinesNeedUpgrading: string[] = [];
        if (summary.MachineIdsForCalamariUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(summary.MachineIdsForCalamariUpgrade);
        }

        if (summary.MachineIdsForTentacleUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(summary.MachineIdsForTentacleUpgrade);
        }

        return (
            <div>
                {orderedHealthStatuses.map(status => this.renderHealthStatusSectionHeading(status, allMachines, machinesNeedUpgrading))}
                {this.renderDisabledSectionHeading(allMachines)}
            </div>
        );
    }

    protected renderHealthStatusSectionHeading(status: MachineModelHealthStatus, allMachines: MachineResource[], machineIdsTobeUpgraded: string[]): React.ReactElement | null {
        let machines = allMachines.filter(x => x.HealthStatus === status);
        if (status === MachineModelHealthStatus.Unknown) {
            machines = machines.filter(x => !x.IsDisabled);
        }

        const machinesTitleIcon = this.machineIconHelper.healthStatusIcons[status];
        let machinesTitle = <React.Fragment />;

        machinesTitle = (
            <div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>{machinesTitleIcon && <img src={machinesTitleIcon} className={styles.healthStatusIcon} alt="Health status" />}</div>
                <div className={styles.healthStatusName}>{MachineHealthStatusHelper.getFriendlyName(status)}</div>
                <div className={styles.healthStatusMachinesCount}>({machines.length})</div>
            </div>
        );

        const componentKey = status;
        const machinesList =
            machines.length > 0 ? (
                <div key={status}>
                    <FormSectionHeading title={machinesTitle} />
                    <MachinesList
                        key={componentKey}
                        initialData={this.state.machineHealthStatusFastLookup[componentKey]}
                        onRow={(item: MachineResource) => this.renderMachine(item, machineIdsTobeUpgraded.includes(item.Id))}
                        onRowRedirectUrl={(machine: MachineResource) => routeLinks.infrastructure.machine(machine).root}
                        onLoadMore={async () => {
                            const newTakeSize = this.getBaseState(state => state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize);
                            const machineHealthStatusFastLookup = this.getBaseState(state => state.machineHealthStatusFastLookup);
                            const response = this.getBaseState(state => state.machinesResponse);
                            const filteredMachines = response.Items.filter(x => x.HealthStatus === status);
                            const machinesForHealthStatus = toPagingCollection(filteredMachines, newTakeSize);
                            machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                            this.setState({
                                machineHealthStatusFastLookup,
                            });
                        }}
                    />
                </div>
            ) : null;

        return machinesList;
    }

    protected renderDisabledSectionHeading(allMachines: MachineResource[]): React.ReactElement | null {
        const machines = allMachines.filter(x => x.IsDisabled);

        const machinesTitleIcon = this.machineIconHelper.healthStatusIcons["Disabled"];
        let machinesTitle = <React.Fragment />;

        machinesTitle = (
            <div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>{machinesTitleIcon && <img src={machinesTitleIcon} className={styles.healthStatusIcon} alt="Health status" />}</div>
                <div className={styles.healthStatusName}>Disabled</div>
                <div className={styles.healthStatusMachinesCount}>({machines.length})</div>
            </div>
        );

        const componentKey = "Disabled";
        const machinesList =
            machines.length > 0 ? (
                <div>
                    <FormSectionHeading title={machinesTitle} />
                    <MachinesList
                        key={componentKey}
                        initialData={this.state.machineHealthStatusFastLookup[componentKey]}
                        onRow={(item: MachineResource) => this.renderMachine(item)}
                        onRowRedirectUrl={(machine: MachineResource) => routeLinks.infrastructure.machine(machine).root}
                        onLoadMore={async () => {
                            const newTakeSize = this.getBaseState(state => state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize);
                            const machineHealthStatusFastLookup = this.getBaseState(state => state.machineHealthStatusFastLookup);
                            const response = this.getBaseState(state => state.machinesResponse);
                            const filteredMachines = response.Items.filter(x => x.IsDisabled);
                            const machinesForHealthStatus = toPagingCollection(filteredMachines, newTakeSize);
                            machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                            this.setState({
                                machineHealthStatusFastLookup,
                            });
                        }}
                    />
                </div>
            ) : null;

        return machinesList;
    }

    protected renderMachineSummaryLinks(summary: SummaryResource, healthStatus: MachineModelHealthStatus) {
        const healthStatusIcon = this.machineIconHelper.healthStatusIcons[healthStatus];
        const value = summary.MachineHealthStatusSummaries[healthStatus] as number;
        if (!value || value === 0) {
            // Don't show links if there's nothing to report.
            return null;
        }

        // If filtering health statuses from the sidebar, just show the health statuses that they've chosen to filter (and don't show them as links).
        if (this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0) {
            return (
                <div key={healthStatus} className={styles.summaryCount}>
                    {healthStatusIcon && <img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt={healthStatus} />}
                    {value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                </div>
            );
        }

        // Else show environment-specific health status actions.
        if (this.state.healthStatusFilter === healthStatus) {
            return (
                <div key={healthStatus} className={styles.summaryCount}>
                    {healthStatusIcon && <img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt={healthStatus} />}
                    {value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                    <SmallCloseButton
                        onClose={() => {
                            this.setState({ healthStatusFilter: "" }, () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            });
                        }}
                    />
                </div>
            );
        } else {
            return (
                <ActionButton
                    key={healthStatus}
                    icon={<img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt={healthStatus} />}
                    className={styles.summaryCount}
                    type={ActionButtonType.Ternary}
                    label={value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                    onClick={(e: React.MouseEvent) => {
                        // The user may click a health status link to open an expander (but it shouldn't ever close it).
                        if (this.state.expanded) {
                            e.preventDefault();
                            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                        }
                        // Clear any disabled filters when a healthStatus filter is clicked. You can't chain inline disabled and healthStatus
                        // filters together because they use different and/or logic at the API and it causes UI confusion.
                        this.setState(
                            {
                                healthStatusFilter: healthStatus,
                                isDisabledFilter: false,
                            },
                            () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            }
                        );
                    }}
                />
            );
        }
    }

    protected renderMachineDisabledSummaryLinks(summary: SummaryResource) {
        const disabledComponentKey = "Disabled";
        const disabledIcon = this.machineIconHelper.healthStatusIcons["Disabled"];
        const value = summary.TotalDisabledMachines;
        if (!value || value === 0) {
            // Don't show links if there's nothing to report.
            return null;
        }

        // If filtering from the sidebar, just show the disabled control (not as a link).
        if (this.props.filter.isDisabled) {
            return (
                <div key={disabledComponentKey} className={styles.summaryCount}>
                    {disabledIcon && <img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt={"Disabled"} />}
                    {value.toLocaleString() + " disabled"}
                </div>
            );
        }

        // Else show environment-specific disabled action.
        if (this.state.isDisabledFilter) {
            return (
                <div key={disabledComponentKey} className={styles.summaryCount}>
                    {disabledIcon && <img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt={"Disabled"} />}
                    {value.toLocaleString() + " disabled"}
                    <SmallCloseButton
                        onClose={() => {
                            this.setState({ isDisabledFilter: false }, () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            });
                        }}
                    />
                </div>
            );
        } else {
            return (
                <ActionButton
                    key={disabledComponentKey}
                    icon={<img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt={"Disabled"} />}
                    className={styles.summaryCount}
                    type={ActionButtonType.Ternary}
                    label={value.toLocaleString() + " disabled"}
                    onClick={(e: React.MouseEvent) => {
                        // The user may click a disabled link to open an expander (but it shouldn't ever close it).
                        if (this.state.expanded) {
                            e.preventDefault();
                            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                        }
                        // Clear any healthStatus filters when disabled is clicked. You can't chain inline disabled and healthStatus
                        // filters together because they use different and/or logic at the API and it causes UI confusion.
                        this.setState(
                            {
                                isDisabledFilter: true,
                                healthStatusFilter: "",
                            },
                            () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            }
                        );
                    }}
                />
            );
        }
    }

    protected setMachineResponseState(machinesResponse: ResourceCollection<WorkerMachineResource | MachineResource>) {
        const machineHealthStatusFastLookup = this.assembleHealthStatusFastLookup(machinesResponse);
        this.setState({ machinesResponse, machineHealthStatusFastLookup });
    }

    protected assembleHealthStatusFastLookup(machinesResponse: ResourceCollection<WorkerMachineResource | MachineResource>) {
        return createMachineHealthMap(machinesResponse, this.machineListTakeSize, this.state.machineHealthStatusFastLookup);
    }

    private getBaseState<T>(accessor: (state: Readonly<BaseAllMachinesSummaryState>) => T) {
        return accessor(this.state as BaseAllMachinesSummaryState);
    }
}

export default BaseAllMachinesSummary;
