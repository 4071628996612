import React from "react";
import ToolTip from "components/ToolTip";
import { withTheme } from "components/Theme";
const styles = require("./ProcessActionErrorIndicator.less");

interface ProcessActionErrorIndicatorProps {
    actionErrors: string[];
}

export const ProcessActionErrorIndicator: React.FC<ProcessActionErrorIndicatorProps> = ({ actionErrors }) => {
    const actionErrorsListItems = actionErrors.map(x => <li>{x}</li>);
    return withTheme(theme => (
        <ToolTip content={<ul>{actionErrorsListItems}</ul>}>
            <div className={styles.indicatorContainer}>
                <em className="fa fa-exclamation-triangle" style={{ color: theme.danger }} />
            </div>
        </ToolTip>
    ));
};
