import { DeploymentTargetResource, ResourceCollection, TaskResource } from "client/resources";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import FormPage from "components/FormPage/FormPage";
import List from "components/List";
import PaperLayout from "components/PaperLayout/PaperLayout";
import TaskDetails from "components/TaskDetails/TaskDetails";
import * as React from "react";
import { RouteComponentProps } from "react-router";

import routeLinks from "../../../../routeLinks";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";

class DeploymentTasksList extends List<TaskResource<{}>> {}

interface MachineDeploymentsProps extends RouteComponentProps<MachineDeploymentsParams> {
    initialData: InitialData;
}

interface MachineDeploymentsParams {
    machineId: string;
}

interface MachineDeploymentsState extends DataBaseComponentState {
    tasksResponse: ResourceCollection<TaskResource<{}>>;
}

interface InitialData {
    machine: DeploymentTargetResource;
    tasksResponse: ResourceCollection<TaskResource<{}>>;
}

const Title = "Deployments";
const MachineDeploymentsLayoutFormPage = FormPage<InitialData>();
const MachineDeploymentsLayout: React.FC<RouteComponentProps<MachineDeploymentsParams>> = (props: RouteComponentProps<MachineDeploymentsParams>) => {
    return (
        <MachineDeploymentsLayoutFormPage
            title={Title}
            load={async () => {
                const machineId = props.match.params.machineId;
                const machine = await repository.Machines.get(machineId);
                const tasksResponse = await repository.Machines.getTasks(machine, { skip: 0 });

                return { machine, tasksResponse };
            }}
            renderWhenLoaded={data => <MachineDeploymentsLayoutInner initialData={data} {...props} />}
        />
    );
};

class MachineDeploymentsLayoutInner extends DataBaseComponent<MachineDeploymentsProps, MachineDeploymentsState> {
    constructor(props: MachineDeploymentsProps) {
        super(props);
        this.state = {
            tasksResponse: props.initialData.tasksResponse,
        };
    }

    render() {
        return (
            <PaperLayout title={Title} busy={this.state.busy} errors={this.state.errors}>
                <DeploymentTasksList
                    initialData={this.state.tasksResponse}
                    onRow={(item: TaskResource<{}>) => this.buildDeploymentTaskRow(item)}
                    onRowRedirectUrl={(task: TaskResource<{}>) => routeLinks.task(task).root}
                    showPagingInNumberedStyle={true}
                    onPageSelected={async (skip: number, p: number) => {
                        await this.loadDeploymentTasks(skip);
                    }}
                />
            </PaperLayout>
        );
    }

    private async loadDeploymentTasks(skip: number) {
        const tasksResponse = await repository.Machines.getTasks(this.props.initialData.machine, { skip });
        this.setState({ tasksResponse });
    }

    private buildDeploymentTaskRow(task: TaskResource<{}>) {
        return <TaskDetails task={task} stripTopBottomPadding={true} />;
    }
}

export default MachineDeploymentsLayout;
