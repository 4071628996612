import * as React from "react";
import BaseComponent from "../BaseComponent";
import LateStateChangesChild from "components/StyleGuide/LateStateChangesChild";

interface State {
    removeChild: boolean;
}

export default class LateStateChanges extends BaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = { removeChild: false };
    }

    async componentDidMount() {
        setTimeout(() => this.setState({ removeChild: true }), 1000);
    }
    render() {
        return !this.state.removeChild && <LateStateChangesChild />;
    }
}
