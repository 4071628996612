import * as React from "react";
import List from "components/List";
import { ResourceCollection } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { ProxyResource } from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton, NavigationButtonType } from "components/Button";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../InfrastructureLayout";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "routeLinks";
import ToolTip from "components/ToolTip";
import FormPage from "components/FormPage/FormPage";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import ActionList from "components/ActionList";

class ProxyList extends List<ProxyResource> {}

interface ProxiesLayoutInnerProps extends RouteComponentProps<void> {
    initialData: InitialData;
}

interface InitialData {
    proxiesResponse: ResourceCollection<ProxyResource>;
}

const Title = "Machine Proxies";
const ProxiesLayoutFormPage = FormPage<InitialData>();
const ProxiesLayout: React.FC<RouteComponentProps<void>> = (props: RouteComponentProps<void>) => {
    return (
        <ProxiesLayoutFormPage
            title={Title}
            load={async () => {
                return { proxiesResponse: await repository.Proxies.list() };
            }}
            renderWhenLoaded={data => <ProxiesLayoutInner initialData={data} {...props} />}
            renderAlternate={args => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};
class ProxiesLayoutInner extends DataBaseComponent<ProxiesLayoutInnerProps, DataBaseComponentState> {
    constructor(props: ProxiesLayoutInnerProps) {
        super(props);
        this.state = {};
    }

    render() {
        const addButton = (
            <PermissionCheck permission={Permission.ProxyCreate}>
                <ActionList
                    actions={[
                        <ToolTip content={"Modify the proxy used by the Octopus Server for web requests"}>
                            <NavigationButton type={NavigationButtonType.Secondary} label="Modify Web Request Proxy" href={routeLinks.configuration.setting("server-proxy")} />
                        </ToolTip>,
                        <NavigationButton type={NavigationButtonType.Primary} label="Add Proxy" href={routeLinks.infrastructure.proxies.create} />,
                    ]}
                />
            </PermissionCheck>
        );

        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout title={Title} sectionControl={addButton}>
                    <ProxyList
                        initialData={this.props.initialData.proxiesResponse}
                        onRow={(item: ProxyResource) => this.buildProxyRow(item)}
                        onFilter={this.Filter}
                        filterSearchEnabled={true}
                        apiSearchParams={["partialName"]}
                        match={this.props.match}
                        filterHintText="Filter by name..."
                    />
                </PaperLayout>
            </InfrastructureLayout>
        );
    }

    private Filter(filter: string, resource: ProxyResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildProxyRow(proxy: ProxyResource) {
        return <ListTitle>{proxy.Name}</ListTitle>;
    }
}

export default ProxiesLayout;
