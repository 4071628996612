import * as React from "react";
import { Text } from "components/form";
import { CircularProgress, Dialog, Grid } from "@material-ui/core";
import { DialogLayout } from "components/DialogLayout/DialogLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import { Callout, CalloutType } from "components/Callout";
import { filter, join, trimEnd } from "lodash";
import KeyboardHandler, { Key } from "components/KeyboardHandler";

enum CommitDialogStep {
    CommitMessage = 1,
    Committing = 2,
    CommitFailed = 3,
}

interface CommitDialogProps {
    open: boolean;
    branchName: string;
    defaultSummary: string;
    autoCommit?: boolean;
    onClose: () => void;
    onCommit: (commitMessage: string) => Promise<boolean>;
}

const CommitMessageStep: React.FC<{ onChange: (summary: string, details: string) => void; defaultSummary: string }> = ({ onChange, defaultSummary }) => {
    const [summary, setSummary] = React.useState("");
    const [details, setDetails] = React.useState("");

    const updateSummary = (newValue: string) => {
        setSummary(newValue);
        onChange(newValue, details);
    };

    const updateDetails = (newValue: string) => {
        setDetails(newValue);
        onChange(summary, newValue);
    };

    return (
        <>
            <h2>Enter commit message</h2>
            <div>
                <Text key="summary" id="summary" name="Summary" label="Summary" value={summary} onChange={updateSummary} placeholder={defaultSummary} autoFocus={true} />
                <Text key="details" id="details" name="Details" label="Details" value={details} onChange={updateDetails} multiline={true} rows={5} />
            </div>
        </>
    );
};

const CommittingStep: React.FC<{}> = () => {
    return (
        <>
            <h2>Committing...</h2>
            <CircularProgress />
        </>
    );
};

const CommitFailed: React.FC<{}> = () => {
    return (
        <Callout title="Errors found" type={CalloutType.Danger}>
            Please cancel this dialog to review the errors (or discard your changes).
        </Callout>
    );
};

const CommitDialog: React.FC<CommitDialogProps> = props => {
    const [activeStep, setActiveStep] = React.useState(props.autoCommit ? CommitDialogStep.Committing : CommitDialogStep.CommitMessage);
    const [commitMessage, setCommitMessage] = React.useState(props.defaultSummary);

    const updateCommitMessage = (summary: string, details: string) => {
        summary = summary || props.defaultSummary;
        const formattedMessage = join(filter([summary, trimEnd(details)]), "\n\n");
        setCommitMessage(formattedMessage);
    };

    const doCommit = async () => {
        if (commitDisabled) return;
        setActiveStep(CommitDialogStep.Committing);
        const success = await props.onCommit(commitMessage);
        if (success) {
            props.onClose();
        } else {
            setActiveStep(CommitDialogStep.CommitFailed);
        }
    };

    const actions = () => {
        return [
            <ActionButton key="Cancel" label="Cancel" title="Cancel" onClick={props.onClose} disabled={cancelDisabled} />,
            <ActionButton type={ActionButtonType.Save} key="Commit" label="Commit" title="Commit" onClick={doCommit} disabled={commitDisabled} />,
        ];
    };

    const steps: Record<CommitDialogStep, JSX.Element> = {
        [CommitDialogStep.CommitMessage]: <CommitMessageStep onChange={updateCommitMessage} defaultSummary={props.defaultSummary} />,
        [CommitDialogStep.Committing]: (
            <Grid container direction="column" alignItems="center">
                <CommittingStep />
            </Grid>
        ),
        [CommitDialogStep.CommitFailed]: <CommitFailed />,
    };

    const resetInitialState = () => {
        setActiveStep(props.autoCommit ? CommitDialogStep.Committing : CommitDialogStep.CommitMessage);
        setCommitMessage(props.defaultSummary);
    };

    const doCommitOnLoad = async () => {
        if (props.autoCommit) {
            await doCommit();
        }
    };

    const commitDisabled = activeStep !== CommitDialogStep.CommitMessage;
    const cancelDisabled = activeStep === CommitDialogStep.Committing;

    const closeDialog = () => {
        if (!cancelDisabled) {
            props.onClose();
        }
    };

    const onEnter = (event: KeyboardEvent): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target: any = event.target ? event.target : event.srcElement;
        const tagName = target.tagName;
        if (tagName.toUpperCase() === "INPUT") {
            doCommit();
            return true;
        }
        return false;
    };

    const onCtrlEnter = (event: KeyboardEvent) => {
        doCommit();
        return true;
    };

    const keyboardRegistrations = [
        { key: Key.Enter, onKeyPressed: onEnter },
        { key: Key.CtrlEnter, onKeyPressed: onCtrlEnter },
    ];

    return (
        <Dialog open={props.open} fullWidth onEnter={resetInitialState} onEntered={doCommitOnLoad}>
            <KeyboardHandler registrations={keyboardRegistrations}>
                <DialogLayout title={"Committing to " + props.branchName} actions={actions()} closeDialog={closeDialog}>
                    {steps[activeStep]}
                </DialogLayout>
            </KeyboardHandler>
        </Dialog>
    );
};

export default CommitDialog;
