/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
type DoBusyTask = import("./DataBaseComponent").DoBusyTask;

export const useDoBusyTaskEffect = <T extends any>(doBusyTask: DoBusyTask, fetch: () => Promise<T>, dependencies: React.DependencyList) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refresh = React.useCallback(() => doBusyTask(fetch), dependencies);

    // https://reactjs.org/docs/hooks-reference.html#useeffect
    React.useEffect(() => {
        // It's important not to make useEffect async or to return a promise here, since useEffect can return a cleanup function.
        // eslint-disable-next-line: no-floating-promises
        refresh();
    }, [refresh]);

    return refresh;
};

export default useDoBusyTaskEffect;
