import * as React from "react";
const styles = require("./style.less");

const ListTitle: React.StatelessComponent = props => {
    return <h3 className={styles.listTitle}>{props.children}</h3>;
};

ListTitle.displayName = "ListTitle";

export default ListTitle;
