/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { Reducer } from "react";
export type TypeConstant = string;
export type Action<TType extends TypeConstant = TypeConstant> = {
    type: TType;
};
export type ConstantAction<T extends TypeConstant> = Action<T>;
export interface AnyConstantAction<TType extends string = TypeConstant> extends ConstantAction<TType> {
    // Allows any extra properties to be defined in an action.
    [extraProps: string]: any;
}

export type ConstantReducer<TState = any, TAction extends ConstantAction<TypeConstant> = AnyConstantAction> = Reducer<TState, TAction>;
export type ConstantReducersMapObject<S = any, A extends ConstantAction<TypeConstant> = ConstantAction<TypeConstant>> = {
    [K in keyof S]: ConstantReducer<S[K], A>;
};
//React allows us to dispatch pretty much anything, but if we are combining reducers,
//then we really need a way to discriminate actions, hence we require flux/redux type actions.
export function combineConstantReducers<TState, TAction extends ConstantAction<any>>(reducers: ConstantReducersMapObject<TState, TAction>) {
    const reducerKeys = Object.keys(reducers) as Array<keyof TState>;
    function combinedReducer(state: TState, action: TAction): TState {
        const nextState: Partial<TState> = {};
        let hasChanged = false;
        reducerKeys.forEach(key => {
            const reducer = reducers[key];
            const previousStateForKey = state[key];
            const nextStateForKey = reducer(previousStateForKey, action);
            if (typeof nextStateForKey === "undefined") {
                throw new Error(`Returned undefined for reducer key ${key}. Please return null if you don't want to set the value.`);
            }
            nextState[key] = nextStateForKey;
            if (nextStateForKey !== previousStateForKey) {
                hasChanged = true;
            }
        });

        return (hasChanged ? nextState : state) as TState;
    }
    return combinedReducer;
}

export default combineConstantReducers;
