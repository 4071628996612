import CopyToClipboard from "components/CopyToClipboardButton";
import React from "react";
import Section from "components/Section";

const styles = require("./style.less");

const CopyValueToClipboard: React.FC<{ value: string }> = ({ value }) => {
    return (
        <Section className={styles.copyValue}>
            <strong>{value}</strong>
            <CopyToClipboard value={value} />
        </Section>
    );
};

export default CopyValueToClipboard;
