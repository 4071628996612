import Client from "../client";
import { ProjectResource, VcsBranchResource } from "client/resources";
import { DeploymentProcessRepository } from "./deploymentProcessRepository";

class ProjectContextRepository {
    DeploymentProcesses: DeploymentProcessRepository;

    constructor(client: Client, project: ProjectResource, branch: VcsBranchResource | undefined) {
        this.DeploymentProcesses = new DeploymentProcessRepository(client, project, branch);
    }
}

export default ProjectContextRepository;
