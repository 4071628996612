import * as React from "react";
import * as tenantTagsets from "components/tenantTagsets";

import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { EnvironmentsSummaryResource, TagSetResource, TenantResource, WorkerPoolsSummaryResource } from "client/resources";
import { NavigationButton, NavigationButtonType } from "components/Button";

import ActionList from "components/ActionList/ActionList";
import EnvironmentsCard from "./EnvironmentsCard";
import FormPage from "components/FormPage/FormPage";
import HealthStatusCard from "./HealthStatusCard";
import InfrastructureLayout from "../InfrastructureLayout";
import MachineIconHelper from "utils/MachineIconHelper";
import MachinesCard from "./MachinesCard";
import Onboarding from "./Onboarding";
import PaperLayout from "components/PaperLayout/PaperLayout";
import Permission from "../../../../client/resources/permission";
import RolesCard from "./RolesCard";
import TagSetsCard from "./TagSetsCard";
import TenantsCard from "./TenantsCard";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import WorkersCard from "./WorkersCard";
import { connect } from "react-redux";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { repository } from "clientInstance";
import routeLinks from "routeLinks";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

const styles = require("./style.less");

interface InfrastructureDashboardState extends DataBaseComponentState {
    filterPartialName: string;
    isSearching: boolean;
}

interface InitialData {
    environmentsSummary: EnvironmentsSummaryResource | undefined;
    workerpoolsSummary: WorkerPoolsSummaryResource | undefined;
    machineRoles: string[] | undefined;
    tenants: TenantResource[];
    tagSets: TagSetResource[];
    showOnboarding: boolean;
}

interface InfrastructureDashboardProps {
    initialData: InitialData;
}

interface GlobalConnectedProps extends InfrastructureDashboardProps {
    isMultiTenancyEnabled: boolean;
}

const InfrastructureDashboardFormPage = FormPage<InitialData>();
const Title = "Overview";

const InfrastructureDashboardPage: React.FC = () => {
    return (
        <InfrastructureDashboardFormPage
            title={Title}
            load={async () => {
                const environmentsSummary = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? await repository.Environments.summary() : undefined;

                const workerpoolsSummary = isAllowed({ permission: Permission.WorkerView }) ? await repository.WorkerPools.summary() : undefined;
                const machineRoles = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? await repository.MachineRoles.all() : undefined;
                const tenants = await repository.Tenants.all();
                const tagSets = await tenantTagsets.getAll();

                const environmentOnboarding = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) && (await repository.Environments.list({ take: 0 }).then(v => v.TotalResults === 0));
                const workersOnboarding = isAllowed({ permission: Permission.WorkerView }) && (await repository.Workers.list({ take: 0 }).then(v => v.TotalResults === 0));
                const showOnboarding = environmentOnboarding && workersOnboarding;

                return { environmentsSummary, workerpoolsSummary, machineRoles, tenants, tagSets, showOnboarding };
            }}
            renderWhenLoaded={data => <InfrastructureDashboard initialData={data} />}
            renderAlternate={args => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class InfrastructureDashboardInner extends DataBaseComponent<GlobalConnectedProps, InfrastructureDashboardState> {
    private machineIconHelper = new MachineIconHelper();

    constructor(props: GlobalConnectedProps) {
        super(props);
        this.state = {
            isSearching: false,
            filterPartialName: "",
        };
    }

    render() {
        const environementSummary: EnvironmentsSummaryResource | undefined = this.props.initialData.environmentsSummary;
        const actions = [
            environementSummary && environementSummary.EnvironmentSummaries && environementSummary.EnvironmentSummaries.length === 0 && (
                <NavigationButton label="Go to Environments" href={routeLinks.infrastructure.environments.root} type={NavigationButtonType.Primary} />
            ),
        ];
        const actionSection = <ActionList actions={actions} />;

        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout title={Title} busy={this.state.busy} errors={this.state.errors} sectionControl={actionSection}>
                    {this.props.initialData.showOnboarding === true && <Onboarding />}
                    {this.props.initialData.showOnboarding === false && (
                        <div className={styles.cardsContainer}>
                            <EnvironmentsCard key="environmentsCard" environmentSummary={this.props.initialData.environmentsSummary} />
                            <MachinesCard
                                key="machinesCard"
                                title="Deployment Targets"
                                link={filter => routeLinks.infrastructure.machines.filtered(filter)}
                                summary={this.props.initialData.environmentsSummary}
                                machineIconHelper={this.machineIconHelper}
                            />
                            <HealthStatusCard
                                key="healthStatusCard"
                                title="Target Status"
                                link={filter => routeLinks.infrastructure.machines.filtered(filter)}
                                summary={this.props.initialData.environmentsSummary}
                                machineIconHelper={this.machineIconHelper}
                            />
                            <RolesCard key="rolesCard" machineRoles={this.props.initialData.machineRoles} />
                            <WorkersCard key="workersCard" workerSummary={this.props.initialData.workerpoolsSummary} />

                            {/* These are wrapped in animations so they fade in when the data is available */}
                            {this.props.initialData.workerpoolsSummary && this.props.initialData.workerpoolsSummary.TotalMachines > 0 && (
                                <TransitionAnimation>
                                    <MachinesCard
                                        key="workersMachinesCard"
                                        title="Workers"
                                        link={filter => routeLinks.infrastructure.workerMachines.filtered(filter)}
                                        summary={this.props.initialData.workerpoolsSummary}
                                        machineIconHelper={this.machineIconHelper}
                                    />
                                </TransitionAnimation>
                            )}
                            {this.props.initialData.workerpoolsSummary && this.props.initialData.workerpoolsSummary.TotalMachines > 0 && (
                                <TransitionAnimation>
                                    <HealthStatusCard
                                        key="healthStatusCard"
                                        title="Worker Status"
                                        link={filter => routeLinks.infrastructure.workerMachines.filtered(filter)}
                                        summary={this.props.initialData.workerpoolsSummary}
                                        machineIconHelper={this.machineIconHelper}
                                    />
                                </TransitionAnimation>
                            )}
                            {this.props.initialData.environmentsSummary &&
                                this.props.initialData.tagSets &&
                                this.props.isMultiTenancyEnabled &&
                                isAllowed({
                                    permission: Permission.TenantView,
                                    tenant: "*",
                                }) && (
                                    <TransitionAnimation>
                                        <TagSetsCard key="tagSetsCard" environmentSummary={this.props.initialData.environmentsSummary} tagSets={this.props.initialData.tagSets} />
                                    </TransitionAnimation>
                                )}
                            {this.props.initialData.environmentsSummary &&
                                this.props.initialData.tenants &&
                                this.props.isMultiTenancyEnabled &&
                                isAllowed({
                                    permission: Permission.TenantView,
                                    tenant: "*",
                                }) && (
                                    <TransitionAnimation>
                                        <TenantsCard key="tenantsCard" environmentSummary={this.props.initialData.environmentsSummary} tenants={this.props.initialData.tenants} />
                                    </TransitionAnimation>
                                )}
                        </div>
                    )}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: InfrastructureDashboardProps): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
        initialData: props.initialData,
    };
};

const InfrastructureDashboard = connect(mapGlobalStateToProps)(InfrastructureDashboardInner);
export default InfrastructureDashboardPage;
