import * as React from "react";
import { repository } from "clientInstance";
import BaseComponent from "components/BaseComponent";
import { FormFieldProps } from "components/form";
import { MultiSelect } from "components/MultiSelect";
import { EndpointCommunicationStyleChip } from "components/Chips";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";
import { CommunicationStyle, CommunicationStyleResource } from "client/resources";

interface EndpointCommunicationStyleMultiSelectProps extends FormFieldProps<CommunicationStyle[]> {
    items: CommunicationStyleResource[];
    label?: string | JSX.Element;
    error?: string;
}

const EndpointCommunicationStyleTypedMultiSelect = MultiSelect<CommunicationStyleResource>();

const EndpointCommunicationStyleMultiSelect: React.StatelessComponent<EndpointCommunicationStyleMultiSelectProps> = props => {
    const chipRenderer = (r: CommunicationStyleResource, onRequestDelete: (event: object) => void) => {
        return (
            <LookupReferenceDataItemChip
                lookupCollection={props.items}
                lookupId={r.Id}
                type={ChipIcon.CommunicationStyle}
                chipRender={(item: CommunicationStyleResource) => <EndpointCommunicationStyleChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} commStyle={item} />}
            />
        );
    };

    return <EndpointCommunicationStyleTypedMultiSelect items={props.items} fieldName="communication styles" renderChip={chipRenderer} {...props} />;
};

export default EndpointCommunicationStyleMultiSelect;
