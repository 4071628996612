import * as React from "react";
import ActionButton, { ActionButtonType, IconPosition } from "../../Button/ActionButton";
import { LargeDialogFrame, DialogFrameProp } from "./CustomDialogFrames";
import { RenderProps } from "components/Dialog/CustomDialog";
import { CustomDialogTitleBar } from "./CustomDialogTitleBar";
import { CustomDialogContent } from "./CustomDialogContent";
import { CustomDialogActions } from "./CustomDialogActions";

export type CustomExternalLinkDialogLayoutProps = RenderProps &
    DialogFrameProp & {
        title: string;
        externalLink: string;
        externalLinkLabel: string;
        closeButtonLabel?: string;
        additionalActions?: React.ReactNode;
    };

export const CustomExternalLinkDialogLayout: React.SFC<CustomExternalLinkDialogLayoutProps> = ({ closeButtonLabel = "Close", children, title, externalLink, externalLinkLabel, close, frame: Frame = LargeDialogFrame, additionalActions }) => {
    return (
        <Frame>
            <CustomDialogTitleBar title={title} />
            <CustomDialogContent>{children}</CustomDialogContent>
            <CustomDialogActions
                actions={
                    <React.Fragment>
                        {additionalActions}
                        <ActionButton key="close" onClick={close} label={closeButtonLabel} type={ActionButtonType.Secondary} />
                        <ActionButton
                            key="link"
                            onClick={event => {
                                event.preventDefault();
                                window.open(externalLink, "_blank");
                            }}
                            label={externalLinkLabel}
                            type={ActionButtonType.Primary}
                            icon={<em style={{ marginLeft: "0.250rem", color: "#FFF" }} className="fa fa-external-link" aria-hidden="true" />}
                            iconPosition={IconPosition.Right}
                        />
                    </React.Fragment>
                }
            />
        </Frame>
    );
};

export default CustomExternalLinkDialogLayout;
