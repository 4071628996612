import * as React from "react";
import { useProjectContext } from "areas/projects/context";
import NavigationButton, { NavigationButtonProps } from "components/Button/NavigationButton";
import routeLinks from "routeLinks";
import { PermissionCheck } from "components/PermissionCheck";
import { useProcessContext } from "../Process/Contexts/ProcessContext";
import { processScopedEditPermission, isRunbookProcessState } from "../Process/Common/CommonProcessHelpers";
import { VcsBranchResource } from "client/resources";

type AddRunbookStepButtonPropChanges = Omit<NavigationButtonProps, "href" | "label"> & { href?: NavigationButtonProps["href"]; label?: NavigationButtonProps["label"] };

type AddRunbookStepButtonProps = {
    projectId: string;
    processId: string;
    runbookId: string;
    projectSlug: string;
} & AddRunbookStepButtonPropChanges;

const AddRunbookStepButton: React.FC<AddRunbookStepButtonProps> = ({ projectId, projectSlug, processId, runbookId, ...rest }) => {
    const href = routeLinks
        .project(projectSlug)
        .operations.runbook(runbookId)
        .runbookProcess.runbookProcess(processId).process.stepTemplates;
    const processContext = useProcessContext();
    return (
        <PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
            <NavigationButton label="Add Step" href={href} {...rest} />
        </PermissionCheck>
    );
};

type AddDeploymentsStepButtonProps = {
    projectId: string;
    projectSlug: string;
    branch: VcsBranchResource | undefined;
};

const AddDeploymentsStepButton: React.FC<AddDeploymentsStepButtonProps> = ({ projectId, projectSlug, branch, ...rest }) => {
    const href = routeLinks.projectBranch(projectSlug, branch).deploymentProcess.stepTemplates;
    const processContext = useProcessContext();
    return (
        <PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
            <NavigationButton label="Add Step" href={href} {...rest} />
        </PermissionCheck>
    );
};

export type ContextAddStepNavigationButtonProps = AddRunbookStepButtonPropChanges;
const ContextAddStepNavigationButton: React.FC<ContextAddStepNavigationButtonProps> = props => {
    const processContext = useProcessContext();
    const project = useProjectContext();
    const process = processContext.state.model.process;

    if (isRunbookProcessState(process)) {
        return <AddRunbookStepButton projectSlug={project.state.model.Slug} projectId={project.state.model.Id} processId={process.Id} runbookId={process.RunbookId} {...props} />;
    } else {
        return <AddDeploymentsStepButton projectSlug={project.state.model.Slug} projectId={project.state.model.Id} branch={project.state.branch} {...props} />;
    }
};

export default ContextAddStepNavigationButton;
