/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DashboardItemResource, TagSetResource, ChannelResource, DashboardProjectResource, DashboardTenantResource, DashboardProjectGroupResource, ReferenceDataItem } from "client/resources";
import { ReleaseResource } from "../../../../client/resources/releaseResource";
import { LifecycleResource } from "../../../../client/resources/lifecycleResource";

enum DimensionTypes {
    None,
    Release,
    Channel,
    Environment,
    Tenant,
    TagSet,
    Project,
    ProjectGroup,
    ProjectName, //for filtering only
}

interface DashboardFilters {
    [key: number]: { [key: string]: boolean };
    groupBy?: DimensionTypes;
    groupByExtra?: string;
    rowDimension?: DimensionTypes;
    columnDimension?: DimensionTypes;
}

interface DataCube {
    releaseIndex: { [releaseId: string]: ReleaseResource };
    channelIndex: { [channelId: string]: ChannelResource };
    lifecycleIndex: { [lifecycleId: string]: LifecycleResource };
    tenantIndex: { [tenantId: string]: DashboardTenantResource };
    tenantTagIndex: { [tenantId: string]: string[] };
    projectIndex: { [projectId: string]: DashboardProjectResource };
    projectGroupIndex: { [projectGroupId: string]: DashboardProjectGroupResource };
    channelEnvironments: { [index: string]: string[] };
    environmentIndex: { [environmentId: string]: ReferenceDataItem };
    tagSetIndex: { [canonicalTagName: string]: TagSetResource };
    nextAvailableDeployments: { [releaseId: string]: { [environmentId: string]: string[] } };
    blockedReleases: string[];
    deployments: DashboardItemResource[];
    missingVariableTenants: string[];
}

const DimensionGetters: { [key: number]: (item: DashboardItemResource) => string } = {
    [DimensionTypes.Channel]: item => item.ChannelId,
    [DimensionTypes.Release]: item => item.ReleaseId,
    [DimensionTypes.Environment]: item => item.EnvironmentId,
    [DimensionTypes.Tenant]: item => item.TenantId!,
    [DimensionTypes.Project]: item => item.ProjectId,
    [DimensionTypes.None]: item => null!,
};

export { DimensionTypes, DashboardFilters, DataCube, DimensionGetters };
