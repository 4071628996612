import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectTopics } from "../../CommonComponents/CommonProjectViews";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
import ExternalLink from "components/Navigation/ExternalLink";

export const ProjectSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().settings.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (
            <OverviewContainer>
                <OverviewHighlightText>
                    <p>
                        <ExternalLink href="OnboardingProjectsLearnMore">Project settings</ExternalLink> allow you to edit details about the project, such as the name, description, and the project group, add a logo and control different aspects of
                        the project's release behaviour.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>
        );
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTopics />;
    },
};
