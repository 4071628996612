import * as React from "react";
import { ProjectGroupResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { ProjectGroupChip } from "components/Chips/index";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";

interface ProjectGroupMultiSelectProps extends FormFieldProps<string[]> {
    items: ProjectGroupResource[];
    label?: string | JSX.Element;
    error?: string;
}

const ProjectGroupTypedMultiSelect = MultiSelect<ProjectGroupResource>();

export const ProjectGroupMultiSelect: React.StatelessComponent<ProjectGroupMultiSelectProps> = props => {
    const chipRenderer = (r: ProjectGroupResource, onRequestDelete: (event: object) => void) => {
        return (
            <LookupReferenceDataItemChip
                lookupCollection={props.items}
                lookupId={r.Id}
                type={ChipIcon.ProjectGroup}
                chipRender={(item: ProjectGroupResource) => <ProjectGroupChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} projectGroup={item} />}
            />
        );
    };

    return <ProjectGroupTypedMultiSelect items={props.items} fieldName="project groups" renderChip={chipRenderer} {...props} />;
};
