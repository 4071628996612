import * as React from "react";
import { CommitDetail } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import ExternalLink from "components/Navigation/ExternalLink";
const styles = require("./style.less");

interface CommitDetailsProps {
    data: CommitDetail[];
}

export default class CommitDetails extends DataBaseComponent<CommitDetailsProps, DataBaseComponentState> {
    constructor(props: CommitDetailsProps) {
        super(props);
        this.state = {};
    }

    resolveLink = (commitDetail: CommitDetail): JSX.Element => {
        const shortCommitSha = commitDetail.Id.substring(0, 7);
        return (
            <div className={styles.commitCommentContainer} key={commitDetail.Id}>
                {commitDetail.LinkUrl ? (
                    <ExternalLink className={styles.commitLink} href={commitDetail.LinkUrl}>
                        {shortCommitSha}
                    </ExternalLink>
                ) : (
                    <div className={styles.commitLink}>{shortCommitSha}</div>
                )}
                <div className={styles.commitComment}>{commitDetail.Comment}</div>
            </div>
        );
    };

    render() {
        return this.props.data.map(commitDetail => this.resolveLink(commitDetail));
    }
}
