/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ActionTemplateSearchResource from "../../../../client/resources/actionTemplateSearchResource";
import { ActionTemplateResource } from "../../../../client/resources/actionTemplateResource";
import { LibraryVariableSetResource } from "../../../../client/resources/libraryVariableSetResource";

export default (root: string) => {
    const libraryRoot = `${root}/library`;
    const stepTemplatesRoot = `${libraryRoot}/steptemplates`;
    return {
        root: libraryRoot,
        feeds: `${libraryRoot}/feeds`,
        feedCreate: `${libraryRoot}/feeds/create`,
        feed: (feedId: string) => ({
            root: `${libraryRoot}/feeds/${feedId}`,
            test: `${libraryRoot}/feeds/${feedId}/test`,
            redirect: `${libraryRoot}/feeds/${feedId}/redirect`,
        }),
        stepTemplates: {
            root: stepTemplatesRoot,
            builtIn: `${stepTemplatesRoot}/builtin`,
            community: `${stepTemplatesRoot}/community`,
            installedStep: (template: ActionTemplateSearchResource) => `${stepTemplatesRoot}?installedStep=${template.Id}`,
            new: (templateSearch: ActionTemplateSearchResource | string) => {
                if (!!templateSearch && typeof templateSearch !== "string") {
                    templateSearch = templateSearch.Type;
                }
                return `${stepTemplatesRoot}/new/${templateSearch}`;
            },
            communityTemplate: (communityTemplateId: string | ActionTemplateSearchResource, projectId?: string) => {
                if (!!communityTemplateId && typeof communityTemplateId !== "string") {
                    communityTemplateId = communityTemplateId.CommunityActionTemplateId!;
                }

                return {
                    root: `${stepTemplatesRoot}/community/${communityTemplateId}${projectId ? `?projectId=${projectId}` : ""}`,
                    details: `${stepTemplatesRoot}/community/${communityTemplateId}/details`,
                };
            },
        },
        stepTemplate: (templateId: string | ActionTemplateResource) => {
            if (!!templateId && typeof templateId !== "string") {
                templateId = templateId.Id;
            }
            const stepTemplateRoot = `${stepTemplatesRoot}/${templateId}`;
            return {
                root: `${stepTemplateRoot}`,
                usage: `${stepTemplateRoot}/usage`,
                run: `${stepTemplateRoot}/run`,
            };
        },
        lifecycles: `${libraryRoot}/lifecycles`,
        lifecyclesCreate: `${libraryRoot}/lifecycles/create`,
        lifecycle: (lifecycleId: string) => `${libraryRoot}/lifecycles/${lifecycleId}`,
        certificates: {
            root: `${libraryRoot}/certificates`,
            archive: `${libraryRoot}/certificates/archive`,
            create: `${libraryRoot}/certificates/create`,
        },
        certificate: (certificateId: string) => `${libraryRoot}/certificates/${certificateId}`,
        variableSets: `${libraryRoot}/variables`,
        variableSet: (variableSetId: string | LibraryVariableSetResource, activeTab?: string) => {
            if (!!variableSetId && typeof variableSetId !== "string") {
                variableSetId = variableSetId.Id;
            }
            return `${libraryRoot}/variables/${variableSetId}${activeTab ? `?activeTab=${activeTab}` : ""}`;
        },
        tagSets: {
            root: `${libraryRoot}/tagsets`,
            create: `${libraryRoot}/tagsets/create`,
        },
        tagSet: (tagSetId: string) => `${libraryRoot}/tagsets/${tagSetId}`,
        builtInRepository: {
            root: `${libraryRoot}/builtinrepository`,
            package: (versionedPackage: string) => `${libraryRoot}/builtinrepository/${versionedPackage}`,
            versions: (packageId: string) => `${libraryRoot}/builtinrepository/versions/${packageId}`,
        },
        buildInformationRepository: {
            root: `${libraryRoot}/buildinformation`,
            package: (versionedPackage: string) => `${libraryRoot}/buildinformation/${versionedPackage}`,
            versions: (packageId: string, shouldEncode = true) => {
                if (shouldEncode) {
                    packageId = encodeURIComponent(packageId);
                }

                return `${libraryRoot}/buildinformation/versions/${packageId}`;
            },
        },
        scripts: {
            root: `${libraryRoot}/scripts`,
            create: `${libraryRoot}/scripts/create`,
        },
        script: (scriptId: string) => `${libraryRoot}/scripts/${scriptId}`,
        v3routes: {
            certificates: {
                create: `${libraryRoot}/certificates/new`,
            },
            feeds: {
                create: `${libraryRoot}/feeds/new`,
            },
            lifecycles: {
                create: `${libraryRoot}/lifecycles/new`,
            },
            scripts: {
                create: `${libraryRoot}/scripts/new`,
            },
            tagSets: {
                create: `${libraryRoot}/tagsets/new`,
            },
            variableSets: {
                create: `${libraryRoot}/variables/new`,
            },
        },
    };
};
