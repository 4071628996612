/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState, Refresh } from "components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { ExtensionsInfoResource } from "client/resources/extensionsInfoResource";
import { ServerStatusResource } from "client/resources/serverStatusResource";
import { SystemInfoResource } from "client/resources/systemInfoResource";
import { ActivityLogElement } from "client/resources/taskDetailsResource";
import SystemInformation from "areas/configuration/components/Diagnostics/SystemInformation";
import ServerExtensions from "areas/configuration/components/Diagnostics/ServerExtensions";
import ServerLogs from "areas/configuration/components/Diagnostics/ServerLogs";
import { NavigationButton } from "components/Button/NavigationButton";
import SystemDiagnosticReport from "areas/configuration/components/Diagnostics/SystemDiagnosticReport";
import SystemIntegrityCheck from "areas/configuration/components/Diagnostics/SystemIntegrityCheck";
import { TaskName, TaskResource } from "client/resources/taskResource";
import { reverse } from "lodash";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";

interface DiagnosticsState extends DataBaseComponentState {
    extensions?: ExtensionsInfoResource[];
    status?: ServerStatusResource;
    systemInfo?: SystemInfoResource;
    logs?: ActivityLogElement[];
    latestIntegrityCheck?: TaskResource<{}>;
    redirectToTaskId?: string;
    hasLoadedOnce?: boolean;
    isHA: boolean;
}

export class Diagnostics extends DataBaseComponent<{}, DiagnosticsState> {
    constructor(props: {}) {
        super(props);
        this.state = { isHA: false };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const nodes = repository.OctopusServerNodes.list();
            this.doRefresh = await this.startRefreshLoop(() => this.refresh(), 5000);
            this.setState({ isHA: (await nodes).Items.length > 1 });
        });
    }

    async refresh() {
        const getExtensions = repository.ServerStatus.getExtensionStats();
        const getLatestIntegrityCheck = repository.Tasks.list({ name: TaskName.SystemIntegrityCheck, take: 1 });
        const status = await repository.ServerStatus.getServerStatus();
        const getSystemInfo = repository.ServerStatus.getSystemInfo(status);
        const getLogs = repository.ServerStatus.getLogs(status, { take: 5, includeDetail: false });

        return {
            extensions: await getExtensions,
            status,
            systemInfo: await getSystemInfo,
            logs: reverse(await getLogs),
            latestIntegrityCheck: (await getLatestIntegrityCheck).Items[0],
            hasLoadedOnce: true,
        };
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }

        const section = this.state.extensions && (
            <div>
                <NavigationButton label="Auto deploy logs" href={routeLinks.configuration.diagnostics.logs.autoDeploy} />
                <NavigationButton label="Scheduled trigger logs" href={routeLinks.configuration.diagnostics.logs.scheduledDeploy} />
                <NavigationButton label="Subscription logs" href={routeLinks.configuration.diagnostics.logs.subscription} />
                <NavigationButton label="Machine clean up events" href={routeLinks.configuration.diagnostics.logs.machineCleanup} />
            </div>
        );

        const sideBar = this.state.extensions && (
            <div>
                <SystemInformation systemInfo={this.state.systemInfo!} onCollectClicked={() => this.onCollectClicked()} />
                <ServerExtensions extensions={this.state.extensions} />
            </div>
        );

        const body = this.state.logs && (
            <div>
                <SystemDiagnosticReport serverStatus={this.state.status!} />
                <hr />
                <SystemIntegrityCheck latestCheck={this.state.latestIntegrityCheck!} performIntegrityCheck={() => this.performIntegrityCheck()} />
                <hr />
                <ServerLogs logs={this.state.logs} isHA={this.state.isHA} />
            </div>
        );

        return (
            <PaperLayout title="Diagnostics" sectionControl={section} busy={this.state.busy} enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce} errors={this.state.errors}>
                <SidebarLayout sideBar={sideBar}>{body}</SidebarLayout>
            </PaperLayout>
        );
    }

    async onCollectClicked() {
        await this.doBusyTask(async () => {
            await repository.ServerStatus.gcCollect(this.state.status!);
        });
        await this.doRefresh();
    }

    async performIntegrityCheck() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createPerformIntegrityCheckTask();
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    private doRefresh: Refresh = () => Promise.resolve();
}

export default Diagnostics;
