/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { client, repository } from "clientInstance";
import { Note, RadioButton, RadioButtonGroup, Text } from "components/form";
import FeedResource, { FeedType } from "client/resources/feedResource";
import DebounceValue from "components/DebounceValue/DebounceValue";
import { getDefaultFeedId, noFeedsWarning, possibleFeeds } from "components/PackageSelector/PackageSelector";
import SelectWithAddRefresh from "components/form/SelectWithAddRefresh/SelectWithAddRefresh";
import routeLinks from "../../routeLinks";
import callAll from "utils/callAll";
import { withBoundField } from "components/form/BoundField/BoundField";
import { DynamicWorkerPoolResource, Permission } from "client/resources";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import Callout, { CalloutType } from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink";
import InternalLink from "components/Navigation/InternalLink";
import { WorkerToolsLatestImages } from "../../client/resources/workerToolsLatestImages";
import { RunOn, RunOnServerOrWorkerPool } from "areas/projects/components/Process/types";
import { isRunOnBuiltInWorker, isRunOnServerOrWorkerPool } from "areas/projects/components/Process/Common/CommonProcessHelpers";
import isBound from "../form/BoundField/isBound";
import { WorkerPoolType } from "../../client/resources/workerPoolsSupportedTypesResouce";

export enum StepExecutionOption { // Note that running on Octopus Server means we run in the inbuilt worker
    RunDirectlyOnWorker = "RunDirectlyOnWorker",
    RunInsideContainerOnWorker = "RunInsideContainerOnWorker",
}
class StepExecutionOptionRadioButtonGroup extends RadioButtonGroup<StepExecutionOption> {}

const BoundDebounceText = withBoundField(DebounceValue(Text));

interface ExecutionContainerImageSelectorProps {
    runOn: RunOnServerOrWorkerPool;
    feeds: FeedResource[];
    autoFocus?: boolean;
    feedIdError?: string;
    feedSelectLabel?: string;
    imageNameError?: string;
    workerPoolId: string | undefined;
    refreshFeeds(): Promise<void>;
    resetContainer(runOn: RunOnServerOrWorkerPool): void;
    onImageNameChange(value: string): void;
    onFeedIdChange(value: string): void;
    onStepExecutionOptionChange?(option: StepExecutionOption): void;
}

const ExecutionContainerImageSelector: React.FC<ExecutionContainerImageSelectorProps> = props => {
    const { feeds: providedFeeds, autoFocus = false, feedIdError, feedSelectLabel = "Container Registry", refreshFeeds, onFeedIdChange, onImageNameChange, onStepExecutionOptionChange, resetContainer, runOn, imageNameError, workerPoolId } = props;
    const feedType = [FeedType.Docker, FeedType.AwsElasticContainerRegistry];
    const feeds = possibleFeeds(providedFeeds, feedType);

    const defaultFeedId = runOn.container.FeedId ?? getDefaultFeedId(feeds);
    const defaultImageName = runOn.container.Image ?? "";

    const [feedId, setFeedId] = React.useState(defaultFeedId);
    const [imageName, setImageName] = React.useState(defaultImageName);

    const [stepExecutionOption, setStepExecutionOption] = React.useState<StepExecutionOption>(runOn.runningInContainer ? StepExecutionOption.RunInsideContainerOnWorker : StepExecutionOption.RunDirectlyOnWorker);

    const [latestWindowsImage, setLatestWindowsImage] = React.useState("");
    const [latestLinuxImage, setLatestLinuxImage] = React.useState("");

    const [isWindows2016CloudWorkerPool, setWindows2016CloudWorkerPool] = React.useState(false);

    const runInsideContainer = stepExecutionOption === StepExecutionOption.RunInsideContainerOnWorker;

    const noFeeds = feeds == null || feeds.length === 0;

    React.useEffect(() => {
        if (runInsideContainer) {
            findLatestWorkerToolsImageTag(setLatestWindowsImage, setLatestLinuxImage);
        }
    }, [runInsideContainer]);

    React.useEffect(() => {
        isWindows2016CloudWorker(workerPoolId, setWindows2016CloudWorkerPool);
    }, [workerPoolId]);

    if (!runOn.container.FeedId && defaultFeedId && runInsideContainer) {
        onFeedIdChange(defaultFeedId);
    }

    if (!runOn.container.Image && imageName && runInsideContainer) {
        onImageNameChange(imageName);
    }

    const runningInContainer = Boolean(isRunningInContainer(runOn) || (feedId && imageName));

    const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, wildcard: true });
    const feedEditPermissionGranted = isAllowed({ permission: Permission.FeedEdit, wildcard: true });

    const runningOnBuiltInWorker = isRunOnBuiltInWorker(runOn);

    return (
        <>
            {actionContainersEarlyAccessCallout()}

            <StepExecutionOptionRadioButtonGroup
                value={stepExecutionOption}
                onChange={option => {
                    setStepExecutionOption(option);
                    if (onStepExecutionOptionChange) {
                        onStepExecutionOptionChange(option);
                    }
                }}
            >
                <RadioButton value={StepExecutionOption.RunDirectlyOnWorker} label={`Runs directly on ${runningOnBuiltInWorker ? "Octopus Server" : "a worker"}`} isDefault={true} />
                <Note>{runningOnBuiltInWorker ? "Octopus Server" : "The worker"} will need the required dependencies pre-installed.</Note>

                <RadioButton value={StepExecutionOption.RunInsideContainerOnWorker} label={`Runs inside a container, on ${runningOnBuiltInWorker ? "the Octopus Server" : "a worker"}`} />
                <Note>{runningOnBuiltInWorker ? "Octopus Server" : "The worker"} will need Docker installed</Note>

                {!feedViewPermissionGranted ? feedViewPermissionMissingCallout(runningInContainer) : ""}

                {runInsideContainer && isWindows2016CloudWorkerPool ? dockerNotInstalledOnWindows2016WorkersWarning() : ""}

                {runInsideContainer && noFeeds && feedViewPermissionGranted ? (
                    noFeedsWarning(feedType, refreshFeeds)
                ) : runInsideContainer && feedViewPermissionGranted ? (
                    <div>
                        <SelectWithAddRefresh
                            value={feedId}
                            onChange={callAll(setFeedId, onFeedIdChange)}
                            items={feeds.map(f => ({ value: f.Id, text: f.Name }))}
                            error={feedIdError}
                            autoFocus={autoFocus}
                            label={feedSelectLabel}
                            addUrl={`#${routeLinks.library.feeds}`}
                            onRequestRefresh={refreshFeeds}
                            {...(!feedEditPermissionGranted
                                ? {
                                      disableAdd: true,
                                      addButtonTooltipText: "FeedEdit permissions required to add or edit a new feed",
                                  }
                                : {})}
                        />
                        <BoundDebounceText
                            variableLookup={{
                                localNames: null!,
                            }}
                            debounceDelay={500}
                            placeholder="Enter the image, including the tag"
                            value={imageName}
                            onChange={callAll(setImageName, onImageNameChange)}
                            error={imageNameError}
                        />
                        {actionContainersHelp(latestWindowsImage, latestLinuxImage, setImageName)}
                    </div>
                ) : (
                    ""
                )}
            </StepExecutionOptionRadioButtonGroup>
        </>
    );
};

const feedViewPermissionMissingCallout = (isRunningInContainerCheck: boolean) => {
    return (
        <Callout type={CalloutType.Warning} title={`FeedView Permission required`}>
            <div>You will need FeedView permission to {isRunningInContainerCheck ? "edit selected container and/or feed" : "enable running inside a container"}</div>
            <ExternalLink href="spaces-and-permissions">System and Space Permissions</ExternalLink>{" "}
        </Callout>
    );
};

const actionContainersEarlyAccessCallout = () => {
    return (
        <Note>
            If you have any feedback on execution containers, <ExternalLink href="ActionContainersFeedbackForm">please let us know</ExternalLink>.
        </Note>
    );
};

const actionContainersHelp = (latestWindowsImage: string, latestLinuxImage: string, setImageName: (image: string) => void) => {
    return (
        <div>
            <Note>The image should include the tag.</Note>

            <Note>
                Learn more about <ExternalLink href="ExecutionContainersWhichImage">which image you can use</ExternalLink>.
            </Note>

            <Note>
                The <ExternalLink href="WorkerToolsDockerHub">octopusdeploy/worker-tools</ExternalLink> images contain many tools commonly used in deployments.
                {!!latestWindowsImage && (
                    <ul>
                        <li>
                            <a href="#" onClick={e => setImageOnClick(e, latestWindowsImage, setImageName)}>
                                Use latest Windows-based image
                            </a>
                            : <code>{latestWindowsImage}</code>
                        </li>
                        <li>
                            <a href="#" onClick={e => setImageOnClick(e, latestLinuxImage, setImageName)}>
                                Use latest Linux-based image
                            </a>
                            : <code>{latestLinuxImage}</code>
                        </li>
                    </ul>
                )}
            </Note>
        </div>
    );
};

const dockerNotInstalledOnWindows2016WorkersWarning = () => {
    return (
        <Callout title="Docker not available" type={CalloutType.Warning}>
            Docker is not installed on Windows 2016 hosted workers. Attempting to use execution containers on this worker pool will fail. Please select a different <ExternalLink href="DynamicWorkerPools">worker pool</ExternalLink>.
        </Callout>
    );
};

const findLatestWorkerToolsImageTag = (setLatestWindowsImage: (image: string) => void, setLatestLinuxImage: (image: string) => void) => {
    (async () => {
        return (await client.get(client.getLink("WorkerToolsLatestImages"))) as WorkerToolsLatestImages;
    })()
        .then(latestImages => {
            const latestWindows2019Image = latestImages["windows.ltsc2019"];
            const latestUbuntu1804Image = latestImages["ubuntu.18.04"];
            if (!!latestWindows2019Image) {
                setLatestWindowsImage(latestWindows2019Image);
            }
            if (!!latestUbuntu1804Image) {
                setLatestLinuxImage(latestUbuntu1804Image);
            }
        })
        .catch(e => console.error("Error fetching latest images for octopusdeploy/worker-tools:" + e.message));
};

const isWindows2016CloudWorker = (workerPoolId: string | undefined, setWindows2016CloudWorkerPool: (isWindows2016: boolean) => void) => {
    if (!workerPoolId || isBound(workerPoolId)) return false;

    (async () => {
        return await repository.WorkerPools.get(workerPoolId);
    })()
        .then(workerPool => {
            if (!!workerPool && workerPool.WorkerPoolType === WorkerPoolType.Dynamic && (workerPool as DynamicWorkerPoolResource).WorkerType === "Windows2016") {
                setWindows2016CloudWorkerPool(true);
            }
        })
        .catch(e => console.error("Error fetching worker pool" + e.message));
};

export const isRunningInContainer = (runOn: RunOn): boolean => {
    if (!isRunOnServerOrWorkerPool(runOn)) {
        return false;
    }

    if (!runOn.runningInContainer) {
        return false;
    }

    if (runOn.container.FeedId === null || runOn.container.Image === null) {
        return false;
    }

    return true;
};

const setImageOnClick = (e: React.MouseEvent<Element, MouseEvent>, image: string, setImage: (image: string) => void) => {
    e.preventDefault();
    setImage(image);
};

export default ExecutionContainerImageSelector;
