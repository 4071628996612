/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import NavigationSidebarLayout, { Navigation } from "components/NavigationSidebarLayout";
import { ActionTemplateResource } from "client/resources";
import * as React from "react";
import BaseComponent from "components/BaseComponent";
import AreaTitle from "components/AreaTitle";
import { repository } from "clientInstance";
import { RouteComponentProps, withRouter } from "react-router";
import Badge from "material-ui/Badge";
import { connect } from "react-redux";
import { actionTemplateFetch, newActionTemplateSelected } from "../../../reducers/libraryArea";
import routeLinks from "routeLinks";
import { withTheme } from "components/Theme";

interface ActionTemplateLayoutParams {
    templateId: string;
}

interface StateProps {
    actionTemplateId?: string;
    actionTemplateName?: string;
    actionTemplateLogo?: string;
    actionTemplatePendingUpdates?: number;
}

interface GlobalDispatchProps {
    onFetchActionTemplate(actionTemplate: ActionTemplateResource): void;
    onNewActionTemplateSelected(): void;
}

type Props = RouteComponentProps<ActionTemplateLayoutParams> & StateProps & GlobalDispatchProps;

class ActionTemplateLayoutInternal extends BaseComponent<Props, never> {
    private actionTemplateId: string;

    constructor(props: Props) {
        super(props);
        this.actionTemplateId = this.props.match && this.props.match.params ? this.props.match.params.templateId : null!;
    }

    async componentDidMount() {
        if (this.actionTemplateId) {
            const actionTemplate = await repository.ActionTemplates.get(this.actionTemplateId);
            this.props.onFetchActionTemplate(actionTemplate);
        } else {
            this.props.onNewActionTemplateSelected();
        }
    }

    render() {
        return withTheme(theme => {
            const actionTemplateId = this.actionTemplateId;
            const navLinks = [];
            const style = { padding: "9px 15px" };
            const badgeStyle = { backgroundColor: theme.alert };
            if (actionTemplateId) {
                const pendingUpdates = this.props.actionTemplatePendingUpdates;
                const usageLabel = pendingUpdates ? (
                    <span>
                        Usage <Badge style={style} badgeContent={pendingUpdates} badgeStyle={badgeStyle} />
                    </span>
                ) : (
                    "Usage"
                );

                navLinks.push(Navigation.navItem("Details", routeLinks.library.stepTemplate(this.actionTemplateId).root, true)!);
                navLinks.push(Navigation.navItem(usageLabel, routeLinks.library.stepTemplate(this.actionTemplateId).usage)!);
            }

            return (
                <main id="maincontent">
                    <AreaTitle link={routeLinks.library.stepTemplates.root} title="Step Templates" breadcrumbTitle={"Library"} breadcrumbPath={routeLinks.library.root} />
                    <NavigationSidebarLayout name={this.props.actionTemplateName || "New step template"} logoUrl={this.props.actionTemplateLogo} navLinks={navLinks} content={this.props.children} />
                </main>
            );
        });
    }
}

const mapGlobalStateToProps = (state: GlobalState) => {
    return !state.libraryArea.currentActionTemplate
        ? {}
        : {
              actionTemplateId: state.libraryArea.currentActionTemplate.id,
              actionTemplateName: state.libraryArea.currentActionTemplate.name,
              actionTemplateLogo: state.libraryArea.currentActionTemplate.logo,
              actionTemplatePendingUpdates: state.libraryArea.currentActionTemplate.pendingUpdates,
          };
};

const mapGlobalActionDispatchersToProps = (dispatch: any) => {
    return {
        onFetchActionTemplate: (actionTemplate: ActionTemplateResource) => {
            dispatch(actionTemplateFetch(actionTemplate));
        },
        onNewActionTemplateSelected: () => {
            dispatch(newActionTemplateSelected());
        },
    };
};

const ActionTemplateLayout = connect<StateProps, GlobalDispatchProps, RouteComponentProps<ActionTemplateLayoutParams>>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ActionTemplateLayoutInternal);

export default withRouter(ActionTemplateLayout);
