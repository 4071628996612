/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { History, LocationDescriptorObject } from "history";
type LocationDescriptor = History.LocationDescriptor;
import * as pathToRegexp from "path-to-regexp";

export function resolvePathWithSpaceId(to: LocationDescriptor, spaceId: string | undefined): LocationDescriptor {
    if (isLocationDescriptorObject(to)) {
        return { ...to, pathname: resolveStringPathWithSpaceId(to.pathname!, spaceId) };
    }

    return resolveStringPathWithSpaceId(to, spaceId);
}

export function isLocationDescriptorObject(descriptor: LocationDescriptor): descriptor is LocationDescriptorObject {
    return typeof descriptor !== "string" && typeof descriptor !== "undefined" && typeof descriptor.pathname !== "undefined";
}

const FakeSpaceId = "Spaces-0";
export function resolveStringPathWithSpaceId(path: string, spaceId: string | undefined): string {
    // path contains space id parameter so it needs to be resolved even if space id is not provided
    spaceId = spaceId ? spaceId : FakeSpaceId;

    // Switch to generatePath once a new version of router is released : https://github.com/ReactTraining/react-router/issues/6024
    const link = pathToRegexp.compile(path)({ spaceId });
    return spaceId === FakeSpaceId ? link.replace(FakeSpaceId, "").replace("//", "/") : link;
}
