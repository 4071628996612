/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
const styles = require("./style.less");

const CardTitle: React.StatelessComponent<{ children: any }> = props => {
    return <h3 className={styles.cardTitle}>{props.children}</h3>;
};

CardTitle.displayName = "CardTitle";

export default CardTitle;
