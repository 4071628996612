import { DeploymentTargetResource, EventCategoryResource, EventResource, ResourceCollection } from "client/resources";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import EventListing from "components/EventListing/EventListing";
import FormPage from "components/FormPage/FormPage";
import PaperLayout from "components/PaperLayout/PaperLayout";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";

interface MachineEventsProps {
    initialData: InitialData;
}

interface MachineEventsParams {
    machineId: string;
}

const Title = "Events";
interface InitialData {
    machineId: string;
    eventsResponse: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
}

const MachineEventsFormPage = FormPage<InitialData>();

const MachineEventsLayout: React.FC<RouteComponentProps<MachineEventsParams>> = (props: RouteComponentProps<MachineEventsParams>) => {
    return (
        <MachineEventsFormPage
            title={Title}
            load={async () => {
                const machineId = props.match.params.machineId;
                const eventsResponse = await repository.Events.list({ regarding: [machineId] });
                const eventCategories = await repository.Events.categories({});
                return { machineId, eventsResponse, eventCategories };
            }}
            renderWhenLoaded={data => <MachineEventsLayoutInner initialData={data} />}
        />
    );
};

class MachineEventsLayoutInner extends DataBaseComponent<MachineEventsProps, DataBaseComponentState> {
    constructor(props: MachineEventsProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <PaperLayout title={Title} busy={this.state.busy} errors={this.state.errors}>
                {this.props.initialData.eventsResponse && <EventListing data={this.props.initialData.eventsResponse} eventCategories={this.props.initialData.eventCategories} regarding={[this.props.initialData.machineId]} />}
            </PaperLayout>
        );
    }
}

export default MachineEventsLayout;
