import * as React from "react";
import cn from "classnames";
const styles = require("./style.less");
import { DisabledChip, EarlyAccessChip } from "components/Chips";

interface CardProps {
    className?: string;
    isEarlyAccess?: boolean;
    logo: React.ReactNode;
    header: React.ReactNode;
    content: React.ReactNode;
    footerIcon?: React.ReactNode;
    footerText?: React.ReactNode;
    footerBackgroundColor?: string;
    onHoverView?: React.ReactNode;
    link?: React.ReactNode;
    isDisabled?: boolean;
    includeContentFade?: boolean;
    leftAlign?: boolean;
    contentClassName?: string;
}

const Card: React.StatelessComponent<CardProps> = (props: CardProps) => {
    let header: React.ReactNode = null;
    if (props.isDisabled) {
        header = (
            <div>
                {props.header}
                <br />
                <DisabledChip />
            </div>
        );
    } else {
        header = props.header;
    }
    return (
        <div className={cn(props.className, styles.container, props.isDisabled ? styles.disabled : null, props.leftAlign ? styles.cardLeft : null)}>
            {props.link && <div className={styles.link}>{props.link}</div>}
            <div className={styles.logo}>
                <div>{props.isEarlyAccess === true ? <EarlyAccessChip /> : null}</div>
                {props.logo}
            </div>
            <div className={!props.logo ? styles.headerNoLogo : styles.header}>{header}</div>
            <div className={cn(styles.content, props.includeContentFade ? styles.contentFade : null, props.contentClassName)}>{props.content}</div>
            {(props.footerText || props.footerIcon || props.footerBackgroundColor) && (
                <div className={styles.footer} style={{ backgroundColor: props.footerBackgroundColor }}>
                    {props.footerText}
                    <span className={styles.footerIcon}>{props.footerIcon}</span>
                </div>
            )}
            {props.onHoverView && <div className={styles.onHover}>{props.onHoverView}</div>}
        </div>
    );
};

Card.displayName = "Card";

export default Card;
