import { ActionTemplateUsageResource, ProcessType } from "client/resources";
import * as React from "react";
import { ActionButtonType } from "components/Button/ActionButton";
import ActionButton from "components/Button";
import InternalLink from "components/Navigation/InternalLink";
import SimpleDataTable from "components/SimpleDataTable";
import routeLinks from "routeLinks";
import { NoResults } from "components/NoResults/NoResults";
import { ActionTemplateUsageProcessLink, ActionTemplateUsageStepLink } from "./ActionTemplateUsageLinks";

interface ActionTemplateUsageTableProps {
    usages: ActionTemplateUsageResource[];
    templateVersion: number;
    onUpdateAction(u: ActionTemplateUsageResource): void;
    onUpdateAll(): void;
}

export const ActionTemplateUsageTable = (props: ActionTemplateUsageTableProps) => {
    if (props.usages.length === 0) {
        return <NoResults />;
    }
    const showUpdateAll = props.usages.some(u => props.templateVersion.toString() !== u.Version);

    const updateAllButton = showUpdateAll ? <ActionButton type={ActionButtonType.Ternary} onClick={e => props.onUpdateAll()} label="Update All..." /> : null;

    return (
        <SimpleDataTable
            data={props.usages}
            headerColumns={["Project", "Process", "Step", "Version", updateAllButton]}
            onRow={(u: ActionTemplateUsageResource) => [
                <InternalLink to={routeLinks.project(u.ProjectSlug).root}>{u.ProjectName}</InternalLink>,
                <ActionTemplateUsageProcessLink usage={u} />,
                <ActionTemplateUsageStepLink usage={u} />,
                u.Version,
                props.templateVersion.toString() === u.Version ? "Up to date" : <ActionButton type={ActionButtonType.Secondary} onClick={e => props.onUpdateAction(u)} label="Update..." />,
            ]}
        />
    );
};
