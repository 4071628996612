import { StaticWorkerPoolResource } from "client/resources";
import WorkerPoolEditBase, { WorkerPoolEditState } from "./WorkerPoolEditBase";
import { cloneDeep } from "lodash";

type StaticWorkerPoolEditState = WorkerPoolEditState<StaticWorkerPoolResource>;

class StaticWorkerPoolEdit extends WorkerPoolEditBase<StaticWorkerPoolResource, StaticWorkerPoolEditState> {
    getDefaultState(): StaticWorkerPoolEditState {
        return {
            deleted: false,
            model: this.props.workerPool,
            cleanModel: cloneDeep(this.props.workerPool),
        };
    }

    customExpandableFormSections(): JSX.Element[] {
        return [];
    }
}

export default StaticWorkerPoolEdit;
