/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { OctopusError } from "client/resources";
import { Errors } from "components/DataBaseComponent/Errors";

function findKey<T>(val: string, template: T) {
    if (!template) {
        return val;
    }
    const found = Object.keys(template).find(k => k.toLowerCase() === val.toLowerCase());
    return found ? found : val;
}

export default function matchErrorsToFieldNames<T>(err: OctopusError, template: T) {
    const details = err.Details
        ? Object.keys(err.Details).reduce((acc, val) => {
              (acc as any)[findKey(val, template)] = err.Details ? err.Details[val] : null;
              return acc;
          }, {})
        : {};

    return {
        ...details,
    };
}
