import * as H from "history";
import URI from "urijs";

const QUERYSTRING_NAME = "tasklineid";

function goToLog({ location, history }: { location: H.Location; history: H.History }, taskLogLineId: string) {
    const current = new URI(location.search);
    current.setSearch({
        [QUERYSTRING_NAME]: taskLogLineId,
        activeTab: "taskLog",
    });
    history.push({ ...location, search: current.search() });
}

function clearLogFromURI({ location, history }: { location: H.Location; history: H.History }) {
    const current = new URI(location.search);
    current.removeSearch(QUERYSTRING_NAME);
    history.replace({ ...location, search: current.search() });
}

export { goToLog, clearLogFromURI };
