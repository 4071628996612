import * as React from "react";
import { default as MaterialChip } from "material-ui/Chip";
import ToolTip, { ToolTipContent } from "components/ToolTip/ToolTip";
import { normal } from "../../fontWeights";
import { LocationDescriptor } from "history";
import { OctopusTheme, withTheme } from "components/Theme";
import { CSSProperties } from "@material-ui/styles";

const styles = require("./styles.less");

interface DeletableChipProps extends CommonChipProps {
    onRequestDelete: (event: object) => void;
    deleteButtonAccessibleName: string;
}

// @types/material-ui currently buggy. remove this if it ever works
interface CommonChipProps {
    className?: string;
    description?: string | ToolTipContent;
    onClick?: (event: object) => void;
    backgroundColor?: string;
    labelColor?: string;
    markAsRemoved?: boolean;
    tabIndex?: number;
    avatar?: React.ReactNode;
    bordercolor?: string; // Had to use lowercase here because it's showing warnings otherwise ><
    style?: object;
    fullWidth?: boolean;
    noMargin?: boolean;
    to?: LocationDescriptor;
}

type ChipProps = CommonChipProps | DeletableChipProps;

function isDeletableChipProps(chipProps: ChipProps): chipProps is DeletableChipProps {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (chipProps as DeletableChipProps).onRequestDelete !== undefined;
}

function buildStyle(theme: OctopusTheme, props: ChipProps): CSSProperties {
    return {
        lineHeight: "1.5rem",
        height: "1.5rem", // Specify height explicitly, or svg icons can push the container height out (eg. if you display
        // multiple chips in a row where some do/don't contain an avatar. E.g. environments list).
        margin: props.noMargin ? 0 : 4,
        display: "inline-flex",
        backgroundColor: props.backgroundColor || theme.chipBackground,
        borderWidth: props.bordercolor ? "0.0625rem" : "0",
        borderStyle: props.bordercolor ? "solid" : "none",
        borderColor: props.bordercolor ? props.bordercolor : "transparent",
        cursor: "inherit",
        verticalAlign: "middle",
    };
}

function buildLabelStyle(theme: OctopusTheme, props: ChipProps): CSSProperties {
    const { markAsRemoved, fullWidth, labelColor } = props;

    return {
        lineHeight: "1.5rem",
        fontSize: "0.75rem",
        color: labelColor || theme.chipText,
        fontWeight: normal,
        maxWidth: fullWidth ? "none" : "12.5rem",
        textDecoration: markAsRemoved ? "line-through" : "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    };
}

function buildDeleteIconStyle(theme: OctopusTheme, props: ChipProps): CSSProperties {
    return {
        margin: "0.0625rem 0.0625rem 0 0",
        width: "1.375rem",
        height: "1.375rem",
        fill: theme.iconNeutral,
    };
}

function pluckUnwantedPropsBeforeSpreading(props: React.PropsWithChildren<CommonChipProps | DeletableChipProps>) {
    if (isDeletableChipProps(props)) {
        const { deleteButtonAccessibleName, ...otherProps } = props;
        return removeUnwantedCommonProps(otherProps);
    }
    return removeUnwantedCommonProps(props);
}

function removeUnwantedCommonProps(props: React.PropsWithChildren<CommonChipProps>) {
    const { markAsRemoved, avatar, fullWidth, noMargin, ...otherProps } = props;
    return otherProps;
}

const Chip: React.FC<ChipProps> = props => {
    // We need to remove deleteButtonAccessibleName and a few other props
    // from the props spread into MaterialChip as they are not valid DOM properties.
    const propsToSpread = pluckUnwantedPropsBeforeSpreading(props);

    const getDeleteButton = () => {
        if (isDeletableChipProps(props)) {
            return <button tabIndex={-1} aria-label={props.deleteButtonAccessibleName} className={styles.visuallyHidden} onClick={props.onRequestDelete}>{`Delete ${props.description}`}</button>;
        }
    };

    return withTheme((theme: OctopusTheme) => (
        <MaterialChip
            style={buildStyle(theme, props)}
            labelStyle={buildLabelStyle(theme, props)}
            deleteIconStyle={buildDeleteIconStyle(theme, props)}
            className={styles.chip}
            onRequestDelete={isDeletableChipProps(props) ? props.onRequestDelete : undefined}
            tabIndex={-1}
            {...propsToSpread}
        >
            {props.avatar}
            <ToolTip content={<span>{props.description || props.children}</span>}>{props.children}</ToolTip>
            {getDeleteButton()}
        </MaterialChip>
    ));
};

export default Chip;
export { ChipProps };
