import BasicRepository from "./basicRepository";
import Client from "../client";
import { AccountResource, AccountUsageResource, AzureEnvironment, AccountType, AzureWebSite, AzureWebSiteSlot } from "../resources";

type AccountRepositoryListArgs = {
    skip?: number;
    take?: number;
    orderBy?: string;
    accountType?: AccountType[];
};

class AccountRepository extends BasicRepository<AccountResource, AccountResource, AccountRepositoryListArgs> {
    constructor(client: Client) {
        super("Accounts", client);
    }
    getWebSites(account: AccountResource): Promise<AzureWebSite[]> {
        return this.client.get(account.Links["WebSites"]);
    }
    getWebSiteSlots(account: AccountResource, resourceGroupName: string, webSiteName: string): Promise<AzureWebSiteSlot[]> {
        const args: { resourceGroupName: string; webSiteName: string } = { resourceGroupName, webSiteName };
        return this.client.get(account.Links["WebSiteSlots"], args);
    }
    getStorageAccounts(account: AccountResource) {
        return this.client.get(account.Links["StorageAccounts"]);
    }
    getResourceGroups(account: AccountResource) {
        return this.client.get(account.Links["ResourceGroups"]);
    }
    getFabricApplications(account: AccountResource) {
        return this.client.get(account.Links["FabricApplications"]);
    }
    getAccountUsages(account: AccountResource): Promise<AccountUsageResource> {
        return this.client.get(account.Links["Usages"]);
    }
    getIsolatedAzureEnvironments(): Promise<AzureEnvironment[]> {
        return this.client.get(this.client.getLink("AzureEnvironments"));
    }
}

export default AccountRepository;
