/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { RouteComponentProps } from "react-router";
import LibraryLayout from "../../LibraryLayout/LibraryLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout";
import { ExpandableFormSection, Summary, SummaryNode, FormSectionHeading, Text, required, MarkdownEditor, Sensitive, SensitiveFileUpload, Note } from "components/form";
import { Callout, CalloutType } from "components/Callout/Callout";
import { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { EnvironmentMultiSelect } from "components/MultiSelect";
import { CertificateExpiryChip, environmentChipList } from "components/Chips";
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import { CertificateResource, TenantedDeploymentMode, EnvironmentResource, TenantResource, CertificateDataFormat } from "client/resources";
import { repository } from "clientInstance";
import * as _ from "lodash";
import CertificateDetail from "./CertificateDetail";
import CertificateUsage from "./CertificateUsage";
import { certificateUsageSummary, CertificateUsageEntry } from "./certificateUsageSummary";
import ReplaceCertificate from "./ReplaceCertificate";
import { ArchiveAction, default as ArchiveCertificate } from "./ArchiveCertificate";
import TenantedDeploymentParticipationSelector from "components/TenantedDeploymentParticipationSelector";
import ThumbprintText from "components/ThumbprintText";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import Markdown from "components/Markdown/index";
import { AdvancedTenantsAndTenantTagsSelector } from "components/AdvancedTenantSelector";
import DownloadCertificate from "areas/library/components/Certificates/Certificate/DownloadCertificate";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../../routeLinks";
import InternalRedirect from "../../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { UrlNavigationTabsContainer, TabItem } from "components/Tabs";
import RadioButtonGroup from "../../../../../components/form/RadioButton/RadioButtonGroup";
import RadioButton from "../../../../../components/form/RadioButton";
import { withTheme } from "components/Theme";
const styles = require("./style.less");

interface CertificateProps extends RouteComponentProps<CertificateRouteParams> {
    create?: boolean;
}

interface CertificateRouteParams {
    certificateId: string;
}

interface CertificateState extends OptionalFormBaseComponentState<CertificateResource> {
    deleted: boolean;
    newId: string;
    allEnvironments: EnvironmentResource[];
    allTenants: TenantResource[];
    certificateUsages: CertificateUsageEntry[];
    replacedByCertificate: CertificateResource;
    uploadFormat: UploadFormatOptions;
}

type UploadFormatOptions = "File" | "Text";

class Certificate extends FormBaseComponent<CertificateProps, CertificateState, CertificateResource> {
    constructor(props: CertificateProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null!,
            allEnvironments: [],
            allTenants: [],
            certificateUsages: null!,
            replacedByCertificate: null!,
            uploadFormat: "File",
        };
    }

    async componentDidMount() {
        let certificate: CertificateResource = null!;
        let allEnvironments: EnvironmentResource[] = null!;
        let allTenants: TenantResource[] = null!;
        let replacedByCertificate: CertificateResource = null!;

        if (this.props.create) {
            certificate = {
                Id: null!,
                Name: "",
                Notes: "",
                CertificateData: { NewValue: null!, HasValue: false },
                Password: { NewValue: null!, HasValue: false },
                EnvironmentIds: [],
                TenantIds: [],
                TenantTags: [],
                TenantedDeploymentParticipation: TenantedDeploymentMode.Untenanted,
                Links: null!,
            };
        } else {
            await this.doBusyTask(async () => {
                certificate = await repository.Certificates.get(this.props.match.params.certificateId);

                if (certificate.ReplacedBy) {
                    replacedByCertificate = await repository.Certificates.get(certificate.ReplacedBy);
                }
            });
        }

        await this.doBusyTask(async () => {
            [allEnvironments, allTenants] = await Promise.all<EnvironmentResource[], TenantResource[]>([repository.Environments.all(), repository.Tenants.all()]);
        });

        this.setState({
            model: certificate,
            cleanModel: _.cloneDeep(certificate),
            allEnvironments,
            allTenants,
            replacedByCertificate,
        });
    }

    render() {
        const title = this.props.create ? "New Certificate" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions = [];
        if (this.state.model && !this.props.create) {
            const download = <DownloadCertificate certificate={this.state.model} />;
            overFlowActions.push(OverflowMenuItems.dialogItem("Download", download));

            const replace = <ReplaceCertificate certificate={this.state.model} afterCertificateReplace={c => this.handleCertificateReplaced(c)} />;
            overFlowActions.push(OverflowMenuItems.dialogItem("Replace", replace, { permission: Permission.CertificateEdit, wildcard: true }));

            if (this.state.model.Archived) {
                overFlowActions.push(OverflowMenuItems.deleteItemDefault("certificate", this.handleDeleteConfirm, { permission: Permission.CertificateDelete, wildcard: true }));
                if (!this.state.model.ReplacedBy) {
                    const unarchive = <ArchiveCertificate certificate={this.state.model} action={ArchiveAction.Unachive} afterAction={() => this.handleArchive()} />;
                    overFlowActions.push(OverflowMenuItems.dialogItem("Unarchive", unarchive, { permission: Permission.CertificateEdit, wildcard: true }));
                }
            } else {
                const archive = <ArchiveCertificate certificate={this.state.model} action={ArchiveAction.Archive} afterAction={() => this.handleArchive()} />;
                overFlowActions.push(OverflowMenuItems.dialogItem("Archive", archive, { permission: Permission.CertificateEdit, wildcard: true }));
            }

            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null!, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        const saveText: string = this.state.newId ? "Certificate created" : "Certificate details updated";

        return (
            <LibraryLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Certificates"}
                    breadcrumbPath={routeLinks.library.certificates.root}
                    busy={this.state.busy}
                    errors={this.state.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: this.props.create ? Permission.CertificateCreate : Permission.CertificateEdit, wildcard: true }}
                    onSaveClick={() => this.handleSaveClick()}
                    saveText={saveText}
                    expandAllOnMount={this.props.create}
                    overFlowActions={overFlowActions}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.library.certificates.root} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.library.certificate(this.state.newId)} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <UrlNavigationTabsContainer defaultValue="details">
                                <TabItem label="Details" value="details">
                                    {this.state.replacedByCertificate && (
                                        <Callout title="Replaced" type={CalloutType.Information}>
                                            This certificate was replaced by certificate with thumbprint <ThumbprintText thumbprint={this.state.replacedByCertificate.Thumbprint!} />
                                        </Callout>
                                    )}
                                    {this.state.model.Archived && (
                                        <Callout title="Archived" type={CalloutType.Information}>
                                            This certificate was archived on {this.state.model.Archived}
                                        </Callout>
                                    )}
                                    {this.state.model.CertificateDataFormat === CertificateDataFormat.Unknown && (
                                        <Callout title="Invalid Certificate" type={CalloutType.Warning}>
                                            This certificate was unable to be parsed and may be in an invalid format. This certificate will not be able to be used in Octopus deployments and you may need to upload a new certificate which can be
                                            correctly loaded.
                                        </Callout>
                                    )}
                                    <ExpandableFormSection
                                        errorKey="Name"
                                        title="Name"
                                        focusOnExpandAll
                                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your certificate")}
                                        help="A short, memorable, unique name for this certificate."
                                    >
                                        <Text value={this.state.model.Name} onChange={Name => this.setModelState({ Name })} label="Name" error={this.getFieldError("Name")} validate={required("Please enter a certificate name")} autoFocus={true} />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="Notes" title="Notes" summary={this.notesSummary()} help="This summary will be presented to users when selecting the certificate for inclusion in a variable.">
                                        <MarkdownEditor value={this.state.model.Notes} label="Notes" onChange={Notes => this.setModelState({ Notes })} />
                                    </ExpandableFormSection>
                                    {!this.props.create && this.state.model.CertificateDataFormat !== CertificateDataFormat.Unknown && (
                                        <div>
                                            <ExpandableFormSection errorKey="Details" title="Details" summary={this.detailsSummary()} help="Certificate details.">
                                                <CertificateDetail certificate={this.state.model} />
                                                {this.state.model.CertificateChain!.length > 0 && (
                                                    <div>
                                                        <h4>Certificate Chain</h4>
                                                        {this.state.model.CertificateChain!.map((cert, index) => (
                                                            <div key={index}>
                                                                <CertificateDetail certificate={cert} />
                                                                <br />
                                                                <br />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ExpandableFormSection>
                                        </div>
                                    )}
                                    {this.props.create && this.state.model.CertificateDataFormat !== CertificateDataFormat.Unknown && (
                                        <div>
                                            <FormSectionHeading title="Certificate" />
                                            <ExpandableFormSection
                                                errorKey="CertificateData"
                                                title="Certificate Data"
                                                summary={Summary.summary("Supported formats: PFX (PKCS #12), DER, PEM")}
                                                help="Select the option which you wish to use to supply the Certificate Data"
                                            >
                                                <RadioButtonGroup value={this.state.uploadFormat} onChange={(uploadFormat: UploadFormatOptions) => this.setState({ uploadFormat }, () => this.setModelState({ CertificateData: { HasValue: true } }))}>
                                                    <RadioButton value={"File"} label="Upload a File" />
                                                    {this.state.uploadFormat === "File" && (
                                                        <SensitiveFileUpload
                                                            label="Certificate File"
                                                            value={this.state.model.CertificateData}
                                                            onChange={CertificateData => this.setModelState({ CertificateData })}
                                                            error={this.getFieldError("CertificateData")}
                                                        />
                                                    )}

                                                    <RadioButton value={"Text"} label="Paste Text" />
                                                    {this.state.uploadFormat === "Text" && (
                                                        <div>
                                                            <Note>Paste your certificate as text, either base64 encoded or in PEM format</Note>
                                                            <Text
                                                                multiline={true}
                                                                value={this.state.model.CertificateData.NewValue!}
                                                                onChange={CertificateData => this.setModelState({ CertificateData: { NewValue: CertificateData, HasValue: true } })}
                                                            />
                                                        </div>
                                                    )}
                                                </RadioButtonGroup>
                                            </ExpandableFormSection>
                                            <ExpandableFormSection errorKey="Password" title="Password" summary={Summary.summary("The password protecting the file (if required).")} help="Password">
                                                <Sensitive value={this.state.model.Password} onChange={Password => this.setModelState({ Password })} label="Password" error={this.getFieldError("Password")} />
                                            </ExpandableFormSection>
                                        </div>
                                    )}
                                    <FormSectionHeading title="Restrictions" />
                                    <ExpandableFormSection errorKey="Environments" title="Environments" summary={this.environmentsSummary()} help="Choose the environments that are allowed to use this certificate.">
                                        <Note>If this field is left blank, the certificate can be used for deployments to any environment. Specifying environment/s (especially for production certificates) is strongly recommended.</Note>
                                        <EnvironmentMultiSelect items={this.state.allEnvironments} onChange={EnvironmentIds => this.setModelState({ EnvironmentIds })} value={this.state.model.EnvironmentIds} />
                                    </ExpandableFormSection>
                                    <FeatureToggle feature={Feature.MultiTenancy}>
                                        <PermissionCheck permission={Permission.TenantView} tenant="*">
                                            <ExpandableFormSection
                                                errorKey="TenantedDeploymentParticipation"
                                                title="Tenanted Deployments"
                                                summary={this.tenantDeploymentModeSummary()}
                                                help={"Choose the kind of deployments where this certificate should be included."}
                                            >
                                                <TenantedDeploymentParticipationSelector
                                                    tenantMode={this.state.model.TenantedDeploymentParticipation!}
                                                    resourceTypeLabel="certificate"
                                                    onChange={x => this.setModelState({ TenantedDeploymentParticipation: x as TenantedDeploymentMode })}
                                                />
                                            </ExpandableFormSection>
                                            {this.state.model.TenantedDeploymentParticipation !== TenantedDeploymentMode.Untenanted && (
                                                <ExpandableFormSection errorKey="Tenants" title="Associated Tenants" summary={this.tenantSummary()} help={"Choose tenants this certificate should be associated with."}>
                                                    <AdvancedTenantsAndTenantTagsSelector
                                                        tenants={this.state.allTenants}
                                                        selectedTenantIds={this.state.model.TenantIds}
                                                        selectedTenantTags={this.state.model.TenantTags}
                                                        doBusyTask={this.doBusyTask}
                                                        onChange={(TenantIds, TenantTags) => this.setModelState({ TenantIds, TenantTags })}
                                                        showPreviewButton={true}
                                                    />
                                                </ExpandableFormSection>
                                            )}
                                        </PermissionCheck>
                                    </FeatureToggle>
                                </TabItem>
                                {!this.props.create && (
                                    <TabItem label="Usage" value="usage" onActive={() => this.onUsageTabActive()}>
                                        <ExpandableFormSection errorKey="Usage" title="Usage" summary={this.usageSummary()} help="This certificate can be referenced by variables">
                                            <CertificateUsage certificateUsage={this.state.certificateUsages} />
                                        </ExpandableFormSection>
                                    </TabItem>
                                )}
                            </UrlNavigationTabsContainer>
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </LibraryLayout>
        );
    }

    private async onUsageTabActive() {
        if (this.state.certificateUsages || this.props.create) {
            return;
        }
        await this.doBusyTask(async () => {
            const certificateUsageData = await repository.CertificateConfiguration.usage(this.state.model!);
            const certificateUsages = certificateUsageSummary(certificateUsageData);
            this.setState({
                certificateUsages,
            });
        });
    }

    private notesSummary() {
        return this.state.model!.Notes ? Summary.summary(<Markdown markup={this.state.model!.Notes} />) : Summary.placeholder("Notes not provided");
    }

    private detailsSummary(): SummaryNode {
        return this.state.model
            ? Summary.summary(
                  withTheme(theme => (
                      <div className={styles.row}>
                          <div className={styles.propertyContainer}>
                              <span>
                                  <ThirdPartyIcon iconType={ThirdPartyIconType.InfoOutline} color={theme.primaryText} />
                              </span>
                              <span>{this.state.model!.SubjectCommonName || this.state.model!.SubjectOrganization || this.state.model!.SubjectDistinguishedName}</span>
                          </div>
                          <div className={styles.propertyContainer}>
                              <span>
                                  <ThirdPartyIcon iconType={ThirdPartyIconType.AccountBox} color={theme.primaryText} />
                              </span>
                              <span>{this.state.model!.SelfSigned ? "Self-Signed" : this.state.model!.IssuerCommonName || this.state.model!.IssuerOrganization || this.state.model!.IssuerDistinguishedName}</span>
                          </div>
                          <div className={styles.propertyContainer}>
                              <CertificateExpiryChip certificate={this.state.model!} />
                          </div>
                      </div>
                  ))
              )
            : Summary.placeholder("Certificate details");
    }

    private usageSummary(): SummaryNode {
        return this.state.certificateUsages && this.state.certificateUsages.length > 0
            ? this.state.certificateUsages.length > 1
                ? Summary.summary(
                      <span>
                          This certificate is used in <b>{this.state.certificateUsages.length}</b> places
                      </span>
                  )
                : Summary.summary(
                      <span>
                          This certificate is used in <b>one</b> place
                      </span>
                  )
            : Summary.placeholder("This certificate is not used anywhere");
    }

    private environmentsSummary(): SummaryNode {
        return this.state.model!.EnvironmentIds && this.state.model!.EnvironmentIds.length
            ? Summary.summary(<span>Only available for deployments to {environmentChipList(this.state.allEnvironments, this.state.model!.EnvironmentIds)}</span>)
            : Summary.default("Available for deployments to any environment");
    }

    private tenantDeploymentModeSummary() {
        return CommonSummaryHelper.tenantDeploymentModeSummary(this.state.model!.TenantedDeploymentParticipation!, this.state.model!.TenantIds, this.state.model!.TenantTags);
    }

    private tenantSummary() {
        return CommonSummaryHelper.tenantSummary(this.state.model!.TenantIds, this.state.model!.TenantTags, this.state.allTenants);
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = !!this.state.model?.Id;
            const certificate = await repository.Certificates.save(this.state.model!);
            this.setState({
                model: certificate,
                cleanModel: _.cloneDeep(certificate),
                newId: isNew ? certificate.Id : null!,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.Certificates.del(this.state.model!);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null,
                deleted: true,
            };
        });
        return true;
    };

    private handleCertificateReplaced(replacedCertificate: CertificateResource) {
        const cert = this.state.model;
        this.setState({
            model: replacedCertificate!,
            cleanModel: _.cloneDeep(replacedCertificate),
            newId: replacedCertificate.Id,
            replacedByCertificate: cert!,
        });
    }

    private handleArchive = async () => {
        await this.doBusyTask(async () => {
            const certificate = await repository.Certificates.get(this.props.match.params.certificateId);
            let replacedByCertificate: CertificateResource | null = null;
            if (certificate.ReplacedBy) {
                replacedByCertificate = await repository.Certificates.get(certificate.ReplacedBy);
            }
            this.setState(() => ({
                model: certificate!,
                cleanModel: _.cloneDeep(certificate),
                certificateUsages: null,
                replacedByCertificate,
            }));
        });
    };
}

export default Certificate;
