/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { DataTable, DataTableHeader, DataTableHeaderColumn, DataTableBody, DataTableToolsRow, DataTableRow, DataTableRowColumn, DataTableFooter, DataTableFooterColumn } from "../DataTable";
import PagingBaseComponent from "components/PagingBaseComponent";
import { PagingBaseProps, PagingBaseState, HasId } from "components/PagingBaseComponent";
import { isEqual } from "lodash";
const styles = require("./style.less");
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import cn from "classnames";
import { getNavigationUrl } from "../PagingBaseComponent/PagingBaseComponent";
import Checkbox from "../form/Checkbox/Checkbox";
import { intersection } from "lodash";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { NoResults } from "components/NoResults/NoResults";

interface PagingDataTableProps<R extends HasId> extends PagingBaseProps<R> {
    onToolsSection?: any;
    headerColumns: any[];
    headerColumnClassNames?: string[];
    rowColumnClassName?: string;
    rowClassName?: string;
    headerRowClassName?: string;
    onEmpty?: any;
    empty?: any;
    hideHeader?: boolean;
    onItemsChecked?: (ids: string[]) => void;
}

interface PagingDataTableState<R extends HasId> extends PagingBaseState<R> {
    checkedItems: { [key: string]: boolean };
}

export abstract class PagingDataTable<R extends HasId> extends PagingBaseComponent<R, PagingDataTableProps<R>, PagingDataTableState<R>> {
    private empty: React.ReactNode = (<NoResults />);

    constructor(props: PagingDataTableProps<R>) {
        super(props);
        this.state = { ...this.state, checkedItems: {} };
        this.empty = props.empty || this.empty;
    }

    componentWillUpdate(newProps: PagingDataTableProps<R>, newState: PagingDataTableState<R>) {
        this.reEvaluateCheckedItems(newState);
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }
        if (!this.state.data) {
            return <BusyIndicator show={true} />;
        }

        const { onToolsSection, headerColumns, headerColumnClassNames, rowColumnClassName, onRow, onEmpty } = this.props;

        const data = this.state.data;
        const thereIsData = data && data.Items && data.Items.length > 0;
        const items = thereIsData ? data.Items.filter((item: any) => this.onFilter(this.state.filter, item)) : null;
        const allChecked = thereIsData && items!.length > 0 && this.props.onItemsChecked ? Object.keys(this.state.checkedItems).length === items!.length : false;
        const fullColLength = this.props.onItemsChecked ? headerColumns.length + 1 : headerColumns.length;
        return (
            <div className={styles.tableContainer}>
                <DataTable>
                    {!this.props.hideHeader && (
                        <DataTableHeader>
                            {(this.props.filterSearchEnabled || onToolsSection) && (
                                <DataTableToolsRow>
                                    <DataTableHeaderColumn colSpan={fullColLength}>
                                        {this.renderFilterSearchComponents()}
                                        {onToolsSection && onToolsSection()}
                                    </DataTableHeaderColumn>
                                </DataTableToolsRow>
                            )}
                            {thereIsData ? this.buildTableHeader(headerColumns, headerColumnClassNames!, allChecked) : null}
                        </DataTableHeader>
                    )}
                    <DataTableBody>
                        {thereIsData ? (
                            items!.map((item: any, index: number) => this.buildTableRow(item, index, onRow(item), rowColumnClassName!))
                        ) : (
                            <DataTableRow displayNoBorder={true} className={this.props.rowClassName}>
                                <DataTableRowColumn>{onEmpty ? onEmpty() : null}</DataTableRowColumn>
                            </DataTableRow>
                        )}
                    </DataTableBody>
                    {thereIsData && data.Items.length < this.state.data.TotalResults ? (
                        <DataTableFooter>
                            <DataTableToolsRow>
                                <DataTableFooterColumn colSpan={fullColLength}>{this.props.showPagingInNumberedStyle ? this.showPagingInNumberedStyle() : this.showPagingInLoadMoreStyle()}</DataTableFooterColumn>
                            </DataTableToolsRow>
                        </DataTableFooter>
                    ) : null}
                </DataTable>
            </div>
        );
    }

    private buildTableHeader(headerColumns: any[], columnClassNames: string[], allChecked: boolean) {
        return (
            <DataTableRow className={this.props.headerRowClassName}>
                {this.props.onItemsChecked && (
                    <DataTableHeaderColumn>
                        <Checkbox noMargin={true} value={allChecked} onChange={state => this.toggleAllChecked(state)} />
                    </DataTableHeaderColumn>
                )}
                {headerColumns.map((col, index) => {
                    const props: any = {
                        key: index,
                    };

                    if (columnClassNames) {
                        const className = columnClassNames[index];
                        props["className"] = className;
                    }

                    return <DataTableHeaderColumn {...props}>{col}</DataTableHeaderColumn>;
                })}
            </DataTableRow>
        );
    }

    private buildTableRow(item: R, index: number, rowColumns: any, className: string) {
        // Only some tables include a redirect, so only show cursor if necessary.
        const redirectUrl = getNavigationUrl(this.props as any, item);
        const linkClassName = redirectUrl ? styles.linkableItem : null;
        return (
            <DataTableRow key={index} onClick={(e: any) => this.navigate(item)} className={this.props.rowClassName}>
                {this.props.onItemsChecked && (
                    <DataTableRowColumn>
                        <Checkbox value={!!this.state.checkedItems[item.Id]} noMargin={true} onChange={val => this.itemChecked(item, val)} />
                    </DataTableRowColumn>
                )}
                {rowColumns.map((col: any, idx: number) => {
                    const props: any = {
                        key: idx,
                    };
                    if (className) {
                        props["className"] = cn(className, props["className"]);
                    }
                    if (linkClassName) {
                        props["className"] = cn(linkClassName, props["className"]);
                    }
                    return <DataTableRowColumn {...props}>{col}</DataTableRowColumn>;
                })}
            </DataTableRow>
        );
    }

    private toggleAllChecked = (state: boolean) => {
        let checkedItems = {};
        if (state) {
            checkedItems = this.state
                .data!.Items.filter((item: any) => this.onFilter(this.state.filter, item))
                .reduce((idx: { [key: string]: boolean }, item: R) => {
                    idx[item.Id] = true;
                    return idx;
                }, {});
        }
        this.setState({ checkedItems }, () => this.props.onItemsChecked!(Object.keys(checkedItems)));
    };

    private itemChecked = (item: R, state: boolean) => {
        const checkedItems = { ...this.state.checkedItems };
        if (state) {
            checkedItems[item.Id] = true;
        } else {
            delete checkedItems[item.Id];
        }
        this.setState({ checkedItems }, () => {
            this.props.onItemsChecked!(Object.keys(this.state.checkedItems));
        });
    };

    // When the list is filtered or changed we need to re-evaluate which ones are selected.
    private reEvaluateCheckedItems(newState: PagingDataTableState<R>) {
        if (!this.props.onItemsChecked || !newState.data) {
            return; // Selection isn't possible
        }

        if (this.state.data === newState.data && this.state.filter === newState.filter) {
            return; // Nothing has changed that should affect the selected items
        }

        const currentChecked = Object.keys(this.state.checkedItems);

        if (currentChecked.length === 0) {
            return; // Nothing selected already so no need to check what else has loaded
        }

        const validItems = newState.data.Items.filter((item: any) => this.onFilter(this.state.filter, item)).map(item => item.Id);

        const newChecked = intersection(currentChecked, validItems).reduce((idx: { [key: string]: boolean }, id: string) => {
            idx[id] = true;
            return idx;
        }, {});

        // Only trigger change if there is a change in items
        if (!isEqual(currentChecked, Object.keys(newChecked))) {
            this.setState({ checkedItems: newChecked }, () => this.props.onItemsChecked!(Object.keys(this.state.checkedItems)));
        }
    }
}

(PagingDataTable as any).defaultProps = {
    showFilterWithinSection: false,
    autoFocusOnFilterSearch: true,
};
