/* eslint-disable no-eq-null */

import * as React from "react";

interface ExternalLinkProps extends React.HTMLProps<HTMLElement> {
    href: string;
    showIcon?: boolean;
    openInSelf?: boolean;
    onClick?: () => void;
    className?: string;
}

const ExternalLink: React.SFC<ExternalLinkProps> = (props: ExternalLinkProps) => {
    if (props.href == null) {
        return null;
    }

    const onClick = (e: React.MouseEvent<HTMLAnchorElement, {}>) => {
        if (props.onClick) {
            props.onClick();
        }
        e.stopPropagation();
    };

    const linkProps = props.openInSelf ? { target: "_self" } : { target: "_blank", rel: "noopener noreferrer" };

    return (
        <a className={props.className} href={formatUrl(props.href)} {...linkProps} onClick={onClick}>
            {props.children}
            {props.showIcon && <em style={{ marginLeft: "0.250rem" }} className="fa fa-external-link" aria-hidden="true" />}
        </a>
    );
};

ExternalLink.defaultProps = {
    showIcon: true,
    openInSelf: false,
};

export function formatUrl(href: string) {
    if (parseUrl(href)) {
        return href;
    }
    return "https://g.octopushq.com/" + href;

    function parseUrl(url: string): boolean {
        try {
            // We need these .startsWith checks because IE11 will assume URL("someString") is relative to the current
            // domain and won't throw (like every other browser in the universe).
            if (url.startsWith("http://") || url.startsWith("https://")) {
                const ignored = new URL(url);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }
}

export default ExternalLink;
