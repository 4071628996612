import * as React from "react";
import BaseComponent from "components/BaseComponent";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { LifecycleResource, PhaseResource } from "client/resources";
import { EnvironmentResource } from "client/resources/environmentResource";
import LifecycleMap from "areas/library/components/Lifecycle/LifecycleMap/LifecycleMap";
import SelectLifecycle from "areas/projects/components/Process/Common/SelectLifecycle";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { Permission } from "client/resources";
import PermissionCheck, { PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import Callout, { CalloutType } from "components/Callout/Callout";
const styles = require("./style.less");
import routeLinks from "routeLinks";
import Note from "components/form/Note/Note";
import { ScriptModulesWithActions } from "../../ScriptModules";
import { ProcessState } from "../types";
import Logger from "client/logger";

interface SideBarProps {
    process: ProcessState;
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource | null;
    environmentsById: { [id: string]: EnvironmentResource };
    onDataChanged: () => Promise<void>;
}

interface SideBarState {
    largeLifecyleToggle?: boolean;
}

export interface ScriptModule {
    Id: string;
    Name: string;
}

export default class SideBar extends BaseComponent<SideBarProps, SideBarState> {
    private lifecycleThreshold = 20;

    constructor(props: SideBarProps) {
        super(props);
        this.state = {
            largeLifecyleToggle: true,
        };
    }

    render() {
        if (!this.props.process) {
            Logger.error("Expecting process to exist.");
            return null;
        }

        const largeLifecycle =
            this.props.lifecyclePreview &&
            this.props.lifecyclePreview.Phases &&
            (this.props.lifecyclePreview.Phases.length > this.lifecycleThreshold || this.props.lifecyclePreview.Phases.some((p: PhaseResource) => p.OptionalDeploymentTargets.length > this.lifecycleThreshold));

        return (
            <div>
                <PermissionCheck
                    permission={Permission.LifecycleView}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LifecycleView} permission is required to view the Lifecycle
                        </Callout>
                    }
                >
                    {this.props.lifecyclePreview && (
                        <div>
                            <h4>Lifecycle</h4>
                            <div className={styles.row}>
                                <InternalLink to={routeLinks.library.lifecycle(this.props.lifecyclePreview.Id)}>
                                    <span className={styles.lifeCycleName}>{this.props.lifecyclePreview.Name}</span>
                                </InternalLink>
                                <OpenDialogButton
                                    label={"CHANGE"}
                                    permission={{
                                        permission: Permission.ProjectEdit,
                                        project: this.props.process.ProjectId,
                                        wildcard: true,
                                    }}
                                >
                                    <SelectLifecycle projectId={this.props.process.ProjectId} saveDone={() => this.props.onDataChanged()} />
                                </OpenDialogButton>
                            </div>

                            {largeLifecycle && this.props.lifecyclePreview && this.props.lifecyclePreview.Phases && <Note>This lifecycle has many phases / environments</Note>}

                            {largeLifecycle && (
                                <ActionButton onClick={() => this.setState({ largeLifecyleToggle: !this.state.largeLifecyleToggle })} label={this.state.largeLifecyleToggle ? "SHOW LIFECYCLE" : "HIDE LIFECYCLE"} type={ActionButtonType.Ternary} />
                            )}
                            {(!largeLifecycle || !this.state.largeLifecyleToggle) && <LifecycleMap environmentsById={this.props.environmentsById} lifecyclePreview={this.props.lifecyclePreview} />}
                        </div>
                    )}
                    <Note>
                        Lifecycles can be defined in the <InternalLink to={routeLinks.library.lifecycles}>Library</InternalLink>
                    </Note>
                </PermissionCheck>
                <hr />
                <PermissionCheck
                    permission={Permission.LibraryVariableSetView}
                    wildcard={true}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LibraryVariableSetView} permission is required to view any included script modules
                        </Callout>
                    }
                >
                    {this.props.includedScriptModules && (
                        <div>
                            <ScriptModulesWithActions projectId={this.props.process.ProjectId} includedScriptModules={this.props.includedScriptModules} onDataChanged={this.props.onDataChanged} />
                        </div>
                    )}
                </PermissionCheck>
            </div>
        );
    }
}
