import ParseHelper from "utils/ParseHelper";
import StringHelper from "utils/StringHelper";
import { TimeSpan, TimeSpanString } from "client/resources";

export default class TimeSpanHelper {
    // Gets a js timespan object from a .NET formatted timespan from our API.
    public static getTimeSpanFromText(timeSpanText: TimeSpanString | null | undefined): TimeSpan | null {
        if (!timeSpanText) {
            return null;
        }

        const timeSpanParts = timeSpanText.split(":");
        let days = 0;
        let hours = 0;
        let mins = 0;
        let seconds = 0;
        const tsPart1 = timeSpanParts[0];
        if (tsPart1.indexOf(".") !== -1) {
            // days included
            const daysAndHours = tsPart1.split(".");
            days = ParseHelper.safeParseInt(daysAndHours[0]);
            hours = ParseHelper.safeParseInt(daysAndHours[1]);
            mins = ParseHelper.safeParseInt(timeSpanParts[1]);
            seconds = ParseHelper.safeParseInt(timeSpanParts[2]);
        } else {
            // no days
            hours = ParseHelper.safeParseInt(timeSpanParts[0]);
            mins = ParseHelper.safeParseInt(timeSpanParts[1]);
            seconds = ParseHelper.safeParseInt(timeSpanParts[2]);
        }
        const timeSpan: TimeSpan = {
            Days: days,
            Hours: hours,
            Minutes: mins,
            Seconds: seconds,
        };
        return timeSpan;
    }

    // Returns a .NET formatted timespan that we can pass to our API (play carefully with this).
    public static getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan: TimeSpan | null | undefined): string {
        if (!timeSpan) {
            return "";
        }
        return timeSpan.Days + "." + this.pad(timeSpan.Hours) + ":" + this.pad(timeSpan.Minutes) + ":" + this.pad(timeSpan.Seconds);
    }

    // Returns a human-readable string for displaying in the UI.
    public static timeSpanTextToHumanReadableString(timeSpanText: string): string {
        const timeSpan = this.getTimeSpanFromText(timeSpanText);
        if (!timeSpan) {
            return "No value";
        }

        const parts = [this.valueWithNounWhenNonZero(timeSpan.Days, "day"), this.valueWithNounWhenNonZero(timeSpan.Hours, "hour"), this.valueWithNounWhenNonZero(timeSpan.Minutes, "minute"), this.valueWithNounWhenNonZero(timeSpan.Seconds, "second")];

        const partsWithValues = parts.filter(p => !StringHelper.isNullOrWhiteSpace(p));

        return partsWithValues.length > 0 ? partsWithValues.join(", ") : "Zero";
    }

    public static valueWithNounWhenNonZero(value: number, singular: string): string {
        if (value === 0) {
            return "";
        }
        return value + " " + (value === 1 ? singular : singular + "s");
    }

    public static timeSpanTextValuesAreEqual(timeSpanTextA: string, timeSpanTextB: string) {
        const valueA = TimeSpanHelper.getTimeSpanFromText(timeSpanTextA);
        const valueB = TimeSpanHelper.getTimeSpanFromText(timeSpanTextB);

        return valueA?.Days === valueB?.Days && valueA?.Hours === valueB?.Hours && valueA?.Minutes === valueB?.Minutes && valueA?.Seconds === valueB?.Seconds;
    }

    private static pad(num: number): string {
        return ("00" + num).substr(-2);
    }
}
