/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { TenantMultiSelect, TenantTagMultiSelect } from "components/MultiSelect";
import { repository } from "clientInstance";
import { TenantResource, TagSetResource } from "client/resources";
import * as tenantTagsets from "components/tenantTagsets";
import Checkbox from "components/form/Checkbox/Checkbox";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import TenantTagsList from "components/TenantTagsList/TenantTagsList";
import ToolTip from "components/ToolTip/index";
import TagsList from "components/TagsList/TagsList";
import { TagIndex } from "components/tenantTagsets";
const styles = require("./style.less");
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { withTheme } from "components/Theme";
import { Text } from "components/form";

interface TenantTagDesignDialogLayoutProps {
    hideTenantsByName?: boolean;
    selectedTags?: string[];
    selectedTenants?: string[];
    availableTenants?: string[];
    onUpdate(selectedTenants: string[], selectedTags: string[]): void;
}

interface TenantTagDesignDialogLayoutState extends DataBaseComponentState {
    selectedTags: string[];
    selectedTenants: string[];
    matchCount: number;
    matchResults: MatchResult[];
    allTenants: TenantResource[];
    tenantIndex: { [tenantId: string]: TenantResource };
    allTags: TagSetResource[];
    tagIndex: TagIndex;
    searchText: string;
    onlyShowMatching: boolean;
}

interface MatchResult {
    id: string;
    name: string;
    reason: string;
    isMatched: boolean;
    isMatchedByName: boolean;
    missingTags: string[];
    matchedTags: string[];
}

class TenantTagDesignDialogLayout extends DataBaseComponent<TenantTagDesignDialogLayoutProps, TenantTagDesignDialogLayoutState> {
    constructor(props: TenantTagDesignDialogLayoutProps) {
        super(props);
        this.state = {
            selectedTags: this.props.selectedTags || [],
            selectedTenants: this.props.selectedTenants || [],
            matchCount: 0,
            allTenants: undefined!,
            allTags: undefined!,
            tagIndex: null!,
            matchResults: [],
            searchText: "",
            onlyShowMatching: true,
            tenantIndex: {},
        };
    }

    tagChanged = (tags: any) => {
        this.setState({ selectedTags: tags }, () => this.runTagTest());
    };

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const [allTenants, allTags, tagIndex] = await Promise.all([repository.Tenants.all(), tenantTagsets.getAll(), tenantTagsets.getTagIndex()]);
            const tenantIndex = allTenants.reduce((idx: any, item) => {
                idx[item.Id] = item;
                return idx;
            }, {});
            let availableTenants = allTenants;
            if (this.props.availableTenants) {
                availableTenants = allTenants.filter(t => this.props.availableTenants!.indexOf(t.Id) !== -1);
            }
            this.setState({ allTenants: availableTenants, allTags, tenantIndex, tagIndex }, () => this.runTagTest());
        });
    }

    tenantChanged = (tags: any) => {
        this.setState({ selectedTenants: tags }, () => this.runTagTest());
    };

    async runTagTest() {
        await this.doBusyTask(async () => {
            const result = await repository.Tenants.tagTest(this.state.selectedTenants, this.state.selectedTags);
            let matchCount = 0;

            const matchResults = Object.keys(result)
                .map(
                    (tenantId): MatchResult => {
                        const tenantTestResult = result[tenantId];
                        const tenant = this.state.tenantIndex[tenantId];
                        const isAvailable = !this.props.availableTenants || this.props.availableTenants.indexOf(tenantId) !== -1;
                        const isMatch = tenantTestResult.IsMatched && isAvailable;
                        if (isMatch) {
                            matchCount++;
                        }
                        const matchedTags = tenant.TenantTags.filter(tag => this.state.selectedTags.indexOf(tag) !== -1);
                        return {
                            id: tenantId,
                            name: tenant.Name,
                            reason: isAvailable ? tenantTestResult.Reason : "Tenant not available in this context",
                            missingTags: tenantTestResult.MissingTags,
                            isMatchedByName: this.state.selectedTenants.indexOf(tenantId) !== -1,
                            matchedTags,
                            isMatched: isMatch,
                        };
                    }
                )
                .sort((resultA, resultB) => {
                    if (resultA.isMatched && !resultB.isMatched) {
                        return -1;
                    } else if (!resultA.isMatched && resultB.isMatched) {
                        return 1;
                    }

                    if (resultA.name < resultB.name) {
                        return -1;
                    } else if (resultA.name > resultB.name) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

            this.setState({ matchCount, matchResults });
        });
    }

    render() {
        if (!this.state.allTenants) {
            return null;
        }

        const searchText = this.state.searchText.toLowerCase();
        return withTheme(theme => (
            <OkDialogLayout
                title={"Tenant Preview"}
                errors={this.state.errors}
                busy={this.state.busy}
                onOkClick={() => {
                    this.props.onUpdate(this.state.selectedTenants, this.state.selectedTags);
                    return true;
                }}
            >
                <div>
                    {!this.props.hideTenantsByName && <h3>Include tenants by name</h3>}
                    {!this.props.hideTenantsByName && <TenantMultiSelect onChange={this.tenantChanged} value={this.state.selectedTenants} items={this.state.allTenants} />}

                    <h3>Include tenants by tags</h3>
                    <TenantTagMultiSelect onChange={this.tagChanged} value={this.state.selectedTags} doBusyTask={this.doBusyTask} items={this.state.allTags} />

                    <div className={styles.previewHeader}>
                        <h3 className={styles.previewTitle}>
                            Preview - {this.state.matchCount} matching tenant{this.state.matchCount === 1 ? "" : "s"}
                        </h3>
                        <div>
                            <Text label="Filter results..." value={this.state.searchText} onChange={(e: any) => this.setState({ searchText: e.target.value })} className={styles.previewFilter} />
                            <Checkbox label="Only show matching" value={this.state.onlyShowMatching} onChange={e => this.setState({ onlyShowMatching: !this.state.onlyShowMatching })} />
                        </div>
                    </div>
                </div>
                <div>
                    <Table selectable={false} multiSelectable={false} className={styles.tableStyle}>
                        <TableBody displayRowCheckbox={false} deselectOnClickaway={false}>
                            {this.state.matchResults
                                .filter(t => (t.isMatched || !this.state.onlyShowMatching) && (this.state.searchText.length < 1 || t.name.toLowerCase().includes(searchText)))
                                .map((row, index) => (
                                    <TableRow key={index}>
                                        <TableRowColumn>{row.isMatched && <em className="fa fa-check" />}</TableRowColumn>
                                        <TableRowColumn>{row.name}</TableRowColumn>
                                        <TableRowColumn style={{ overflow: "visible" }}>{this.renderReason(row)}</TableRowColumn>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>
            </OkDialogLayout>
        ));
    }

    renderReason(match: MatchResult) {
        if (match.isMatchedByName) {
            return null;
        }

        if (match.isMatched) {
            return <TagsList canonicalNames={match.matchedTags} tagIndex={this.state.tagIndex} />;
        }

        const content =
            match.reason === "Missing Tags" ? (
                <div>
                    <div>Missing Tags</div>
                    <TenantTagsList tags={match.missingTags} />
                </div>
            ) : (
                match.reason
            );
        return (
            content && (
                <ToolTip content={content}>
                    <em className="fa fa-info-circle" />
                </ToolTip>
            )
        );
    }
}

export default TenantTagDesignDialogLayout;
