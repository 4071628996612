/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import ExternalLink from "../Navigation/ExternalLink";
import { repository } from "clientInstance";
import Divider from "components/Divider/Divider";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { default as Callout, CalloutType } from "components/Callout/Callout";
import { ServerStatusResource, OctopusError } from "../../client/resources";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { clearUnhandledErrors, raiseUnhandledError, currentUnhandledErrorSelector } from "components/UnhandledError/reducers";

import { KnownErrorCodes, UnhandledError } from "components/UnhandledError";
import { parseLinks, isTextPart, isInternalLinkPart } from "components/TextWithLinks/parseLinks";
import ServerStatusHealthResource from "client/resources/serverStatusHealthResource";

const styles = require("./style.less");

interface SystemMessagesBannerState extends DataBaseComponentState {
    serverStatusMessage: string;
}

interface GlobalConnectedProps {
    error: UnhandledError;
}

interface GlobalDispatchProps {
    onError: (error: Error, id?: string) => void;
    clearError: () => void;
}

type SystemMessagesBannerProps = GlobalConnectedProps & GlobalDispatchProps;

declare global {
    interface Document {
        documentMode?: number;
    }
}

export class SystemMessagesBanner extends DataBaseComponent<SystemMessagesBannerProps, SystemMessagesBannerState> {
    // this resource object is needed only once so that we can obtain the health url
    private singleUseServerStatusResource: ServerStatusResource = undefined!;
    private browserNotSupported = false;
    private browserName = "";

    constructor(props: SystemMessagesBannerProps) {
        super(props);

        const ieVersion = document.documentMode;
        if (ieVersion && ieVersion <= 10) {
            this.browserNotSupported = true;
            this.browserName = "Internet Explorer " + ieVersion;
        }

        this.state = { serverStatusMessage: "" };
    }

    async componentDidMount() {
        this.singleUseServerStatusResource = await repository.ServerStatus.getServerStatus();
        await this.doBusyTask(async () => {
            await this.startRefreshLoop(() => this.refreshData(), 10000);
        });
    }

    async refreshData() {
        let health;

        try {
            health = await repository.ServerStatus.getHealth(this.singleUseServerStatusResource);
            if (this.props.error && this.props.error.id === KnownErrorCodes.NetworkError) {
                this.props.clearError();
            }
        } catch (error) {
            if (error instanceof OctopusError) {
                if (error.StatusCode === 418) {
                    health = (error as unknown) as ServerStatusHealthResource;
                } else if (error.StatusCode === KnownErrorCodes.NetworkError) {
                    this.props.onError(error);
                    health = { IsOperatingNormally: false, Description: error.ErrorMessage };
                }
            } else {
                throw error;
            }
        }

        return { serverStatusMessage: health && !health.IsOperatingNormally ? health.Description : "" };
    }

    render() {
        return (
            <div className={styles.pageBanners}>
                {this.state.serverStatusMessage && (
                    <Callout type={CalloutType.Warning} title={"Server status"}>
                        {this.state.serverStatusMessage.split("\n").map(line => (
                            <div>
                                {parseLinks(line).map((part, index) => {
                                    if (isTextPart(part)) {
                                        return <span key={index}>{part.content}</span>;
                                    }
                                    if (!isInternalLinkPart(part)) {
                                        return (
                                            <ExternalLink key={index} href={part.externalUrl}>
                                                {part.text}
                                            </ExternalLink>
                                        );
                                    }
                                })}
                            </div>
                        ))}
                    </Callout>
                )}
                {this.state.serverStatusMessage && this.browserNotSupported && <Divider />}
                {this.browserNotSupported && (
                    <Callout
                        type={CalloutType.Warning}
                        title={
                            <span>
                                Your current browser, <strong>{this.browserName}</strong>, is not supported in <strong>Octopus 4</strong>.
                            </span>
                        }
                    >
                        We still allow you to use Octopus but no guarantee that everything works!
                        <br />
                        If you have any questions or feedback then please share them with us{" "}
                        <strong>
                            <ExternalLink href="https://github.com/OctopusDeploy/Issues/issues/3457">here</ExternalLink>
                        </strong>
                        .
                    </Callout>
                )}
            </div>
        );
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => ({
    onError: (error: Error, id?: string) => dispatch(raiseUnhandledError(error, id)),
    clearError: () => dispatch(clearUnhandledErrors()),
});

const mapGlobalStateToProps = (state: GlobalState) => ({
    error: currentUnhandledErrorSelector(state),
});

const EnhancedSystemMessagesBanner = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(SystemMessagesBanner);
export default EnhancedSystemMessagesBanner;
