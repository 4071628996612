enum ActionKeys {
    FORM_PAPER_LAYOUT_MOUNTED = "FORM_PAPER_LAYOUT_MOUNTED",
    OTHER_ACTION = "__any_other_action_type__",
}

interface IOnSaveClickAction {
    type: ActionKeys.FORM_PAPER_LAYOUT_MOUNTED;
    saveLabel?: string;
    onSaveClick?: () => Promise<boolean>;
}

interface IOtherAction {
    type: ActionKeys.OTHER_ACTION;
}

type ActionTypes = IOnSaveClickAction | IOtherAction;

export function createFormPaperLayoutMountedAction(onSaveClick: (() => Promise<boolean>) | undefined, saveLabel: string | undefined): IOnSaveClickAction {
    return { type: ActionKeys.FORM_PAPER_LAYOUT_MOUNTED, onSaveClick, saveLabel };
}

export interface FormPaperLayoutReducerState {
    onSaveClick?: () => Promise<boolean>;
    saveLabel?: string;
}

const formPaperLayoutReducer = (state: FormPaperLayoutReducerState = { onSaveClick: undefined, saveLabel: undefined }, action: ActionTypes): FormPaperLayoutReducerState => {
    switch (action.type) {
        case ActionKeys.FORM_PAPER_LAYOUT_MOUNTED:
            return { onSaveClick: action.onSaveClick, saveLabel: action.saveLabel };
        default:
            return state;
    }
};
export default formPaperLayoutReducer;
