/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */

import * as React from "react";
import { repository } from "clientInstance";
import { TaskResource } from "client/resources";
import { RouteComponentProps } from "react-router";
import Task, { BuiltInTask } from "./Task";
import TaskLayout from "../../TaskLayout/TaskLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "routeLinks";

interface TaskScreenState extends DataBaseComponentState {
    task?: TaskResource<any>;
    redirectTo?: string;
}

export class TaskScreen extends DataBaseComponent<RouteComponentProps<{ taskId: string }>, TaskScreenState> {
    taskId: string;
    constructor(props: RouteComponentProps<{ taskId: string }>) {
        super(props);
        this.taskId = this.props.match.params.taskId;
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.taskId);

            if (task.Name === BuiltInTask.Deploy && task.Arguments.DeploymentId) {
                this.setState({ redirectTo: routeLinks.deployment(task.Arguments.DeploymentId).root, task });
                return;
            }

            if (task.Name === BuiltInTask.RunbookRun && task.Arguments.RunbookRunId) {
                this.setState({ redirectTo: routeLinks.runbookRun(task.Arguments.RunbookRunId).root, task });
                return;
            }

            this.setState({ task });
        });
    }

    render() {
        const { redirectTo, task } = this.state;
        if (redirectTo) {
            return <InternalRedirect to={{ pathname: redirectTo }} push={false} />;
        }

        return (
            <TaskLayout>
                <Task task={task} taskId={this.taskId} delayRender={() => task == null} />
            </TaskLayout>
        );
    }
}
