import * as React from "react";
import { PaperLayoutProps } from "components/PaperLayout";
import { RouteComponentProps, withRouter } from "react-router";
import { useProcessContext } from "../Contexts/ProcessContext";
import routeLinks from "routeLinks";
import { useRunbookContext, useOptionalRunbookContext } from "../../Runbooks/RunbookContext";
import { FormTitleForRunbook } from "../../Runbooks/LastPublishedChip";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import ContextualRunNowButton from "../../Runbooks/RunNowButton";
import { ProcessPaperLayout } from "./ProcessPaperLayout";
import ActionList from "components/ActionList";
import { MenuItem } from "components/Menu/OverflowMenu";
import { OverflowMenu } from "components/Menu";
import StringHelper from "utils/StringHelper";
import ContextAddStepNavigationButton from "../../Steps/ContextAddStepNavigationButton";
import { ProcessType } from "client/resources";
import { BranchSelector } from "../../BranchSelector/BranchSelector";
import PublishButton from "../../Runbooks/PublishButton";
import { DoBusyTask } from "components/DataBaseComponent";

const styles = require("./style.less");

interface ProcessContextPaperLayoutPropsInternal {
    processType: ProcessType;
    overflowMenuItems: Array<MenuItem | MenuItem[]>;
    doBusyTask: DoBusyTask;
}

type ProcessContextPaperLayoutProps = ProcessContextPaperLayoutPropsInternal & PaperLayoutProps & RouteComponentProps<{ projectSlug: string }>;
const ProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps> = ({ children, ...rest }) => {
    const processContext = useProcessContext();
    const hasSteps = processContext.selectors.hasSteps();
    const projectSlug = rest.match.params.projectSlug;

    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;

    if (rest.processType === ProcessType.Runbook) {
        return (
            <RunbooksProcessContextPaperLayout title={runbookName} breadcrumbTitle="Runbooks" hasSteps={hasSteps} projectSlug={projectSlug} {...rest}>
                {children}
            </RunbooksProcessContextPaperLayout>
        );
    } else {
        return (
            <DeploymentProcessContextPaperLayout title={"Process"} projectSlug={projectSlug} {...rest}>
                {children}
            </DeploymentProcessContextPaperLayout>
        );
    }
};

export const EnhancedProcessContextPaperLayout = withRouter(ProcessContextPaperLayout);

const renderBranchSelector = (sectionControl: React.ReactNode) => {
    return (
        <div className={styles.sectionControlContainer}>
            <BranchSelector />
            {sectionControl}
        </div>
    );
};

const DeploymentProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps & { projectSlug: string }> = ({ projectSlug, ...props }) => {
    const actions = [<ContextAddStepNavigationButton />];
    if (props.overflowMenuItems.length > 0) {
        actions.push(<OverflowMenu menuItems={props.overflowMenuItems} />);
    }
    return (
        <ProcessPaperLayout sectionControl={renderBranchSelector(<ActionList actions={actions} />)} {...props}>
            {props.children}
        </ProcessPaperLayout>
    );
};

const RunbooksProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps & { projectSlug: string; hasSteps: boolean; doBusyTask: DoBusyTask }> = ({ projectSlug, hasSteps, doBusyTask, ...props }) => {
    const runbooksContext = useRunbookContext();
    const title = <FormTitleForRunbook runbookName={runbooksContext.state.runbook?.Name ?? ""} />;
    const actions = [<PublishButton doBusyTask={doBusyTask} />, <ContextualRunNowButton isDisabled={!hasSteps} />, <ContextAddStepNavigationButton />];
    if (props.overflowMenuItems.length > 0) {
        actions.push(<OverflowMenu menuItems={props.overflowMenuItems} />);
    }
    return (
        <RunbooksPaperLayout title={title} breadcrumbTitle={"Runbooks"} breadcrumbPath={routeLinks.project(projectSlug).operations.runbooks} sectionControl={<ActionList actions={actions} />} {...props}>
            {props.children}
        </RunbooksPaperLayout>
    );
};
