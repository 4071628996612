/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { repository } from "clientInstance";
import { Permission } from "client/resources/permission";
import { OctopusError, ProjectResource, ReleaseResource } from "client/resources";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import RefreshLoop from "../../utils/RefreshLoop/refresh-loop";
import { OnboardingResource } from "client/resources";

interface TaskStatus {
    IsEnabled: boolean;
    IsComplete: boolean;
}

interface Task extends TaskStatus {
    Percent: number;
}

export interface Tasks {
    Infrastructure: (Task & { CreatedEnvironment: TaskStatus; CreatedMachine: TaskStatus; AddedAzureAccount: TaskStatus }) | null;
    Package: (Task & { UploadedPackage: TaskStatus; AddedFeed: TaskStatus }) | null;
    Project: (Task & { CreatedProject: TaskStatus; AddedProjectStep: TaskStatus }) | null;
    Deploy: (Task & { CreatedRelease: TaskStatus; DeployedRelease: TaskStatus }) | null;
}

export interface GettingStartedStatus {
    tasks: Tasks;
    next: string;
    projects: ProjectResource[];
    releases: ReleaseResource[];
}

class GettingStartedLoader {
    private static subscriberId: number = 0;

    private subscribers: {
        [subscriberId: string]: (status: GettingStartedStatus) => void;
    } = {};

    private readonly loop = new RefreshLoop(
        async isLoopStillRunning => {
            const status = await this.loadStatus();
            if (status.tasks?.Deploy?.DeployedRelease.IsComplete && isLoopStillRunning) {
                this.loop.stop();
                this.triggerSubscribers(status); // Trigger subscribers one last time so they can refresh if necessary.
            } else {
                this.triggerSubscribers(status);
            }
        },
        hidden => (hidden ? 60000 : 2000)
    );

    loadStatus = async (): Promise<GettingStartedStatus> => {
        const data = await this.loadData();
        return this.buildStatus(data.userOnboardingData, data.projects, data.releases);
    };

    subscribe = (callback: (status: GettingStartedStatus) => void): (() => void) => {
        const subscriberId = ++GettingStartedLoader.subscriberId;
        this.subscribers[subscriberId] = callback;

        if (Object.getOwnPropertyNames(this.subscribers).length === 1) {
            this.loop.start();
        }

        return () => {
            this.unsubscribe(subscriberId);
        };
    };

    private unsubscribe = (subscriberId: number) => {
        delete this.subscribers[subscriberId];
        if (Object.getOwnPropertyNames(this.subscribers).length === 0) {
            this.loop.stop();
        }
    };

    private populateReleasesList = async () => {
        if (isAllowed({ permission: Permission.ReleaseView, wildcard: true })) {
            try {
                return (await repository.Releases.list()).Items;
            } catch (ex) {
                if (ex instanceof OctopusError && ex.StatusCode !== 403) {
                    throw ex;
                }
            }
        }

        return [];
    };

    private populateProjectsList = async () => {
        if (isAllowed({ permission: Permission.ProjectView, wildcard: true })) {
            try {
                return await repository.Projects.all();
            } catch (ex) {
                if (ex instanceof OctopusError && ex.StatusCode !== 403) {
                    throw ex;
                }
            }
        }

        return [];
    };

    private loadData = async () => {
        const userOnboardingData = await repository.UserOnboarding.get();

        // If a release has been deployed, on-boarding is complete so skip loading
        const [projects, releases] = !userOnboardingData.Tasks.find(t => t.Category === "Deploy" && t.Name === "DeployedRelease")!.IsComplete ? await Promise.all([this.populateProjectsList(), this.populateReleasesList()]) : [[], []];

        return {
            userOnboardingData,
            projects,
            releases,
        };
    };

    private triggerSubscribers = (gettingStartedStatus: GettingStartedStatus) => {
        const subscriberIds = Object.getOwnPropertyNames(this.subscribers);
        subscriberIds.forEach((s, _, subscribers) => {
            try {
                this.subscribers[s](gettingStartedStatus);
            } catch (ex) {
                //Ignore on purpose
            }
        }, this);
    };

    private buildStatus = (userOnboardingData: OnboardingResource, projects: ProjectResource[], releases: ReleaseResource[]): GettingStartedStatus => {
        const tasks: Tasks = { Infrastructure: null, Deploy: null, Package: null, Project: null };
        let next = "";

        for (const task of userOnboardingData.Tasks) {
            (tasks as any)[task.Category] = (tasks as any)[task.Category] || {};
            (tasks as any)[task.Category][task.Name] = (tasks as any)[task.Category][task.Name] || {};
            (tasks as any)[task.Category][task.Name].IsComplete = task.IsComplete;
            (tasks as any)[task.Category][task.Name].IsEnabled = task.IsEnabled;
        }

        tasks.Infrastructure!.IsEnabled = tasks!.Infrastructure!.CreatedEnvironment.IsEnabled;
        tasks.Infrastructure!.Percent = 0;
        tasks.Infrastructure!.IsComplete = false;

        if (tasks.Infrastructure!.CreatedEnvironment.IsComplete) {
            tasks.Infrastructure!.Percent += 50;
        } else if (!!!next) {
            next = "Infrastructure.CreatedEnvironment";
        }

        if (tasks.Infrastructure!.CreatedMachine.IsComplete) {
            tasks.Infrastructure!.Percent += 50;
        } else if (tasks.Infrastructure!.AddedAzureAccount.IsComplete) {
            tasks.Infrastructure!.Percent += 50;
        } else if (!!!next) {
            next = "Infrastructure.CreatedMachineOrAccount";
        }

        if (tasks!.Infrastructure!.Percent === 100) {
            tasks!.Infrastructure!.IsComplete = true;
        }

        tasks.Package!.IsEnabled = tasks.Package!.UploadedPackage.IsEnabled;
        tasks.Package!.Percent = 0;
        tasks.Package!.IsComplete = false;

        if (tasks.Package!.UploadedPackage.IsComplete) {
            tasks.Package!.Percent += 100;
        } else if (tasks.Package!.AddedFeed.IsComplete) {
            tasks.Package!.Percent += 100;
        } else if (!!!next) {
            if (tasks.Deploy!.CreatedRelease.IsEnabled && !tasks.Deploy!.CreatedRelease.IsComplete) {
                next = "Deploy.CreatedRelease";
            } else if (tasks.Deploy!.DeployedRelease.IsEnabled && !tasks.Deploy!.DeployedRelease.IsComplete) {
                next = "Deploy.DeployedRelease";
            } else {
                next = "Package";
            }
        }

        if (tasks.Package!.Percent === 100) {
            tasks.Package!.IsComplete = true;
        }

        tasks.Project!.IsEnabled = tasks.Project!.CreatedProject.IsEnabled;
        tasks.Project!.Percent = 0;
        tasks.Project!.IsComplete = false;

        if (tasks.Project!.CreatedProject.IsComplete) {
            tasks.Project!.Percent += 50;
        } else if (!!!next) {
            next = "Project.CreatedProject";
        }

        if (tasks.Project!.AddedProjectStep.IsComplete) {
            tasks.Project!.Percent += 50;
        } else if (!!!next) {
            next = "Project.AddedProjectStep";
        }

        if (tasks.Project!.Percent === 100) {
            tasks.Project!.IsComplete = true;
        }

        tasks.Deploy!.IsEnabled = tasks.Deploy!.CreatedRelease.IsEnabled;
        tasks.Deploy!.Percent = 0;
        tasks.Deploy!.IsComplete = false;

        if (tasks.Deploy!.CreatedRelease.IsComplete) {
            tasks.Deploy!.Percent += 50;
        } else if (!!!next) {
            next = "Deploy.CreatedRelease";
        }

        if (tasks.Deploy!.DeployedRelease.IsComplete) {
            tasks.Deploy!.Percent += 50;
        } else if (!!!next) {
            next = "Deploy.DeployedRelease";
        }

        if (tasks.Deploy!.Percent === 100) {
            tasks.Deploy!.IsComplete = true;
        }

        return {
            tasks,
            next,
            projects,
            releases: releases.filter(r => projects.some(p => p.Id === r.ProjectId)), //Ensure we only display releases that we have projects for.
        };
    };
}

export const gettingStartedLoader = new GettingStartedLoader();
