import { FormFieldProps } from "components/form";
import React from "react";
import WorkerPoolVariableSelect from "components/form/WorkerPoolSelect/WorkerPoolVariableSelect";

interface WorkPoolVariableInputProps extends FormFieldProps<string> {
    label: string;
    projectId: string;
    allowClear: boolean;
    defaultValueIndicator: JSX.Element | undefined;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}

const WorkerPoolVariableInput: React.FC<WorkPoolVariableInputProps> = props => {
    const { value, label, projectId, allowClear, defaultValueIndicator, doBusyTask, ...rest } = props;
    const formProps = { ...rest, label };

    return (
        <React.Fragment>
            <WorkerPoolVariableSelect value={value} projectId={projectId} doBusyTask={doBusyTask} allowClear={allowClear} {...formProps} />
            {defaultValueIndicator}
        </React.Fragment>
    );
};

export default WorkerPoolVariableInput;
