/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import cn from "classnames";

const styles = require("./style.less");

interface DataTableProps {
    headerColumns?: string[];
    items?: any[];
}

export class DataTable extends React.Component<any, any> {
    render() {
        return <table className={cn(this.props.className, styles.dataTable)}>{this.props.children}</table>;
    }
}
