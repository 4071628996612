/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ScopeSpecificationTypes, VariablePromptOptions, VariableResourceBase } from "client/resources/variableResource";
import { FilterableValue } from "areas/variables/VariableFilter/VariableFilter";
import { VariableValueModel } from "../VariablesModel";

type ReadonlyVariableResource = Readonly<VariableResourceBase<ScopeSpecification, Readonly<VariablePromptOptions>>>;

export type ScopeSpecification = Readonly<ReadonlyArrays<ScopeSpecificationTypes>>;

export type ReadonlyArrays<T> = {
    [P in keyof T]: ReadonlyArray<T[P]>;
};

export function convertToFilterableValue(value: VariableValueModel): FilterableValue {
    return {
        description: value.Description,
        type: value.Type,
        scope: value.Scope,
        value: value.Value!,
        isPrompted: !!value.Prompt,
    };
}

export default ReadonlyVariableResource;
