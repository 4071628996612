import * as React from "react";
import { Navbar } from "components/Navbar";
import ErrorPanel from "components/ErrorPanel";
import RootRoutes from "../RootRoutes";
import GettingStartedFooter from "../GettingStarted/GettingStartedFooter";
import { UnhandledError } from "components/UnhandledError";
import SystemMessagesBanner from "components/SystemMessagesBanner/SystemMessagesBanner";
import Sticky from "components/Sticky/Sticky";
import { client } from "../../clientInstance";
import SpaceLoader, { isSpecificSpaceContext } from "./SpaceLoader";
import { zIndexStickies } from "theme";
import { isEqual } from "lodash";

interface StandardLayoutProps {
    versionText: string;
    unhandledError: UnhandledError;
    onErrorClose(): void;
}

export default class StandardLayoutInternal extends React.Component<StandardLayoutProps> {
    // @Performance - HashRouter was causing re-renders when query-strings were changing.
    // We should only be re-rendering our layout if these top-level props change.
    shouldComponentUpdate(nextProps: StandardLayoutProps) {
        const versionTextChanged = nextProps.versionText !== this.props.versionText;
        const unhandledErrorChanged = !isEqual(nextProps.unhandledError, this.props.unhandledError);
        const onErrorCloseChanged = !isEqual(nextProps.onErrorClose, this.props.onErrorClose);
        if (versionTextChanged || unhandledErrorChanged || onErrorCloseChanged) {
            return true;
        }
        return false;
    }

    render() {
        const serverInfo = client.tryGetServerInformation();
        const version = serverInfo ? serverInfo.version : undefined;
        return (
            <div>
                <SpaceLoader
                    render={spaceContext => (
                        <React.Fragment>
                            <Sticky innerZ={zIndexStickies}>
                                <div id="toppanel">
                                    <Navbar spaceContext={spaceContext} />
                                    {this.props.unhandledError && (
                                        <ErrorPanel
                                            message={`An unexpected error occurred in Octopus v${version}: ${this.props.unhandledError.message}`}
                                            errors={this.props.unhandledError.errors}
                                            fullWidth={true}
                                            canClose={true}
                                            onErrorClose={this.onErrorClose}
                                        />
                                    )}
                                    <SystemMessagesBanner />
                                </div>
                            </Sticky>

                            <RootRoutes spaceContext={spaceContext} />
                            {isSpecificSpaceContext(spaceContext) && <GettingStartedFooter />}
                        </React.Fragment>
                    )}
                />
            </div>
        );
    }

    private onErrorClose = () => {
        this.props.onErrorClose();
    };
}
