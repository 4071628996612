import * as React from "react";
import { EventCategoryResource, EventResource } from "../../client/resources/eventResource";
import { ResourceCollection } from "client/resources/resourceCollection";
import { repository } from "clientInstance";
import EventListing, { NotPermittedToViewEventsCallout } from "components/EventListing";
import PaperLayout from "components/PaperLayout";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { Permission } from "client/resources/permission";
import DataLoader from "components/DataLoader";
import { Errors } from "components/DataBaseComponent";
import { ReleaseResource } from "client/resources";

interface EventListLoaderProps {
    release: ReleaseResource;
}

const EventListingLoader = DataLoader<{ events: ResourceCollection<EventResource>; eventCategories: EventCategoryResource[] }>();

export const ListEventsForRelease: React.FC<EventListLoaderProps> = props => {
    const permitEvents: boolean = isAllowed({ permission: Permission.EventView, project: props.release.ProjectId, wildcard: true });

    const loadEventsAndCategories = async () => {
        const events = repository.Events.list({ regarding: [props.release.Id] });
        const eventCategories = repository.Events.categories({});

        return {
            events: await events,
            eventCategories: await eventCategories,
        };
    };

    const displayHistoryTable = (events: ResourceCollection<EventResource>, eventCategories: EventCategoryResource[], eventsFor: string[]) => {
        return <EventListing data={events} regarding={eventsFor} eventCategories={eventCategories} />;
    };

    const handleNoDataMessage = (busy: boolean, errors: Errors | undefined) => {
        return <PaperLayout errors={errors} busy={busy} />;
    };

    return permitEvents ? (
        <EventListingLoader load={loadEventsAndCategories} renderWhenLoaded={data => displayHistoryTable(data.events, data.eventCategories, [props.release.Id])} renderAlternate={({ busy, errors }) => handleNoDataMessage(busy, errors)} />
    ) : (
        <NotPermittedToViewEventsCallout />
    );
};
