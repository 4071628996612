/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import ConfirmNavigate from "./ConfirmNavigate";
import * as ReactDOM from "react-dom";
import * as React from "react";
import AppContext from "AppContext";
import store from "store";

const getUserConfirmation = (message: any, callback: any) => {
    const app = document.getElementById("app");
    const modal = document.createElement("div");
    app!.appendChild(modal);

    // The name need to be the same as formPaperLayoutReducer in ../FormPaperLayout/reducers.ts, would be nice if we have nameOf
    const formPaperLayoutState = store.getState().formPaperLayout;
    const withCleanup = (answer: any) => {
        callback(answer);
        setTimeout(() => {
            // Give the close animation time to do its thing.
            ReactDOM.unmountComponentAtNode(modal);
            app!.removeChild(modal);
        }, 500);
    };

    ReactDOM.render(
        // We HAVE to explicitly setup context here since we are rendering this separately or this will break
        //TODO: look at using React portal for this sort of thing instead or a redux dispatch
        <AppContext>
            <ConfirmNavigate message={message} callback={withCleanup} onSaveClick={formPaperLayoutState.onSaveClick!} saveLabel={formPaperLayoutState.saveLabel} />
        </AppContext>,
        modal
    );
};

export default getUserConfirmation;
