import CertificateSelect from "components/form/CertificateSelect/CertificateSelect";
import { CertificateResource } from "client/resources";
import { FormFieldProps } from "components/form";
import React from "react";

interface CertificateInputProps extends FormFieldProps<string> {
    items: () => Promise<CertificateResource[]>;
    label: string;
    tenantId?: string;
    warning?: string;
    allowClear: boolean;
    defaultValueIndicator: JSX.Element | undefined;
    onRequestRefresh: () => Promise<boolean>;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}

const CertificateInput: React.FC<CertificateInputProps> = props => {
    const { value, items, label, warning, tenantId, allowClear, defaultValueIndicator, onRequestRefresh, doBusyTask, ...rest } = props;
    const formProps = { ...rest, label };

    return (
        <React.Fragment>
            <CertificateSelect value={value} items={items} tenantId={tenantId} warning={warning} allowClear={allowClear} onRequestRefresh={onRequestRefresh} doBusyTask={doBusyTask} {...formProps} />
            {defaultValueIndicator}
        </React.Fragment>
    );
};

export default CertificateInput;
