import * as React from "react";
import { ActionScope } from "./pluginRegistry";

export type WithActionScopeInjectedProps = { scope: ActionScope };

//This HOC sets up a specific scope to be injected into a component; non changeable at this point in time. This may change
//once we implement the next part of Runbooks.
export function withActionScope(scope: ActionScope) {
    return <T extends {}>(Component: React.ComponentType<T & WithActionScopeInjectedProps>) => {
        type Externalprops = Omit<T, keyof WithActionScopeInjectedProps>;
        const WithActionScope: React.FC<Externalprops> = (props: T) => {
            return <Component scope={scope} {...props} />;
        };
        return WithActionScope;
    };
}
