/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { RouteComponentProps, withRouter } from "react-router";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import React from "react";
import { Item } from "components/form/Select/Select";
import { repository } from "clientInstance";
import { VcsBranchResource, ProjectResource } from "client/resources";
import BusyIndicator from "components/BusyIndicator";
import IconButton from "material-ui/IconButton";
import { transitions } from "material-ui/styles";
import Popover, { Origin } from "components/Popover";
import { ThirdPartyIcon, ThirdPartyIconType } from "components/Icon";
import Events from "material-ui/utils/events";
import ReactDOM from "react-dom";
import * as PropTypes from "prop-types";
import Menu from "components/Menu/Menu";
import ClearFix from "material-ui/internal/ClearFix";
import FilterSearchBox from "components/FilterSearchBox";
import { MenuItem } from "material-ui";
import { OctopusTheme, withTheme } from "components/Theme";
const keycode = require("keycode");
const styles = require("./style.less");
import ActionButton, { ActionButtonType } from "components/Button";
import { SvgIcon } from "@material-ui/core";
import * as pathToRegexp from "path-to-regexp";

const GitIcon = (props: any) =>
    withTheme(theme => (
        <SvgIcon {...props} htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral, width: "0.7rem", height: "auto" }} viewBox="0 0 640 1024">
            {/* eslint-disable-next-line */}
            <path d="M512 192c-71 0-128 57-128 128 0 47 26 88 64 110v18c0 64-64 128-128 128-53 0-95 11-128 29v-303c38-22 64-63 64-110 0-71-57-128-128-128s-128 57-128 128c0 47 26 88 64 110v419c-38 22-64 63-64 110 0 71 57 128 128 128s128-57 128-128c0-34-13-64-34-87 19-23 49-41 98-41 128 0 256-128 256-256v-18c38-22 64-63 64-110 0-71-57-128-128-128z m-384-64c35 0 64 29 64 64s-29 64-64 64-64-29-64-64 29-64 64-64z m0 768c-35 0-64-29-64-64s29-64 64-64 64 29 64 64-29 64-64 64z m384-512c-35 0-64-29-64-64s29-64 64-64 64 29 64 64-29 64-64 64z" />
        </SvgIcon>
    ));

function getStyles(context: any, theme: OctopusTheme) {
    const spacing = context.muiTheme.baseTheme.spacing;
    const palette = context.muiTheme.baseTheme.palette;
    return {
        control: {
            cursor: "pointer" as const,
            height: "100%",
            position: "relative" as const,
            width: "100%",
        },
        icon: {
            width: `1.5rem`,
            height: `1.5rem`,
            padding: 0,
            right: 0,
            top: 0,
            marginTop: 0,
            fill: theme.secondaryText,
        },
        iconChildren: {
            fill: "inherit",
        },
        label: {
            display: "flex" as const,
            alignItems: "center" as const,
            color: palette.textColor,
            height: `2rem`,
            lineHeight: `2rem`,
            overflow: "hidden" as const,
            opacity: 1,
            position: "relative" as const,
            paddingRight: spacing.iconSize * 2 + spacing.desktopGutterMini,
            textOverflow: "ellipsis" as const,
            paddingLeft: spacing.desktopGutter,
        },
        labelWhenOpen: {
            opacity: 0,
            top: spacing.desktopToolbarHeight / 8,
        },
        root: {
            display: "inline-block",
            fontSize: spacing.desktopDropDownMenuFontSize,
            height: `2rem`,
            fontFamily: context.muiTheme.baseTheme.fontFamily,
            outline: "none",
            position: "relative",
            transition: transitions.easeOut(),
        },
        rootWhenOpen: {
            opacity: 1,
        },
        buttons: {
            position: "absolute" as const,
            right: 0,
            top: "0.2rem",
        },
        dropDownMenu: {
            display: "block",
            border: `1px solid ${theme}`,
            borderRadius: 5,
        },
        filter: {
            margin: "0 1rem",
        },
        empty: {
            margin: "0 1rem",
        },
    };
}

interface FilterableDropDownMenuProps {
    onChange: (value: string) => void;
    items: Item[];
    value: any;
    empty?: string;
    onFilterChanged?(value: string): Promise<Item[]>;
    onRequestRefresh(): Promise<void>;
}

interface FilterableDropDownMenuState {
    open: boolean;
    anchorElement: any;
    filter: string | undefined;
    filteredItems: Item[] | null;
    refreshing: boolean;
}

class FilterableDropDownMenu extends React.Component<FilterableDropDownMenuProps, FilterableDropDownMenuState> {
    static muiName = "DropDownMenu";
    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    constructor(props: FilterableDropDownMenuProps) {
        super(props);
        this.state = {
            open: false,
            anchorElement: null,
            filter: undefined,
            filteredItems: null,
            refreshing: false,
        };
    }

    private menu: Menu | undefined;
    rootNode = undefined as any;
    arrowNode = undefined as any;

    /**
     * This method is deprecated but still here because the TextField
     * need it in order to work. TODO: That will be addressed later.
     */
    getInputNode() {
        const rootNode = this.rootNode;

        rootNode.focus = () => {
            this.setState({
                open: !this.state.open,
                anchorElement: this.rootNode,
            });
        };

        return rootNode;
    }

    handleTouchTapControl = (event: any) => {
        event.preventDefault();
        this.setState({
            open: !this.state.open,
            anchorElement: this.rootNode,
        });
    };

    handleRequestCloseMenu = () => {
        this.close(false);
    };

    handleEscKeyDownMenu = () => {
        this.close(true);
    };

    handleKeyDown = (event: React.KeyboardEvent<{}>) => {
        switch (keycode(event)) {
            case "up":
            case "down":
            case "space":
            case "enter":
                event.preventDefault();
                this.setState({
                    open: true,
                    anchorElement: this.rootNode,
                });
                break;
        }
    };

    handleFilterKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (keycode(event)) {
            case "esc":
                this.close(true);
                break;
            case "down":
                if (this.menu && React.Children.count(this.props.children) > 0) {
                    event.preventDefault();
                    this.menu.setFocusIndex(event, 0, true);
                }
                break;
        }
    };

    handleItemTouchTap = (event: any, child: any, index: number) => {
        event.persist();
        event.preventDefault();
        this.setState(
            {
                open: false,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(child.props.value);
                }

                this.close(Events.isKeyboard(event));
            }
        );
    };

    handleChange = (event: any, value: any) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    close = (isKeyboard: boolean) => {
        this.setState(
            {
                open: false,
                filter: undefined,
                filteredItems: null,
            },
            () => {
                if (isKeyboard) {
                    const dropArrow = this.arrowNode;
                    // eslint-disable-next-line react/no-find-dom-node
                    const dropNode = ReactDOM.findDOMNode(dropArrow) as HTMLElement;
                    dropNode.focus();
                    dropArrow.setKeyboardFocus(true);
                }
            }
        );
    };

    private handleFilterChanged = async (value: string) => {
        let filteredItems: Item[] = [];
        if (this.props.onFilterChanged) {
            filteredItems = await this.props.onFilterChanged(value);
        } else {
            filteredItems = this.props.items.filter(item => {
                return item.text.toLowerCase().search(value.toLowerCase()) !== -1;
            });
        }

        this.setState(prev => {
            return {
                ...prev,
                filter: value,
                filteredItems,
            };
        });
    };

    private onRequestRefresh = async () => {
        this.setState({ refreshing: true });
        try {
            await this.props.onRequestRefresh();
        } finally {
            this.setState({ refreshing: false });
        }
    };

    private renderFetch() {
        return (
            <div>
                <div className={styles.emptyButton}>
                    <ActionButton type={ActionButtonType.Ternary} label="FETCH" title="Fetch the latest branches from your configured remote repository" onClick={this.onRequestRefresh} />
                </div>
                {this.state.refreshing && <BusyIndicator show={true} />}
            </div>
        );
    }

    private renderDropDown = (theme: OctopusTheme) => {
        const anchorOrigin: Origin = {
            vertical: "bottom",
            horizontal: "left",
        };

        const { items, value } = this.props;
        const { anchorElement, open } = this.state;

        const { prepareStyles } = this.context.muiTheme;
        const inlineStyles = getStyles(this.context, theme);

        return (
            <div
                ref={node => {
                    this.rootNode = node;
                }}
                className={styles.dropDownMenu}
                style={prepareStyles(Object.assign({}, inlineStyles.root, open && inlineStyles.rootWhenOpen))}
            >
                <ClearFix style={inlineStyles.control} onClick={this.handleTouchTapControl}>
                    <div style={inlineStyles.label}>
                        <GitIcon />
                        <span className={styles.value}>{value}</span>
                    </div>
                    <div style={inlineStyles.buttons}>
                        <IconButton
                            onKeyDown={this.handleKeyDown}
                            ref={node => {
                                this.arrowNode = node;
                            }}
                            style={Object.assign({}, inlineStyles.icon)}
                            iconStyle={inlineStyles.iconChildren}
                            {...{ "aria-label": "ToggleDropDown" }}
                        >
                            <ThirdPartyIcon iconType={ThirdPartyIconType.ArrowDropDown} />
                        </IconButton>
                    </div>
                </ClearFix>
                <Popover anchorOrigin={anchorOrigin} anchorEl={anchorElement} open={open} onClose={this.handleRequestCloseMenu}>
                    <div onKeyDown={this.handleFilterKeyDown} style={inlineStyles.filter}>
                        <FilterSearchBox placeholder={"Search for branches..."} autoFocus={true} value={this.state.filter} onChange={this.handleFilterChanged} />
                    </div>
                    <Menu
                        ref={(menu: Menu) => (this.menu = menu)}
                        disableAutoFocus={true}
                        maxHeight={500}
                        desktop={true}
                        value={value}
                        onEscKeyDown={this.handleEscKeyDownMenu}
                        onItemTouchTap={this.handleItemTouchTap}
                        selectedMenuItemStyle={{ color: theme.primary }}
                        autoWidth={true}
                        width={null}
                    >
                        {(this.state.filteredItems || items).length === 0 && this.props.empty && <span style={inlineStyles.empty}>{this.props.empty}</span>}
                        {(this.state.filteredItems || items).map((item, index) => {
                            // Needs preventDefault onMouseDown to prevent https://www.chromestatus.com/features/6662647093133312
                            return <MenuItem onMouseDown={e => e.preventDefault()} key={item.value ?? index} value={item.value} primaryText={item.text} />;
                        })}
                    </Menu>
                    {!this.state.filter && <div className={styles.warning}>Displaying up to 15 branches. If your branch isn't displayed, please search for it.</div>}
                    {this.renderFetch()}
                </Popover>
            </div>
        );
    };

    render() {
        return withTheme(theme => {
            return this.renderDropDown(theme);
        });
    }
}

type BranchSelectorProps = WithProjectContextInjectedProps & RouteComponentProps<ProjectRouteParams>;

function toItem(branch: VcsBranchResource, project: ProjectResource): Item {
    return {
        text: branch.Name === project.VersionControlSettings.DefaultBranch ? `${branch.Name} (default)` : branch.Name,
        value: branch.Name,
    };
}

function toItems(branches: VcsBranchResource[], project: ProjectResource): Item[] {
    return branches.map(branch => toItem(branch, project));
}

const BranchSelectorInternal: React.FC<BranchSelectorProps> = (props: BranchSelectorProps) => {
    const [branches, setBranches] = React.useState<Item[]>([]);
    const project = props.projectContext.state.model;

    const ensureSelectedBranchExists = async (value: string, collection: Item[]) => {
        // Note: If a branch is selected that doesn't exist in the base collection of branches, insert it.
        // This may occur if a branch is searched for that is older than the 'latest n' retrieved by default
        if (!collection.find(item => item.value === value)) {
            const branch = await repository.Projects.getBranch(project, value);
            return [toItem(branch, project), ...collection];
        }

        return collection;
    };

    const calculateNewPath = (branchName: string): string => {
        // Switch to generatePath once a new version of router is released : https://github.com/ReactTraining/react-router/issues/6024
        const newUrl = pathToRegexp.compile(props.match.path)({
            ...props.match.params,
            ["branchName"]: branchName,
        });
        return newUrl + props.location.pathname.substr(props.match.url.length);
    };

    const injectBranchIntoLocation = (newBranch: string) => {
        const newPath = calculateNewPath(newBranch);
        props.history.push({ ...props.location, pathname: newPath });
    };

    const onChanged = async (newBranch: string | undefined) => {
        if (!newBranch) {
            throw new Error("We should not be here!");
        }

        // Note: When we have a newly selected branch, all we need to do is push it into our router history
        // Note: The props will then flow back into BranchSelector and its initialization useEffect will be triggered
        // Note: As it has a dependency on props.projectContext.state.branch, which in turn changes with there is a new branchName route match
        injectBranchIntoLocation(newBranch);
    };

    const refresh = async () => {
        const selectedBranch = props.projectContext.state.branch;
        if (!selectedBranch) return;

        const branchResources = await repository.Projects.getBranches(project, true);
        const items = await ensureSelectedBranchExists(selectedBranch.Name, toItems(branchResources, project));
        setBranches(items);
        props.projectContext.actions.onBranchSelected(project, selectedBranch.Name);
    };

    const search = async (value: string) => {
        const branchResources = await repository.Projects.searchBranches(project, value);
        return toItems(branchResources, project);
    };

    React.useEffect(() => {
        async function retrieveBranches(branch: VcsBranchResource) {
            const branchResources = await repository.Projects.getBranches(project);

            // Note: there is a chance that the resource passed to us isn't in the collection returned by getBranches, as it only returns the latest `n` active branches
            // Note: if it isn't in the collection retrieved, we will insert it here
            if (branch && !branchResources.some(x => x.Name === branch.Name)) {
                setBranches(toItems([branch, ...branchResources], project));
            } else {
                setBranches(toItems(branchResources, project));
            }
        }

        if (project && project.IsVersionControlled && props.projectContext.state.branch) {
            retrieveBranches(props.projectContext.state.branch);
        }
    }, [project, props.projectContext.state.branch]);

    return project && project.IsVersionControlled && props.projectContext.state.branch ? (
        <div id="branchSelector" className={styles.selectContainer}>
            <FilterableDropDownMenu value={props.projectContext.state.branch.Name} items={branches} empty="No branches found" onChange={onChanged} onRequestRefresh={refresh} onFilterChanged={search} />
        </div>
    ) : (
        <div></div>
    );
};

export const BranchSelector = withRouter(withProjectContext(BranchSelectorInternal));
