import * as React from "react";
import { useProjectContext } from "areas/projects/context";
import { NavigationButtonProps } from "components/Button/NavigationButton";
import { PermissionCheck } from "components/PermissionCheck";
import { processScopedEditPermission, isRunbookProcessState } from "../Process/Common/CommonProcessHelpers";
import { useProcessContext } from "../Process/Contexts/ProcessContext";
import ActionButton, { ActionButtonType } from "components/Button";
import { useProcessQueryStringContext } from "../Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import { ProcessType } from "client/resources";

type AddRunbookStepButtonPropChanges = Omit<NavigationButtonProps, "href" | "label"> & { href?: NavigationButtonProps["href"]; label?: NavigationButtonProps["label"] };

type AddRunbookStepButtonProps = {
    projectId: string;
    processId: string;
    runbookId: string;
    projectSlug: string;
} & AddRunbookStepButtonPropChanges;

const AddRunbookStepButton: React.FC<AddRunbookStepButtonProps> = ({ projectId, projectSlug, processId, runbookId, ...rest }) => {
    const processQueryStringContext = useProcessQueryStringContext();
    return (
        <PermissionCheck permission={processScopedEditPermission(ProcessType.Runbook)} project={projectId} wildcard={true}>
            <ActionButton
                type={ActionButtonType.Secondary}
                label="Add Step"
                onClick={() => {
                    processQueryStringContext.actions.showProcessStepTemplates();
                }}
            />
        </PermissionCheck>
    );
};

type AddDeploymentsStepButtonProps = {
    projectId: string;
    projectSlug: string;
};

const AddDeploymentsStepButton: React.FC<AddDeploymentsStepButtonProps> = ({ projectId, projectSlug, ...rest }) => {
    const processQueryStringContext = useProcessQueryStringContext();
    return (
        <PermissionCheck permission={processScopedEditPermission(ProcessType.Deployment)} project={projectId} wildcard={true}>
            <ActionButton
                type={ActionButtonType.Secondary}
                label="Add Step"
                onClick={() => {
                    processQueryStringContext.actions.showProcessStepTemplates();
                }}
            />
        </PermissionCheck>
    );
};

export type ContextAddStepButtonProps = AddRunbookStepButtonPropChanges;
const ContextAddStepButton: React.FC<ContextAddStepButtonProps> = props => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;

    const processContext = useProcessContext();
    const process = processContext.selectors.getOptionalStoredProcess();

    if (isRunbookProcessState(process)) {
        return <AddRunbookStepButton projectSlug={project.Slug} projectId={project.Id} processId={process.Id} runbookId={process.RunbookId} {...props} />;
    } else {
        return <AddDeploymentsStepButton projectSlug={project.Slug} projectId={project.Id} {...props} />;
    }
};

export default ContextAddStepButton;
