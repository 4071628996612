/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { repository } from "clientInstance";
import AreaTitle from "components/AreaTitle";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import * as H from "history";
import * as React from "react";
import { RouteComponentProps } from "react-router";

import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { FeedType } from "client/resources/feedResource";

interface OverviewFilterRedirectState extends DataBaseComponentState {
    redirectTo?: H.LocationDescriptor;
}

interface FeedParams {
    feedId: string;
}

export class RedirectFeedBasedOnType extends DataBaseComponent<RouteComponentProps<FeedParams>, OverviewFilterRedirectState> {
    constructor(props: RouteComponentProps<FeedParams>) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const id = this.props.match.params.feedId;
            const feed = await repository.Feeds.get(id);

            const root = routeLinks.forSpace(feed.SpaceId);
            const redirectTo = feed.FeedType === FeedType.BuiltIn ? root.library.builtInRepository.root : root.library.feed(id).root;

            this.setState({ redirectTo });
        });
    }

    render() {
        const to = this.state.redirectTo;
        if (!to) {
            return (
                <main id="maincontent">
                    <AreaTitle link={routeLinks.configuration.teams.root()} title="Library" />
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.state.errors} />
                </main>
            );
        }

        return <InternalRedirect push={false} to={to} />;
    }
}
