import * as React from "react";
import { History } from "history";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { resolvePathWithSpaceId } from "../resolvePathWithSpaceId";
type LocationDescriptor = History.LocationDescriptor;

interface InternalRedirectComponentProps {
    to: LocationDescriptor;
    push?: boolean;
    exact?: boolean;
    from?: string;
}

type InternalRedirectProps = InternalRedirectComponentProps & RouteComponentProps<{ spaceId: string }>;

const InternalRedirect: React.SFC<InternalRedirectProps> = (props: InternalRedirectProps) => {
    /* eslint-disable react/forbid-elements */
    return <Redirect from={props.from} to={resolvePathWithSpaceId(props.to, props.match.params.spaceId)} push={props.push} exact={props.exact} />;
};

const EnhancedInternalRedirect = withRouter(InternalRedirect);

const InternalRedirectWithHistory: React.SFC<InternalRedirectProps> = props => <EnhancedInternalRedirect push={true} {...props} />;

export default EnhancedInternalRedirect;

export { InternalRedirectWithHistory };
