/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ProjectGroupResource, TriggerActionType, ProjectSettingsMetadata, RunbookResource, TriggerActionCategory, ProjectSummaryResource, ProjectSummary, NewProjectResource, VcsBranchResource, HasVcsProjectResourceLinks } from "../resources/index";
import BasicRepository from "./basicRepository";
import Client from "../client";
import { ProjectResource, ChannelResource, TriggerResource, ReleaseResource, ResourceCollection } from "../resources";
import { repository } from "clientInstance";
import { BranchSpecifier, ShouldUseDefaultBranch } from "areas/projects/components/ProjectsRoutes/BranchSpecifier";

export interface ListProjectsArgs {
    skip?: number;
    take?: number;
    partialName?: string;
    clonedFromProjectId?: string;
}

class ProjectRepository extends BasicRepository<ProjectResource, NewProjectResource> {
    constructor(client: Client) {
        super("Projects", client);
    }
    getDeployments(project: ProjectResource) {
        return this.client.get(this.client.getLink("Deployments"), { projects: project.Id });
    }
    getReleases(project: ProjectResource, args?: { skip?: number; take?: number; searchByVersion?: string }): Promise<ResourceCollection<ReleaseResource>> {
        return this.client.get<ResourceCollection<ReleaseResource>>(project.Links["Releases"], args!);
    }
    getReleaseByVersion(project: ProjectResource, version: string): Promise<ReleaseResource> {
        return this.client.get(project.Links["Releases"], { version });
    }
    list(args?: ListProjectsArgs): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(this.client.getLink("Projects"), { ...args });
    }
    listByGroup(projectGroup: ProjectGroupResource): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(projectGroup.Links["Projects"]);
    }
    getChannels(project: ProjectResource, skip: number = 0, take: number = repository.takeAll): Promise<ResourceCollection<ChannelResource>> {
        return this.client.get<ResourceCollection<ChannelResource>>(project.Links["Channels"], { skip, take });
    }
    getTriggers(project: ProjectResource, skip?: number, take?: number, triggerActionType?: TriggerActionType, triggerActionCategory?: TriggerActionCategory, runbooks?: string[], partialName?: string): Promise<ResourceCollection<TriggerResource>> {
        return this.client.get<ResourceCollection<TriggerResource>>(project.Links["Triggers"], { skip, take, triggerActionType, triggerActionCategory, runbooks, partialName });
    }
    orderChannels(project: ProjectResource, channelIds: string[]) {
        return this.client.post(project.Links["OrderChannels"], channelIds);
    }
    getPulse(projects: ProjectResource[]) {
        const projectIds = projects
            .map(p => {
                return p.Id;
            })
            .join(",");
        return this.client.get(this.client.getLink("ProjectPulse"), { projectIds });
    }
    getMetadata(project: ProjectResource): Promise<ProjectSettingsMetadata[]> {
        return this.client.get(project.Links["Metadata"], {});
    }
    getRunbooks(project: ProjectResource, args?: { skip?: number; take?: number }): Promise<ResourceCollection<RunbookResource>> {
        return this.client.get<ResourceCollection<RunbookResource>>(project.Links["Runbooks"], args);
    }
    summaries(): Promise<ProjectSummaryResource[]> {
        return this.client.get(this.client.getLink("ProjectsExperimentalSummaries"));
    }
    getSummary(project: ProjectResource): Promise<ProjectSummary> {
        return this.client.get(project.Links["Summary"], {});
    }
    getBranch(project: ProjectResource, branch: BranchSpecifier): Promise<VcsBranchResource> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            const branchName: string = ShouldUseDefaultBranch(branch) ? project.VersionControlSettings.DefaultBranch : branch;
            return this.client.get(project.Links.Branches, { name: branchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    getBranches(project: ProjectResource, refresh?: boolean): Promise<VcsBranchResource[]> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Branches, refresh ? { refresh } : {});
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    searchBranches(project: ProjectResource, partialBranchName: string): Promise<VcsBranchResource[]> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Branches, { searchByName: partialBranchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
}

export default ProjectRepository;
