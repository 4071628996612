import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import DeploymentToProjectTaskRedirect from "./DeploymentToProjectTaskRedirect";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import routeLinks from "../../routeLinks";

export default class DeploymentRoutes extends React.Component<RouteComponentProps<{}>> {
    render() {
        return (
            <Switch>
                <ReloadableRoute path={routeLinks.deployment(":deploymentId").root} component={DeploymentToProjectTaskRedirect} />
            </Switch>
        );
    }
}
