/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import Note from "components/form/Note/Note";
const styles = require("./style.less");

interface MoreInfoProps {
    content: React.ReactNode;
    label?: string;

    onClick?(content: React.ReactNode): void;
}

class MoreInfo extends React.Component<MoreInfoProps, {}> {
    constructor(props: MoreInfoProps) {
        super(props);
    }

    render() {
        return (
            <Note>
                <a href="#" onClick={this.openMoreInfo}>
                    {this.props.label || "More information"}
                </a>
            </Note>
        );
    }

    private openMoreInfo = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onClick) {
            this.props.onClick(this.props.content);
        }
    };
}

(MoreInfo as any).defaultProps = {
    isMoreInfo: true,
};

export default MoreInfo;
