import * as React from "react";
import CloseButton from "components/Button/CloseButton";
import { useSelector } from "react-redux";
import { pageConfigSelector } from "components/PageLayout/reducers/pageLayoutArea";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import { useDevToolsDispatch, useDevToolsState, actions } from "components/DevTools/DevToolsContext";

const styles = require("./DevBanner.less");

interface DevBannerProps {
    onClose(): void;
}

const DevBanner: React.FC<DevBannerProps> = ({ onClose }) => {
    const { page } = useSelector(pageConfigSelector);
    const handleClose = React.useCallback(onClose, [onClose]);
    const dispatcher = useDevToolsDispatch();
    const stateContext = useDevToolsState();
    return (
        <div className={styles.devBanner}>
            <div className={styles.entries}>
                <span className={styles.entry}>Current Page: {page && page.Id}</span>
                <span className={styles.entry}>
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            dispatcher?.dispatch(actions.toggleDrawer());
                        }}
                    >
                        {stateContext?.drawer.open ? (
                            <span>
                                Close Dev Tools <pre style={{ display: "inline" }}>ctrl+shift+~</pre>
                            </span>
                        ) : (
                            <span>
                                Open Dev Tools <pre style={{ display: "inline" }}>ctrl+shift+~</pre>
                            </span>
                        )}
                    </a>
                </span>
                <span className={styles.entry}>
                    <InternalLink to={routeLinks.uxGuide.root}>UX Guide</InternalLink>
                </span>
                <span className={styles.entry}>
                    <InternalLink to={routeLinks.styleGuide}>Style Guide</InternalLink>
                </span>
            </div>
            <div className={styles.closeBanner}>
                <CloseButton onClose={handleClose} />
            </div>
        </div>
    );
};

export default DevBanner;
