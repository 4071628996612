import * as React from "react";
import { Text } from "components/form";
import Callout, { CalloutType } from "components/Callout";
import { DoBusyTask } from "../../../../components/DataBaseComponent/DataBaseComponent";

interface DeleteRunbookProps {
    runbookName: string;
    runbookId: string;
    doBusyTask: DoBusyTask;
    onChange(canDelete: boolean): void;
}

interface DeleteRunbookState {
    confirmationRunbookName: string;
}

export default class DeleteRunbook extends React.Component<DeleteRunbookProps, DeleteRunbookState> {
    constructor(props: DeleteRunbookProps) {
        super(props);
        this.state = {
            confirmationRunbookName: "",
        };
    }

    render() {
        return (
            <div>
                <Callout title="This is a destructive action" type={CalloutType.Danger}>
                    This action <strong>cannot</strong> be undone. This will permanently delete the <strong>{this.props.runbookName}</strong> Runbook and all of its contents, including Runbook Snapshots and Runbook Runs history.
                </Callout>
                <div>
                    <p>Please type in the name of the Runbook ({this.props.runbookName}) to confirm.</p>
                    <Text value={this.state.confirmationRunbookName} onChange={this.onChange} />
                </div>
            </div>
        );
    }

    private onChange = (confirmationRunbookName: string) => {
        this.setState({ confirmationRunbookName });
        this.props.onChange(confirmationRunbookName.replace(/\s+/g, " ").toLowerCase() === this.props.runbookName.replace(/\s+/g, " ").toLowerCase());
    };
}
