import { VcsBranchResource } from "client/resources";

export const UseDefaultBranch = { UseDefaultBranch: true };
type UseDefaultBranch = typeof UseDefaultBranch;
export type SpecifiedBranch = string;
export type BranchSpecifier = SpecifiedBranch | UseDefaultBranch;

export function ShouldUseDefaultBranch(branch: BranchSpecifier): branch is UseDefaultBranch {
    return typeof branch === "object";
}
