import * as React from "react";
import { useRequiredContext } from "hooks";
import { isEqual } from "lodash";
import { ProcessEditorSearchFilter, getEmptySearchFilter, ProcessEditorSearchFilterResult, useFilteredProcess } from "./";
import { ProcessStateSelectors } from "../ProcessContextState";
import { ProcessType } from "client/resources";

interface ProcessSearchFilterContextLookupState {
    searchFilter: ProcessEditorSearchFilter;
}

export type ProcessSearchFilterContextProps = {
    state: ProcessSearchFilterContextLookupState;
    filteredSteps: ProcessEditorSearchFilterResult;
    isFiltering: boolean;
    getEmptyFilter: () => ProcessEditorSearchFilter;
    actions: ProcessSearchFilterContextProviderSetupActions;
    selectors: ProcessStateSelectors; // This has crossover with the ProcessContext.
    processType: ProcessType;
};

export const ProcessSearchFilterContext = React.createContext<ProcessSearchFilterContextProps | undefined>(undefined);

export const useProcessSearchFilterContext = () => {
    return useRequiredContext(ProcessSearchFilterContext, "ProcessSearchFilter");
};

export const useOptionalProcessSearchFilterContext = () => {
    return React.useContext(ProcessSearchFilterContext);
};

const useProcessState = (initialSearchFilter: ProcessEditorSearchFilter) => {
    return React.useState<ProcessSearchFilterContextLookupState>({
        searchFilter: initialSearchFilter,
    });
};

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProcessSearchFilterContextLookupState>>) => {
    return {
        onFilterChange: (callback: (prev: ProcessEditorSearchFilter) => ProcessEditorSearchFilter) => setState(current => ({ ...current, searchFilter: callback(current.searchFilter) })),
        onClearFilter: () => setState(current => ({ ...current, searchFilter: getEmptySearchFilter() })),
    };
};

export interface ProcessSearchFilterContextProviderSetup {
    state: ProcessSearchFilterContextLookupState;
    setState: React.Dispatch<React.SetStateAction<ProcessSearchFilterContextLookupState>>;
    filteredSteps: ProcessEditorSearchFilterResult;
    isFiltering: boolean;
    getEmptyFilter: () => ProcessEditorSearchFilter;
    actions: ProcessSearchFilterContextProviderSetupActions;
}

export interface ProcessSearchFilterContextProviderSetupActions {
    onFilterChange: (callback: (prev: ProcessEditorSearchFilter) => ProcessEditorSearchFilter) => void;
    onClearFilter: () => void;
}

interface ProcessSearchFilterControllerProps {
    initialFilter?: ProcessEditorSearchFilter;
    children: (renderProps: ProcessSearchFilterContextProps) => React.ReactNode;
    processType: ProcessType;
    selectors: ProcessStateSelectors; // This has crossover with the ProcessContext.
}

export const ProcessSearchFilterController: React.FC<ProcessSearchFilterControllerProps> = ({ children, selectors, initialFilter = getEmptySearchFilter(), processType }) => {
    const [lookupsState, setState] = useProcessState(initialFilter);
    const filteredSteps = useFilteredProcess(selectors, lookupsState.searchFilter);
    const stateUpdaters = React.useMemo(() => getStateUpdaters(setState), [setState]);

    const contextValue: ProcessSearchFilterContextProps = {
        processType,
        state: lookupsState,
        filteredSteps,
        isFiltering: !isEqual(lookupsState.searchFilter, getEmptySearchFilter()),
        getEmptyFilter: getEmptySearchFilter,
        actions: stateUpdaters,
        selectors,
    };

    return <ProcessSearchFilterContext.Provider value={contextValue}>{children(contextValue)}</ProcessSearchFilterContext.Provider>;
};

export interface WithProcessSearchFilterContextInjectedProps {
    processSearchFilterContext: ProcessSearchFilterContextProps;
}

export const withProcessSearchFilterContext = <T extends unknown>(Component: React.ComponentType<T & WithProcessSearchFilterContextInjectedProps>) => {
    const WithProcessSearchFilterContext: React.FC<T> = props => {
        const context = useProcessSearchFilterContext();
        return <Component processSearchFilterContext={context} {...props} />;
    };
    return WithProcessSearchFilterContext;
};
