import * as React from "react";
import cn from "classnames";
import ToolTip from "components/ToolTip/ToolTip";
const styles = require("./style.less");

export enum VariableStatus {
    Existing,
    New,
    Deleted,
    Modified,
}

interface VariableStatusIconProps {
    status: VariableStatus;
}

const VariableStatusIcon = (props: VariableStatusIconProps) => {
    switch (props.status) {
        case VariableStatus.New:
            return (
                <ToolTip content={`New`}>
                    <em className={cn("fa fa-star", styles.newIcon)} />
                </ToolTip>
            );
        case VariableStatus.Deleted:
            return (
                <ToolTip content={`Deleted`}>
                    <em className={cn("fa fa-times", styles.deletedIcon)} />
                </ToolTip>
            );
        case VariableStatus.Modified:
            return (
                <ToolTip content={`Changed`}>
                    <em className={cn("fa fa-pencil", styles.editIcon)} />
                </ToolTip>
            );
        default:
            return null;
    }
};

export default VariableStatusIcon;
