/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OctopusError } from "client/resources";
import { getType } from "typesafe-actions";
import { Reducer } from "redux";
import { ErrorAction, clearUnhandledErrors, raiseUnhandledError } from "./actions";
import { UnhandledError } from "../UnhandledError";

export type UnhandledErrorState = null | UnhandledError;

const getStoredError = (state: UnhandledErrorState, error: Error, id: string | number | null = null): UnhandledErrorState => {
    if (error instanceof OctopusError) {
        return {
            id: id || error.StatusCode,
            message: error.ErrorMessage,
            errors: error.Errors,
            originalError: error,
        };
    }

    if (error instanceof Error) {
        const details = error.stack!.split(" at ");
        return {
            id: id || details[0],
            message: error.message,
            errors: details,
            originalError: error,
        };
    }

    return {
        id: id || "unknown",
        message: JSON.stringify(error),
        errors: undefined,
        originalError: undefined,
    };
};

const currentUnhandledErrorReducer: Reducer<UnhandledErrorState, ErrorAction> = (state = null, action) => {
    switch (action.type) {
        case getType(raiseUnhandledError):
            const { error, id } = action.payload;
            return getStoredError(state, error, id);
        case getType(clearUnhandledErrors):
            return null;
    }
    return state;
};

export default currentUnhandledErrorReducer;
