/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { ScopeSpecification } from "areas/variables/ReadonlyVariableResource/ReadonlyVariableResource";
import { ScopeValues } from "client/resources/variableSetResource";
import { ScopeItem } from "../VariableSorting/sortVariables";

export type ArrayType<T> = T extends Array<infer P> ? P : never;
export type AvailableScopeLookup = <T extends keyof ScopeValues>(type: T, id: string) => ArrayType<ScopeValues[T]>;
export type ScopeLookupsMap<T extends ScopeValues> = { [K in keyof T]: { [id: string]: ArrayType<T[K]> } };

export interface ScopeConsistencyRule {
    isApplicable: (scopes: ScopeSpecification, scopeLookup: AvailableScopeLookup) => boolean;
    validate: (scopes: ScopeSpecification, scopeLookup: AvailableScopeLookup) => ScopeConsistencyResult;
}

export type ScopeConsistencyResult = ScopeConsistencySuccessResult | ScopeConsistencyFailureResult;

export interface ScopeConsistencySuccessResult {
    success: true;
}

export interface ScopeConsistencyFailureResult {
    success: false;
    message: string;
    culprits: ScopeItem[];
}

export function isScopeConsistencyFailureResult(result: ScopeConsistencyResult): result is NonNullable<ScopeConsistencyFailureResult> {
    if (!result) {
        return false;
    }
    const converted = result as ScopeConsistencyFailureResult;
    return !converted.success && converted.message !== undefined && converted.message !== null;
}
