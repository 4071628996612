import { ScopeSpecification } from "areas/variables/ReadonlyVariableResource/ReadonlyVariableResource";
import { ScopeConsistencyRule, AvailableScopeLookup, isScopeConsistencyFailureResult } from "./model";

export function checkScopeConsistency(rules: ScopeConsistencyRule[], getScopeLookup: () => AvailableScopeLookup, scope: ScopeSpecification) {
    const lookup = getScopeLookup();
    return rules
        .filter(rule => rule.isApplicable(scope, lookup))
        .map(rule => rule.validate(scope, lookup))
        .filter(isScopeConsistencyFailureResult);
}
