/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { UrlNavigationTabsContainer } from "components/Tabs";
import TabItem from "components/Tabs/TabItem";
import { Section } from "components/Section/Section";
import Markdown from "components/Markdown/index";
import Logo from "components/Logo/Logo";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { CommunityActionTemplateResource } from "client/resources/communityActionTemplateResource";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import Callout, { CalloutType } from "components/Callout/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import Note from "components/form/Note/Note";
const styles = require("./style.less");
import CodeEditor from "components/CodeEditor/CodeEditor";
import { ScriptingLanguage } from "components/scriptingLanguage";
import { Permission } from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import cn from "classnames";
import { ActionTemplateParameterResource } from "client/resources/actionTemplateParameterResource";
import URI from "urijs";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { noOp } from "utils/noOp";

interface CommunityTemplateParams {
    templateId: string;
}

class ParameterTable extends SimpleDataTable<ActionTemplateParameterResource> {}

export interface CommunityTemplateState extends DataBaseComponentState {
    redirectTo: string;
    template?: CommunityActionTemplateResource;
    isLoaded: boolean;
    currentlyInstalledVersion?: number;
    isUpdateAvailable: boolean;
    projectIdToComeBackTo?: string;
}

export default class CommunityTemplate extends DataBaseComponent<RouteComponentProps<CommunityTemplateParams>, CommunityTemplateState> {
    constructor(props: RouteComponentProps<CommunityTemplateParams>) {
        super(props);
        this.state = {
            redirectTo: null!,
            isLoaded: false,
            currentlyInstalledVersion: null!,
            isUpdateAvailable: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectId = this.extractProjectId();
            this.setState({ projectIdToComeBackTo: projectId });

            const template = await repository.CommunityActionTemplates.get(this.props.match.params.templateId);
            const currentlyInstalledVersion = (await this.currentlyInstalledVersion(template))!;
            const isUpdateAvailable = !!currentlyInstalledVersion && currentlyInstalledVersion < template.Version;

            this.setState({
                isLoaded: true,
                template,
                currentlyInstalledVersion,
                isUpdateAvailable,
            });
        });
    }

    render() {
        return (
            <LibraryLayout {...this.props}>
                <PaperLayout
                    title={this.state.isLoaded && this.summary(this.state.template!)}
                    busy={this.state.busy}
                    errors={this.state.errors}
                    sectionControl={
                        <PermissionCheck permission={Permission.ActionTemplateDelete}>
                            <ActionButton label={this.getLabel()} onClick={this.installOrUpdate} type={ActionButtonType.Primary} />
                        </PermissionCheck>
                    }
                >
                    {this.state.isLoaded && (
                        <div>
                            {this.state.redirectTo && <InternalRedirect to={this.state.redirectTo} push={true} />}
                            {this.state.isUpdateAvailable ? (
                                <Callout type={CalloutType.Warning} title={"Update available"}>
                                    Please review <ExternalLink href={this.state.template!.HistoryUrl}>the history of this template</ExternalLink> to find out what's changed between currently installed version{" "}
                                    <strong>{this.state.currentlyInstalledVersion}</strong> and new version <strong>{this.state.template!.Version}</strong>.
                                </Callout>
                            ) : (
                                <Section>
                                    <Note>
                                        This is a community contributed step that can be installed into your <InternalLink to={routeLinks.library.stepTemplates.root}>step templates</InternalLink> library. Learn more about{" "}
                                        <ExternalLink href="CommunityContributedStepTemplates">community steps</ExternalLink>.
                                    </Note>
                                </Section>
                            )}
                            <Section>
                                <Markdown markup={this.state.template!.Description} />
                            </Section>
                            <UrlNavigationTabsContainer defaultValue="parameters">
                                <TabItem label="Parameters" value="parameters">
                                    <Section>
                                        <ParameterTable
                                            data={this.state.template!.Parameters}
                                            headerColumns={["Name", "Description"]}
                                            headerColumnClassNames={[styles.headerColumn, styles.headerColumn]}
                                            onRow={parameter => [
                                                <span>{parameter.Label ? parameter.Label : parameter.Name}</span>,
                                                <div>
                                                    <Markdown markup={parameter.HelpText} />
                                                </div>,
                                            ]}
                                        />
                                    </Section>
                                </TabItem>
                                <TabItem label="Source code" value="sourceCode">
                                    <Section>
                                        <CodeEditor value={this.getSourceCode(this.state.template!)} language={this.getSourceCodeSyntax(this.state.template!)} readOnly={true} allowFullScreen={true} onChange={noOp} />
                                    </Section>
                                </TabItem>
                            </UrlNavigationTabsContainer>
                            <p className={styles.licence}>
                                Provided under the <ExternalLink href="CommunityLibraryLicense">Apache License version 2.0.</ExternalLink>
                            </p>
                        </div>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private summary = (template: CommunityActionTemplateResource) => {
        return (
            <div className={styles.summary}>
                <div className={styles.logo}>
                    <Logo url={template.Links.Logo} />
                </div>
                <div className={styles.metadata}>
                    {template.Name}
                    <div className="template-external-resources">
                        <small>
                            <span>
                                <strong>Version {template.Version}</strong>
                            </span>
                            <span className={styles.divider}>|</span>
                            <span>
                                By <ExternalLink href={`https://github.com/${template.Author}`}>{template.Author}</ExternalLink>
                                <em className={cn("fa", "fa-users", styles.icon)} />
                            </span>
                            <span className={styles.divider}>|</span>
                            <span>
                                <ExternalLink href={template.Website}>View website</ExternalLink> <em className={cn("fa", "fa-globe", styles.icon)} />
                            </span>
                        </small>
                    </div>
                </div>
            </div>
        );
    };

    private extractProjectId(): string {
        const fullUrl = new URI(window.location);
        const relativeUrl = new URI(fullUrl.fragment());
        let projectId: string = null!;
        relativeUrl.hasQuery("projectId", (value: string) => {
            projectId = value;
        });

        return projectId;
    }

    private installOrUpdate = async () => {
        await this.doBusyTask(async () => {
            const actionTemplate = this.state.isUpdateAvailable ? await repository.CommunityActionTemplates.updateInstallation(this.state.template!) : await repository.CommunityActionTemplates.install(this.state.template!);
            const url = this.state.projectIdToComeBackTo ? routeLinks.project(this.state.projectIdToComeBackTo).deployments.process.new(actionTemplate.ActionType, null!, actionTemplate.Id) : routeLinks.library.stepTemplate(actionTemplate).root;
            this.setState({ redirectTo: url });
        });
    };

    private getSourceCode(template: CommunityActionTemplateResource) {
        if (template.Type !== "Octopus.Script") {
            return "Source code is not available for this step template because it is not a script template.";
        }

        return template.Properties["Octopus.Action.Script.ScriptBody"] ? (template.Properties["Octopus.Action.Script.ScriptBody"] as string) : "Not available";
    }

    private getSourceCodeSyntax(template: CommunityActionTemplateResource) {
        return template.Properties["Octopus.Action.Script.Syntax"] ? (template.Properties["Octopus.Action.Script.Syntax"] as ScriptingLanguage) : ScriptingLanguage.PowerShell;
    }

    private async currentlyInstalledVersion(template: CommunityActionTemplateResource) {
        try {
            const actionTemplate = await repository.ActionTemplates.getByCommunityTemplate(template);
            return actionTemplate.Version;
        } catch (error) {
            if (error.StatusCode === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }

    private getLabel() {
        const primaryAction = this.state.isUpdateAvailable ? "Update" : "Install";
        const secondaryAction = this.state.projectIdToComeBackTo ? " and add step" : "";

        return primaryAction + secondaryAction;
    }
}
