import * as React from "react";
import pluginRegistry, { ActionEditProps } from "components/Actions/pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import WildflyFeatureTemplate, { WildflyStateProperties } from "components/Actions/wildfly/wildflyFeatureTemplate";

class DeplotToWildflyFeature extends BaseComponent<ActionEditProps<WildflyStateProperties>, never> {
    render() {
        return (
            <WildflyFeatureTemplate
                actionDescription={"Deploying"}
                domainDefaultMessage={"Deploying an application to a domain."}
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                projectId={this.props.projectId}
                applicationNameNote={
                    <span>
                        This optional field can be used to override the name of the deployed artifact. For example, setting this field to <em>myapp.war</em> will result in the deployment having that name in WildFly regardless of the name of the
                        package that was selected above.
                    </span>
                }
                applicationStateNote={
                    <span>
                        This option allows you to deploy the application in either an enabled or disabled state to a standalone server. This value has no effect when deploying to domain servers. Set the <em>Standalone or Domain Server</em> option
                        to&nbsp;
                        <em>Domain</em> to see the <em>Enabled Server Groups</em> and <em>Disabled Server Groups</em> options, which allow you to configure the state of the application during a deployment to a domain.
                    </span>
                }
                applicationServerHostnameNote={<span>This is the hostname or IP address of the application server that the package will be uploaded to.</span>}
                expandedByDefault={this.props.expandedByDefault}
            />
        );
    }
}

pluginRegistry.registerFeatureForAllScopes({
    featureName: "Octopus.Features.WildflyDeployCLI",
    title: "Wildfly deployment feature",
    description: "Deploys packages to Wildfly via the manager API",
    edit: DeplotToWildflyFeature,
    priority: 1,
});
