import React from "react";
import FlatButton from "material-ui/FlatButton";
import ToolTip from "components/ToolTip/index";
import { SharedButtonProps } from "./types";
import { iconButtonTestId } from "uiTestIds";
const styles = require("./styles.less");

interface IconButtonWithToolTipProps extends SharedButtonProps {
    icon: JSX.Element;
}

export const IconButtonWithToolTip: React.FC<IconButtonWithToolTipProps> = ({ toolTipContent, disabled, tabIndex, onClick, buttonRef, style, icon }) => {
    return (
        <ToolTip content={toolTipContent}>
            <FlatButton octopus-test-id={iconButtonTestId} className={styles.materialUIIcon} disabled={disabled} tabIndex={tabIndex} onClick={onClick} ref={buttonRef} label={icon} style={style} />
        </ToolTip>
    );
};

export default IconButtonWithToolTip;
