/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { Permission } from "client/resources";
import Callout, { CalloutType } from "components/Callout";
import { ScriptModulesWithActions, ScriptModule } from "../ScriptModules";
import { PermissionCheck } from "components/PermissionCheck";

interface RunbookProcessOverviewSidebarProps {
    projectId: string;
    includedScriptModules: ScriptModule[];
    onDataChanged: () => Promise<any>;
}

export const RunbookProcessOverviewSidebar: React.FC<RunbookProcessOverviewSidebarProps> = props => {
    return (
        <PermissionCheck
            permission={Permission.LibraryVariableSetView}
            wildcard={true}
            alternate={
                <Callout type={CalloutType.Information} title={"Permission required"}>
                    The {Permission.LibraryVariableSetView} permission is required to view any included script modules
                </Callout>
            }
        >
            {props.includedScriptModules && (
                <div>
                    <ScriptModulesWithActions projectId={props.projectId} includedScriptModules={props.includedScriptModules} onDataChanged={props.onDataChanged} />
                </div>
            )}
        </PermissionCheck>
    );
};
