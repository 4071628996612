/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { ExtensionsInfoResource } from "client/resources/extensionsInfoResource";
import { SFC } from "react";
import { SimpleList } from "components/List/SimpleList";
const text = require("resources/styles/text.less");

interface ServerExtensionsProps {
    extensions: ExtensionsInfoResource[];
}

class ExtensionsInfoList extends SimpleList<ExtensionsInfoResource> {}

const ServerExtensions: SFC<ServerExtensionsProps> = props => {
    return (
        <div>
            <h4>Server Extensions</h4>
            <ExtensionsInfoList
                items={props.extensions}
                onFilter={null!}
                onRow={ext => (
                    <div>
                        {ext.Name}&nbsp;
                        <small>{ext.Version}</small>
                        <br />
                        <small className={text.secondary}>by {ext.Author}</small>
                    </div>
                )}
            />
        </div>
    );
};

ServerExtensions.displayName = "ServerExtensions";

export default ServerExtensions;
