/* eslint-disable @typescript-eslint/init-declarations */

import * as React from "react";
import pluginRegistry, { ActionScope, ActionPlugin } from "components/Actions/pluginRegistry";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { ProcessStepsLayoutLoaderLookupData } from "./ProcessStepsLayoutLoader";
import { Errors } from "components/DataBaseComponent";
import * as H from "history";
import { match as Match, RouteComponentProps } from "react-router-dom";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { withProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import { WithProcessContextInjectedProps, withProcessContext, loadProcess } from "./Contexts/ProcessContext";
import { IProcessResource, GetPrimaryPackageReference, ProcessType } from "client/resources";
import routeLinks from "routeLinks";
import { withOptionalRunbookContext, WithOptionalRunbookContextInjectedProps } from "../Runbooks/RunbookContext";
import {
    whereToRun,
    calculateEnvironmentSelection,
    loadAvailableWorkerPools,
    processScopedEditPermission,
    assembleParentStep,
    assembleExistingAction,
    assembleNewAction,
    getCommonOverflowMenuItems,
    mergeProcesses,
    convertProcessTypeToActionScope,
    NoMergeRequiredResult,
    MergedProcessResult,
    isRunOnDeploymentTarget,
    isRunOnServerOrWorkerPool,
} from "./Common/CommonProcessHelpers";
import { repository } from "clientInstance";
import ProcessSidebarLayout from "./ProcessSidebarLayout";
import PageTitleHelper from "utils/PageTitleHelper";
import { StoredStep, StoredAction, ProcessFilter, RunOnDeploymentTarget, ExecutionLocation, EnvironmentOption, AssembledAction } from "./types";
import { EnhancedProcessContextFormPaperLayout } from "./CustomPaperLayouts/ProcessContextFormPaperLayout";
import { EnhancedProcessActionDetailsPage, EnhancedProcessParentStepDetailsPage, EnhancedActionTemplateSelectionPage } from "./Pages";
import { ProcessPaperLayout } from "./CustomPaperLayouts/ProcessPaperLayout";
import { noOp } from "utils/noOp";
import { ExpansionButtons } from "components/form";
import InternalRedirect from "components/Navigation/InternalRedirect";
import { ProcessErrorActions, WithProcessErrorActionsContextInjectedProps, withProcessErrorActionsContext } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import Logger from "client/logger";
import { NoResults } from "components/NoResults/NoResults";
import { withProcessQueryStringContext, WithProcessQueryStringContextInjectedProps } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import BusyIndicator from "components/BusyIndicator";
import { memoize } from "lodash";
import { Section } from "components/Section/Section";
import { ProcessStepLookupState, ProcessStepActionState } from "./ProcessStepsLayoutTypes";
import { NothingToSeeHere } from "components/NothingToSeeHere";
import { Fade } from "@material-ui/core";
import ProcessesMergedDialog from "./ProcessesMergedDialog";
import Callout, { CalloutType } from "components/Callout";
import CommitButton from "../VersionControl/CommitButton";

export enum ProcessPageIntent {
    Unknown = "Unknown",
    ChooseStepTemplates = "ChooseStepTemplates",
    ChooseChildStepTemplates = "ChooseChildStepTemplates",
    CreateNewAction = "CreateNewAction",
    CreateNewChildAction = "CreateNewChildAction",
    ViewAction = "ViewAction",
    ViewParentStep = "ViewParentStep",
}

export function isIntentToCreateNew(intent: ProcessPageIntent) {
    return intent === ProcessPageIntent.CreateNewAction || intent === ProcessPageIntent.CreateNewChildAction;
}

function intentFromFilter(filter: ProcessFilter): ProcessPageIntent {
    if (filter.new && filter.actionType) {
        if (filter.parentStepId) {
            return ProcessPageIntent.CreateNewChildAction;
        }
        return ProcessPageIntent.CreateNewAction;
    }
    if (filter.stepTemplates) {
        return ProcessPageIntent.ChooseStepTemplates;
    }
    if (filter.childStepTemplates && filter.parentStepId) {
        return ProcessPageIntent.ChooseChildStepTemplates;
    }
    if (filter.actionId) {
        return ProcessPageIntent.ViewAction;
    }
    if (filter.parentStepId) {
        return ProcessPageIntent.ViewParentStep;
    }
    return ProcessPageIntent.Unknown;
}

export interface ProcessStepActionData {
    stepLookups: ProcessStepLookupState;
    stepOther: ProcessStepActionState;
    step: StoredStep;
    action: StoredAction;
}

export interface ProcessParentStepData {
    stepNumber: string;
    step: StoredStep;
    machineRoles: string[];
    isFirstStep: boolean;
    errors?: Errors | undefined;
}

interface ProcessPageProps {
    lookups: ProcessStepsLayoutLoaderLookupData;
    errors?: Errors;
    busy?: Promise<void>;
    doBusyTask: DoBusyTask;
    history: H.History;
    location: H.Location;
    match: Match<ProjectRouteParams>;
    isBuiltInWorkerEnabled: boolean;
}

interface ProcessPageState {
    actionData: ProcessStepActionData | null;
    parentStepData: ProcessParentStepData | null;
    redirectTo?: string;
}

type Props = RouteComponentProps<ProjectRouteParams> &
    ProcessPageProps &
    WithProjectContextInjectedProps &
    WithProcessContextInjectedProps &
    WithOptionalRunbookContextInjectedProps &
    WithProcessErrorActionsContextInjectedProps &
    WithProcessQueryStringContextInjectedProps;

class ProcessStepsLayout extends React.Component<Props, ProcessPageState> {
    private isAddingStep = false;
    private isLoadingDataForActionId: string | undefined = undefined;
    private isLoadingDataForParentStepId: string | undefined = undefined;
    private memoizedLoadingIndicatorForStep: () => JSX.Element = memoize(() => this.loadingIndicatorForStep());

    constructor(props: Props) {
        super(props);
        this.state = {
            actionData: null,
            parentStepData: null,
        };
    }

    // markse: @Performance - I've tried splitting this up into a shouldComponentUpdate and having the guard conditions in there to save renders. Doing this does
    // save some re-renders, but makes the code much less readable. I decided to let readability win here, in order to get DX adoption. But splitting
    // this up is an option if we want to investigate some perf gains.
    async componentDidUpdate() {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return;
        }

        if (!this.canSafelyNavigateToFilter(this.props.processQueryStringContext.state.queryFilter)) {
            Logger.warn("Failed to find action in context.");
            this.props.processQueryStringContext.actions.showEmptyStepEditor();
            return;
        }

        const currentIntent = intentFromFilter(this.props.processQueryStringContext.state.queryFilter);
        switch (currentIntent) {
            case ProcessPageIntent.ViewAction:
                {
                    const { actionId, actionType, templateId } = this.props.processQueryStringContext.state.queryFilter;
                    const guardAgainstAlreadyLoading = this.isLoadingDataForActionId === actionId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = this.filtersAreAlignedWithActionData(actionId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }

                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForActionId = actionId;
                        let actionData: ProcessStepActionData | null = null;
                        try {
                            actionData = await this.loadActionData(actionId, actionType, templateId, currentIntent);
                        } finally {
                            this.isLoadingDataForActionId = undefined;
                            this.setState({ actionData, parentStepData: null });
                        }
                    });
                }
                break;
            case ProcessPageIntent.ViewParentStep:
                {
                    const parentStepId = this.props.processQueryStringContext.state.queryFilter.parentStepId;
                    const guardAgainstAlreadyLoading = this.isLoadingDataForParentStepId === parentStepId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = !parentStepId || this.filtersAreAlignedWithParentStepData(parentStepId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }

                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForParentStepId = parentStepId;
                        let parentStepData: ProcessParentStepData | null = null;
                        try {
                            parentStepData = await this.loadParentStepData();
                        } finally {
                            this.isLoadingDataForParentStepId = undefined;
                            this.setState({ parentStepData, actionData: null });
                        }
                    });
                }
                break;
            case ProcessPageIntent.CreateNewChildAction:
            case ProcessPageIntent.CreateNewAction:
                {
                    if (this.isAddingStep) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isAddingStep = true;
                        let actionData: ProcessStepActionData | null = null;
                        try {
                            const { actionId, actionType, templateId } = this.props.processQueryStringContext.state.queryFilter;
                            actionData = await this.loadActionData(actionId, actionType, templateId, currentIntent);
                            if (actionData && actionData.step && actionData.action) {
                                const step = actionData.step;
                                const action = actionData.action;
                                if (currentIntent === ProcessPageIntent.CreateNewAction) {
                                    this.props.processContext.actions.addStep(step, action);
                                } else if (currentIntent === ProcessPageIntent.CreateNewChildAction && this.props.processQueryStringContext.state.queryFilter.parentStepId) {
                                    this.props.processContext.actions.addChildAction(this.props.processQueryStringContext.state.queryFilter.parentStepId, actionData.action);
                                }
                                this.setState({ parentStepData: null, actionData: null }, () => this.props.processQueryStringContext.actions.showProcessAction(action.Id));
                            } else {
                                throw Error("Failed to create step or action.");
                            }
                        } finally {
                            this.isAddingStep = false;
                            if (actionData && actionData.action) {
                                const action = actionData.action;
                                this.setState({ parentStepData: null, actionData: null }, () => this.props.processQueryStringContext.actions.showProcessAction(action.Id));
                            } else {
                                this.setState({ parentStepData: null, actionData: null });
                            }
                        }
                    });
                }
                break;
        }
    }

    filtersAreAlignedWithActionData = (actionId: string | undefined): boolean => {
        const { actionData: currentActionData } = this.state;
        return !!actionId && !!currentActionData && currentActionData.action.Id === actionId;
    };

    filtersAreAlignedWithParentStepData = (parentStepId: string): boolean => {
        const { parentStepData: currentParentStepData } = this.state;
        return !!parentStepId && !!currentParentStepData && currentParentStepData.step.Id === parentStepId;
    };

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const processContext = this.props.processContext;
        const { model: project, branch, projectContextRepository } = this.props.projectContext.state;
        const { selectors, actions } = processContext;
        const processType = selectors.getProcessType();
        const isVersionControlled = project.IsVersionControlled && processType === ProcessType.Deployment;

        const actionLabel = isVersionControlled ? "Commit" : "Save";

        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData() || !this.canSafelyNavigateToFilter(this.props.processQueryStringContext.state.queryFilter)) {
            return <EnhancedProcessContextFormPaperLayout busy={true} doBusyTask={this.props.doBusyTask} disableAnimations={true} model={undefined} cleanModel={undefined} onSaveClick={noOp} saveButtonLabel={actionLabel} />;
        }

        const runbook = this.props.runbookContext?.state?.runbook;
        const overflowMenuItems = getCommonOverflowMenuItems(projectContextRepository, project, runbook, processType, selectors, actions, this.props.processErrorActions, this.redirectToList);

        const intent = intentFromFilter(this.props.processQueryStringContext.state.queryFilter);
        const filter = this.props.processQueryStringContext.state.queryFilter;
        let layout: React.ReactNode = null;
        if ((intent === ProcessPageIntent.CreateNewAction || intent === ProcessPageIntent.CreateNewChildAction) && filter.new && filter.actionType) {
            layout = <ProcessPaperLayout processType={processType} busy={true} />;
        } else if (intent === ProcessPageIntent.ChooseStepTemplates && filter.stepTemplates) {
            layout = this.renderActionTemplateSelectionPage();
        } else if (intent === ProcessPageIntent.ChooseChildStepTemplates && filter.childStepTemplates && filter.parentStepId) {
            layout = this.renderActionTemplateSelectionPage(filter.parentStepId);
        } else if (intent === ProcessPageIntent.ViewAction && filter.actionId) {
            if (filter.actionId === this.state.actionData?.action.Id) {
                layout = this.renderProcessStepDetailsPage(intent);
            } else {
                layout = this.memoizedLoadingIndicatorForStep;
            }
        } else if (intent === ProcessPageIntent.ViewParentStep && filter.parentStepId) {
            if (filter.parentStepId === this.state.parentStepData?.step.Id) {
                layout = this.renderProcessStepDetailsPage(intent);
            } else {
                layout = this.memoizedLoadingIndicatorForStep;
            }
        } else {
            Logger.info("Failed to determine layout for intent. Assuming no results.");
            layout = (
                <>
                    <NothingToSeeHere content="Step does not exist" />
                    <NoResults />
                </>
            );
        }

        const isNew = isIntentToCreateNew(intent);
        const innerLayout = (
            <ProcessSidebarLayout
                actionId={filter.actionId}
                parentStepId={filter.parentStepId}
                isNew={isNew}
                id={selectors.getStoredProcess().Id}
                doBusyTask={this.props.doBusyTask}
                isAddStepDisabled={isNew}
                render={() => {
                    const animationReloadKey = !!filter.actionId ? filter.actionId : filter.parentStepId;
                    return (
                        <Fade in={true} mountOnEnter unmountOnExit key={animationReloadKey}>
                            <div>{layout}</div>
                        </Fade>
                    );
                }}
                history={this.props.history}
                location={this.props.location}
                match={this.props.match}
                busy={this.props.busy}
                errors={this.props.errors}
            />
        );

        return (
            <ProcessErrorActions>
                {errorActions => {
                    const saveProcess = async (message?: string) => {
                        //TODO: this merge conflict logic can be moved into a side effect in the reducer if we
                        if (!processContext.state.model.process?.Id) {
                            throw Error("Failed to find processId");
                        }

                        //TODO: markse - Move this into an onSave method that we can test in isolation.
                        const { cleanModelProcess: clientCleanProcessResource, modelProcess: clientProcessResource } = processContext.selectors.getProcessesForMerge();
                        this.applyCommonLogicToProcessResource(clientProcessResource);
                        this.applyCommonLogicToProcessResource(clientCleanProcessResource);

                        const serverProcessResource = await loadProcess(projectContextRepository, processContext.selectors.getProcessType(), processContext.state.model.process.Id);
                        const mergeResult = mergeProcesses(clientCleanProcessResource, clientProcessResource, serverProcessResource);

                        if (mergeResult instanceof NoMergeRequiredResult) {
                            await processContext.actions.saveOnServer(
                                projectContextRepository,
                                mergeResult.value,
                                errors => errorActions.setErrors(errors, processContext.selectors),
                                () => errorActions.clearErrors(),
                                message
                            );
                        } else if (mergeResult instanceof MergedProcessResult) {
                            processContext.actions.conflictDetected(serverProcessResource, mergeResult.value);
                        }
                    };

                    const onCommit = async (message: string) => {
                        await saveProcess(message);
                        return !this.props.errors;
                    };

                    const commitButton = isVersionControlled && <CommitButton branchName={branch?.Name ?? project.VersionControlSettings.DefaultBranch} defaultSummary="Update deployment process" onCommit={onCommit} />;

                    return (
                        <EnhancedProcessContextFormPaperLayout
                            model={selectors.getModel()}
                            cleanModel={selectors.getCleanModel()}
                            busy={this.props.busy}
                            doBusyTask={this.props.doBusyTask}
                            errors={this.props.errors} // We have to pass errors here for our ConfirmNavigate to function correctly.
                            hideErrors={true} // We have custom error handling for our process form.
                            savePermission={{
                                permission: processScopedEditPermission(processContext.selectors.getProcessType()),
                                project: project.Id,
                                wildcard: true,
                            }}
                            hideExpandAll={true} // We position these manually due to a custom process layout.
                            saveButtonLabel={actionLabel}
                            dirtyTrackingKey="ProcessForm"
                            onSaveClick={saveProcess}
                            overFlowActions={overflowMenuItems}
                            disableScrollToActiveError={true} // We have custom error handling for our process form.
                            disableAnimations={true}
                            customPrimaryAction={commitButton}
                            confirmNavigateSaveLabel={`${actionLabel} Changes`}
                        >
                            <ProcessesMergedDialog
                                open={selectors.isMerging() && !selectors.isMergeDialogClosed()}
                                onClose={actions.mergeDialogClosed}
                                onDiscard={actions.discardedChanges}
                                onMerge={actions.mergedProcess}
                                onAcceptClientChanges={actions.acceptedClientChanges}
                            />
                            {selectors.isProcessMerged() && (
                                <Callout title="Action Required" type={CalloutType.Warning}>
                                    This process has been merged with the server process but has not been saved. Please review the process before saving.
                                </Callout>
                            )}
                            {innerLayout}
                        </EnhancedProcessContextFormPaperLayout>
                    );
                }}
            </ProcessErrorActions>
        );
    }

    private loadingIndicatorForStep = () => {
        return (
            <Section>
                <BusyIndicator inline={true} show={true} />
            </Section>
        );
    };

    private redirectToList = () => {
        const type = this.props.processContext.selectors.getProcessType();
        if (type === ProcessType.Deployment) {
            const redirectTo = routeLinks.project(this.props.match.params.projectSlug).deployments.process.root;
            this.setState({ redirectTo });
        } else {
            const runbook = this.props.runbookContext?.state.runbook;
            if (runbook) {
                const redirectTo = routeLinks
                    .project(this.props.match.params.projectSlug)
                    .operations.runbook(runbook.Id)
                    .runbookProcess.runbookProcess(runbook.RunbookProcessId).root;
                this.setState({ redirectTo });
            }
        }
    };

    private canSafelyNavigateToFilter(filter: ProcessFilter): boolean {
        // Check if this action actually exists in our context (the user may have refreshed the screen and not yet saved, so our filter and context are out of sync).
        const { actionId, parentStepId } = filter;
        if (actionId && this.props.processContext.selectors.isReady() && !this.props.processContext.selectors.tryGetActionById(actionId)) {
            return false;
        }
        if (parentStepId && this.props.processContext.selectors.isReady() && !this.props.processContext.selectors.tryGetStepById(parentStepId)) {
            return false;
        }
        return true;
    }

    // @Cleanup: markse - This was common logic we were previously applying at the point of saving a single action. Flagging for later review.
    // For multi-step editing, we now loop over all actions and apply to each. Now sure how I feel about this happening right at the point of save.
    private applyCommonLogicToProcessResource(process: IProcessResource) {
        const availableWorkerPools = loadAvailableWorkerPools(this.props.lookups.workerPoolsSummary);
        const { selectors } = this.props.processContext;

        process.Steps.forEach(step => {
            step.Actions.forEach(action => {
                const plugin = selectors.getActionPluginByTypeName(action.ActionType);
                const runOn = whereToRun(!!step.Properties["Octopus.Action.TargetRoles"], action, availableWorkerPools, plugin, this.props.isBuiltInWorkerEnabled);
                if (runOn) {
                    if (!isRunOnServerOrWorkerPool(runOn)) {
                        action.Container = { FeedId: null, Image: null };
                    } else {
                        if (runOn.executionLocation === ExecutionLocation.OctopusServer || runOn.executionLocation === ExecutionLocation.WorkerPool) {
                            step.Properties["Octopus.Action.TargetRoles"] = "";
                        }
                        if (runOn.executionLocation !== ExecutionLocation.WorkerPool && runOn.executionLocation !== ExecutionLocation.WorkerPoolForRoles) {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            action.WorkerPoolId = null!;
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            action.WorkerPoolVariable = null!;
                        }
                        action.Container = runOn.container;
                    }
                }

                if (!action.Name || action.Name.length === 0) {
                    const primaryPackage = GetPrimaryPackageReference(action.Packages);
                    if (primaryPackage) {
                        action.Name = primaryPackage.PackageId;
                    }
                }
            });
        });
    }

    private loadActionData = async (actionId: string | undefined, actionType: string | undefined, templateId: string | undefined, intent: ProcessPageIntent): Promise<ProcessStepActionData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            Logger.log("Loading...");
            return null;
        }

        const scope = convertProcessTypeToActionScope(this.props.processContext.selectors.getProcessType());
        let plugin: ActionPlugin | null = null;
        if (actionId) {
            plugin = this.props.processContext.selectors.getActionPlugin(actionId);
        } else if (actionType) {
            plugin = pluginRegistry.getAction(actionType, scope);
        }

        if (!plugin) {
            throw new Error("Failed to load plugin.");
        }

        const { feeds, actionTemplates } = this.props.processContext.lookupsState;
        const processContextSelectors = this.props.processContext.selectors;

        const isNew = isIntentToCreateNew(intent);
        let result: AssembledAction;
        if (isNew) {
            if (!actionType) {
                throw Error("No action type was provided");
            }
            result = await assembleNewAction(actionType, scope, plugin, actionTemplates, templateId, feeds);
        } else {
            if (!actionId) {
                throw Error("Missing action id");
            }
            result = assembleExistingAction(actionId, processContextSelectors, actionTemplates, feeds);
        }

        PageTitleHelper.setPageTitle(result.pageTitle);

        if (!result.action) {
            Logger.log(result);
            throw new Error("Expecting an action to exist.");
        }

        const stepLookups = await this.loadLookupData(result.action);
        const stepOther: ProcessStepActionState = {
            plugin,
            actionTypeName: result.actionTypeName,
            pageTitle: result.pageTitle,
            isBuiltInWorkerEnabled: this.props.isBuiltInWorkerEnabled,
            environmentOption: (result.action.Environments || []).length > 0 ? EnvironmentOption.Include : (result.action.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All,
            runOn: result.action && plugin ? whereToRun(!!result.step.Properties["Octopus.Action.TargetRoles"], result.action, stepLookups.availableWorkerPools, plugin, this.props.isBuiltInWorkerEnabled) : null,
            environmentSelection: calculateEnvironmentSelection(result.action, this.props.lookups.environmentsById),
        };

        return { stepLookups, stepOther, step: result.step, action: result.action };
    };

    private loadParentStepData = async (): Promise<ProcessParentStepData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            Logger.log("Loading...");
            return null;
        }

        const { parentStepId } = this.props.processQueryStringContext.state.queryFilter;
        if (!parentStepId) {
            throw new Error("Failed to find parentStepId");
        }

        const processContextSelectors = this.props.processContext.selectors;
        const result = assembleParentStep(parentStepId, processContextSelectors);
        PageTitleHelper.setPageTitle(result.pageTitle);

        const stepLookups = await this.loadLookupData(null);
        const stepNumber = this.props.processContext.selectors.getStepNumber(result.step.Id);
        const isFirstStep = this.props.processContext.selectors.isFirstStep(result.step.Id);

        return { stepNumber: stepNumber.toString(), step: result.step, machineRoles: stepLookups.machineRoles, isFirstStep };
    };

    private hasLoadedContexts(): boolean {
        const processContextHasLoaded = this.props.processContext.selectors.isReady();
        const projectContextHasLoaded = !!this.props.projectContext.state.model;
        const processQueryStringContextHasLoaded = !!this.props.processQueryStringContext.state.queryFilter;
        return processContextHasLoaded && projectContextHasLoaded && processQueryStringContextHasLoaded;
    }

    private hasLoadedNecessaryLookupData(): boolean {
        const { actionTemplates, feeds } = this.props.processContext.lookupsState;
        return actionTemplates && actionTemplates.length > 0 && feeds && feeds.length > 0;
    }

    private async loadLookupData(action: StoredAction | null): Promise<ProcessStepLookupState> {
        const getTemplate = this.props.processQueryStringContext.state.queryFilter.templateId
            ? repository.ActionTemplates.get(this.props.processQueryStringContext.state.queryFilter.templateId)
            : action && action.Properties["Octopus.Action.Template.Id"]
            ? repository.ActionTemplates.get(action.Properties["Octopus.Action.Template.Id"].toString())
            : Promise.resolve(null);

        const actionTemplate = await getTemplate;

        const lookups: ProcessStepLookupState = {
            environments: Object.values(this.props.lookups.environmentsById),
            machineRoles: this.props.lookups.machineRoles,
            availableWorkerPools: loadAvailableWorkerPools(this.props.lookups.workerPoolsSummary),
            tagIndex: this.props.lookups.tagIndex,
            actionTemplates: this.props.processContext.lookupsState.actionTemplates,
            actionTemplate,
            channels: this.props.lookups.channelsById ? Object.values(this.props.lookups.channelsById) : [],
        };

        return lookups;
    }

    private renderProcessStepDetailsPage = (intent: ProcessPageIntent) => {
        const { errors, history, location, match, processContext } = this.props;
        const processType = processContext.selectors.getProcessType();

        if (this.state.actionData && this.state.actionData.stepOther && this.state.actionData.stepLookups && this.state.actionData.action) {
            const actionId = this.state.actionData.action.Id;
            const { selectors } = this.props.processContext;
            const isNew = isIntentToCreateNew(intent) || selectors.isNewAction(actionId);

            const action = selectors.getActionById(actionId);
            const step = selectors.getStepById(action.ParentId);
            return (
                <EnhancedProcessActionDetailsPage
                    doBusyTask={this.props.doBusyTask}
                    step={step}
                    action={action}
                    stepLookups={this.state.actionData.stepLookups}
                    stepOther={this.state.actionData.stepOther}
                    processType={processType}
                    isNew={isNew}
                    errors={errors}
                    refreshStepLookups={async () => {
                        await this.props.doBusyTask(async () => {
                            const stepLookups = await this.loadLookupData(selectors.getActionById(actionId));
                            const actionData = this.state.actionData;
                            if (actionData) {
                                actionData.stepLookups = stepLookups;
                                this.setState({ actionData });
                            }
                        });
                    }}
                />
            );
        } else if (this.state.parentStepData && this.state.parentStepData.step) {
            const { selectors } = this.props.processContext;
            const step = selectors.getStepById(this.state.parentStepData.step.Id);
            const isNew = isIntentToCreateNew(intent);

            return (
                <EnhancedProcessParentStepDetailsPage
                    processType={processType}
                    stepNumber={this.state.parentStepData.stepNumber}
                    step={step}
                    machineRoles={this.state.parentStepData.machineRoles}
                    isFirstStep={this.state.parentStepData.isFirstStep}
                    isNew={isNew}
                    errors={errors}
                    history={history}
                    location={location}
                    match={match}
                />
            );
        }
        return <ProcessPaperLayout processType={processType} busy={true} />;
    };

    private renderActionTemplateSelectionPage = (parentStepId?: string) => {
        const { busy, errors, history, location, match } = this.props;
        const processType = this.props.processContext.selectors.getProcessType();
        return <EnhancedActionTemplateSelectionPage processType={processType} parentStepId={parentStepId} busy={busy} errors={errors} history={history} location={location} match={match} />;
    };
}

export default withProjectContext(withOptionalRunbookContext(withProcessContext(withProcessQueryStringContext(withProcessErrorActionsContext(ProcessStepsLayout)))));
