import React from "react";
import { makeStyles } from "@material-ui/styles";
import { OctopusTheme, useOctopusTheme } from "components/Theme";
import ActionButton, { ActionButtonType } from "components/Button";

interface IPanel<TProps = {}> {
    title: React.ReactNode;
    component: React.ComponentType<TProps>;
    props: TProps;
}

export const usePanelStack = () => {
    const [panels, setPanels] = React.useState<IPanel[]>([]);

    const openPanel = React.useCallback(
        <TProps extends {}>(panel: IPanel<TProps>) => {
            setPanels(prev => [...prev, panel]);
        },
        [setPanels]
    );

    const closePanel = React.useCallback(() => {
        setPanels(prev => {
            if (prev.length >= 1) {
                return prev.slice(0, prev.length - 1);
            }
            return prev;
        });
    }, [setPanels]);

    return {
        openPanel,
        closePanel,
        panels,
    };
};

export interface PanelActions {
    closePanel: () => void;
    openPanel: <TProps extends {}>(panel: IPanel<TProps>) => void;
}

export type PanelStackRenderProps = PanelActions;

export interface PanelStackProps {
    onOpenPanel: <TProps extends {}>(panel: IPanel<TProps>) => void;
    onClosePanel: () => void;
    panels: IPanel[];
    renderInitial: (renderProps: PanelStackRenderProps) => React.ReactNode;
}

const useTitleBarStyles = makeStyles(theme => ({
    root: (props: { octopusTheme: OctopusTheme }) => ({
        backgroundColor: props.octopusTheme.paper1,
        height: "48/16rem", // This matches our NavBar.navbar height
        display: "flex",
        flexDdirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `0.0625rem solid ${props.octopusTheme.divider}`,
    }),
    rowSpaced: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 1rem",
    },
    title: {
        flexGrow: 1,
        fontSize: "1.25rem",
        "& > h1, h2": {
            fontSize: "1.25rem",
            margin: 0,
            padding: 0,
        },
    },
}));

interface PanelStackTitleBarProps extends PanelActions {
    title: React.ReactNode;
}

const PanelStackTitleBar: React.FC<PanelStackTitleBarProps> = props => {
    const octopusTheme = useOctopusTheme();
    const classes = useTitleBarStyles({ octopusTheme });

    return (
        <div className={classes.root}>
            <div className={classes.rowSpaced}>
                <div className={classes.title}>
                    <ActionButton onClick={props.closePanel} label={"Back"} type={ActionButtonType.Ternary} style={{ display: "inline-block" }} />
                    <h2>{props.title}</h2>
                </div>
            </div>
        </div>
    );
};

export const PanelStack: React.FC<PanelStackProps> = props => {
    if (props.panels.length === 0) {
        return <React.Fragment>{props.renderInitial({ closePanel: props.onClosePanel, openPanel: props.onOpenPanel })}</React.Fragment>;
    }

    const { component: PanelView, title, props: viewProps } = props.panels[props.panels.length - 1];

    return (
        <div>
            <PanelStackTitleBar title={title} closePanel={props.onClosePanel} openPanel={props.onOpenPanel} />
            <PanelView {...viewProps} />
        </div>
    );
};

export default PanelStack;
