/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { ListItem } from "material-ui";
import { withTheme } from "components/Theme";

const BorderedListItem: React.SFC<any> = props =>
    withTheme(theme => (
        <ListItem
            style={{
                borderBottom: "0.0625rem solid",
                borderColor: theme.divider,
            }}
            {...props}
        >
            {props.children}
        </ListItem>
    ));

export default BorderedListItem;
