/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { ProjectResource, ProjectGroupResource, TenantedDeploymentMode, ChannelResource, ResourceCollection, DeploymentProcessResource, Permission, GuidedFailureMode, ProjectSettingsMetadata, SensitiveValue } from "client/resources";
import { repository } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";

import {
    Text,
    RadioButton,
    BooleanRadioButtonGroup,
    StringRadioButtonGroup,
    Select,
    ExpandableFormSection,
    Summary,
    SummaryNode,
    MarkdownEditor,
    MoreInfo,
    LogoEditor,
    Note,
    LogoEditorSettings,
    Checkbox,
    FormSectionHeading,
    UnstructuredFormSection,
} from "components/form";
import { required } from "components/form/Validators";
import { connect } from "react-redux";
import { RoleMultiSelect } from "components/MultiSelect";
import Markdown from "components/Markdown";
import { RouteComponentProps } from "react-router";
import { PermissionCheckProps, isAllowed } from "components/PermissionCheck/PermissionCheck";
import Logo from "components/Logo/Logo";
import { saveLogo } from "client/repositories/logoUpload";
import { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { RoleChip } from "components/Chips";
import AddProject from "areas/projects/components/Projects/AddProject";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import routeLinks from "routeLinks";
import Callout, { CalloutType } from "components/Callout/Callout";
import FailureMode from "areas/projects/components/Releases/Deployments/FailureMode";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "components/Navigation/InternalLink";
import { List } from "components/List/List";
import ListTitle from "components/ListTitle";
import Section from "components/Section";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { displayName, DeploymentActionPackageResource, deploymentActionPackages } from "client/resources/deploymentActionPackageResource";
import * as _ from "lodash";
import DynamicForm from "components/DynamicForm/DynamicForm";
import { ExtensionSettingsValues } from "client/resources/extensionSettingsValues";
import { cloneDeep, flatten } from "lodash";
import DeleteProject from "./DeleteProject";
import { withProjectContext, WithProjectContextInjectedProps } from "../../context";
import { DeploymentModelType } from "../Runbooks/RunbookRunNowLayout";
import { OverviewRedirectPathForProject, IsAllowedToSeeDeploymentsOverview } from "../ProjectsRoutes/ProjectToOverviewRedirect";
import configurationSelectors from "areas/configuration/reducers/selectors";

enum CloneVisibility {
    NotCloned = "NotCloned",
    Available = "Available",
    NotFound = "NotFound",
    AccessDenied = "AccessDenied",
}

interface ClonedFromProjectDetails {
    clonedFromProject: ProjectResource;
    cloneVisibility: CloneVisibility;
}

interface ProjectModel {
    name: string;
    description: string;
    projectGroupId: string | undefined;
    deploymentProcessId: string;
    versionFromPackage: boolean;
    skipIfAlreadyInstalled: boolean;
    tenantedDeploymentMode: TenantedDeploymentMode;
    versioningStrategyTemplate: string;
    versioningStrategyPackage: DeploymentActionPackageResource;
    skipMachines: string;
    skipMachinesRoles: string[];
    allowDeploymentsToNoTargets: boolean;
    discreteChannelRelease: boolean;
    guidedFailureMode: GuidedFailureMode;
    logo: LogoEditorSettings;
    isDisabled: boolean;
    excludeUnhealthyTargets: boolean;
    clonedFromProjectId: string;
    extensionSettings: ExtensionSettingsValues[];
    releaseNotesTemplate: string;
    deploymentChangesTemplate: string;
}

interface ProjectSettingsState extends OptionalFormBaseComponentState<ProjectModel> {
    projectGroups: ProjectGroupResource[] | null;
    channels: ChannelResource[];
    deploymentProcess: DeploymentProcessResource;
    machineRoles: string[];
    showVersionFromPackage: boolean;
    versionPackageActions: DeploymentActionPackageResource[];
    logoPreviewUrl: string;
    project: ProjectResource;
    redirectTo: string;
    clonedFromProjectDetails: ClonedFromProjectDetails;
    clonedProjectsCollection: ResourceCollection<ProjectResource>;
    metadata: ProjectSettingsMetadata[];
    canDelete: boolean;
    showConnectivityCheckDialog: boolean; //todo: mattr: config-as-code: this is... icky (but it's what we do elsewhere)
}

class TenantedDeploymentModeRadioButtonGroup extends RadioButtonGroup<TenantedDeploymentMode> {}

class ClonedProjectsList extends List<ProjectResource> {}

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

type ProjectSettingsProps = RouteComponentProps<ProjectRouteParams>;
type Props = ProjectSettingsProps & GlobalConnectedProps & WithProjectContextInjectedProps;

class ProjectSettingsInternal extends FormBaseComponent<Props, ProjectSettingsState, ProjectModel> {
    readonly defaultTemplate: string = "#{Octopus.Version.LastMajor}.#{Octopus.Version.LastMinor}.#{Octopus.Version.NextPatch}";

    constructor(props: Props) {
        super(props);

        this.state = {
            projectGroups: [],
            channels: [],
            versionPackageActions: [],
            machineRoles: [],
            deploymentProcess: null!,
            showVersionFromPackage: false,
            logoPreviewUrl: null!,
            project: null!,
            redirectTo: null!,
            clonedFromProjectDetails: {
                cloneVisibility: CloneVisibility.NotCloned,
                clonedFromProject: null!,
            },
            clonedProjectsCollection: null!,
            metadata: null!,
            canDelete: false,
            showConnectivityCheckDialog: false,
        };
    }

    resetVersionTemplate = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(state => {
            const nextModel = {
                ...state.model,
                versioningStrategyTemplate: this.defaultTemplate,
            };
            return {
                model: nextModel,
            };
        });
    };

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project, projectContextRepository } = this.props.projectContext.state;
            const [projectGroups, machineRoles, clonedProjectsCollection] = await Promise.all<ProjectGroupResource[] | null, string[], ResourceCollection<ProjectResource>>([
                isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" }) ? repository.ProjectGroups.all() : Promise.resolve<ProjectGroupResource[]>([]),
                repository.MachineRoles.all(),
                repository.Projects.list({ clonedFromProjectId: project.Id }),
            ]);

            const hasProcessViewPermissions = isAllowed({
                permission: Permission.ProcessView,
                project: project.Id,
                tenant: "*",
            });

            const [deploymentProcess, channels] = await Promise.all<DeploymentProcessResource, ResourceCollection<ChannelResource>>([
                hasProcessViewPermissions ? projectContextRepository.DeploymentProcesses.get() : Promise.resolve(null!),
                hasProcessViewPermissions ? repository.Projects.getChannels(project) : Promise.resolve(null!),
            ]);

            let clonedFromProject: ProjectResource = null!;
            let cloneVisibility = CloneVisibility.NotCloned;
            if (project.ClonedFromProjectId) {
                const canAccessClone = isAllowed({ permission: Permission.ProjectView, project: project.ClonedFromProjectId, wildcard: true });
                cloneVisibility = canAccessClone ? CloneVisibility.Available : CloneVisibility.AccessDenied;

                if (canAccessClone) {
                    try {
                        clonedFromProject = await repository.Projects.get(project.ClonedFromProjectId);
                    } catch (error) {
                        // if it's any other error let it go, if we failed to load due to bad data, just move on with 'NotFound'
                        if (error.StatusCode !== 404) {
                            throw error;
                        }
                        cloneVisibility = CloneVisibility.NotFound;
                    }
                }
            }

            const metadataPromise: Promise<ProjectSettingsMetadata[]> = repository.Projects.getMetadata(project);

            const versionPackageActions = deploymentActionPackages(
                _.chain(deploymentProcess && deploymentProcess.Steps)
                    .flatMap(step => step.Actions)
                    .filter(action => action.CanBeUsedForProjectVersioning)
                    .value()
            );

            this.setState({
                projectGroups,
                deploymentProcess,
                versionPackageActions,
                machineRoles,
                project,
                channels: channels ? channels.Items : [],
                clonedFromProjectDetails: {
                    clonedFromProject,
                    cloneVisibility,
                },
                clonedProjectsCollection,
                model: this.buildModel(project),
                cleanModel: this.buildModel(project),
                metadata: await metadataPromise,
            });
        });
    }

    buildModel(project: ProjectResource): ProjectModel {
        if (!project.VersioningStrategy) {
            project.VersioningStrategy = { Template: this.defaultTemplate, DonorPackage: null! };
        }

        if (!project.ProjectConnectivityPolicy) {
            project.ProjectConnectivityPolicy = {
                SkipMachineBehavior: "None",
                TargetRoles: [],
                AllowDeploymentsToNoTargets: false,
                ExcludeUnhealthyTargets: false,
            };
        }

        const model: ProjectModel = {
            name: project.Name,
            description: project.Description,
            projectGroupId: project.ProjectGroupId,
            deploymentProcessId: project.DeploymentProcessId,
            versionFromPackage: !!project.VersioningStrategy.DonorPackage,
            versioningStrategyTemplate: project.VersioningStrategy.Template,
            versioningStrategyPackage: project.VersioningStrategy.DonorPackage!,
            skipIfAlreadyInstalled: project.DefaultToSkipIfAlreadyInstalled,
            tenantedDeploymentMode: project.TenantedDeploymentMode,
            allowDeploymentsToNoTargets: project.ProjectConnectivityPolicy.AllowDeploymentsToNoTargets,
            skipMachines: project.ProjectConnectivityPolicy.SkipMachineBehavior,
            skipMachinesRoles: project.ProjectConnectivityPolicy.TargetRoles,
            discreteChannelRelease: project.DiscreteChannelRelease,
            guidedFailureMode: project.DefaultGuidedFailureMode,
            logo: { file: null!, reset: false },
            isDisabled: project.IsDisabled,
            excludeUnhealthyTargets: project.ProjectConnectivityPolicy.ExcludeUnhealthyTargets,
            clonedFromProjectId: project.ClonedFromProjectId,
            extensionSettings: cloneDeep(project.ExtensionSettings),
            releaseNotesTemplate: project.ReleaseNotesTemplate,
            deploymentChangesTemplate: project.DeploymentChangesTemplate,
        };
        return model;
    }

    handleSaveClick = async () => {
        const model = this.state.model!;
        const project: ProjectResource = {
            ...this.state.project,
            Name: model.name,
            Description: model.description,
            ProjectGroupId: model.projectGroupId!,
            DefaultToSkipIfAlreadyInstalled: model.skipIfAlreadyInstalled,
            TenantedDeploymentMode: model.tenantedDeploymentMode,
            VersioningStrategy: {
                DonorPackage: model.versionFromPackage ? model.versioningStrategyPackage : null!,
                Template: model.versionFromPackage ? null! : model.versioningStrategyTemplate,
            },
            ProjectConnectivityPolicy: {
                AllowDeploymentsToNoTargets: model.allowDeploymentsToNoTargets,
                SkipMachineBehavior: model.skipMachines,
                TargetRoles: model.skipMachinesRoles,
                ExcludeUnhealthyTargets: model.excludeUnhealthyTargets,
            },
            DefaultGuidedFailureMode: model.guidedFailureMode,
            DiscreteChannelRelease: model.discreteChannelRelease,
            IsDisabled: model.isDisabled,
            ClonedFromProjectId: model.clonedFromProjectId,
            ExtensionSettings: cloneDeep(model.extensionSettings),
            ReleaseNotesTemplate: model.releaseNotesTemplate,
            DeploymentChangesTemplate: model.deploymentChangesTemplate,
        };

        await this.doBusyTask(async () => {
            await saveLogo(this.state.project, this.state.model!.logo.file!, this.state.model!.logo.reset);
            await this.saveProject(project);
        });
    };

    descriptionSummary() {
        return this.state.model!.description ? Summary.summary(<Markdown markup={this.state.model!.description} />) : Summary.placeholder("No project description provided");
    }

    renderCloneDetails() {
        const visibility = this.state.clonedFromProjectDetails.cloneVisibility;
        const clonedFromId = this.state.project.ClonedFromProjectId;
        if (visibility === CloneVisibility.NotFound) {
            return <div>This project was originally cloned from a project ({clonedFromId}) that cannot be found.</div>;
        } else if (visibility === CloneVisibility.AccessDenied) {
            return <div>This project was originally cloned from a project that you do not have {Permission.ProjectView} for.</div>;
        } else if (visibility === CloneVisibility.Available) {
            return (
                <div>
                    This project was originally cloned from <InternalLink to={routeLinks.project(this.state.clonedFromProjectDetails.clonedFromProject).root}>{this.state.clonedFromProjectDetails.clonedFromProject.Name}</InternalLink>.
                </div>
            );
        }
        return null;
    }

    render() {
        const CloneDialog = () => (
            <AddProject
                title="Add New Project"
                cloneId={this.state.project.Id}
                groupId={this.state.project.ProjectGroupId}
                lifecycleId={this.state.project.LifecycleId}
                projectCreated={project => this.setState({ redirectTo: OverviewRedirectPathForProject(project, undefined) })}
                hasProjects={true}
            />
        );

        const overFlowActions = [];
        if (this.state.project) {
            overFlowActions.push(OverflowMenuItems.item(this.state.model!.isDisabled ? "Enable" : "Disable", this.handleEnabledToggle, this.editPermission()));
            overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, this.clonePermission()));
            overFlowActions.push(
                OverflowMenuItems.deleteItem(
                    "Delete",
                    "Are you sure you want to delete this project?",
                    this.handleDeleteConfirm,
                    dialogDoBusyTask => <DeleteProject doBusyTask={dialogDoBusyTask} projectName={this.state.project.Name} projectId={this.state.project.Id} onChange={this.onDeleteProjectChanged} />,
                    this.deletePermission(),
                    !this.state.canDelete
                )
            );
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForProject(this.state.project.Id), undefined, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const isProjectCloned = this.state.clonedFromProjectDetails.cloneVisibility !== CloneVisibility.NotCloned;
        const includesCloneInformation = (this.state.model && isProjectCloned) || (this.state.clonedProjectsCollection && this.state.clonedProjectsCollection.TotalResults > 0);
        const clonedFromElement = this.state.model && isProjectCloned && this.renderCloneDetails();

        const extensionSettings =
            this.state.metadata &&
            this.state.metadata.length > 0 &&
            this.state.metadata.map(m => {
                let valuesForExtension = this.state.model!.extensionSettings.find(e => e.ExtensionId === m.ExtensionId);
                if (!valuesForExtension || !valuesForExtension.Values) {
                    valuesForExtension = {
                        ExtensionId: m.ExtensionId,
                        Values: {},
                    };

                    this.state.model!.extensionSettings.push(valuesForExtension);
                }

                return (
                    <div>
                        <FormSectionHeading title={m.Metadata.Description} />
                        <DynamicForm
                            types={m.Metadata.Types}
                            values={valuesForExtension.Values}
                            onChange={c => {
                                this.setState({
                                    model: this.state.model,
                                });
                            }}
                        />
                    </div>
                );
            });

        const canViewDeploymentsMenu = IsAllowedToSeeDeploymentsOverview(this.state.project ? this.state.project.Id : null);

        return (
            <FormPaperLayout
                title="Settings"
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={this.editPermission()}
                onSaveClick={this.handleSaveClick}
                overFlowActions={overFlowActions}
                saveText="Project details updated"
            >
                {this.state.model && (
                    <TransitionAnimation>
                        {this.state.cleanModel!.isDisabled && (
                            <UnstructuredFormSection stretchContent={true}>
                                <Callout type={CalloutType.Warning} title={"This project is currently disabled"} />
                            </UnstructuredFormSection>
                        )}
                        {this.getInvalidConfigurationCallout()}

                        <ExpandableFormSection
                            errorKey="name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your project")}
                            help="Enter a name for your project."
                        >
                            <Text value={this.state.model.name} onChange={name => this.setModelState({ name })} label="Project name" error={this.getFieldError("name")} validate={required("Please enter a project name")} />
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            errorKey="IsDisabled"
                            title="Enabled"
                            summary={this.state.model.isDisabled ? Summary.summary("No") : Summary.default("Yes")}
                            help="Disable a project to prevent releases or deployments from being created."
                        >
                            <Checkbox value={!this.state.model.isDisabled} onChange={isDisabled => this.setModelState({ isDisabled: !isDisabled })} label="Enabled" />
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="logo" title="Logo" summary={this.logoSummary()} help="Choose an image to use as a project logo.">
                            <LogoEditor value={this.state.model.logo} onChange={logo => this.setModelState({ logo })} />
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your project.">
                            <MarkdownEditor value={this.state.model.description} label="Project description" onChange={description => this.setModelState({ description })} />
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="projectGroupId" title="Project Group" summary={this.projectGroupSummary()} help="Select which project group this project belongs to.">
                            <Select value={this.state.model.projectGroupId} onChange={projectGroupId => this.setModelState({ projectGroupId })} items={this.state.projectGroups!.map(pg => ({ value: pg.Id, text: pg.Name }))} label="Project group" />
                        </ExpandableFormSection>

                        {canViewDeploymentsMenu && (
                            <>
                                <ExpandableFormSection errorKey="versionFromPackage" title="Release Versioning" summary={this.releaseVersioningSummary()} help="Select how the next release number is generated when creating a release.">
                                    <div>
                                        <BooleanRadioButtonGroup value={this.state.model.versionFromPackage} onChange={versionFromPackage => this.setModelState({ versionFromPackage })}>
                                            <RadioButton value={false} label="Generate version numbers using a template" isDefault={true} />
                                            <RadioButton value={true} label="Use the version number from an included package" />
                                        </BooleanRadioButtonGroup>
                                        {this.releaseVersioning()}
                                        {!this.state.model.versionFromPackage && (
                                            <MoreInfo
                                                label="Template variable information"
                                                content={
                                                    <div>
                                                        <p>You can use variables from the project (un-scoped or scoped only to a channel). In addition, some special variables are provided - example:</p>
                                                        <pre>
                                                            <code>
                                                                1.2.#{"{"}Octopus.Version.NextPatch{"}"}-pre
                                                            </code>
                                                        </pre>
                                                        <p>These special variables take the form:</p>
                                                        <pre>
                                                            <code>Octopus.Version.(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                                                        </pre>
                                                        <p>If you are using channels, channel-specific special variables are also available: </p>
                                                        <pre>
                                                            <code>Octopus.Version.Channel.(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                                                        </pre>
                                                        <p>Version components from other channels in the project can be referenced using the channel name as the index:</p>
                                                        <pre>
                                                            <code>Octopus.Version.Channel[ChannelName].(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                                                        </pre>
                                                        <p>The channel name can also be used (generally as part of the suffix):</p>
                                                        <pre>
                                                            <code>Octopus.Release.Channel.Name</code>
                                                        </pre>
                                                        <p>
                                                            The version can also include Octopus <em>semantic version mask</em> characters
                                                            <code>i</code> and <code>c</code> referring to the <strong>i</strong>ncremented and <strong>c</strong>urrent values of the version, respectively. For example:
                                                        </p>
                                                        <pre>2.1.c.i</pre>
                                                        <p>Finally, date fields can be also be used, for example: </p>
                                                        <pre>
                                                            #{"{"}Octopus.Date.Year}.#{"{"}Octopus.Date.Month{"}"}.#{"{"}Octopus.Date.Day{"}"}
                                                        </pre>
                                                        <p>These take the form:</p>
                                                        <pre>
                                                            <code>Octopus.Date.(Day|Month|Year|DayOfYear)</code>
                                                            <br />
                                                            <code>Octopus.Time.(Hour|Minute|Second)</code>
                                                        </pre>
                                                    </div>
                                                }
                                            />
                                        )}
                                    </div>
                                </ExpandableFormSection>

                                <ExpandableFormSection
                                    errorKey="releaseNotesTemplate"
                                    title="Release Notes Template"
                                    summary={this.state.model.releaseNotesTemplate ? Summary.summary("A release notes template has been specified") : Summary.placeholder("No release notes template provided")}
                                    help={this.buildReleaseNotesTemplateHelpInfo()}
                                >
                                    <MarkdownEditor value={this.state.model.releaseNotesTemplate} label="Release notes template" onChange={releaseNotesTemplate => this.setChildState1("model", { releaseNotesTemplate })} />
                                </ExpandableFormSection>

                                <FormSectionHeading title="Deployment Settings" />

                                <ExpandableFormSection
                                    errorKey="skipIfAlreadyInstalled"
                                    title="Package Re-deployment"
                                    summary={this.skipIfAlreadyInstalledSummary()}
                                    help="Choose to always re-deploy a package or skip if the same package version is already installed on a deployment target."
                                >
                                    <div>
                                        <BooleanRadioButtonGroup value={this.state.model.skipIfAlreadyInstalled} onChange={skipIfAlreadyInstalled => this.setModelState({ skipIfAlreadyInstalled })}>
                                            <RadioButton value={false} label="Always deploy all packages" isDefault={true} />
                                            <RadioButton value={true} label="Skip any package step that is already installed" />
                                            <Note>If the same version of the package is installed all features of the package step will be skipped.</Note>
                                        </BooleanRadioButtonGroup>
                                    </div>
                                </ExpandableFormSection>

                                {(this.props.isMultiTenancyEnabled || this.state.cleanModel!.tenantedDeploymentMode !== TenantedDeploymentMode.Untenanted) &&
                                    isAllowed({ permission: Permission.TenantView, tenant: "*", project: this.state.project && this.state.project.Id }) && (
                                        <ExpandableFormSection errorKey="tenantedDeploymentMode" title="Multi-tenant Deployments" summary={this.tenantedDeploymentModeSummary()} help="Choose to enable or disable tenanted deployments of this project.">
                                            <TenantedDeploymentModeRadioButtonGroup value={this.state.model.tenantedDeploymentMode} onChange={tenantedDeploymentMode => this.setModelState({ tenantedDeploymentMode })}>
                                                <RadioButton value={TenantedDeploymentMode.Untenanted} label="Disable tenanted deployments" isDefault={true} />
                                                <RadioButton value={TenantedDeploymentMode.TenantedOrUntenanted} label="Allow deployments with or without a tenant" />
                                                <RadioButton value={TenantedDeploymentMode.Tenanted} label="Require a tenant for all deployments" />
                                            </TenantedDeploymentModeRadioButtonGroup>
                                            <Note>
                                                <ExternalLink href="ProjectTenantedDeploymentMode">Learn more about tenanted deployment modes</ExternalLink>
                                            </Note>
                                        </ExpandableFormSection>
                                    )}

                                <ExpandableFormSection errorKey="allowDeploymentsToNoTargets" title="Deployment Targets" summary={this.deploymentTargetsSummary()} help="Choose if deployments are allowed if there are no deployment targets.">
                                    <BooleanRadioButtonGroup value={this.state.model.allowDeploymentsToNoTargets} onChange={allowDeploymentsToNoTargets => this.setModelState({ allowDeploymentsToNoTargets })}>
                                        <RadioButton value={false} label="Deployments with no targets are not allowed" isDefault={true} />
                                        <RadioButton value={true} label="Allow deployments to be created when there are no deployment targets" />
                                    </BooleanRadioButtonGroup>
                                </ExpandableFormSection>

                                <ExpandableFormSection errorKey="skipMachines" title="Deployment Target Status" summary={this.skipMachinesSummary()} help="Choose to skip unavailable, or exclude unhealthy targets from the deployment.">
                                    <StringRadioButtonGroup label="Unavailable Deployment targets" value={this.state.model.skipMachines} onChange={this.handleSkipMachinesChanged}>
                                        <RadioButton value="None" label="Do not skip, and fail deployment" isDefault={true} />
                                        <RadioButton value="SkipUnavailableMachines" label="Skip" />
                                        <Note>Deployment targets that are unavailable at the start of the deployment or become unavailable during the deployment will be skipped and removed from the deployment.</Note>
                                    </StringRadioButtonGroup>
                                    {this.state.model.skipMachines === "SkipUnavailableMachines" && (
                                        <div>
                                            <RoleMultiSelect
                                                onChange={skipMachinesRoles => this.setModelState({ skipMachinesRoles })}
                                                value={this.state.model.skipMachinesRoles}
                                                label="Skip unavailable deployment targets only in selected roles"
                                                items={this.state.machineRoles}
                                            />
                                            <Note>By default, deployment targets will be skipped if they are unavailable in all roles, to limit to certain roles select them here.</Note>
                                        </div>
                                    )}
                                    <StringRadioButtonGroup
                                        value={this.state.model.excludeUnhealthyTargets ? "ExcludeUnhealthy" : "None"}
                                        onChange={skipUnhealthyTargets => this.setModelState({ excludeUnhealthyTargets: skipUnhealthyTargets === "ExcludeUnhealthy" })}
                                        label="Unhealthy Deployment Targets"
                                    >
                                        <RadioButton value="None" label="Do not exclude" isDefault={true} />
                                        <RadioButton value="ExcludeUnhealthy" label="Exclude" />
                                        <Note>Deployment targets that are unhealthy at the start of the deployment will be skipped and removed from the deployment.</Note>
                                    </StringRadioButtonGroup>
                                </ExpandableFormSection>

                                {this.state.channels.length > 1 && (
                                    <ExpandableFormSection
                                        errorKey="discreteChannelRelease"
                                        title="Discrete Channel Releases"
                                        summary={this.discreteChannelReleaseSummary()}
                                        help="Choose if channel release are treated independently or for the entire project."
                                    >
                                        <BooleanRadioButtonGroup value={this.state.model.discreteChannelRelease} onChange={discreteChannelRelease => this.setModelState({ discreteChannelRelease })} label="Discrete channel releases">
                                            <RadioButton value={false} label="Considered for the entire project" isDefault={true} />
                                            <Note>
                                                Any channel release will supersede existing channel releases.
                                                <ExternalLink href="WalkthroughChannelHotfix"> Learn more about hotfix deployments</ExternalLink>
                                            </Note>
                                            <RadioButton value={true} label="Treat independently from other channels" />
                                            <Note>
                                                Will apply to both the project dashboard and for retention policies.
                                                <ExternalLink href="WalkthroughChannelFeatureBranch"> Learn more about feature branches</ExternalLink>
                                            </Note>
                                        </BooleanRadioButtonGroup>
                                    </ExpandableFormSection>
                                )}

                                <ExpandableFormSection
                                    errorKey="deploymentChangesTemplate"
                                    title="Deployment Changes Template"
                                    summary={this.state.model.deploymentChangesTemplate ? Summary.summary("A deployment changes template has been specified") : Summary.placeholder("No deployment changes template provided")}
                                    help={this.buildDeploymentChangesTemplateHelpInfo()}
                                >
                                    <MarkdownEditor value={this.state.model.deploymentChangesTemplate} label="Deployment changes template" onChange={deploymentChangesTemplate => this.setChildState1("model", { deploymentChangesTemplate })} />
                                </ExpandableFormSection>

                                <FailureMode
                                    guidedFailureMode={this.state.model.guidedFailureMode}
                                    onModeChanged={guidedFailureMode => this.setModelState({ guidedFailureMode })}
                                    title="Default Failure Mode"
                                    modelType={DeploymentModelType.Deployment}
                                />
                            </>
                        )}

                        {includesCloneInformation && (
                            <React.Fragment>
                                <FormSectionHeading title="Cloning History" />
                                {isProjectCloned && <ExpandableFormSection errorKey="ClonedFrom" title="Cloned From" summary={Summary.summary(clonedFromElement)} help={clonedFromElement} />}
                                {this.state.clonedProjectsCollection && this.state.clonedProjectsCollection.TotalResults > 0 && (
                                    <ExpandableFormSection
                                        errorKey="ClonedProjects"
                                        title="Cloned Projects"
                                        summary={Summary.summary("This project was cloned to create other projects.")}
                                        help="This project was cloned to create the following projects."
                                    >
                                        <Section>
                                            <ClonedProjectsList
                                                initialData={this.state.clonedProjectsCollection}
                                                onRow={(project: ProjectResource) => {
                                                    return <ListTitle>{project.Name}</ListTitle>;
                                                }}
                                                onRowRedirectUrl={(project: ProjectResource) => routeLinks.project(project).root}
                                                filterSearchEnabled={false}
                                                autoFocusOnFilterSearch={false}
                                                apiSearchParams={["partialName"]}
                                                match={this.props.match}
                                                showPagingInNumberedStyle={true}
                                            />
                                        </Section>
                                    </ExpandableFormSection>
                                )}
                            </React.Fragment>
                        )}

                        {extensionSettings}
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    private onDeleteProjectChanged = (canDelete: boolean) => {
        this.setState({ canDelete });
    };

    private getInvalidConfigurationCallout() {
        if (this.state.deploymentProcess && this.state.project.VersioningStrategy.DonorPackage) {
            const action = flatten(this.state.deploymentProcess.Steps.map(step => step.Actions)).filter(a => a.Name === this.state.project.VersioningStrategy.DonorPackage!.DeploymentAction);
            if (action && action.length > 0 && action[0].IsDisabled) {
                return (
                    <UnstructuredFormSection stretchContent={true}>
                        <Callout type={CalloutType.Warning} title="Invalid Configuration">
                            Step <InternalLink to={routeLinks.project(this.state.project).deployments.process.step(action[0].Id)}>{action[0].Name}</InternalLink> is currently used for release versioning, but it has been disabled.
                            <br />
                            Please re-enable the step, change the step used for release versioning, or change to using a version template.
                        </Callout>
                    </UnstructuredFormSection>
                );
            }
        }
        return null;
    }

    private handleSkipMachinesChanged = (skipMachines: string) => {
        this.setState(state => {
            return {
                model: {
                    ...state.model,
                    skipMachines,
                    skipMachinesRoles: skipMachines === "None" ? [] : state.model!.skipMachinesRoles,
                },
            };
        });
    };

    private logoSummary(): SummaryNode {
        if (!this.state.project || this.state.model!.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model!.logo.file) {
            return Summary.summary(this.state.model!.logo.file.name);
        }
        return Summary.summary(<Logo url={this.state.project.Links.Logo} size="2.5em" />);
    }

    private projectGroupSummary(): SummaryNode {
        const projectGroup = this.state.projectGroups!.find(g => g.Id === this.state.model!.projectGroupId);
        return projectGroup ? Summary.summary(projectGroup.Name) : Summary.placeholder("No project group selected");
    }

    private releaseVersioningSummary(): SummaryNode {
        if (this.state.model!.versionFromPackage) {
            const versioningPackage = this.state.model!.versioningStrategyPackage;
            return !!versioningPackage && !!versioningPackage.DeploymentAction
                ? Summary.summary(
                      <span>
                          Based on the package step <strong>{displayName(versioningPackage)}</strong>
                      </span>
                  )
                : Summary.summary("Based on the package in a step, please select a step");
        }
        const template = this.state.model!.versioningStrategyTemplate;
        return Summary.default(
            template ? (
                <span>Based on template {template}</span>
            ) : (
                <span>
                    Based on template, <strong>no template set</strong>
                </span>
            )
        );
    }

    private tenantedDeploymentModeSummary(): SummaryNode {
        switch (this.state.model!.tenantedDeploymentMode) {
            case TenantedDeploymentMode.Untenanted:
                return Summary.default("No tenanted deployments");
            case TenantedDeploymentMode.TenantedOrUntenanted:
                return Summary.summary("Both tenanted and untenanted deployments allowed");
            case TenantedDeploymentMode.Tenanted:
                return Summary.summary("Tenants required for all deployments");
            default:
                return Summary.placeholder("Please select");
        }
    }

    private deploymentTargetsSummary(): SummaryNode {
        return this.state.model!.allowDeploymentsToNoTargets ? Summary.summary("Deployments with no target allowed") : Summary.default("Deployment target is required");
    }

    private skipMachinesSummary(): SummaryNode {
        //TODO: convert to enum
        if (this.state.model!.skipMachines !== "SkipUnavailableMachines") {
            return this.state.model!.excludeUnhealthyTargets ? Summary.summary("Deployment will exclude unhealthy targets, and fail if there is an unavailable target") : Summary.default("Deployment will fail if a deployment target is unavailable");
        }

        const roles = this.state.model!.skipMachinesRoles;
        const summary = [this.state.model!.excludeUnhealthyTargets ? <span key="skipMachines">Deployment will exclude unhealthy targets, and skip unavailable targets</span> : <span key="skipMachines">Deployment will skip unavailable targets</span>];

        if (roles.length > 0) {
            summary.push(this.state.model!.skipMachinesRoles.length > 1 ? <span> in roles</span> : <span> in role</span>);

            roles.forEach(r => {
                summary.push(<RoleChip role={r} key={"role-" + r} />);
            });
        }
        return Summary.summary(React.Children.toArray(summary));
    }

    private discreteChannelReleaseSummary(): SummaryNode {
        return this.state.model!.discreteChannelRelease ? Summary.summary("Treat independently from other channels (feature branch style)") : Summary.default("Any channel release will supersede existing channel releases (hotfix style)");
    }

    private buildReleaseNotesTemplateHelpInfo(): string {
        const helpInfo = "Enter a template for the release notes that will be used for new releases. " + "This template will appear as the default release notes text on the Create Release page, where it can be edited if necessary.";
        return helpInfo;
    }

    private buildDeploymentChangesTemplateHelpInfo() {
        return (
            <span>
                Enter a template for the markdown generated for each deployment's changes. The markdown can be accessed during a deployment using the <code>Octopus.Deployment.ChangesMarkdown</code> variable.{" "}
                <ExternalLink href="https://g.octopushq.com/DeploymentChangesTemplate">Learn more</ExternalLink>
            </span>
        );
    }

    private skipIfAlreadyInstalledSummary(): SummaryNode {
        return this.state.model!.skipIfAlreadyInstalled ? Summary.summary("Package steps will be skipped if the package version is already installed") : Summary.default("All packages will always be installed");
    }

    private releaseVersioning() {
        if (this.state.model!.versionFromPackage) {
            return (
                <Select
                    value={String(this.state.versionPackageActions.findIndex(pa => _.isEqual(pa, this.state.model!.versioningStrategyPackage)))}
                    onChange={(packageActionIndex: string) => this.setModelState({ versioningStrategyPackage: this.state.versionPackageActions[Number(packageActionIndex)] })}
                    items={this.state.versionPackageActions.map((pa, index) => ({ value: String(index), text: displayName(pa), disabled: this.stepIsDisabled(pa.DeploymentAction) }))}
                    label="Versioning package step"
                />
            );
        } else {
            return (
                <div>
                    <Text value={this.state.model!.versioningStrategyTemplate} onChange={versioningStrategyTemplate => this.setModelState({ versioningStrategyTemplate })} label="Version template" />
                    {!this.state.model!.versioningStrategyTemplate && (
                        <Note>
                            <br />
                            <a href="#" onClick={this.resetVersionTemplate}>
                                Reset to default template
                            </a>
                        </Note>
                    )}
                </div>
            );
        }
    }

    private stepIsDisabled(deploymentAction: string) {
        const action = _.chain(this.state.deploymentProcess.Steps)
            .flatMap(step => step.Actions)
            .find(x => x.Name === deploymentAction)
            .value();
        return action?.IsDisabled ?? false;
    }

    private clonePermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectCreate,
            projectGroup: this.state.project && this.state.project.ProjectGroupId,
        };
    }

    private deletePermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectDelete,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }

    private handleDeleteConfirm = async () => {
        await repository.Projects.del(this.state.project);
        this.setState({ redirectTo: routeLinks.projects.root });
        return true;
    };

    private async saveProject(project: ProjectResource) {
        const result = await repository.Projects.save(project);
        await this.props.projectContext.actions.onProjectUpdated(result);

        const projectNameHasChanged = this.state.cleanModel!.name !== result.Name;
        const redirectTo = projectNameHasChanged ? routeLinks.project(result).settings : null;

        this.setState(s => {
            return {
                model: this.buildModel(result),
                cleanModel: this.buildModel(result),
                project: result,
                redirectTo,
            };
        });
    }

    private handleEnabledToggle = async () => {
        const model = this.state.model;
        const project: ProjectResource = {
            ...this.state.project,
            IsDisabled: !model!.isDisabled,
        };
        await this.doBusyTask(async () => {
            await this.saveProject(project);
        });
    };
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

const ProjectSettings = connect(mapGlobalStateToProps, null)(withProjectContext(ProjectSettingsInternal));

export default ProjectSettings;
