import React from "react";
import PanelStack, { usePanelStack } from "components/PanelStack";
import { DirtyList } from "./DirtyList";
import DirtyStateDetail from "./DirtyStateDetail";

export const DirtyStateView: React.FC = () => {
    const { openPanel, closePanel, panels } = usePanelStack();

    return (
        <PanelStack
            onOpenPanel={openPanel}
            onClosePanel={closePanel}
            panels={panels}
            renderInitial={renderProps => (
                <DirtyList
                    onSelect={name =>
                        renderProps.openPanel({
                            component: DirtyStateDetail,
                            props: { ...renderProps, name },
                            title: `${name} Changes`,
                        })
                    }
                />
            )}
        />
    );
};

export default DirtyStateView;
