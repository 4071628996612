/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { repository } from "../../clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/index";
import routeLinks from "../../routeLinks";
import PaperLayout from "components/PaperLayout/index";
import AreaTitle from "components/AreaTitle/index";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { Repository } from "../../client";
import { RunbookSnapshotResource } from "client/resources";

interface ProjectRunbookChildRedirectState extends DataBaseComponentState {
    redirectTo: string;
}

interface ProjectRunbookChildRedirectProps {
    getRunbookSnapshot: (repository: Repository) => Promise<RunbookSnapshotResource>;
    getRedirectPath: (projectId: string, runbookId: string) => string;
}

class ProjectRunbookChildRedirect extends DataBaseComponent<ProjectRunbookChildRedirectProps, ProjectRunbookChildRedirectState> {
    constructor(props: ProjectRunbookChildRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const runbookSnapshot = await this.props.getRunbookSnapshot(repository);
            const redirectTo = this.props.getRedirectPath(runbookSnapshot.ProjectId, runbookSnapshot.RunbookId);
            this.setState({
                redirectTo,
            });
        });
    }

    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (
                <main id="maincontent">
                    <AreaTitle link={routeLinks.projects.root} title="Projects" />
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.state.errors} />
                </main>
            );
        }

        return <InternalRedirect push={false} to={{ pathname: redirectTo }} />;
    }
}

export const RunbookSnapshotRedirect: React.SFC<{ runbookSnapshotId: string }> = props => {
    return (
        <ProjectRunbookChildRedirect
            getRunbookSnapshot={r => r.RunbookSnapshots.get(props.runbookSnapshotId)}
            getRedirectPath={(projectId: string, runbookId: string) =>
                routeLinks
                    .project(projectId)
                    .operations.runbook(runbookId)
                    .runbookSnapshot(props.runbookSnapshotId).root
            }
        />
    );
};
