import React from "react";
import { Drawer, makeStyles } from "@material-ui/core";
import { DirtyStateView } from "./DirtyStateTracking";
import { DevToolbar } from "./DevToolbar";

interface DevDrawerProps {
    open: boolean;
    height?: string;
    onRequestClose: () => void;
    onToggleFullscreen: () => void;
    fullscreen: boolean;
}

const useDrawerStyles = makeStyles({
    paper: (props: DevDrawerProps) => ({
        height: props.open ? (props.fullscreen ? "100%" : props.height ?? "500px") : "0",
    }),
});

const useTabPanelStyles = makeStyles({
    root: {
        overflowY: "auto",
    },
});

const TabPanel: React.FC<{ selectedTab: string; value: string }> = props => {
    const styles = useTabPanelStyles();
    return <div className={styles.root}>{props.selectedTab === props.value && props.children}</div>;
};

export const DevDrawer: React.FC<DevDrawerProps> = props => {
    const drawerClasses = useDrawerStyles(props);
    const tabs = {
        Dirty: {
            value: "Dirty",
            label: "Dirty State Tracking",
        },
    };
    const [selectedTab, setTab] = React.useState("Dirty");

    return (
        <Drawer open={props.open} anchor={"bottom"} variant={"permanent"} classes={drawerClasses}>
            {props.open && (
                <React.Fragment>
                    <DevToolbar tabs={Object.values(tabs)} onSelectTab={setTab} selectedTab={selectedTab} onRequestClose={props.onRequestClose} onToggleFullscreen={props.onToggleFullscreen} isFullScreen={props.fullscreen} />
                    <TabPanel value={"Dirty"} selectedTab={selectedTab}>
                        <DirtyStateView />
                    </TabPanel>
                </React.Fragment>
            )}
        </Drawer>
    );
};
