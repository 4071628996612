import { DynamicWorkerPoolResource } from "client/resources";
import WorkerPoolEditBase, { WorkerPoolEditState } from "./WorkerPoolEditBase";
import { cloneDeep, sortBy } from "lodash";
import { repository } from "clientInstance";
import { DynamicWorkerType } from "client/resources/workerPoolsSupportedTypesResouce";
import { Summary, Select, ExpandableFormSection } from "components/form";
import React = require("react");
import { DeprecatedDynamicWorkerTypeChip, DeprecatedDynamicWorkerTypeCallout } from "../DeprecatedWorkerType";

interface DynamicWorkerPoolEditState extends WorkerPoolEditState<DynamicWorkerPoolResource> {
    workerTypes: DynamicWorkerType[];
}

class DynamicWorkerPoolEdit extends WorkerPoolEditBase<DynamicWorkerPoolResource, DynamicWorkerPoolEditState> {
    getDefaultState(): DynamicWorkerPoolEditState {
        return {
            deleted: false,
            model: this.props.workerPool,
            cleanModel: cloneDeep(this.props.workerPool),
            workerTypes: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const dynamicWorkerTypes = await repository.WorkerPools.getDynamicWorkerTypes();
            this.setState({
                workerTypes: dynamicWorkerTypes,
            });
        });
    }

    customExpandableFormSections(): JSX.Element[] {
        const baseElements: JSX.Element[] = [];
        const items = this.state.workerTypes
            ? sortBy(
                  this.state.workerTypes.map(t => ({ value: t.Type, text: t.Description + (t.Type.endsWith("Default") ? " (default)" : "") })),
                  t => t.text
              )
            : [];
        const workerType = this.state.workerTypes.find(x => x.Type == this.state.model.WorkerType);

        baseElements.push(
            <ExpandableFormSection key="WorkerType" errorKey="WorkerType" title="Worker Image" summary={this.workerTypeSummary()} help="The worker base image">
                <Select value={this.state.model.WorkerType} onChange={this.handleWorkerTypeChange} items={items} />
                <DeprecatedDynamicWorkerTypeCallout workerType={workerType} isNew={false} />
            </ExpandableFormSection>
        );

        return baseElements;
    }

    workerTypeSummary() {
        if (this.state.model.WorkerType) {
            const workerType = this.state.workerTypes.find(x => x.Type == this.state.model.WorkerType);
            return Summary.summary(
                <>
                    {workerType?.Description}
                    {workerType?.Type.endsWith("Default") ? " (default)" : ""}
                    <DeprecatedDynamicWorkerTypeChip workerType={workerType} />
                </>
            );
        }
        return Summary.placeholder("No worker image selected");
    }

    private handleWorkerTypeChange = (value: string) => {
        const workerType = this.state.workerTypes.find(l => l.Type === value);
        if (!workerType) {
            throw Error("Could not locate selected worker image");
        }

        this.setModelState({ WorkerType: workerType.Type });
    };
}

export default DynamicWorkerPoolEdit;
