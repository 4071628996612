import * as React from "react";
import SvgImage, { SvgImageProps } from "../../SvgImage";
const styles = require("../../styles.less");

const OfflinePackageDrop = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 58 70" title="OfflinePackageDrop" {...props}>
        <g fillRule="nonzero" strokeWidth="1">
            <path
                className={styles.imgDark}
                d="M27.333 45.748V57.5c-1.169.431-2.155 1.04-2.958 1.826-.803.786-1.345 1.673-1.625 2.66H10.312c-.791.341-1.187.846-1.187 1.514s.396 1.165 1.188 1.491H22.75c1.162 3.006 3.141 4.509 5.938 4.509 2.796 0 4.736-1.503 5.819-4.509H46.75c1-.264 1.5-.761 1.5-1.491 0-.73-.5-1.234-1.5-1.514H34.507c-.272-1.098-.735-1.985-1.389-2.66-.653-.675-1.598-1.243-2.833-1.702V45.748h-2.952z"
            />
            <rect className={styles.imgCyan} width="57" height="48" x=".5" y=".5" rx="3" />
            <path
                className={styles.imgWhite}
                d="M35.47 21.07L29 27.63l-6.47-6.56h3.19V16.2h6.56v4.87h3.19zm4.88 8.15V13H17.57v16.22h6.55c0 .852.232 1.689.67 2.42a5 5 0 0 0 1.79 1.8 4.77 4.77 0 0 0 4.84 0 5 5 0 0 0 1.79-1.8 4.72 4.72 0 0 0 .67-2.42h6.47zm0-19.5A3.33 3.33 0 0 1 43.62 13v22.7a3.31 3.31 0 0 1-3.27 3.3H17.57a3 3 0 0 1-2.28-1 3.25 3.25 0 0 1-.91-2.3V13a3.25 3.25 0 0 1 .91-2.3 3 3 0 0 1 2.28-1l22.78.02z"
            />
        </g>
    </SvgImage>
);
export { OfflinePackageDrop };
