/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";

//https://medium.com/@apostolos/server-side-rendering-code-splitting-and-hot-reloading-with-react-router-v4-87239cfc172c
function importAsync(getArea: () => Promise<any>, getComponent = (m: any) => m.default, deferLoading = false) {
    const asyncComponent = class AsyncComponent extends React.Component<any, any> {
        static Component: any = null;

        static async loadInBackground() {
            await AsyncComponent.load();
        }

        static componentAccessorToString(accessor: any) {
            return accessor.toString().replace("function", "");
        }

        static async load() {
            if (AsyncComponent.Component) {
                return;
            }

            try {
                console.log(`Loading dynamic chunk: ${AsyncComponent.componentAccessorToString(getComponent)}`);
                const area = await getArea();
                const component = getComponent(area);
                AsyncComponent.Component = component;
                console.log(`Loaded dynamic chunk: ${AsyncComponent.componentAccessorToString(getComponent)}`);
            } catch (error) {
                console.log(`Failed to load a dynamic chunk: ${AsyncComponent.componentAccessorToString(getComponent)}`);
                console.error(error);
                console.log("Reloading application...");
                location.reload(true);
            }
        }

        mounted = false;

        state = {
            Component: AsyncComponent.Component,
        };

        async componentDidMount() {
            this.mounted = true;
            if (this.state.Component === null) {
                if (!AsyncComponent.Component) {
                    await AsyncComponent.load();
                }

                if (this.mounted) {
                    this.setState({ Component: AsyncComponent.Component });
                }
            }
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        render() {
            const { Component } = this.state;
            if (Component) {
                return <Component {...this.props} />;
            }
            return <BusyIndicator show={true} />;
        }
    };

    if (!deferLoading) {
        // eslint-disable-next-line: no-floating-promises
        asyncComponent.loadInBackground();
    }

    return asyncComponent;
}

export default importAsync;
