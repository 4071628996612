import React from "react";
import { createAction, getType, ActionType } from "typesafe-actions";
import { combineConstantReducers } from "utils/Reducers/combineConstantReducers";

export interface DirtyItemState {
    name: string;
    model: object | undefined;
    cleanModel: object | undefined;
}

export type DirtyLookup = { [name: string]: DirtyItemState };

export const actions = {
    registerDirty: createAction("dirty/register", resolve => (state: DirtyItemState) => resolve(state)),
    unregisterDirty: createAction("dirty/unregister", resolve => (name: string) => resolve(name)),
    openDrawer: createAction("drawer/open"),
    toggleDrawer: createAction("drawer/toggle"),
    closeDrawer: createAction("drawer/close"),
    toggleFullscreen: createAction("drawer/toggle/fullscreen"),
};

type DevToolsActions = ActionType<typeof actions>;

const dirtyLookupReducer = (state: DirtyLookup, action: DevToolsActions) => {
    switch (action.type) {
        case getType(actions.registerDirty):
            return {
                ...state,
                [action.payload.name]: action.payload,
            };
        case getType(actions.unregisterDirty):
            if (Object.prototype.hasOwnProperty.call(state, action.payload)) {
                const { [action.payload]: deleted, ...next } = state;
                return next;
            }
            return state;
    }
    return state;
};

interface DevDrawerState {
    open: boolean;
    fullscreen: boolean;
}

const drawerReducer = (state: DevDrawerState, action: DevToolsActions) => {
    switch (action.type) {
        case getType(actions.openDrawer):
            return { ...state, open: true };
        case getType(actions.closeDrawer):
            return { ...state, open: false };
        case getType(actions.toggleDrawer):
            return { ...state, open: !state.open };
        case getType(actions.toggleFullscreen):
            return { ...state, fullscreen: !state.fullscreen };
    }
    return state;
};

export interface DevToolsContextState {
    dirty: DirtyLookup;
    drawer: DevDrawerState;
}

export type DevToolsContextActions = {
    dispatch: React.Dispatch<DevToolsActions>;
};

export const reducer = combineConstantReducers<DevToolsContextState, DevToolsActions>({
    dirty: dirtyLookupReducer,
    drawer: drawerReducer,
});

const DevToolsContextStateInternal = React.createContext<DevToolsContextState | undefined>(undefined);
const DevToolsContextActionsInternal = React.createContext<DevToolsContextActions | undefined>(undefined);

export const DevToolsContextProvider: React.FC = props => {
    const [state, dispatch] = React.useReducer(reducer, { dirty: {}, drawer: { open: false, fullscreen: false } });

    return (
        <DevToolsContextStateInternal.Provider value={state}>
            <DevToolsContextActionsInternal.Provider value={{ dispatch }}>{props.children}</DevToolsContextActionsInternal.Provider>
        </DevToolsContextStateInternal.Provider>
    );
};

DevToolsContextProvider.displayName = "DevToolsContextProvider";

export const useDevToolsState = () => {
    return React.useContext(DevToolsContextStateInternal);
};

export const useDevToolsDispatch = () => {
    return React.useContext(DevToolsContextActionsInternal);
};

type TrackDirtyProps = DirtyItemState;

export const TrackDirty: React.FC<TrackDirtyProps> = ({ cleanModel, model, name, children }) => {
    useTrackDirtyEffect(name, cleanModel, model);
    return <>{children}</>;
};

export const useTrackDirtyEffect = (name: string, cleanModel: object | undefined, model: object | undefined) => {
    const context = React.useContext(DevToolsContextActionsInternal);
    React.useEffect(() => {
        context?.dispatch(actions.registerDirty({ cleanModel, model, name }));
        return () => {
            context?.dispatch(actions.unregisterDirty(name));
        };
    }, [cleanModel, context?.dispatch, model, name]);
};
