/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { take, last, zip, keyBy, head } from "lodash";
import { ScopeSpecification } from "areas/variables/ReadonlyVariableResource/ReadonlyVariableResource";
import { ScopeValues } from "client/resources/variableSetResource";
import ActionButton from "components/Button/";
import { ReferenceDataItem } from "client/resources/referenceDataItem";
import { PopoverWhenFocused } from "components/PopoverWhenFocused/PopoverWhenFocused";
import { ChannelChip, EnvironmentChip, MachineChip, RoleChip, StepChip, ChipIcon, ProcessChip } from "components/Chips";
import { TagIndex } from "components/tenantTagsets";
import Tag from "components/Tag/Tag";
import { DEFAULT_COLOR } from "areas/library/components/TagSets/TagListEdit/TagListEdit";
import VariableCell from "areas/variables/VariableCell/VariableCell";

const styles = require("./style.less");
import { ActionButtonType } from "components/Button/ActionButton";
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip/LookupReferenceDataItemChip";
import { compareScopeItems, ScopeItem, ScopeType } from "areas/variables/VariableSorting/sortVariables";
import { MeasureWidthOutOfFlow } from "components/Measure/MeasureOutOfFlow";
import { ProcessType } from "client/resources";
import WarningIcon from "components/WarningIcon";
import ToolTip from "components/ToolTip";
import { checkScopeConsistency, getAllScopeConsistencyRules, ScopeConsistencyFailureResult } from "../ScopeConsistency";
import { createAvailableScopeLookup, AvailableScopeLookup } from "../ScopeConsistency";

interface ResizingScopeSummaryProps {
    scope: ScopeSpecification;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    emptyContent?: JSX.Element;
    containerWidth: number;
    inconsistencies: ScopeConsistencyFailureResult[];
    scopeLookup: AvailableScopeLookup;
    onShowMoreClicked(): void;
}

interface ResizingScopeSummaryState {
    widthsForScopes: { [scopeId: string]: number | undefined };
    measuredMoreButtonWidth: number | undefined;
}

// Make this appear more like a ternary link, with the additional click-area of a secondary button to improve usability.
const labelProps = {
    fontWeight: "inherit",
    fontSize: "0.8125rem",
};

class ResizingScopeSummary extends React.Component<ResizingScopeSummaryProps, ResizingScopeSummaryState> {
    constructor(props: ResizingScopeSummaryProps) {
        super(props);
        this.state = {
            widthsForScopes: {},
            measuredMoreButtonWidth: undefined,
        };
    }

    shouldComponentUpdate(nextProps: ResizingScopeSummaryProps, nextState: ResizingScopeSummaryState) {
        return (
            nextProps.containerWidth !== this.props.containerWidth ||
            nextProps.scope !== this.props.scope ||
            nextProps.availableScopes !== this.props.availableScopes ||
            nextProps.tagIndex !== this.props.tagIndex ||
            nextState.measuredMoreButtonWidth !== this.state.measuredMoreButtonWidth ||
            this.shouldComponentUpdateBasedOnScopeWidths(nextProps, nextState)
        );
    }

    shouldComponentUpdateBasedOnScopeWidths(nextProps: ResizingScopeSummaryProps, nextState: ResizingScopeSummaryState) {
        const currentScopeWidths = getScopeWidths(this.props, this.state);
        const nextScopeWidths = getScopeWidths(nextProps, nextState);
        // We want to avoid re-rendering until we have all of the widths for all of the scope items
        return (
            allScopeItemsHaveWidths(currentScopeWidths) !== allScopeItemsHaveWidths(nextScopeWidths) ||
            // but we should also handle the case where some of the widths have changed
            scopeWidthsHaveChanged()
        );

        function getScopeWidths(props: ResizingScopeSummaryProps, state: ResizingScopeSummaryState): ReadonlyArray<number | undefined> {
            return getScopeItems(props.scope, props.availableScopes)
                .map(getIdForScopeItem)
                .map(id => state.widthsForScopes[id]);
        }

        function scopeWidthsHaveChanged(): boolean {
            // By this point, we have already asserted that this.props.scope === nextProps.scope,
            // so therefore we can just zip up the widths and know that they will match up
            return zip(currentScopeWidths, nextScopeWidths).some(values => {
                const [currentWidth, nextWidth] = values;
                // We are only interested if whether a width changes from one number to another number,
                // so ignore undefined here
                return currentWidth !== undefined && nextWidth !== undefined && currentWidth !== nextWidth;
            });
        }

        function allScopeItemsHaveWidths(scopeWidths: ReadonlyArray<number | undefined>) {
            return scopeWidths.every(w => !!w);
        }
    }

    render() {
        const sortedScopeItems = getSortedScopeItems(this.props.scope, this.props.availableScopes);
        const cumulativeWidths = getCumulativeWidths(sortedScopeItems, this.state.widthsForScopes);
        const availableWidth = this.state.measuredMoreButtonWidth ? this.props.containerWidth - this.state.measuredMoreButtonWidth : this.props.containerWidth;
        const numberOfChipsToDisplay = calculateNumberOfElementsToShow(cumulativeWidths, availableWidth);
        const numberToHide = sortedScopeItems.length - numberOfChipsToDisplay;
        const moreButtonLabel = numberToHide > 0 ? `More...` : "Show";

        return (
            <VariableCell className={styles.scopeCell}>
                <div className={styles.scopesContainer}>
                    <div className={styles.chipContainer}>{this.renderChips(numberOfChipsToDisplay, sortedScopeItems)}</div>
                    {sortedScopeItems.length === 0 && this.props.emptyContent}
                    {numberToHide !== 0 && (
                        <MeasureWidthOutOfFlow onMeasured={width => this.setState({ measuredMoreButtonWidth: width })} key={moreButtonLabel /*Re-measure if the label changes, and therefore the width*/}>
                            <ActionButton
                                type={ActionButtonType.Ternary}
                                tabIndex={-1}
                                label={numberToHide < sortedScopeItems.length ? `More...` : "Show"}
                                labelProps={labelProps}
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    this.props.onShowMoreClicked();
                                }}
                                disabled={false}
                            />
                        </MeasureWidthOutOfFlow>
                    )}
                    {this.props.inconsistencies.length > 0 && (
                        <ToolTip style={{ marginLeft: "0.2em" }} content={head(this.props.inconsistencies)!.message}>
                            <WarningIcon />
                        </ToolTip>
                    )}
                </div>
            </VariableCell>
        );
    }

    private renderChips = (numberToRender: number, scopeItems: ScopeItem[]) => {
        const allChips = scopeItems.map(s => {
            return (
                <MeasureWidthOutOfFlow
                    key={getIdForScopeItem(s)}
                    onMeasured={width =>
                        this.setState(prev => {
                            return { widthsForScopes: { ...prev.widthsForScopes, [getIdForScopeItem(s)]: width } };
                        })
                    }
                >
                    {renderScopeItem(s, this.props.availableScopes, this.props.tagIndex)}
                </MeasureWidthOutOfFlow>
            );
        });
        return take(allChips, numberToRender);
    };
}

function getCumulativeWidths(scope: ScopeItem[], widthsForScopes: { [scopeId: string]: number | undefined }): Array<number | null> {
    return scope.reduce((p, c, i) => {
        const cumulativeWidth = i === 0 ? 0 : last(p)!;
        const currentId = getIdForScopeItem(c);
        const currentWidth = widthsForScopes[currentId];
        const newCumulativeWidth = currentWidth === undefined ? null : cumulativeWidth + currentWidth;
        return [...p, newCumulativeWidth];
    }, []);
}

function calculateNumberOfElementsToShow(widths: ReadonlyArray<number | null>, availableWidth: number) {
    const cw = widths.reduce((p, c, i) => {
        const cumulativeWidth = i === 0 ? 0 : last(p)!;
        const newWidth = c === null ? cumulativeWidth : c;
        return [...p, newWidth];
    }, []);
    return cw.filter(w => w < availableWidth).length;
}

interface VariableScopeProps {
    scope: ScopeSpecification;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    emptyContent?: JSX.Element;
    // Give the popover a min height the same as the cell height if don't have many chips.
    // I couldn't work out a a better way of doing this in CSS, but a solution might exist
    // Either way, this isn't too bad since our tables need to have a height specified in code anyway
    minHeight: number;
    isFocused: boolean;
    showClickIndicator: boolean;
    containerWidth: number;
    onFocus(): void;
    onBlur(): void;
    onClick?(): void;
}

export default class VariableScope extends React.Component<VariableScopeProps> {
    private readonly onClickOutside: () => void;
    constructor(props: VariableScopeProps) {
        super(props);
        this.onClickOutside = () => {
            if (this.props.onBlur) {
                this.props.onBlur();
            }
        };
    }

    render() {
        const renderedScopeChips = getSortedScopeItems(this.props.scope, this.props.availableScopes).map(s => renderScopeItem(s, this.props.availableScopes, this.props.tagIndex));
        const lookup = createAvailableScopeLookup(this.props.availableScopes);
        const inconsistencies = checkScopeConsistency(getAllScopeConsistencyRules(), () => lookup, this.props.scope);

        return (
            <PopoverWhenFocused isFocused={this.props.isFocused} onClickOutside={this.props.isFocused ? this.onClickOutside : undefined} position={{ top: 0, left: 0, right: 0 }}>
                <div
                    className={`${styles.clickContainer} ${this.clickableClassName()}`}
                    onClick={() => {
                        if (this.props.onClick) {
                            this.props.onClick();
                        }
                    }}
                >
                    {this.props.isFocused ? (
                        <VariableCell style={{ minHeight: this.props.minHeight }}>
                            <div className={styles.allChipsContainer}>
                                {renderedScopeChips}
                                {!!renderedScopeChips.length && (
                                    <ActionButton
                                        type={ActionButtonType.Ternary}
                                        tabIndex={-1}
                                        label="Show Summary"
                                        labelProps={labelProps}
                                        onClick={e => {
                                            e.stopPropagation();
                                            this.props.onBlur();
                                        }}
                                    />
                                )}
                                {inconsistencies.length > 0 && (
                                    <ToolTip style={{ marginLeft: "0.2em" }} content={head(inconsistencies)!.message}>
                                        <WarningIcon />
                                    </ToolTip>
                                )}
                            </div>
                            {this.props.showClickIndicator && (
                                <div>
                                    <ReadonlyText text={"Click or press enter to define scope"} className={styles.defineScope} monoSpacedFont={true} />
                                </div>
                            )}
                        </VariableCell>
                    ) : (
                        <ResizingScopeSummary {...this.props} inconsistencies={inconsistencies} scopeLookup={lookup} onShowMoreClicked={() => this.props.onFocus()} />
                    )}
                </div>
            </PopoverWhenFocused>
        );
    }

    private clickableClassName() {
        return this.props.onClick ? styles.clickable : "";
    }
}

function getIdForScopeItem(scopeItem: ScopeItem) {
    return `${scopeItem.type}-${scopeItem.id}-${scopeItem.name}`;
}

function getScopeItems(scope: ScopeSpecification, availableScopes: ScopeValues): ScopeItem[] {
    return [
        ...(scope.Environment || []).map(id => createScopeItem(id!, ScopeType.Environment, availableScopes.Environments)),
        ...(scope.Role || []).map(id => createScopeItem(id!, ScopeType.Role, availableScopes.Roles)),
        ...(scope.Machine || []).map(id => createScopeItem(id!, ScopeType.Machine, availableScopes.Machines)),
        ...(scope.Action || []).map(id => createScopeItem(id!, ScopeType.Action, availableScopes.Actions)),
        ...(scope.Channel || []).map(id => createScopeItem(id!, ScopeType.Channel, availableScopes.Channels)),
        ...(scope.TenantTag || []).map(id => createScopeItem(id!, ScopeType.TenantTag, availableScopes.TenantTags)),
        ...(scope.ProcessOwner || []).map(id => createScopeItem(id!, ScopeType.ProcessOwner, availableScopes.Processes)),
    ];

    function createScopeItem(id: string, type: ScopeType, availableItems: ReferenceDataItem[]) {
        return { type, id, name: findName(id, availableItems) };
    }

    function findName(id: string, availableItems: ReferenceDataItem[]) {
        const item = availableItems.find(i => i.Id === id);
        return item ? item.Name : null;
    }
}

function getSortedScopeItems(scope: ScopeSpecification, availableScopes: ScopeValues): ScopeItem[] {
    return getScopeItems(scope, availableScopes).sort(compareScopeItems);
}

function renderScopeItem(scope: ScopeItem, availableScopes: ScopeValues, tagIndex: TagIndex): React.ReactNode {
    return createChip(scope);

    function createChip(item: ScopeItem) {
        switch (item.type) {
            case ScopeType.Environment:
                return createEnvironmentChip(item.id);
            case ScopeType.Role:
                return createRoleChip(item.id);
            case ScopeType.Machine:
                return createMachineChip(item.id);
            case ScopeType.Action:
                return createStepChip(item.id);
            case ScopeType.Channel:
                return createChannelChip(item.id);
            case ScopeType.TenantTag:
                return createTenantTagChip(item.id);
            case ScopeType.ProcessOwner:
                return createProcessChip(item.id);
            default:
                throw new Error("Cannot render unknown scope type");
        }
    }

    function createProcessChip(processId: string) {
        if (availableScopes.Processes.length === 0) {
            return null;
        }

        const match = availableScopes.Processes.filter(x => x.Id === processId).pop();
        return <LookupReferenceDataItemChip lookupCollection={availableScopes.Processes} key={processId} lookupId={processId} chipRender={item => <ProcessChip processType={match && match.ProcessType} tabIndex={-1} name={item.Name} />} />;
    }

    function createEnvironmentChip(environmentId: string) {
        if (availableScopes.Environments.length === 0) {
            return <span key={environmentId} />;
        }

        return (
            <LookupReferenceDataItemChip lookupCollection={availableScopes.Environments} key={environmentId} lookupId={environmentId} type={ChipIcon.Environment} chipRender={item => <EnvironmentChip tabIndex={-1} environmentName={item.Name} />} />
        );
    }

    function createMachineChip(machineId: string) {
        if (availableScopes.Machines.length === 0) {
            return <span key={machineId} />;
        }

        return <LookupReferenceDataItemChip lookupCollection={availableScopes.Machines} key={machineId} lookupId={machineId} type={ChipIcon.Machine} chipRender={item => <MachineChip tabIndex={-1} machineName={item.Name} />} />;
    }

    function createChannelChip(channelId: string) {
        if (availableScopes.Channels.length === 0) {
            return <span key={channelId} />;
        }

        return <LookupReferenceDataItemChip lookupCollection={availableScopes.Channels} key={channelId} lookupId={channelId} type={ChipIcon.Machine} chipRender={item => <ChannelChip tabIndex={-1} channelName={item.Name} />} />;
    }

    function createRoleChip(roleId: string) {
        if (availableScopes.Roles.length === 0) {
            return <RoleChip tabIndex={-1} role={roleId} />;
        }

        return <LookupReferenceDataItemChip lookupCollection={availableScopes.Roles} key={roleId} lookupId={roleId} type={ChipIcon.Role} allowMissingItem={true} chipRender={item => <RoleChip tabIndex={-1} role={item.Name} />} />;
    }

    function createTenantTagChip(tenantTagId: string) {
        if (availableScopes.TenantTags.length === 0) {
            return <span key={tenantTagId} />;
        }

        const tag = tagIndex[tenantTagId];
        const color = tag ? tag.Color : DEFAULT_COLOR;
        const description = tag ? tag.Description : "";
        return (
            <LookupReferenceDataItemChip
                lookupCollection={availableScopes.TenantTags}
                key={tenantTagId}
                lookupId={tenantTagId}
                type={ChipIcon.Tenant}
                chipRender={item => <Tag tabIndex={-1} description={description} tagName={tag ? tag.Name : item.Name} tagColor={color} />}
            />
        );
    }

    function createStepChip(actionId: string) {
        if (availableScopes.Actions.length === 0) {
            return <span key={actionId} />;
        }

        return <LookupReferenceDataItemChip lookupCollection={availableScopes.Actions} key={actionId} lookupId={actionId} type={ChipIcon.Step} chipRender={item => <StepChip tabIndex={-1} stepName={item.Name} />} />;
    }
}

interface FocusManagedVariableScopeProps {
    scope: ScopeSpecification;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    emptyContent?: JSX.Element;
    showClickIndicator: boolean;
    minHeight: number;
    containerWidth: number;
}

interface FocusManagedVariableScopeState {
    isFocused: boolean;
}

export class FocusManagedVariableScope extends React.Component<FocusManagedVariableScopeProps, FocusManagedVariableScopeState> {
    constructor(props: FocusManagedVariableScopeProps) {
        super(props);
        this.state = { isFocused: false };
    }

    render() {
        return (
            <div className={styles.focusManagedWrapper}>
                <VariableScope {...this.props} isFocused={this.state.isFocused} onFocus={() => this.setState({ isFocused: true })} onBlur={() => this.setState({ isFocused: false })} />
            </div>
        );
    }
}
