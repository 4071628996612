/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import DateFormatter from "utils/DateFormatter";
import BaseComponent from "components/BaseComponent";
import { NavigationButton } from "components/Button";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ListTitle from "components/ListTitle/ListTitle";
const styles = require("./style.less");
import { ChannelChip } from "../../../../components/Chips/index";
import { ReleaseResource } from "../../../../client/resources/releaseResource";
import { ChannelResource } from "../../../../client/resources/channelResource";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { keyBy } from "lodash";
import { ResourceCollection } from "../../../../client/resources/resourceCollection";
import { PagingDataTable } from "../../../../components/PagingDataTable/PagingDataTable";
import { releaseVersionClass } from "uiTestClasses";
import { Select } from "components/form";
import Note from "../../../../components/form/Note/Note";
import { buildPartialReleaseNotes } from "./releaseNoteHelper";
import Markdown from "../../../../components/Markdown/Markdown";
import ActionButton, { ActionButtonType } from "../../../../components/Button/ActionButton";
import cn from "classnames";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";

interface ReleasesTableState {
    selectedChannel: string;
    versionFilter: string;
    channelsIndex: { [channelId: string]: ChannelResource };
    showFullReleaseNotes: { [version: string]: boolean };
}

interface ReleasesTableProps extends RouteComponentProps<ProjectRouteParams> {
    releases: ResourceCollection<ReleaseResource>;
    channels: ChannelResource[];
    onChannelFilterChange(selectedChannel: string): Promise<void>;
    onVersionFilterChange(versionFilter: string): Promise<void>;
}
class PagingReleasesTable extends PagingDataTable<ReleaseResource> {}

export default class ReleasesTable extends BaseComponent<ReleasesTableProps, ReleasesTableState> {
    constructor(props: ReleasesTableProps) {
        super(props);
        this.state = {
            channelsIndex: keyBy(this.props.channels, c => c.Id),
            selectedChannel: null!,
            versionFilter: null!,
            showFullReleaseNotes: {},
        };
    }

    componentWillReceiveProps(nextProps: ReleasesTableProps) {
        if (nextProps.channels !== this.props.channels) {
            this.setState({
                channelsIndex: keyBy(nextProps.channels, c => c.Id),
            });
        }
    }

    render() {
        return (
            <div className={styles.releasesTableContainer}>
                <div className={styles.filter}>{this.handleOnToolsSection()}</div>
                <PagingReleasesTable
                    initialData={this.props.releases}
                    onRow={(r: ReleaseResource) => this.handleOnRow(r)}
                    onRowRedirectUrl={(r: ReleaseResource) => `${this.props.match.url}/${r.Version}`}
                    onEmpty={this.handleOnEmpty}
                    headerColumns={["Release", "Channel", "Release Notes"]}
                    headerColumnClassNames={["", "", styles.notesColumn]}
                />
            </div>
        );
    }

    private handleFilterByVersion = async (value: string) => {
        this.setState({ versionFilter: value });
        await this.props.onVersionFilterChange(value);
    };

    private handleFilterByChannel = async (value: string) => {
        this.setState({ selectedChannel: value });
        await this.props.onChannelFilterChange(value);
    };

    private handleOnEmpty = () => {
        const hasSelectedChannelAndMultiChannels = this.props.channels.length > 1 && this.state.selectedChannel;
        return (
            <div className={styles.container}>
                <div className={styles.noReleases}>There are no releases{this.props.channels.length > 1 && this.state.selectedChannel ? " in the selected channel" : ""}.</div>
                <NavigationButton
                    label={hasSelectedChannelAndMultiChannels ? "Create release in this channel" : "Create release"}
                    href={hasSelectedChannelAndMultiChannels ? `${this.props.match.url}/create?channelId=${this.state.selectedChannel}` : `${this.props.match.url}/create`}
                />
            </div>
        );
    };

    private handleOnToolsSection = () => {
        const baseElements: JSX.Element[] = [];

        const versionFilter = <FilterSearchBox key="versionFilter" placeholder={"Search versions..."} value={this.state.versionFilter} onChange={this.handleFilterByVersion} autoFocus={true} />;

        const channelFilter =
            this.props.channels.length === 1 ? null : (
                <Select
                    key="channelFilter"
                    label="Filter by channel"
                    placeholder="Showing all channels"
                    items={this.props.channels.map(c => ({ value: c.Id, text: c.Name }))}
                    value={this.state.selectedChannel}
                    allowClear={true}
                    onChange={this.handleFilterByChannel}
                />
            );

        if (channelFilter) {
            baseElements.push(channelFilter);
        }
        baseElements.push(versionFilter);

        return baseElements;
    };

    private handleOnRow = (release: ReleaseResource) => {
        const channel = this.state.channelsIndex[release.ChannelId];
        const [releaseNotes, truncated] = buildPartialReleaseNotes(release.ReleaseNotes, 3);
        return [
            // mark.siedle: We want this InternalLink here so users have the option of standard
            // anchor-behaviour (ie. right click) that you don't get with the onClick from our SimpleDataTable component.
            <InternalLink to={{ pathname: `${this.props.match.url}/${release.Version}`, state: release }} size={1.125}>
                <ListTitle>
                    <span className={cn(releaseVersionClass, styles.releaseVersionLink)}>{release.Version}</span>
                </ListTitle>
                <Note>
                    <div title="Assembled date">{DateFormatter.dateToLongFormat(release.Assembled)}</div>
                </Note>
            </InternalLink>,
            <div className={styles.marginTopBottom}>{channel && <ChannelChip channelName={channel.Name} fullWidth={true} />}</div>,
            <div className={styles.releaseNotesContainer} onClick={this.onReleaseNoteColumnClick}>
                <Markdown markup={this.state.showFullReleaseNotes[release.Version] ? release.ReleaseNotes : releaseNotes} />
                {truncated && (
                    <div className={styles.collapseButton}>
                        <ActionButton type={ActionButtonType.Ternary} label={this.state.showFullReleaseNotes[release.Version] ? "Show less" : "Show more"} onClick={() => this.onToggleReleaseNotes(release.Version)} />
                    </div>
                )}
            </div>,
        ];
    };

    private onToggleReleaseNotes = (version: string) => {
        const showFullReleaseNotes = { ...this.state.showFullReleaseNotes };
        showFullReleaseNotes[version] = !this.state.showFullReleaseNotes[version];
        this.setState({ showFullReleaseNotes });
    };

    private onReleaseNoteColumnClick = (event: any) => {
        // Markup might have links, we don't want to trigger the default redirect behaviour to onRowRedirectUrl
        event.stopPropagation();
    };
}
