import React, { useState } from "react";
import { BooleanRadioButtonGroup, RadioButton } from "components/form";
import MaxParallelism from "./MaxParallelism";
import ActionProperties from "client/resources/actionProperties";

interface MaxParallelismOptionsProps {
    projectId: string;
    maxParallelism: string;
    setStepProperties(properties: Partial<ActionProperties>): void;
}

export const MaxParallelismOptions: React.FC<MaxParallelismOptionsProps> = ({ projectId, maxParallelism, setStepProperties }) => {
    const showWindowSize = React.useMemo(() => !!maxParallelism && maxParallelism.length > 0, [maxParallelism]);
    return (
        <>
            <BooleanRadioButtonGroup
                onChange={v => {
                    setStepProperties({ ["Octopus.Action.MaxParallelism"]: v ? "1" : "" });
                }}
                value={showWindowSize}
            >
                <RadioButton value={false} label="Deploy to all deployment targets in parallel." />
                <RadioButton value={true} label="Configure a rolling deployment" />
            </BooleanRadioButtonGroup>
            {showWindowSize && <MaxParallelism projectId={projectId} value={maxParallelism} onChange={x => setStepProperties({ ["Octopus.Action.MaxParallelism"]: x })} />}
        </>
    );
};
