import * as React from "react";
import { buildServerPackagingInstruction } from "../../Instructions";
import { CommonBuildServerPackagingTextForOctopackJs, CommonBuildServerPackagingTextForIntegration, CommonBuildServerPackagingTextForOctoExe } from "../CommonRegistrationText";
import ExternalLink from "../../../../../../../components/Navigation/ExternalLink";

export const BuildServerPackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Node.js applications from build servers, we recommend using <i>Octopack.js</i>. Alternatively you can use <code>Octo.exe pack</code> on the output directory..
        </p>
        {CommonBuildServerPackagingTextForOctopackJs}
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
        <p>
            The <ExternalLink href="GuidesNodejs">Node.js guides</ExternalLink> provide step by step instructions for deploying Node.js applications.
        </p>
    </div>
);

export default buildServerPackagingInstruction(() => <BuildServerPackaging />);
