import * as React from "react";
import { Switch, match as Match, RouteComponentProps } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { Channels } from "./Channels";
import { Edit } from "./Edit";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";

export const EditChannelPage = withPage({ page: pageIds.project().channel })(Edit);
export const CreateChannelPage = withPage({ page: pageIds.project().channelNew })(Edit);
export const ChannelsPage = withPage({ page: pageIds.project().channel })(Channels);

export class ChannelsRoute extends React.Component<{ match: Match }> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={`${match.path}/edit/:channelId`} render={(routeProps: RouteComponentProps<{ channelId: string }>) => <EditChannelPage create={false} channelId={routeProps.match.params.channelId} />} />
                <ReloadableRoute path={`${match.path}/create`} render={() => <CreateChannelPage create={true} />} />
                <ReloadableRoute path={`${match.path}`} component={ChannelsPage} />
            </Switch>
        );
    }
}
