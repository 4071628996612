import * as React from "react";
import { SFC } from "react";
import CodeEditor, { TextFormat } from "components/CodeEditor/CodeEditor";
import { ActionTemplateResource } from "client/resources/actionTemplateResource";
import { repository } from "clientInstance";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import Note from "components/form/Note/Note";
import { noOp } from "utils/noOp";
import { v4 as uuidv4 } from "uuid";
const styles = require("./style.less");

interface ExportActionTemplateDialogProps {
    template: Partial<ActionTemplateResource>;
}

function toJson(template: Partial<ActionTemplateResource>) {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cloned: any = { ...template };
    delete cloned.Links;

    cloned.$Meta = {
        ExportedAt: new Date(),
        OctopusVersion: repository.getServerInformation().version,
        Type: "ActionTemplate",
    };

    /*
        These fields are used when exporting a step to the community library. When importing back into Octopus, these fields are
        replaced or ignored anyway.
     */
    cloned.Id = uuidv4();
    cloned.Version = 1;
    cloned.LastModifiedBy = "Your GitHub Username";
    cloned.SpaceId = undefined;
    cloned.Category = "other";

    return JSON.stringify(cloned, null, 2);
}

const ExportActionTemplateDialog: SFC<ExportActionTemplateDialogProps> = props => (
    <InfoDialogLayout title="Export" errors={null} busy={null}>
        <CodeEditor value={toJson(props.template)} language={TextFormat.JSON} onChange={noOp} />
        <div className={styles.exportNote}>
            <Note>Copy the entire contents of the editor to share this template with other Octopus Deploy servers.</Note>
        </div>
    </InfoDialogLayout>
);

ExportActionTemplateDialog.displayName = "ExportActionTemplateDialog";

export default ExportActionTemplateDialog;
