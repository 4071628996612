/* eslint-disable @typescript-eslint/no-explicit-any */

import BasicRepository from "./basicRepository";
import Client from "../client";
import { ProxyResource } from "client/resources";

class ProxyRepository extends BasicRepository<ProxyResource, any> {
    constructor(client: Client) {
        super("Proxies", client);
    }
}

export default ProxyRepository;
