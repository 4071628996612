import * as React from "react";
import { LogsBase } from "areas/configuration/components/Diagnostics/Logs/LogsBase";

export class SubscriptionLogs extends LogsBase {
    constructor(props: {}) {
        super("ProcessSubscriptions", "Subscription Logs", props);
    }
}

export default SubscriptionLogs;
