import * as React from "react";
import { EventCategoryResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { EventCategoryChip } from "components/Chips/index";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";

interface EventCategoryMultiSelectProps extends FormFieldProps<string[]> {
    items: EventCategoryResource[];
    label?: string | JSX.Element;
    error?: string;
}

const EventCategoryTypedMultiSelect = MultiSelect<EventCategoryResource>();
const LookupEventCategoryChip = LookupResourceChipComponent<EventCategoryResource>();

const EventCategoryMultiSelect: React.StatelessComponent<EventCategoryMultiSelectProps> = props => {
    const chipRenderer = (r: EventCategoryResource, onRequestDelete: (event: object) => void) => {
        return (
            <LookupEventCategoryChip
                lookupCollection={props.items}
                lookupId={r.Id}
                type={ChipIcon.EventCategory}
                chipRender={item => <EventCategoryChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} eventCategory={item} />}
            />
        );
    };

    return <EventCategoryTypedMultiSelect items={props.items} fieldName="event categories" renderChip={chipRenderer} {...props} />;
};

export default EventCategoryMultiSelect;
