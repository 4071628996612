/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { EmptyPage, ImageHeight } from "../EmptyPage/EmptyPage";
import { session } from "clientInstance";
import { Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import styles = require("./style.less");
import { NoPermissionsPage as NoPermissionsPageImage } from "../Images/Images/NoPermissionsPage/NoPermissionsPage";

export default class NoPermissionsPage extends React.Component {
    render() {
        return (
            <main id="maincontent">
                <EmptyPage
                    title={`Welcome ${session.currentUser!.DisplayName}! Looks like you don't have permissions yet.`}
                    description="To get you onboard, please contact your Octopus administrator to add you to the appropriate team(s)."
                    image={<NoPermissionsPageImage height={ImageHeight} />}
                />
                <Note>
                    <div className={styles.note}>
                        <b>New to Octopus?</b> While you are waiting to be added to a team, have a look at our <ExternalLink href="GettingStarted">Getting Started Guide</ExternalLink>.
                    </div>
                </Note>
            </main>
        );
    }
}
