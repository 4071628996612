import * as React from "react";
import { NoResults as NoResultsImage } from "../Images/Images/NoResults/NoResults";

const styles = require("./NoResults.less");
export class NoResults extends React.PureComponent<{}> {
    render() {
        return (
            <div className={styles.container}>
                <NoResultsImage />
            </div>
        );
    }
}
