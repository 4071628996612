/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { FeedType } from "client/resources/feedResource";
import { DoBusyTask } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import { DockerFeedResource, default as FeedResource } from "../../../../../client/resources/feedResource";
import { ReactNode } from "react";

interface FeedTypeRegistration<T extends FeedResource = FeedResource> {
    type: FeedType;
    edit: React.ComponentType<FeedEditProps<T>>;
    text: string;
    uriNotes?: string | JSX.Element;
    uriDefault?: string;
    hasUri?: boolean;
}

interface FeedEditProps<T extends FeedResource> {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | boolean;
    feed: T;
    onChange(newValue: T): void;
    getFieldError(field: string): string;
}

export { FeedTypeRegistration, FeedEditProps };

class FeedTypeRegistry {
    private registeredFeedTypes: { [feedType: string]: FeedTypeRegistration } = {};

    registerFeedType(registration: FeedTypeRegistration) {
        if (registration.hasUri === undefined) {
            registration.hasUri = true;
        }
        this.registeredFeedTypes[registration.type] = registration;
    }

    getRegistration(feedType: FeedType): FeedTypeRegistration {
        return this.registeredFeedTypes[feedType];
    }

    getAllTypes(): FeedType[] {
        return Object.keys(this.registeredFeedTypes) as FeedType[];
    }
}

const feedTypeRegistry = new FeedTypeRegistry();

export default feedTypeRegistry;
