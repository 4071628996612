/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { Route } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";
import { RouteProps } from "react-router";
import { PageLayoutState } from "components/PageLayout/reducers/pageLayoutArea";

interface GlobalDispatchProps {
    onPageLayoutStateUpdated?(pageLayoutState: PageLayoutState): void;
}

type ReloadableRouteProps = RouteProps &
    GlobalDispatchProps & {
        computedMatch?: { params: Record<string, string> };
        keyComponents?: string[];
    };

export default class ReloadableRoute extends React.Component<ReloadableRouteProps, any> {
    render() {
        // We are tagging the parent route of each component with a key made out of params it depends
        // on so redirects to the same component with different params unmount it and mount it again.
        // This can also be done by responding to changes in props but this is a lot of work to do it
        // correctly and it is very easy to forget about it.
        // Details: https://github.com/ReactTraining/react-router/issues/1982
        const key = this.getComponentKey();
        return (
            <ErrorBoundary key={key}>
                <Route {...this.props} />
            </ErrorBoundary>
        );
    }

    private getComponentKey() {
        const match = this.props.computedMatch;
        if (match && match.params) {
            const keyValues = this.props.keyComponents ? this.props.keyComponents.map(component => match.params[component]) : Object.values(match.params);
            return keyValues.filter(x => x).join("/");
        }

        return undefined;
    }
}
