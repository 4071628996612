import * as React from "react";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import callAll from "utils/callAll";
import cn from "classnames";
import { overflowMenuClass } from "uiTestClasses";
import IconButton from "components/IconButton";
import { Icon, IconButtonProps } from "components/IconButton/IconButton";

const styles = require("./IconMenu.less");

interface ButtonProps extends React.AriaAttributes {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface IconMenuRenderProps {
    open: boolean;
    getButtonProps: (props?: Partial<ButtonProps>) => ButtonProps;
    getMenuProps: (props?: Partial<MenuProps>) => MenuProps;
}

interface IconMenuProps {
    open: boolean;
    children: (renderProps: IconMenuRenderProps) => React.ReactElement | null;
}

export const IconMenu: React.FC<IconMenuProps> = ({ children, open, ...props }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose: MenuProps["onClose"] = () => {
        /*We don't want to remove the anchor element straihgt away, we want
          to do this during the next repaint so that we can give other side
          effects a change to propagate. This should avoid passing a null
          element to material for example which will complain about it.
        */
        requestAnimationFrame(() => setAnchorEl(null));
    };

    const handleBackdropClick: React.MouseEventHandler = e => {
        e.stopPropagation();
    };

    return children({
        open,
        getButtonProps: buttonRenderProps => {
            const { onClick, ...buttonProps } = buttonRenderProps ?? {};
            return {
                onClick: callAll(handleClick, onClick),
                "aria-haspopup": "true",
                "aria-label": "more",
                ...buttonProps,
            };
        },
        getMenuProps: renderMenuProps => {
            const { onClose, onBackdropClick, ...menuProps } = renderMenuProps ?? {};
            return {
                open: open && !!anchorEl,
                ...(anchorEl ? { anchorEl } : {}),
                onClose: callAll(onClose, handleClose),
                onBackdropClick: callAll(handleBackdropClick, e => onClose && onClose(e, "backdropClick")),
                transformOrigin: { horizontal: "right", vertical: "top" },
                anchorOrigin: { horizontal: "right", vertical: "bottom" },
                getContentAnchorEl: null,
                ...menuProps,
            };
        },
    });
};

export const OverflowButton: React.FC<Omit<IconButtonProps, "icon">> = ({ className, ...props }) => {
    return <IconButton className={cn(styles.overflowButton, overflowMenuClass, className)} icon={Icon.OverflowMenu} {...props} />;
};

interface OverflowMenuButton {
    open: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClose: () => void;
}

export default IconMenu;

export const OverflowMenuButton: React.FC<OverflowMenuButton> = ({ children, ...rest }) => {
    return (
        <IconMenu {...rest}>
            {({ getButtonProps, getMenuProps, open }) => {
                return (
                    <>
                        <OverflowButton {...getButtonProps({ onClick: callAll(rest.onClick, e => e.stopPropagation()) })} />
                        <Menu {...getMenuProps({ open })}>{children}</Menu>
                    </>
                );
            }}
        </IconMenu>
    );
};
